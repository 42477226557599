import axios from "axios";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Validation from "./Validation";
import classes from "./LoginPage.module.css";
import logoImage from "../../../assets/LOGO.png";
import { Content } from "antd/es/layout/layout";

function Admin(props) {
  // console.log(props);
  const [duplicateEmailError, setDuplicateEmailError] = useState(false);
  const [type, setType] = useState("admin");
  const [values, setValues] = useState({
    email: "",
    password: "",
    userType: type,
  });
  const [email, setUserCode] = useState("");
  const [password, setPassword] = useState("");

  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const [backendError, setBackendError] = useState([]);
  const [showPassword, setShowPassword] = useState(false);

  const handleInput = (event) => {
    setValues((prev) => ({ ...prev, [event.target.name]: event.target.value }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(values);
    console.log(props.url);
    const err = Validation(values);
    setErrors(err);
    if (err.email === "" && err.password === "") {
      axios
        .post(props.url + "/api/v1/type/login", values, {
          withCredentials: true,
          credentials: "include",
        })
        .then((res) => {
          console.log(res);
          if (res.data.errors) {
            console.log("in the .then  in the if");

            setBackendError(res.data.errors);
          } else {
            setBackendError([]);
            console.log(type, "type");

            if (res.statusText === "OK") {
              navigate("/admin/dashboard");
            } else {
              alert("No record existed");
            }
          }
        })
        .catch((err) => {
          if (err) {
            // alert("Wrong Email or Password")
            setDuplicateEmailError(true);
          }
          // console.log(err)
          console.log("hey there it is the error im printinfg out" + err);
        });
    }
  };

  const imageStyle = {
    // position:'absolute',

    marginTop: "20px",
    marginLeft: "20px",
    backgroundImage: `url(${logoImage})`,
    backgroundPosition: "top left",
    backgroundRepeat: "no-repeat",
    backgroundSize: "200px",
  };

  const style = {
    width: "100vw",
  };

  return (
    <div className={classes["container"]} style={style}>
      <div className={classes["form-container"]}>
        <div className={classes["logo"]} style={imageStyle}></div>
        <h2
          style={{
            color: "#8A31A8",
            // textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)',
            // transform: 'rotateX(25deg) translateZ(20px)',
            marginTop: "30px",
          }}
        >
          HI THERE
        </h2>
        <h2
          style={{
            fontSize: "17px",
            marginTop: "-1rem",
            color: "rgb(162 104 182)",
            // textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)',
            // transform: 'rotateX(20deg) translateZ(20px)'
          }}
        >
          Welcome back!
        </h2>

        {backendError ? (
          backendError.map((e) => <p className="text-danger">{e.msg}</p>)
        ) : (
          <span></span>
        )}
        <form action="" onSubmit={handleSubmit}>
          <div
            className="mb-3"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <label htmlFor="email">{/* <strong>Email</strong> */}</label>
            <input
              type="email"
              placeholder="Enter Email"
              name="email"
              onChange={handleInput}
              className="form-control rounded-0"
              style={{
                fontSize: "20px",
                width: "337px",
                height: "91px",
                backgroundImage: "linear-gradient(to bottom, #BEA1C6, white)",
                transform: "perspective(500px) rotateX(3deg)",
                borderBottom: "3px solid #ededed",
                marginTop: "0.5rem",
                textAlign: "center", // Set the placeholder text alignment to center
              }}
            />
          </div>
          {errors.email && <span className="text-danger">{errors.email}</span>}

          <div
            className="mb-3"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                backgroundImage: "linear-gradient(to bottom, #BEA1C6, white)",
              }}
            >
              <input
                type={showPassword ? "text" : "password"}
                placeholder="Enter Password"
                name="password"
                onChange={handleInput}
                className="form-control rounded-0 custom-input"
                style={{
                  fontSize: "20px",
                  width: "337px",
                  height: "91px",
                  backgroundImage: "linear-gradient(to bottom, #BEA1C6, white)",
                  transform: "perspective(500px) rotateX(1deg)",
                  borderBottom: "3px solid #ededed",
                  marginTop: "15px",
                  textAlign: "center",
                  borderTop: "pink",
                  borderBottom: "2px solid white",
                }}
              />
              <button
                type="button"
                onClick={() => setShowPassword(!showPassword)}
                style={{
                  transform: "translateY(-50%)",
                  marginTop: "3rem",

                  background: "transparent",
                  cursor: "pointer",
                  // fontSize: "20px",
                  userSelect: "none",
                  // border: "2px solid red",
                }}
              >
                {showPassword ? "hide" : "show"}
              </button>
            </div>
          </div>
          {errors.password && (
            <span className="text-danger">{errors.password}</span>
          )}
          <div className={classes["remember-forgot"]}>
            <Link
              className={classes["thisilink"]}
              to="/forget"
              style={{ textDecoration: "none", marginTop: "1rem" }}
            >
              <span style={{ color: "rgb(162 104 182)", fontWeight: "bold" }}>
                {/* Forgot Password */}
              </span>
            </Link>
          </div>

          <button
            type="submit"
            // className='btn btn-success w-100 custom-btn'

            className={classes["custom-btn"]}
          >
            Log in
          </button>

          <br />

          {duplicateEmailError && (
            <span className="text-danger">
              {" "}
              <br /> Email or Password Incorrect.
            </span>
          )}

          <div className={classes["remember-font"]}>
            <p>
              {/* Dosen’t have an account?{" "} */}
              <Link to="/sigup" style={{ textDecoration: "none" }}>
                {" "}
                <span
                  style={{
                    color: "#AF71C3",
                  }}
                >
                  {" "}
                  {/* Sign up{" "} */}
                </span>{" "}
              </Link>
            </p>
          </div>
          {/* <p style={{ fontSize: "15px", color: "gray" }}>or login using</p> */}

          {/* <p>You agree to our terms and policies</p> */}
          {/* <Link
        to='/signup'
        className='btn btn-default border w-100 bg-light rounded-0 text-decoration-none'
      >
        Create Account
      </Link> */}
        </form>
      </div>
    </div>
  );
}

export default Admin;
