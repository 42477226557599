
import React from "react";

import { useState } from "react";
import "./payment.css";

import axios from "axios";
import DataContext from "../../../Context/dataContext";
import { useEffect, useContext } from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

import { PaypalPayment } from "./PaypalPayment";

export default function Payment(props) {
  console.log(props);

  const employeeData = useContext(DataContext);
  const [loadsWithAmount, setLoadsWithAmount] = useState([]);
  const [selectedLoadId, setSelectedLoadId] = useState("");
  const [editedAmount, setEditedAmount] = useState("");
  const [yele, setYele] = useState(false);

  const handleAmountEdit = (e) => {
    // Remove non-numeric characters using a regular expression
    const sanitizedValue = e.target.value.replace(/[^0-9]/g, "");
    setEditedAmount(sanitizedValue);
  };

  const paythis = () => {
    console.log("hey ther e it is working");
    // setEditedAmount()
    setYele(true);
  };

  useEffect(() => {
    axios
      .get(employeeData.URL + "/api/v1/admin-amount/getPaypal", {
        withCredentials: true,
      })
      .then((response) => {
        console.log(response);
        // Assuming response.data.data.loadswithamount.rows is an array
        const rows = response.data.data.loadswithamount.rows;
        setLoadsWithAmount(rows);

        if (rows.length > 0) {
          // Select the first load_id in the dropdown
          setSelectedLoadId(rows[0].load_id);
        }
      })

      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleSelectChange = (e) => {
    setSelectedLoadId(e.target.value);
  };
  const selectedLoad = loadsWithAmount.find(
    (item) => item.load_id === selectedLoadId
  );
  console.log(selectedLoad);
  return (
    <>
      <h1 style={{ color: "#F2E0F8", fontWeight: "bold" }}>Payment</h1>
      <div style={{ textAlign: "end" }}>
        <select
          style={{
            marginTop: "2rem",
            width: "20rem",
            height: "3.5rem",
            textAlign: "center",
            border: "1px solid #ddd",
            backgroundColor: "#F2E0F8",
            fontFamily: "Arial",
            fontSize: "1.5rem",
            boxShadow: "-2px 4px 6px rgba(0, 0, 0, 0.3)",
          }}
          value={selectedLoadId} // Set the selected value based on state
          onChange={handleSelectChange}
        >
          <option value="">Select Load ID</option>
          {loadsWithAmount.map((item) => (
            <option key={item.load_id} value={item.load_id}>
              {`Load ID: ${item.load_id}`}
            </option>
          ))}
        </select>
      </div>

      <div style={{ textAlign: "start" }}>
        {selectedLoad ? (
          <>
            {/* Show the button with the initial value of selectedLoad.load_amount */}
            <button
              onClick={() => {
                // Handle the button click event here
              }}
              style={{
                width: "20rem",
                height: "3rem",
                border: "1px solid #ddd",
                backgroundColor: "#531967",
                fontFamily: "Arial",
                fontSize: "1.5rem",
                color: "white",
                marginRight: "1rem",
                boxShadow: "-2px 4px 6px rgba(0, 0, 0, 0.3)",
              }}
            >
              Amount to Pay ${selectedLoad.load_amount}
            </button>
            <input
              placeholder={`Enter Amount to Pay Now`}
              value={editedAmount}
              onChange={handleAmountEdit}
              style={{
                width: "20rem",
                height: "3rem",
                border: "1px solid #ddd",
                backgroundColor: "#531967",
                fontFamily: "Arial",
                fontSize: "1.5rem",
                color: "white",
                marginRight: "1rem",
                marginBottom: ".5rem",
                boxShadow: "-2px 4px 6px rgba(0, 0, 0, 0.3)",
              }}
            />
          </>
        ) : null}
      </div>
      <div>
        <button onClick={paythis}>pay partial</button>
      </div>

      <div style={{ textAlign: "end" }}>
        {selectedLoad && editedAmount > 0 && yele == true ? (
          <PayPalScriptProvider
            options={{
              clientId:
                "ATl2tzBs7wABbhgKAMKXLwhsGNdjm_6hoofpt62YDbIWn5WMLuKcx7WtfLBHKHyBLdRdcn7734ACwvOC",
            }}
          >
            <PaypalPayment amount={selectedLoad} newamount={editedAmount} />
          </PayPalScriptProvider>
        ) : null}
      </div>
    </>
  );
}
