import React from "react";
import { useEffect } from "react";
import { Row, Col, Tabs, Skeleton, message } from "antd";
import { useState } from "react";
import { Link } from "react-router-dom";
import { Menu } from "antd";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";

import { FaSignOutAlt } from "react-icons/fa";
import axios from "axios";
import { useNavigate } from "react-router-dom";
const { Item: MenuItem, Divider } = Menu;

const SiderContentA = (props) => {
  // console.log(props);
  // console.log(props.profiledata !== null);
  // console.log(props.profiledata.pdata.rowCount == 0);
  const history = useNavigate();
  const [hovered, setHovered] = useState(false);
  // const [canPostLoad, setCanPostLoad] = useState(props.profiledata !== null);
  const [check, setCheck] = useState(true);

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  const handleLogout = (event) => {
    axios
      .get(props.url + "/api/v1/type/logout", {
        withCredentials: true,
      })
      .then((response) => {
        message.success({
          content: "Goodbye!!!! See You Again!!!",
          className: "custom-class",
          style: {
            marginTop: "20vh",
          },
        });
        history("/");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  console.log(props.check);

  return (
    <>
      <Menu
        mode="inline"
        defaultSelectedKeys={["1"]}
        defaultOpenKeys={["dashboard"]}
        style={{ height: "100%", borderRight: 0 }}
        theme="light"
      >
        <MenuItem key="1">
          <Link to="/admin/dashboard" style={{ textDecoration: "none" }}>
            Dashbord
          </Link>
        </MenuItem>
        <Divider
          style={{
            margin: "9px 0",
            borderBottom: "1px solid rgba(0, 0, 0, 0.2)",
            width: "100%",
          }}
        />

        <MenuItem key="2">
          <Link to="/admin/Active-lanes" style={{ textDecoration: "none" }}>
            Most Active Lanes
          </Link>
        </MenuItem>

        <Divider
          style={{
            margin: "9px 0",
            borderBottom: "1px solid rgba(0, 0, 0, 0.2)",
            width: "100%",
          }}
        />
        <MenuItem key="3">
          <Link to="/admin/Inactive-lanes" style={{ textDecoration: "none" }}>
            Most Inactive Lanes
          </Link>
        </MenuItem>

        <MenuItem key="4">
          <Link to="/admin/payment" style={{ textDecoration: "none" }}>
            Payment
          </Link>
        </MenuItem>

        <Divider
          style={{
            margin: "9px 0",
            borderBottom: "1px solid rgba(0, 0, 0, 0.2)",
            width: "100%",
          }}
        />

        <MenuItem key="4.5">
          <Link to="/admin/transactions" style={{ textDecoration: "none" }}>
            Completed Loads
          </Link>
        </MenuItem>

        <Divider
          style={{
            margin: "9px 0",
            borderBottom: "1px solid rgba(0, 0, 0, 0.2)",
            width: "100%",
          }}
        />

        <MenuItem key="6">
          <Link to="/admin/All-loads" style={{ textDecoration: "none" }}>
            All Loads
          </Link>
        </MenuItem>
        <MenuItem key="7 ">
          <Link to="/admin/all-truckers" style={{ textDecoration: "none" }}>
            Truckers
          </Link>
        </MenuItem>
        {/* <MenuItem key="6">Setting</MenuItem> */}
        <Divider
          style={{
            margin: "12px 0 40px",
            borderBottom: "1px solid rgba(0, 0, 0, 0.2)",
            width: "100%",
          }}
        />
        <MenuItem key="7" style={{ justifyContent: "center" }}>
          <Link to="/admin/all-shipper" style={{ textDecoration: "none" }}>
            Shippers
          </Link>
        </MenuItem>

        <MenuItem key="8" style={{ justifyContent: "center" }}>
          <Link to="#" style={{ textDecoration: "none" }}>
            Active/Non Active User
          </Link>
        </MenuItem>

        <MenuItem
          key="8"
          style={{ height: "50px", display: "flex", alignItems: "center" }}
        >
          <button
            style={{
              backgroundColor: hovered ? "#FFFFFF" : "rgb(35, 177, 109)",
              boxShadow: "rgba(2, 6, 16, 0.2) 0px 2px 16px",
              color: hovered ? "rgb(35, 177, 109)" : "#FFFFFF",
              border: "none",
              borderRadius: "50%",
              width: "50px",
              height: "50px",
              fontSize: "16px",
              cursor: "pointer",
              transition: "background-color 0.3s ease",
            }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            {hovered ? <ModeEditOutlineIcon /> : <ChatBubbleOutlineIcon />}
          </button>
        </MenuItem>

        {/* <Divider
          style={{
            margin: "12px 0 12px",
            borderBottom: "1px solid rgba(0, 0, 0, 0.2)",
            width: "100%",
          }}
        /> */}
        {/* <MenuItem key="10" style={{ justifyContent: "center" }}>
          Switch
        </MenuItem> */}
        <MenuItem
          key="11"
          style={{ color: "#b23b3b" }}
          onClick={(event) => handleLogout(event)}
          icon={<FaSignOutAlt style={{ color: "red", opacity: "0.7" }} />}
        >
          <Link to="/shipper" style={{ textDecoration: "none" }}>
            Logout
          </Link>
        </MenuItem>
      </Menu>
    </>
  );
};

export default SiderContentA;
