import { Row, Col, Skeleton, Tooltip, Button } from "antd";
import { RiUserSearchFill } from "react-icons/ri";
import axios from "axios";

import { BsEye } from "react-icons/bs"; // Import the eye icon

import ModelView from "./ModelView";
import classes from "../Pages.module.css";
import { useState, useEffect, useContext } from "react";
import DataContext from "../../../Context/dataContext";
// import FreightTable from "../../FreightTable/FreightTable";
import FreightTable from "./Fr"
import { AiFillDelete } from "react-icons/ai";
import "./LoadView.css";
import { UilSearch } from "@iconscout/react-unicons";
import { Link } from "react-router-dom";
import { Modal } from "antd";
const ItemDetails = (props) => {
  console.log(props);
  const openModal = (loadId) => {
    setSelectedLoadId(loadId);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const employeeData = useContext(DataContext);
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState(null);
  const [drows, setDRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [nrow, setNrow] = useState([]);

  const [selectedLoadId, setSelectedLoadId] = useState(null); // Initialize as null


 

  const searchHere = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    const filteredRows = nrow.filter((row) => {
      return Object.values(row).some((value) => {
        if (value === null || typeof value === "undefined") {
          return false;
        }
        return value.toString().toLowerCase().indexOf(searchTerm) !== -1;
      });
    });
  
    // Include additional columns in the filtered rows
    const rowsWithColumns = filteredRows.map(row => {
      const rowWithColumns = {
        view: (
          <Tooltip
            placement="bottom"
            title="View Details"
            color="#1777C4"
          >
            <Link
              to={`/home/load-Details/${row.load_id}`}
              style={{ color: "#1777C4", fontWeight: "bolder" }}
            >
              <BsEye
                style={{
                  color: "#1777C4",
                  fontWeight: "bolder",
                  fontSize: "1rem",
                  marginRight: "5px",
                  // border: "2px solid red",
                }}
              />
            </Link>
          </Tooltip>
        ),
        "Bid Posted": (
          <Tooltip title="Bid" color="#1777C4">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                onClick={() => openModal(row.load_id)}
                style={{
                  width: "80px",
                  backgroundColor: "#8A31A8",
                  color: "#FFF",
                  fontWeight: "bolder",
                  borderRadius: "4px",
                  marginTop: "10px",
                }}
                disabled={row.load_status === "winning"}
              >
                Accept
              </Button>
            </div>
          </Tooltip>
        ),
        ...row
      };
      return rowWithColumns;
    });
  
    setRows(rowsWithColumns);
  };
  




  useEffect(() => {
    setDRows(null);
    setRows(null);
    setColumns([]);

    axios
      .get(employeeData.URL + "/api/v1/load/load-view", {
        withCredentials: true,
      })
      .then((response) => {
        console.log(response);

        setColumns((columns) => {
          let newCols = response.data.data.loads.fields.map((col) => {
            return {
              field: col.name,
              headerName: col.name
                .split("_")
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(" "),

              width: 150,
            };
          });

          const newNewCols = [
            { field: "view", headerName: "View" },
            ...newCols,
            { field: "Bid Posted", headerName: "Bid Posted" },
          ];
          return newNewCols;
        });
        // const [nrow, setNrow] = useState([])

        setNrow((rows) => {
          let newRows = response.data.data.loads.rows.map((row, index) => {
            return {
              id: index + 1,
              ...row,
            };
          });
          return newRows;
        });

        setRows((rows) => {
          let newRows = response.data.data.loads.rows.map((row, index) => {
            return {
              id: index + 1,
              // Add the eye icon and link to the row
              view: (
                <Tooltip
                  placement="bottom"
                  title="View Details"
                  color="#1777C4"
                >
                  <Link
                    to={`/home/load-Details/${row.load_id}`}
                    style={{ color: "#1777C4", fontWeight: "bolder" }}
                  >
                    <BsEye
                      style={{
                        color: "#1777C4",
                        fontWeight: "bolder",
                        fontSize: "1rem",
                        marginRight: "5px",
                        // border: "2px solid red",
                      }}
                    />
                  </Link>
                </Tooltip>
              ),
              ...row,
              // id: index + 1,
              // ...row,
              actual_status:
                row["actual_status"] === "Bidding" ? (
                  <a
                    href={"pending-bids"} // Specify the link you want to open
                    style={{ cursor: "pointer", fontWeight: "bolder" }}
                    onClick={() => {
                      // Handle the click event for rows with "Bidding"
                      // openModal(row.load_id);
                    }}
                  >
                    {row["actual_status"]}
                  </a>
                ) : (
                  row["actual_status"]
                ),
              "Bid Posted": (
                <Tooltip title="Bid" color="#1777C4">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      onClick={() => openModal(row.load_id)}
                      style={{
                        width: "80px",
                        backgroundColor: "#8A31A8",
                        color: "#FFF",
                        fontWeight: "bolder",
                        borderRadius: "4px",
                        marginTop: "10px",
                      }}
                      disabled={row.load_status === "winning"}
                    >
                      Accept
                    </Button>
                  </div>
                </Tooltip>
              ),
            };
          });
          return newRows;
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const deleteHandler = (event, index, newrows, code) => {
    console.log(index);
    console.log(code);

    setRows(null);
    axios
      .delete(employeeData.URL + "/api/v1/items/" + code.toString(), {
        withCredentials: true,
      })
      .then((response) => {
        console.log(response);
        const rowvals = newrows.filter((row) => row["item_code"] !== code);
        setRows((rows) => {
          let newDRows = rowvals.map((row, index) => {
            return {
              D: (
                <Tooltip placement="bottom" title="Delete" color="red">
                  <Link to="#" style={{ color: "red", fontWeight: "bolder" }}>
                    <AiFillDelete
                      onClick={(event) =>
                        deleteHandler(event, index, rowvals, row["item_code"])
                      }
                      style={{
                        color: "red",
                        fontWeight: "bolder",
                        fontSize: "0.8rem",
                      }}
                    />
                  </Link>
                </Tooltip>
              ),
              V: (
                <Tooltip placement="bottom" title="View" color="#1777C4">
                  <Link
                    to={
                      "/sales/itemMaster/item-details-view/" + row["item_code"]
                    }
                    style={{ color: "#1777C4", fontWeight: "bolder" }}
                  >
                    <RiUserSearchFill
                      style={{
                        color: "#1777C4",
                        fontWeight: "bolder",
                        fontSize: "0.9rem",
                      }}
                    />
                  </Link>
                </Tooltip>
              ),

              SNO: index + 1,
              ...row,
            };
          });
          return newDRows;
        });
      })
      .catch((error) => {
        setRows((rows) => {
          let newDRows = newrows.map((row, index) => {
            return {
              D: (
                <Tooltip placement="bottom" title="Delete" color="red">
                  <Link to="#" style={{ color: "red", fontWeight: "bolder" }}>
                    <AiFillDelete
                      onClick={(event) =>
                        deleteHandler(event, index, newrows, row["item_code"])
                      }
                      style={{
                        color: "red",
                        fontWeight: "bolder",
                        fontSize: "0.8rem",
                      }}
                    />
                  </Link>
                </Tooltip>
              ),
              V: (
                <Tooltip placement="bottom" title="View" color="#1777C4">
                  <Link
                    to={
                      "/sales/itemMaster/item-details-view/" + row["item_code"]
                    }
                    style={{ color: "#1777C4", fontWeight: "bolder" }}
                  >
                    <RiUserSearchFill
                      style={{
                        color: "#1777C4",
                        fontWeight: "bolder",
                        fontSize: "0.9rem",
                      }}
                    />
                  </Link>
                </Tooltip>
              ),

              SNO: index + 1,
              ...row,
            };
          });
          return newDRows;
        });
        console.log(error);
      });
  };

  return (
    <>
      <form>
        <div
          id="section"
          className="d-flex flex-column "
          style={{ height: "7rem" }}
        >

<h1
            style={{
              color: "#A769A7",
              // display: "inline",
              width:"100%",
              // width: "25rem",
              // border:"2px solid red",
              // margin:"auto"
              // marginRight: "5rem",
            }}
          >
            Load View
          </h1>

        
          <div
            id="search"
            className="d-flex flex-row justify-content-between"
            style={{ width: "15rem", height: "3rem", marginLeft:"2rem", marginTop:"-2rem" }}
          >
            <input
              id="searchInput"
              type="search"
              placeholder="Search anything..."
              style={{
                // border: "1px solid white",
                width: "13rem",
                height: "1.8rem",
                marginLeft: "2px",
                marginTop: "8px",
              }}
              onChange={searchHere}
            />
            <UilSearch size="20" color="black" style={{ margin: "auto" }} />
          </div>

       

        </div>

        <div
          className="table"
          style={{ display: "inline-block", width: "100%" }}
        >
          {rows && columns.length > 0 ? (
            <div style={{ display: "inline-block" }}>
              <FreightTable
                data={rows}
                columns={columns}
                handelChange={searchHere}
                val={true}
              />
            </div>
          ) : (
            <div>No matching results found.</div>
          )}
        </div>
      </form>

      <Modal
        title="Select a Load"
        open={isModalOpen}
        onCancel={closeModal}
        footer={null}
        width={1290} // Adjust the width as needed
        bodyStyle={{ height: "400px", overflow: "scroll" }}
      >
        {isModalOpen && (
          <ModelView id={selectedLoadId} onCloseModal={closeModal} />
        )}
      </Modal>
    </>
  );
};

export default ItemDetails;
