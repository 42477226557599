import { Layout } from "antd";
import { Link, Route, Routes } from "react-router-dom";
import Dashboard from "../Dashboard/Dashboard"
import TotalExecutedLoads from "../TotalExecutedLoad/TotalExecutedLoads";
import MovingLoads from "../MovingLoads/MovingLoads";
import TotalLoadsPosted from "../TotalLoadsPosted/TotalLoadsPoated";
import NewShipper from "../NewShipper/NewShipper";
import AllLoads from "../AllLoads/AllLoads";
import Truckers from "../Truckers/Truckers";
import Shipper from "../Shipper/Shipper";
import ProfilePage from "../Shipper/Profile/ProfilePage";
import TopFiveUser from "../TopFiveUser/TopFiveUser";
import NewTrucker from "../NewTrucker/NewTrucker";
import Payment from "../Payment/payment";
import ActiveLanes from "../Lanes/ActiveLanes";
import InActiveLanes from "../Lanes/InactiveLanes";
import Transactions from "../Transactions/Transactions";



const FeildDataAdmin = (fieldProps) => {
  

 

  return (
    <>
      <Routes>
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/toal-Executed-loads" element={<TotalExecutedLoads />} />
      <Route path="/moving-loads" element={<MovingLoads />} />
      <Route path="/toal-loads-posted" element={<TotalLoadsPosted />} />
      <Route path="/new-shipper" element={<NewShipper />} />
      <Route path="/all-loads" element={<AllLoads />} />
      <Route path="/all-truckers" element={<Truckers />} />
      <Route path="/all-shipper" element={<Shipper />} />
      <Route path="/profile/:id" element={<ProfilePage />} />
      <Route path="/top-user" element={<TopFiveUser />} />
      <Route path="/new-trucker" element={<NewTrucker />} />
      <Route path="/payment" element={<Payment />} />
      <Route path="/Active-lanes" element={<ActiveLanes />} />
      <Route path="/Inactive-lanes" element={<InActiveLanes />} />
      <Route path="/transactions" element={<Transactions />} />
        
      </Routes>
    </>
  );
};

export default FeildDataAdmin;
