import axios from "axios";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Validation from "../Validationtrucker/ValidationTrucker";
import classes from "./ForgotPage.module.css";
import logoImage from "../../../assets/LOGO.png";
import { Content } from "antd/es/layout/layout";
import { Button, message, Space } from "antd";

function ForgotPage(props) {
  // const history = useHistory();

  const [successMessage, setSuccessMessage] = useState("");
  const [duplicateEmailError, setDuplicateEmailError] = useState(false);
  const [type, setType] = useState("trucker");
  const [email, setUserCode] = useState("");
  const [pwd, setPassword] = useState("");
  const [conPwd, setConPwd] = useState("");

  const [values, setValues] = useState({
    email: "",
    userType: type,
  });

  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const [backendError, setBackendError] = useState([]);

  const handleInput = (event) => {
    setValues((prev) => ({ ...prev, [event.target.name]: event.target.value }));
  };

  const handleConfirmPasswordInput = (event) => {
    setConPwd(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(values);
    console.log(props.url);

    // console.log(conPwd);

    // let err = Validation(values, values.pwd, conPwd);
    // setErrors(err);
    // console.log(err);

    // if (err.email == "" && err.pwd == "" && err.password == "") {
    //   console.log("workling");
    // } else {
    //   console.log("please Enter the fields properly");
    //   console.log(err.email && err.pwd);
    //   console.log(err);
    //   return;
    // }

    const postdata = {
      forgotPage: [
        {
          ...values,
        },
      ],
    };

    axios
      .post(props.url + "/api/v1/type/forgetpass", postdata, {
        withCredentials: true,
      })
      .then((res) => {
        console.log(res.data);
        if (res.data) {
          message.success({
            content: " !!!! Password reset instructions sent to your email !!",
            className: "custom-class",
            style: {
              marginTop: "20vh",
            },
          });
        } // Log the response data specifically
        // setSuccessMessage("Registration successful! Please log in.");
        // alert("YOUR REGISTRATION IS DONE");
        navigate("/trucker"); // Navigate to the login page

        // You can also set a state for displaying a successful message
        // For example:
      })
      .catch((error) => {
        console.error("Error:", error);
        if (error.response.status == 410) {
          message.error({
            content: " Email id not exits !!",
            className: "custom-class",
            style: {
              marginTop: "20vh",
            },
          });
          navigate("/sigup");
        }
      });
  };

  const imageStyle = {
    // position:'absolute',

    // marginTop: "20px",
    marginLeft: "20px",
    backgroundImage: `url(${logoImage})`,
    backgroundPosition: "top left",
    backgroundRepeat: "no-repeat",
    backgroundSize: "200px",
  };

  return (
    <div className={classes["container"]}>
      <div className={classes["form-container"]}>
        <div className={classes["logo"]} style={imageStyle}></div>

        {/* <h2 style={{ color: 'rgb(157 84 177)' }}>Welcome</h2> */}
        <h3
          style={{
            color: "#8A31A8",
            textShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)",
            transform: "rotateX(20deg) translateZ(20px)",
            // fontSize: "30px",
            marginTop: "2rem",
          }}
        >
          ENTER VALID MAIL HERE !!!
        </h3>

        {backendError ? (
          backendError.map((e) => <p className="text-danger">{e.msg}</p>)
        ) : (
          <span></span>
        )}
        <form action="" onSubmit={handleSubmit}>
          <div
            className=" d-flex flex-column"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <label htmlFor="email"></label>
            <input
              type="email"
              placeholder="Enter Email"
              name="email"
              onChange={handleInput}
              required
              className="form-control rounded-0 text-center "
              style={{
                fontSize: "20px",
                width: "22rem",
                height: "3.5rem",
                backgroundImage: "linear-gradient(to bottom, #BEA1C6, white)",
                transform: "perspective(500px) rotateX(3deg)",
                borderColor: "#cbc5c5",
              }}
            />
            {duplicateEmailError && (
              <span className="text-danger">Email already exists.</span>
            )}
            {errors.email && (
              <span className="text-danger"> {errors.email}</span>
            )}
          </div>

          <button
            type="submit"
            className={classes["custom-btn"]}
            style={{ marginTop: "30px" }}
          >
            Submit
          </button>
        </form>
      </div>
      {successMessage && <p className="text-success">{successMessage}</p>}
    </div>
  );
}

export default ForgotPage;
