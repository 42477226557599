import { Scrollbars } from "react-custom-scrollbars-2";
import { Skeleton, Spin, Alert } from "antd";
import classes from "./App.module.css";
import { useState, useEffect } from "react";
import axios from "axios";
import { message } from "antd";
import { Routes, Route } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import LayoutWrapper from "./components/LayoutWrapper/LayoutWrapper";
import LoginPage from "./Pages/FzuluPageShipper/LoginPageShipper/LoginPage";
import { getFabUtilityClass } from "@mui/material";
import { get } from "react-hook-form";
import FreightModule from "./Pages/FzuluPageShipper/FreightZuluPageShipper/FreightModule";
// import CheckProfile from "./Pages/FzuluPageShipper/CheckProfilePageShipper/CheckProfile";
import SignUpPage from "./Pages/FzuluPageShipper/SignUpPageShipper/SignUpPage";
import ForgotPage from "./Pages/FzuluPageShipper/ForgotPageShipper/ForgotPage";
import ResetPages from "./Pages/FzuluPageShipper/ResetPsdShipper/ResetPsw";
import Validation from "./components/FreightModuleShipper/Final/Validation";
import LoginPageTrucker from "./Pages/FzuluPageTrucker/LoginPageTrucker/LoginPageTrucker";
// import { getFabUtilityClass } from "@mui/material";
// import { get } from "react-hook-form";
import FreightModuleTrucker from "./Pages/FzuluPageTrucker/FreightZuluPageTrucker/FreightModuleTrucker";
import CheckProfileTrucker from "./Pages/FzuluPageTrucker/CheckProfilePageTrucker/CheckProfile";
import SignUpPageTrucker from "./Pages/FzuluPageTrucker/SignUpPageTrucker/SignUpPageTrucker";
import ForgetPage from "./Pages/FzuluPageTrucker/ForgotPageTrucker/ForgetPage";
import ResetPage from "./Pages/FzuluPageTrucker/ResetPsdTrucker/ResetPswt";
import Home from "./Pages/FzuluPageTrucker/myHome/Home";
// import Validation as ValidationT from "./Pages/FzuluPageTrucker/Validationtrucker/ValidationTrucker";
// import  Validationt from "./Pages/FzuluPageTrucker/Validationtrucker/ValidationTrucker";
import ValidationT from "./components/FreightModuleTrucker/Final/Validation";
import Admin from "./Pages/Admin/Login/AdminLogin";
import FreightAdmin from "./Pages/Admin/FreightZuluAdmin/FreightAdmin";
function App() {
  const AURL = "http://yzulu.com";
  // const ws = new WebSocket("ws://localhost:8005:8080");
  const [notification, setNotification] = useState("");
  const [analysis, setAnalysis] = useState(null);
  const [danlysis, setDanlysis] = useState(null);
  const [myload, setMyload] = useState(null);
  const [profiledata, setProfiledata] = useState(null);
  const [check, setCheck] = useState(null);
  const [accountId, setAccountId] = useState(null);
  const [accountmail, setAccountM] = useState(null);

  const history = useNavigate();

  useEffect(() => {
    axios
      .get(AURL + "/api/v1/type/checkStatus", {
        withCredentials: true,
      })
      .then((responseA) => {
        console.log(responseA);

        if (responseA.data.data) {
          console.log("inside if");
          // if (responseA.data.data.user.rows[0].userType === "trucker") {
          //   console.log("hi")
          //   setAnalysis(null);
          //   axios
          //     .get(AURL + "/api/v1/dashboard/loads", {
          //       withCredentials: true,
          //     })
          //     .then((response) => {
          //       console.log(response);
          //       setAnalysis((analysis) => {
          //         let newAnalysis = response.data.data;
          //         return newAnalysis;
          //       });
          //     })
          //     .catch((err) => {
          //       console.log(err);
          //     });

          //   axios
          //     .get(AURL + "/api/v1/dashboard/tobid", {
          //       withCredentials: true,
          //     })
          //     .then((response) => {
          //       console.log(response);

          //       setDanlysis((analysis) => {
          //         let newAnalysis = response.data.data;
          //         return newAnalysis;
          //       });
          //     });

          //   axios
          //     .get(AURL + "/api/v1/dashboard/Myloads", {
          //       withCredentials: true,
          //     })
          //     .then((response) => {
          //       console.log(response);

          //       setMyload((analysis) => {
          //         let newAnalysis = response.data.data;
          //         return newAnalysis;
          //       });
          //     })

          //     .catch((err) => {
          //       console.log(err.response);
          //     });
          // }

          if (
            responseA.data.data.user.rows[0].userType !== "trucker" &&
            window.location.href.split("/")[3] === "trucker"
          ) {
            history("/trucker");
            message.error({
              content: "log In to gain Access!!!!",
              className: "custom-class",
              style: {
                marginTop: "20vh",
              },
            });
          }
          
        } else {
          if (
            window.location.href.split("/")[3] !== "trucker" &&
            window.location.href.split("/")[3] === "trucker"
          ) {
            console.log("inside else");
            history("/trucker");
            message.error({
              content: "Sign In to gain Access!!!!",
              className: "custom-class",
              style: {
                marginTop: "20vh",
              },
            });
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(AURL + "/api/v1/profile/check-profile", {
        withCredentials: true,
      })
      .then((getResponse) => {
        console.log(getResponse);
        console.log("Status Code: ", getResponse.status);
        if (getResponse.status === 200) {
          console.log("this is the if ");
          setCheck(true);
        } else {
          setCheck(false);
          console.log("this is the else");
        }
      })
      .catch((getErr) => {
        console.log("Error in GET request:", getErr);
      });
  }, []);



  const n = localStorage.getItem("myId");
  const mail = localStorage.getItem("mail")
  console.log("/n");
  console.log(mail);


  return (
    <LayoutWrapper>
      <Routes>
        <Route
          path="/shipper"
          element={<LoginPage url={AURL} check={check} setCheck={setCheck} acountmail={accountmail}
          setAccoountMail={setAccountM} />}
        />

        {/* <Route path="/check-profile" element={<CheckProfile url={AURL} />} /> */}

        <Route path="/validate/:email" element={<Validation url={AURL} />} />
        <Route
          path="/validate-trucker/:email"
          element={<ValidationT url={AURL} />}
        />
        <Route path="/admin" element={<Admin url={AURL} />} />

        <Route path="/admin/*" element={<FreightAdmin url={AURL} />} />

        <Route
          path="/home/*"
          element={
            <FreightModule url={AURL} check={check} setCheck={setCheck} mail={mail} />
          }
        />

        <Route path="/signup" element={<SignUpPage url={AURL} />} />

        <Route path="/forgot" element={<ForgotPage url={AURL} />} />

        <Route
          path="/reset-pasword/:id/:token"
          element={<ResetPages url={AURL} />}
        />

        <Route path="/" element={<Home url={AURL} />} />
      </Routes>
      <Routes>
        <Route
          path="/trucker"
          element={
            <LoginPageTrucker
              url={AURL}
              analysis={analysis}
              setAnalysis={setAnalysis}
              danlysis={danlysis}
              setDanlysis={setDanlysis}
              myload={myload}
              setMyload={setMyload}
              profiledata={profiledata}
              setProfiledata={setProfiledata}
              check={check}
              setCheck={setCheck}
              acountCode={accountId}
              setAccountId={setAccountId}
              acountmail={accountmail}
          setAccoountMail={setAccountM}
            />
          }
        />
        {/* <Route path="/trucker-login" element={<LoginPage url={AURL} />} /> */}
        {/* <Route
          path="/check-profile"
          element={<CheckProfileTrucker url={AURL} />}
        /> */}

        <Route
          path="/trucker/*"
          element={
            // analysis && danlysis && myload ? (
              <FreightModuleTrucker
                url={AURL}
                analysis={analysis}
                setAnalysis={setAnalysis}
                danlysis={danlysis}
                setDanlysis={setDanlysis}
                myload={myload}
                setMyload={setMyload}
                profiledata={profiledata}
                setProfiledata={setProfiledata}
                check={check}
                setCheck={setCheck}
                acountCode={n}
                mail={mail}
              />
            // ) : (
            //   // Render a loading component or a placeholder while waiting for data
            //   <>
            //     <div
            //       style={{
            //         display: "flex",
            //         flexDirection: "column",
            //         alignItems: "center",
            //         justifyContent: "center",
            //         height: "100vh",
            //         // background: "#F0E5F3", // Set your desired background color
            //         backgroundImage: "linear-gradient(to bottom, #F0E5F3 40%, rgb(138, 45, 125) 100% )"
            //       }}
            //     >
            //       <h1 style={{ color: "white" }}>Welcome To Freight Zulu</h1>
            //     </div>
            //     {/* <Skeleton active />
            //   <Skeleton active />
            //   <Skeleton active /> */}
            //   </>
            // )
          }
        />

        <Route path="/sigup" element={<SignUpPageTrucker url={AURL} />} />

        <Route path="/forget" element={<ForgetPage url={AURL} />} />
        <Route
          path="/reset-password/:id/:token"
          element={<ResetPage url={AURL} />}
        />
      </Routes>
    </LayoutWrapper>
  );
}

export default App;
