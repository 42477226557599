// import React from "react";
// import { useEffect } from "react";
// import { Row, Col, Tabs, Skeleton, message } from "antd";
// import { useState } from "react";
// import { Link } from "react-router-dom";
// import { Menu } from "antd";
// import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
// import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";

// import { FaSignOutAlt } from "react-icons/fa";
// import axios from "axios";
// import { useNavigate } from "react-router-dom";
// const { Item: MenuItem, Divider } = Menu;
// const SiderContentF = (props) => {
//   // console.log(props);
//   // console.log(props.profiledata !== null);
//   // console.log(props.profiledata.pdata.rowCount == 0);
//   const history = useNavigate();
//   const [hovered, setHovered] = useState(false);
//   // const [canPostLoad, setCanPostLoad] = useState(props.profiledata !== null);
//   const [check, setCheck] = useState(true);

//   const handleMouseEnter = () => {
//     setHovered(true);
//   };

//   const handleMouseLeave = () => {
//     setHovered(false);
//   };

//   // const handleClick = () => {
//   //   if (canPostLoad) {
//   //     // Navigate to the "Post a Load" page
//   //     window.location.href = "/home/post-load";
//   //   } else {
//   //     // Display a message indicating why the action is not allowed
//   //     // window.location.href = "/check-profile";
//   //     history("/check-profile");
//   //   }
//   // };
//   useEffect(() => {
//     axios
//       .get("http://yzulu.com/api/v1/profile/check-profile", {
//         withCredentials: true,
//       })
//       .then((response) => {
//         const toCheck = response.data.profile.rows[0];
//         console.log(response.data.profile.rows[0]);

//         // Define a function to check the object properties asynchronously
//         const checkProperties = async (obj) => {
//           let midCheck = true;
//           for (const key in obj) {
//             if (obj.hasOwnProperty(key) && !obj[key]) {
//               midCheck = false;
//               return false; // If any key is empty, return false
//             }
//           }
//           return midCheck;
//         };

//         // Use the function and set the result asynchronously
//         checkProperties(toCheck)
//           .then((result) => {
//             if (result === true) {
//               console.log(result);
//               setCheck(result);
//             }
//             if (result === false) {
//               setCheck(result);
//               console.log(result);
//               console.log("setCheckValue:" + check);
//             }

//             console.log("waah bete moj kardi");
//           })
//           .catch((error) => {
//             console.error("abe yarr ab ye kya ho gya");
//           });

//         // const toCheck=response.data.profile.rows[0]
//         // console.log(response.data.profile.rows[0]);
//         // let midCheck=true;
//         // for (const key in toCheck) {
//         //     if (obj.hasOwnProperty(key) && !obj[key]) {
//         //         midCheck=false;
//         //       return false; // If any key is empty, return false
//         //     }
//         //   }
//         //   setCheck(midCheck)

//         //   if (midCheck===false){
//         //     setCheck(midCheck)
//         //   }
//         //   if (midCheck===true){
//         //     setCheck(midCheck)
//         //   }
//       })
//       .catch((error) => {
//         console.error("Error fetching data:", error);
//       });
//   }, []);

//   const handleLogout = (event) => {
//     axios
//       .get(props.url + "/api/v1/type/logout", {
//         withCredentials: true,
//       })
//       .then((response) => {
//         message.success({
//           content: "Goodbye!!!! See You Again!!!",
//           className: "custom-class",
//           style: {
//             marginTop: "20vh",
//           },
//         });
//         history("/");
//       })
//       .catch((err) => {
//         console.log(err);
//       });
//   };

//   return (
//     <>
//       <Menu
//         mode="inline"
//         defaultSelectedKeys={["1"]}
//         defaultOpenKeys={["dashboard"]}
//         style={{ height: "100%", borderRight: 0 }}
//         theme="light"
//       >
//         <MenuItem key="1" style={{ justifyContent: "flex-start" }}>
//           <Link to="/home" style={{ textDecoration: "none" }}>
//             Dashbord
//           </Link>
//         </MenuItem>

//         {/* <MenuItem key="2" onClick={handleClick}>
//           <Link to="/home/post-load" style={{ textDecoration: "none" }}>
//             Post a Load
//           </Link>
//         </MenuItem> */}
//         {check ? (
//           <MenuItem key="2">
//             <Link to="/home/post-load" style={{ textDecoration: "none" }}>
//               Post a Load
//             </Link>
//           </MenuItem>
//         ) : (
//           <MenuItem key="2">
//             <Link to="Checking" style={{ textDecoration: "none" }}>
//               Post a Load
//             </Link>
//           </MenuItem>
//         )}
//         <Divider
//           style={{
//             margin: "9px 0",
//             borderBottom: "1px solid rgba(0, 0, 0, 0.2)",
//             width: "100%",
//           }}
//         />
//         <MenuItem key="3" className="align-right">
//           <Link to="/home/load-view" style={{ textDecoration: "none" }}>
//             Load View
//           </Link>
//         </MenuItem>
//         <Divider
//           style={{
//             margin: "9px 0",
//             borderBottom: "1px solid rgba(0, 0, 0, 0.2)",
//             width: "100%",
//           }}
//         />
//         <MenuItem key="4">
//           <Link to="/home/payment" style={{ textDecoration: "none" }}>
//             Payment
//           </Link>
//         </MenuItem>
//         <MenuItem key="5">
//           <Link to='/home/tracking' style={{ textDecoration: 'none' }}>
//             Tracking
//           </Link>
//         </MenuItem>
//         {/* <MenuItem key="6">Setting</MenuItem> */}
//         <Divider
//           style={{
//             margin: "12px 0 40px",
//             borderBottom: "1px solid rgba(0, 0, 0, 0.2)",
//             width: "100%",
//           }}
//         />
//         <MenuItem key="7" style={{ justifyContent: "center" }}>
//           <Link to="/home/ledger" style={{ textDecoration: "none" }}>
//             Ledger
//           </Link>
//         </MenuItem>
//         <MenuItem
//           key="8"
//           style={{ height: "50px", display: "flex", alignItems: "center" }}
//         >
//           <button
//             style={{
//               backgroundColor: hovered ? "#FFFFFF" : "rgb(35, 177, 109)",
//               boxShadow: "rgba(2, 6, 16, 0.2) 0px 2px 16px",
//               color: hovered ? "rgb(35, 177, 109)" : "#FFFFFF",
//               border: "none",
//               borderRadius: "50%",
//               width: "50px",
//               height: "50px",
//               fontSize: "16px",
//               cursor: "pointer",
//               transition: "background-color 0.3s ease",
//             }}
//             onMouseEnter={handleMouseEnter}
//             onMouseLeave={handleMouseLeave}
//           >
//             {hovered ? <ModeEditOutlineIcon /> : <ChatBubbleOutlineIcon />}
//           </button>
//         </MenuItem>

//         {/* <Divider
//           style={{
//             margin: "12px 0 12px",
//             borderBottom: "1px solid rgba(0, 0, 0, 0.2)",
//             width: "100%",
//           }}
//         /> */}
//         {/* <MenuItem key="10" style={{ justifyContent: "center" }}>
//           Switch
//         </MenuItem> */}
//         <MenuItem
//           key="11"
//           style={{ color: "#b23b3b" }}
//           onClick={(event) => handleLogout(event)}
//           icon={<FaSignOutAlt style={{ color: "red", opacity: "0.7" }} />}
//         >
//           <Link to="/shipper" style={{ textDecoration: "none" }}>
//             Logout
//           </Link>
//         </MenuItem>
//       </Menu>
//     </>
//   );
// };

// export default SiderContentF;

// {
//   /* <Menu.Item key="empLogout" onClick = {(event) => handleLogout(event)} icon={<FaSignOutAlt style = {{color : "red", opacity: "0.7"}}/>}>
//                     <NavLink to = '/customer-portal'>
//                         Logout
//                     </NavLink>
//                 </Menu.Item> */
// }

import React from "react";
import { useEffect } from "react";
import { Row, Col, Tabs, Skeleton, message } from "antd";
import { useState } from "react";
import { Link } from "react-router-dom";
import { Menu } from "antd";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";

import { FaSignOutAlt } from "react-icons/fa";
import axios from "axios";
import { useNavigate } from "react-router-dom";
const { Item: MenuItem, Divider } = Menu;

const SiderContentF = (props) => {
  // console.log(props);
  // console.log(props.profiledata !== null);
  // console.log(props.profiledata.pdata.rowCount == 0);
  const history = useNavigate();
  const [hovered, setHovered] = useState(false);
  // const [canPostLoad, setCanPostLoad] = useState(props.profiledata !== null);
  const [check, setCheck] = useState(true);

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  // const myclick =
  //   (() => {
  //     axios
  //       .get("http://yzulu.com/api/v1/profile/check-profile", {
  //         withCredentials: true,
  //       })
  //       .then((response) => {
  //         const toCheck = response.data.profile.rows[0];
  //         console.log(response.data.profile.rows[0]);

  //         // Define a function to check the object properties asynchronously
  //         const checkProperties = async (obj) => {
  //           let midCheck = true;
  //           for (const key in obj) {
  //             if (obj.hasOwnProperty(key) && !obj[key]) {
  //               midCheck = false;
  //               return false; // If any key is empty, return false
  //             }
  //           }
  //           return midCheck;
  //         };

  //         // Use the function and set the result asynchronously
  //         checkProperties(toCheck)
  //           .then((result) => {
  //             if (result === true) {
  //               console.log(result);
  //               setCheck(result);
  //             }
  //             if (result === false) {
  //               setCheck(result);
  //               console.log(result);
  //               console.log("setCheckValue:" + check);
  //             }

  //             console.log("waah bete moj kardi");
  //           })
  //           .catch((error) => {
  //             console.error("abe yarr ab ye kya ho gya");
  //           });
  //       })
  //       .catch((error) => {
  //         console.error("Error fetching data:", error);
  //       });
  //   },
  //   []);

  const handleLogout = (event) => {
    axios
      .get(props.url + "/api/v1/type/logout", {
        withCredentials: true,
      })
      .then((response) => {
        message.success({
          content: "Goodbye!!!! See You Again!!!",
          className: "custom-class",
          style: {
            marginTop: "20vh",
          },
        });
        history("/");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  console.log(props.check);

  return (
    <>
      <Menu
        mode="inline"
        defaultSelectedKeys={["1"]}
        defaultOpenKeys={["dashboard"]}
        style={{ height: "100vh", borderRight: 0, width:"100%" }}
        theme="light"
      >
        <MenuItem key="1" style={{ justifyContent: "flex-start" }}>
          <Link to="/home" style={{ textDecoration: "none" }}>
            Dashbord
          </Link>
        </MenuItem>

        {/* <MenuItem key="2" onClick={handleClick}>
          <Link to="/home/post-load" style={{ textDecoration: "none" }}>
            Post a Load
          </Link>
        </MenuItem> */}
        <MenuItem key="2">
          {props.check ? (
            <Link to="/home/post-load" style={{ textDecoration: "none" }}>
              Post a Load
            </Link>
          ) : (
            <Link to="/home/Checking" style={{ textDecoration: "none" }}>
              Post a Load
            </Link>
          )}
        </MenuItem>

        <Divider
          style={{
            margin: "9px 0",
            borderBottom: "1px solid rgba(0, 0, 0, 0.2)",
            width: "100%",
          }}
        />
        <MenuItem key="3" className="align-right">
          <Link to="/home/load-view" style={{ textDecoration: "none" }}>
            Load View
          </Link>
        </MenuItem>
        <Divider
          style={{
            margin: "9px 0",
            borderBottom: "1px solid rgba(0, 0, 0, 0.2)",
            width: "100%",
          }}
        />
        <MenuItem key="4">
          <Link to="/home/payment" style={{ textDecoration: "none" }}>
            Payment
          </Link>
        </MenuItem>
        <MenuItem key="5">
          <Link to="/home/tracking" style={{ textDecoration: "none" }}>
            Tracking
          </Link>
        </MenuItem>
        {/* <MenuItem key="6">Setting</MenuItem> */}
        <Divider
          style={{
            margin: "12px 0 40px",
            borderBottom: "1px solid rgba(0, 0, 0, 0.2)",
            width: "100%",
          }}
        />
        <MenuItem key="7" style={{ justifyContent: "center" }}>
          <Link to="/home/ledger" style={{ textDecoration: "none" }}>
            Ledger
          </Link>
        </MenuItem>
        <MenuItem
          key="8"
          style={{ height: "50px", display: "flex", alignItems: "center", display:"none" }}
        >
          <button
            style={{
              backgroundColor: hovered ? "#FFFFFF" : "rgb(35, 177, 109)",
              boxShadow: "rgba(2, 6, 16, 0.2) 0px 2px 16px",
              color: hovered ? "rgb(35, 177, 109)" : "#FFFFFF",
              border: "none",
              borderRadius: "50%",
              width: "50px",
              height: "50px",
              fontSize: "16px",
              cursor: "pointer",
              transition: "background-color 0.3s ease",
            }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            {hovered ? <ModeEditOutlineIcon /> : <ChatBubbleOutlineIcon />}
          </button>
        </MenuItem>

        {/* <Divider
          style={{
            margin: "12px 0 12px",
            borderBottom: "1px solid rgba(0, 0, 0, 0.2)",
            width: "100%",
          }}
        /> */}
        {/* <MenuItem key="10" style={{ justifyContent: "center" }}>
          Switch
        </MenuItem> */}
        <MenuItem
          key="11"
          style={{ color: "#b23b3b" }}
          onClick={(event) => handleLogout(event)}
          icon={<FaSignOutAlt style={{ color: "red", opacity: "0.7" }} />}
        >
          <Link to="/shipper" style={{ textDecoration: "none" }}>
            Logout
          </Link>
        </MenuItem>
      </Menu>
    </>
  );
};

export default SiderContentF;
