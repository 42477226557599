import React, { useRef } from "react";
import "./Home.css";
import Mysmallbox from "./mysmallbox";
import ReadMore from "./ReadMore";
import WhatSay from "./WhatSay";
import LOGO from "../../../assets/LOGO.png";

const Home = () => {
  const topRef = useRef(null);
  const middleRef = useRef(null);

  const scrollToTop = () => {
    topRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const scrollToMiddle = () => {
    middleRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const shipper =
    "https://i0.wp.com/freightzulu.com/wp-content/uploads/2023/04/Untitled-design-59.png?fit=465%2C465&ssl=1";
  const shippern =
    "https://i0.wp.com/freightzulu.com/wp-content/uploads/2023/04/Untitled-design-68-compressed.jpg?fit=350%2C350&ssl=1";
  const truckker =
    "https://i0.wp.com/freightzulu.com/wp-content/uploads/2023/04/Untitled-design-58.png?fit=465%2C465&ssl=1";

  const P1 =
    "https://i0.wp.com/freightzulu.com/wp-content/uploads/2023/03/y12.png?w=400&ssl=1";
  const P2 =
    "https://i0.wp.com/freightzulu.com/wp-content/uploads/2023/03/y11.png?w=400&ssl=1";
  const P3 =
    "https://i0.wp.com/freightzulu.com/wp-content/uploads/2023/03/y10.png?w=400&ssl=1";

  const p1t =
    '"Freightzulu reshape our logistics management. Real-time bidding and custom support made supply chain efficient."';
  const p2t = `"Freightzulu.com was a game-changer for our trucking company. Efficient load booking and fair bidding opportunities increased revenue."`;
  const p3t = `"Freightzulu.com improved our supply chain visibility with real-time tracking and advanced analytics. We're happier with the results."`;

  const shi = [
    "Trusted and reliable network of carriers",
    "Increased load-fill rates",
    "Efficient and cost-effective load booking",
  ];

  const truck = [
    "Reliable shippers for consistent business",
    "Real-time bidding for loads",
    "Efficient and cost-effective load booking",
  ];

  const reads = [
    "Real-time tracking and delivery updates",
    "Increased transparency in the supply chain",
    "Reduced manual labor and paperwork",
    "Real-time load tracking and management",
    "Automated payment and invoicing system",
  ];

  const readt = [
    "Reduced deadhead miles",
    "Improved load planning and utilization",
    "Increased revenue per mile",
    "Improved fleet and asset utilization",
    "Efficient routing and scheduling of loads",
  ];

  // Function to check if the user has scrolled to the bottom of the page
  function isScrolledToBottom() {
    const windowHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

    return scrollTop + windowHeight >= documentHeight;
  }

  // Function to show the footer when scrolled to the bottom
  function showFooter() {
    const footer = document.querySelector("footer");
    if (isScrolledToBottom()) {
      footer.classList.remove("hidden");
    } else {
      footer.classList.add("hidden");
    }
  }

  // Attach a scroll event listener to the window
  window.addEventListener("scroll", showFooter);
  // Now, when a user scrolls to the bottom of the page, the footer will become visible. Make sure to adjust the styles and content of the footer to suit your needs.

  function toggle() {
    const a = document.getElementById("insidethetoogle");
    console.log("hey there we are about to done");

    const c = document.getElementById("thissifa");
    const d = document.getElementById("thissifb");
    const e = document.getElementById("thissifc");
    c.style.textDecoration = "none";
    c.style.color = "white";
    d.style.textDecoration = "none";
    d.style.color = "white";
    e.style.textDecoration = "none";
    e.style.color = "white";
    // console.log(b);
    // console.log(a);
    if (a.style.display === "none") {
      a.style.display = "block";
    } else {
      a.style.display = "none";
    }

    a.style.backgroundColor = "rgb(77, 62, 81)";
    a.style.width = "100vw";
    a.style.height = "8rem";
    a.style.margin = "3rem 1rem 2rem 0rem";

    a.style.fontSize = "1rem";
    a.style.fontFamily = "Comfortaa";
  }

  // if (screenWidth >= 1500) {

  //         abab.style.display = "none";

  // }
  // const tgl = document.getElementById('insidethetoogle')
  //     if  (tgl.style.display==="")

  return (
    <>
      <br />
      <br />
      <div className="optimus">
        <div className="hey">
          <nav className="navbar">
            {/* operation */}

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
                // border: "2px solid black",
                width: "100%",
              }}
            >
              <div className="logotitle">
                <a className="navbar-brand" href="/">
                  <img className="imsg" src={LOGO} alt="logo" />
                </a>
              </div>

              <div style={{ alignItems: "end" }}>
                <div class="navbar-toggle" id="navbar-toggle" onClick={toggle}>
                  <svg viewBox="0 0 100 80" width="40" height="4">
                    <rect width="100" height="20"></rect>
                    <rect y="30" width="100" height="20"></rect>
                    <rect y="60" width="100" height="20"></rect>
                  </svg>
                </div>
                <ul className="nav-links" id="navbar-links">
                  <li className="nav-item">
                    <a href="/">Home</a>
                  </li>

                  <li className="nav-item dropdown">
                    <a href="/trucker">Trucker</a>
                  </li>
                  <li className="nav-item dropdown">
                    <a href="/shipper">Shipper</a>
                  </li>
                  <li className="nav-item dropdown">
                    <a href="/admin">Admin</a>
                  </li>
                </ul>
              </div>
            </div>

            {/* operation */}
          </nav>
        </div>
        <br /> <br />
        <div className="promise">
          <ul id="insidethetoogle">
            <li>
              <a id="thissifa" className="isidetgla" href="/">
                Home
              </a>
            </li>
            <li>
              <a id="thissifb" className="isidetgla" href="/trucker">
                Trucker
              </a>
            </li>
            <li>
              <a id="thissifc" className="isidetgla" href="/shipper">
                Shipper
              </a>
            </li>
            <li>
              <a id="thissifc" className="isidetgla" href="/admin">
                Admin
              </a>
            </li>
          </ul>
        </div>
        <br />
        <br />
        <div id="smallbox">
          <div className="outofsmall">
            <Mysmallbox pic={shipper} names={"Shipper"} data={shi} />
            <br />
            <button className="thisisbtnh" onClick={scrollToTop}>
              Read More
            </button>
            <br />
          </div>

          <div className="outofsmall">
            <Mysmallbox pic={truckker} names={"Trucker"} data={truck} />
            <br />
            <button className="thisisbtnh" onClick={scrollToMiddle}>
              Read More
            </button>
            <br />
          </div>
          <br />
        </div>
        <br />
        <br />
        <br />
        <br />
        {/* <br />
        <br />
        <br />
        <br />
        <br /> */}
        <div className="readmore">
          <div className="thisistheseconddiv" ref={topRef}>
            <br />
            <br />
            <br />
            <br />
            <h1 style={{ marginLeft: "2rem", textDecoration: "underline" }}>
              Shiper
            </h1>
            <ReadMore data={reads} pic={shippern} />
          </div>

          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />

          <div className="thisistheseconddivn" ref={middleRef}>
            <br />
            <br />
            <br />
            <br />
            <h1 style={{ marginLeft: "2rem", textDecoration: "underline" }}>
              Trucker
            </h1>
            <ReadMore data={readt} pic={truckker} />
          </div>
        </div>
        <div className="whattheysay">
          <div className="thelastdiv">
            <h1 style={{ margin: "auto", display: "inline-block" }}>
              What they say
            </h1>
          </div>
          <div className="insidesay">
            <WhatSay
              pic={P1}
              text={p1t}
              type={"John D."}
              subtype={"Production manager"}
            />
            <WhatSay
              pic={P2}
              text={p2t}
              type={"Sarah M."}
              subtype={"Truck Driver"}
            />
            <WhatSay pic={P3} text={p3t} type={"David P."} subtype={"Broker"} />
          </div>
        </div>
        <br />
        <div
          className="line"
          style={{ border: "1px solid black", width: "80%", margin: "auto" }}
        ></div>
        <div
          style={{
            marginTop: "2rem",
            display: "flex",
            justifyContent: "space-around",
            flexDirection: "row",
          }}
        >
          <div className="theO">
            <a className="navbar-brand" href="/">
              <img className="imsng" src={LOGO} alt="logo" />
            </a>
          </div>

          <div className="theS">
            <h3 style={{ textDecoration: "underline" }}>Contact Us</h3>
            <a href="tel:+91-9555040001" className="mylinks">
              +91-9555040001
            </a>
            <br />
            <a className="mylinks" href="mailto:Sales@freightzulu.com">
              Sales@freightzulu.com
            </a>
            <br />
            <a className="mylinks" href="mailto:smm@imax.co.in">
              smm@imax.co.in
            </a>
          </div>
        </div>
        <footer className="hidden">
          <p>Copyright © 2023 FreightZulu - made in INDIA with ❤</p>
        </footer>
      </div>
    </>
  );
};

export default Home;
