import React, { useState } from "react";
import "./FreightTable.css";

const FreightTable = (props) => {
  const { data, columns } = props;
  console.log(data, columns);

  const [sortInfo, setSortInfo] = useState({
    field: null,
    order: "asc",
  });

  const handleSort = (field) => {
    setSortInfo((prevSortInfo) => {
      if (prevSortInfo.field === field) {
        return {
          ...prevSortInfo,
          order: prevSortInfo.order === "asc" ? "desc" : "asc",
        };
      }
      return {
        field,
        order: "asc",
      };
    });
  };

  
  const sortedData = [...data].sort((a, b) => {
    const aValue = a[sortInfo.field];
    const bValue = b[sortInfo.field];
  
    // Custom comparison function for strings, numeric strings, and dates
    const compareValues = (a, b) => {
      // Check if the values are dates in the format "DD-MM-YYYY"
      const isDate = (value) => {
        const datePattern = /^\d{2}-\d{2}-\d{4}$/;
        return datePattern.test(value);
      };
  
      if (!isNaN(a) && !isNaN(b)) {
        return Number(a) - Number(b);
      } else if (isDate(a) && isDate(b)) {
        const dateA = new Date(a.split("-").reverse().join("-"));
        const dateB = new Date(b.split("-").reverse().join("-"));
        return dateA - dateB;
      } else {
        return String(a).localeCompare(String(b), undefined, {
          numeric: true,
          sensitivity: "base",
        });
      }
    };
  
    if (sortInfo.order === "asc") {
      return compareValues(aValue, bValue);
    } else {
      return compareValues(bValue, aValue);
    }
  });
  
  

  // const sortedData = [...data].sort((a, b) => {
  //   const aValue = a[sortInfo.field];
  //   const bValue = b[sortInfo.field];

  //   if (aValue < bValue) {
  //     return sortInfo.order === "asc" ? -1 : 1;
  //   }
  //   if (aValue > bValue) {
  //     return sortInfo.order === "asc" ? 1 : -1;
  //   }
  //   return 0;
  // });

  // Determine the text alignment of the second row's <td> elements
  const secondRowTextAlign =
    sortedData.length > 0
      ? columns.map((col) => {
          const field = col.field;
          const value = sortedData[0][field]; // Assuming the first row has the alignment you want
          return isNaN(value) ? "left" : "right"; // Adjust this logic based on your requirements
        })
      : [];

  return (
    <>
      <div style={{ overflow: "hidden", width: "77vw" }}>
        <table>
          <tbody>
            <tr
              style={{
                // borderRadius: "8px",
                // border:"4px solid black",
                // borderRadius:"12px",
                height: "3em",
                backgroundImage:
                  "linear-gradient(to bottom, #c99cd8, rgb(254, 252, 254))",
                boxShadow: "5px 8px 18px #888888",
                marginBottom: "5rem",
              }}
            >
             

             {columns.map((col, index) => (
  <th
    key={index}
    style={{
      minWidth:
        col.field === "Load Id"
          ? "1rem"
          : col.field === "Destination City" ||
            col.field === "Origin City"
          ? "5rem"
          : col.field === "Des Zip Cd" || col.field === "Origin Zip Cd"
          ? "3rem"
          : "100px", // Default width for the heading
      maxWidth:
        col.field === "Load Id"
          ? "1rem"
          : col.field === "Destination City" ||
            col.field === "Origin City"
          ? "5rem"
          : col.field === "Des Zip Cd" || col.field === "Origin Zip Cd"
          ? "3rem"
          : "200px", // Default width for the heading
      whiteSpace: "nowrap", // Prevent text from wrapping
      textAlign: col.field === "Load Id" || col.field === "load_wt" ? "center" : secondRowTextAlign[index] || "left", // Center "Load Id", or use second row's alignment for other columns
      color: "black", // You can adjust the color as needed
      fontWeight: "bolder", // You can adjust the font weight as needed
      // border: "2px solid red", // You can adjust the border as needed
      padding: "0rem 1rem",
    }}
    onClick={() => handleSort(col.field)}
  >
    {col.headerName
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ")}
  </th>
))}


            </tr>

            <br />

            {sortedData.map((row, rowIndex) => (
              <tr
                style={{
                  boxShadow: "0px 2px 5px rgb(94, 90, 91) inset",
                  backgroundColor: "#c99cd8",
                  backgroundImage:
                    "linear-gradient(to bottom, #c99cd8, rgb(254, 252, 254))",
                }}
                key={rowIndex}
              >
                {columns.map((col, colIndex) => (
                  <td
                    key={colIndex}
                    style={{
                      minWidth:
                        col.field === "Load Id"
                          ? "1rem"
                          : col.field === "Destination City" ||
                            col.field === "Origin City"
                          ? "5rem"
                          : col.field === "Des Zip Cd" ||
                            col.field === "Origin Zip Cd"
                          ? "3rem"
                          : "100px", // Default width for data rows
                      maxWidth:
                        col.field === "Load Id"
                          ? "1rem"
                          : col.field === "Destination City" ||
                            col.field === "Origin City"
                          ? "5rem"
                          : col.field === "Des Zip Cd" ||
                            col.field === "Origin Zip Cd"
                          ? "3rem"
                          : "200px", // Default width for data rows
                      whiteSpace: "nowrap", // Prevent text from wrapping
                      // textAlign:
                      // col.field === "load_id" || col.field === "weight"
                      //   ? "center"
                      //   : secondRowTextAlign[index] || "left",
                      textAlign:
                        colIndex === 0|| col.field === "load_wt"
                          ? "center"
                          : isNaN(row[col.field])
                          ? "left"
                          : "right",
                      color: row[col.field] === "P" ? "red" : "black",
                      fontWeight: row[col.field] === "P" ? "bold" : "normal",
                      // border: "2px solid black",
                      padding: "0rem 1rem",
                    }}
                  >
                    {row[col.field] === "P" ? (
                      "POSTED"
                    ) : row[col.field] === "O" ? (
                      <span style={{ color: "yellow", fontWeight: "bold" }}>
                        ON-ROUTE
                      </span>
                    ) : row[col.field] === "L" ? (
                      <span style={{ color: "green", fontWeight: "bold" }}>
                        LOADED
                      </span>
                    ) : row[col.field] === "D" ? (
                      <span style={{ color: "orange", fontWeight: "bold" }}>
                        DRAFTED
                      </span>
                    ) : (
                      row[col.field]
                    )}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default FreightTable;
