
import React from "react";
import axios from "axios";
import { useEffect, useState } from "react";
import {
  LaptopOutlined,
  NotificationOutlined,
  LogoutOutlined,
  SettingOutlined,
  SearchOutlined,
  UploadOutlined,
  UserOutlined,
  VideoCameraOutlined,
} from "@ant-design/icons";

// import "./PayrollPage.css";
import { Breadcrumb, Input, Layout, Menu, theme } from "antd";
import logoImage from "../../../assets/LOGO.png";
import SiderContentA from "../../../components/Admin/SiderContentAdmin/SiderContentA";
import FeildDataAdmin from "../../../components/Admin/FeildDataAdmin/FeildDataAdmin";
//import LoginPage from '../LoginPage/LoginPage';
import { DataProvider } from "../../../Context/dataContext";
import { Link, Route, Routes } from "react-router-dom";


const { Header, Content, Sider } = Layout;
const { Item: MenuItem } = Menu;
const { Search } = Input;



const FreightAdmin = (props) => {
  

  console.log(props);

  const FreightData = {
    URL: props.url,
    
  };

 
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  return (
    <Layout>
      <Header
        style={{
          background: "linear-gradient(to right, #F0E5F3, #FFFFFF)",
          padding: "0rem",
          height: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {/* Insert your logo here */}
          <img src={logoImage} style={{ height: "2rem", width: "192px" }} />
          
         
        </div>
      </Header>

      <Layout >
        <Sider
        width={150}
          className="sidebar"
          style={{ background: colorBgContainer, width: "10rem" }}
        >
          <SiderContentA
            url={props.url}
            
          />
        </Sider>
        <Layout
          style={{
            padding: "3.5rem 3rem 1rem 3rem",
            background: "#F0E5F3",
            width: "100%",
          }}
        >
          <DataProvider value={FreightData}>
            <Content
              style={{
                padding: "0rem 1rem 1rem 1rem",
                minHeight: "45rem",
                background: colorBgContainer,
                overflow: "auto",
              }}
            >
              <FeildDataAdmin />
            </Content>
          </DataProvider>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default FreightAdmin;
