function Validation(values, mypwd, conPwd) {
  console.log("testing");
  let error = {};
  const email_pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const password_pattern =
    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$/;

  // const password_pattern = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9]{8,}$/;
  // const password_pattern = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9]{8,}$/;

  const testPassword = "Password123";
  const wpas = "asdf";
  console.log("checking the password");
  console.log(password_pattern.test(testPassword)); // Should output true
  console.log(password_pattern.test(wpas)); // Should output true

  if (values.email === "") {
    error.email = "Email should not be empty";
  } else if (!email_pattern.test(values.email)) {
    error.email = "Email format is invalid";
  } else {
    error.email = "";
  }

  if (values.pwd === "") {
    error.password = "Password should not be empty";
  } else if (!password_pattern.test(values.pwd)) {
    error.password =
      "Password must contain at least 8 characters with at least one uppercase letter, one lowercase letter, one digit, and one special character (!@#$%^&*).";
  } else {
    error.password = "";
  }

  if (conPwd !== mypwd) {
    console.log("inside the if block of validation for confirm password");
    error.pwd = "Confirm Password should Match with the Password";
  } else {
    error.pwd = "";
  }

  return error;
}

export default Validation;
