import React from "react";
import Template from "./template";

const FtoFifteen = () => {
  return (
    <>
      <Template name={"Fifteen to Thirty"} case={2}/>
    </>
  );
};

export default FtoFifteen;
