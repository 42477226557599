import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import "./Headbox";
import Headbox from "./Headbox";
import "./dash.css";
import { Link } from "react-router-dom";
// import Amap from "../MyMap/amap";
import DataContext from "../../../Context/dataContext";

export default function Dashboard(props) {
  const employeeData = useContext(DataContext);
  console.log(props);
  console.log(employeeData);

  const [data, setData] = useState([]);
  const [newdata, setNewData] = useState([]);
  const [newtrucker, setNewTrucker] = useState(0);
  const [transactions, setTransactions] = useState([]);
  const [subTrucker, setSubTrucker] = useState([]);
  const [subShipper, setSubShipper] = useState([]);

  console.log("admin testing is here");

  useEffect(() => {
    // pending-bids
    console.log("admin testing is here");
    axios
      .get(employeeData.URL + "/api/v1/admin/total", {
        withCredentials: true,
      })
      .then((response) => {
        setData(response.data.data);
        console.log(response);
        setNewData(response.data.data.TodaysBookingDetails.rows);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });

    axios
      .get(employeeData.URL + "/api/v1/admin/trucker-new-week", {
        withCredentials: true,
      })
      .then((response) => {
        // setData(response.data.data);
        console.log(response);
        setNewTrucker(response.data.data.newtrucker.rows[0].count);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });

    axios
      .get(employeeData.URL + "/api/v1/admin/transactions", {
        withCredentials: true,
      })
      .then((response) => {
        // setData(response.data.data);
        console.log(response);
        setTransactions(response.data.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });

    axios
      .get(employeeData.URL + "/api/v1/admin/subscribe", {
        withCredentials: true,
      })
      .then((response) => {
        console.log(response);
        // setData(response.data.data);
        setSubTrucker(response.data.data.TRUCKER.rows[0].total);
        setSubShipper(response.data.data.Shipper.rows[0].total);
        // setTransactions(response.data.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  return (
    <>
      {data.TotalexecutedLoad &&
      data.totalmovingloads &&
      data.totalpostedloads &&
      data.thisweeknewshipper ? (
        <div id="topbox">
          {/* <Link className="myLinks" to="/admin/toal-Executed-loads" state: { apiData }>
            <Headbox data={data.TotalexecutedLoad.rows[0]?.count} abc="TOTAL EXECUTED LOADS" />
        </Link> */}
          <Link
            className="myLinks"
            to={{ pathname: "/admin/toal-Executed-loads", state: "hi" }}
          >
            <Headbox
              data={data.TotalexecutedLoad.rows[0]?.count}
              abc="TOTAL EXECUTED LOADS"
            />
          </Link>

          <Link className="myLinks" to="/admin/moving-loads">
            <Headbox
              data={data.totalmovingloads.rows[0].count}
              abc="MOVING LOADS"
            />
          </Link>

          <Link className="myLinks" to="/admin/toal-loads-posted">
            <Headbox
              data={data.totalpostedloads.rows[0]?.count}
              abc="TOTAL LOADS POSTED"
            />
          </Link>

          <Link className="myLinks" to="/admin/new-shipper">
            <div
              style={{
                margin: "1.4rem 0rem 0rem 0rem",
                borderRadius: "5px",
                width: "8rem",
                height: "10rem",
                boxShadow: "-2px 4px 6px rgba(0, 0, 0, 0.3)",
                background: "linear-gradient(to bottom, #D9BFE0, #fff)",
              }}
            >
              <div
                style={{
                  
                  borderRadius: "5px",
                  width: "5rem",
                  height: "1rem",
                  marginLeft: "1.8rem",
                  marginTop: "1.4rem",
                  color: "black",
                }}
              >
                This Week
              </div>
              <br />
              <div style={{ fontSize: "2.5rem", color: "black" }}>
                {data.thisweeknewshipper.rows[0]?.count}
              </div>
              <br />
              <div style={{ fontSize: "1rem" }}>New Shippers</div>
            </div>
          </Link>
        </div>
      ) : (
        // Loading state for the entire topbox
        <div
          style={{
            textAlign: "center",
            marginTop: "1rem",
            fontStyle: "italic",
            color: "#888",
          }}
        >
        
        </div>
      )}

      {/* the second line */}

      <div id="topbox" style={{}}>
        <div
          style={{
            boxShadow: "inset 1px 2px 1px 1px gray",
            width: "33%",
            height: "20rem",
            background: "linear-gradient(to bottom, #D9BFE0, #fff)",
          }}
        >
          <div style={{ fontSize: "2rem" }}>Transactions</div>
          <div>
            <>
              {transactions &&
              transactions.AmountPaindbyShippers &&
              transactions.AmountPaybletoTrucker &&
              transactions.AverageRevenueperLoad &&
              transactions.AverageRevenueperShipper &&
              transactions.AverageRevenueperTrucker ? (
                <div>
                  <div
                    style={{
                      margin: "auto",
                      width: "90%",
                      height: "2rem",
                      backgroundColor: "white",
                      boxShadow: "1px 0px 1px 0px gray",
                      marginBottom: "1rem",
                      display: "flex",
                      justifyContent: "space-around",
                    }}
                  >
                    <span>Amount Payable to Truckers</span>
                    <span>
                      ${transactions.AmountPaybletoTrucker.rows[0].final_amt}
                    </span>
                  </div>

                  <div
                    style={{
                      margin: "auto",
                      width: "90%",
                      height: "2rem",
                      backgroundColor: "white",
                      boxShadow: "1px 0px 1px 0px gray",
                      marginBottom: "1rem",
                      display: "flex",
                      justifyContent: "space-around",
                    }}
                  >
                    <span>Amount Paid by Shippers</span>
                    <span>
                      ${transactions.AmountPaindbyShippers.rows[0].amount}
                    </span>
                  </div>

                  <div
                    style={{
                      margin: "auto",
                      width: "90%",
                      height: "2rem",
                      backgroundColor: "white",
                      boxShadow: "1px 0px 1px 0px gray",
                      marginBottom: "1rem",
                      display: "flex",
                      justifyContent: "space-around",
                    }}
                  >
                    <span>Average Revenue per Load</span>
                    <span>
                      ${transactions.AverageRevenueperLoad.rows[0].amount}
                    </span>
                  </div>

                  <div
                    style={{
                      margin: "auto",
                      width: "90%",
                      height: "2rem",
                      backgroundColor: "white",
                      boxShadow: "1px 0px 1px 0px gray",
                      marginBottom: "1rem",
                      display: "flex",
                      justifyContent: "space-around",
                    }}
                  >
                    <span>Average Revenue per Shipper</span>
                    <span>
                      ${transactions.AverageRevenueperShipper.rows[0].amount}
                    </span>
                  </div>

                  <div
                    style={{
                      margin: "auto",
                      width: "90%",
                      height: "2rem",
                      backgroundColor: "white",
                      boxShadow: "1px 0px 1px 0px gray",
                      marginBottom: "1rem",
                      display: "flex",
                      justifyContent: "space-around",
                    }}
                  >
                    <span>Average Revenue per Trucker</span>
                    <span>
                      ${transactions.AverageRevenueperTrucker.rows[0].amount}
                    </span>
                  </div>
                </div>
              ) : (
                <div
                  style={{
                    textAlign: "center",
                    marginTop: "1rem",
                    fontStyle: "italic",
                    color: "#888",
                  }}
                >
                  Loading data...
                </div>
              )}
            </>
          </div>
        </div>

        <div
          style={{
            boxShadow: "inset 1px 2px 1px 1px gray",
            width: "43%",
            height: "20rem",
            background: "linear-gradient(to bottom, #D9BFE0, #fff)",
          }}
        >
          <div style={{ fontSize: "2rem" }}>Today’s Booking Details</div>
          <div>
            <table style={{ borderSpacing: "0 40px" }}>
              <thead>
                <tr>
                  <th>Load ID</th>
                  <th>Amount</th>
                  <th>Status</th>
                </tr>
              </thead>

              <tbody>
                {newdata.map((item) => (
                  <React.Fragment key={item.load_id}>
                    <tr>
                      <td>{item.load_id}</td>
                      <td>{item.load_amount}</td>
                      <td
                        style={{
                          backgroundColor:
                            item.booking_status === "completed"
                              ? "rgb(122, 240, 157)"
                              : item.booking_status === "cancelled"
                              ? "rgb(247, 87, 109)"
                              : "rgb(247, 205, 126)",
                          fontWeight: "bold",
                        }}
                      >
                        {item.booking_status}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="3" style={{ height: "0.5rem" }}></td>
                    </tr>
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </div>
        </div>

      
      </div>

      {/* the last line  */}
      <div id="topbox">
        <Link className="myLinks" to="/admin/top-user">
          <div
            style={{
              border: "1px solid #ddd",
              width: "17rem",
              boxShadow: "-2px 4px 6px rgba(0, 0, 0, 0.3)",
              height: "10rem",
              marginTop: "1.2rem",
              background: "linear-gradient(to bottom, #D9BFE0, #fff)",
              overflow: "hidden",
            }}
          >
            <span
              style={{ color: "black", marginTop: "2rem", fontSize: "3rem" }}
            >
              Top 5 User
            </span>
            <br />
            <br />
            <br />
            <span
              style={{
                margin: "10rem 0rem 0rem 7rem",
                color: "black",
                fontSize: "1rem",
              }}
            >
              {" "}
              View All
            </span>
          </div>
        </Link>

        <div
          style={{
            border: "1px solid #ddd",
            width: "17rem",
            boxShadow: "-2px 4px 6px rgba(0, 0, 0, 0.3)",
            height: "10rem",
            marginTop: "1.2rem",
            background: "linear-gradient(to bottom, #D9BFE0, #fff)",
            overflow: "hidden",
          }}
        >
          <span style={{ color: "black", marginTop: "2rem", fontSize: "2rem" }}>
            Shipper Subscription
          </span>
          <br />
          <br />
          <br />
          {/* {subShipper.rows.length > 0 && (
  <span style={{ margin: "0rem 0rem 0rem 3rem", color: "black", fontSize: "1rem" }}>
    Total: {subShipper.rows[0].total}
  </span>
)} */}{" "}
          Total:
          {subShipper ? subShipper : "No data"}
          {/* <span style={{ margin: "0rem 0rem 0rem 3rem", color: "black", fontSize: "1rem" }}>Total: 950</span> */}
        </div>

        <div
          style={{
            border: "1px solid #ddd",
            width: "17rem",
            boxShadow: "-2px 4px 6px rgba(0, 0, 0, 0.3)",
            height: "10rem",
            marginTop: "1.2rem",
            background: "linear-gradient(to bottom, #D9BFE0, #fff)",
            overflow: "hidden",
          }}
        >
          <span style={{ color: "black", marginTop: "2rem", fontSize: "2rem" }}>
            Truckers Subscription
          </span>
          <br />
          <br />
          <br />
          {/* {subTrucker.rows.length > 0 && (
  <span style={{ margin: "0rem 0rem 0rem 3rem", color: "black", fontSize: "1rem" }}>
    Total: {subTrucker.rows[0].total}
  </span>
)} */}
          Total:
          {subTrucker ? subTrucker : "No data"}
          {/* <span style={{ margin: "0rem 0rem 0rem 3rem", color: "black", fontSize: "1rem" }}>Total: {subTrucker.rows[0].total}</span> */}
        </div>

        <Link className="myLinks" to="/admin/new-trucker">
          <div
            style={{
              margin: "1.4rem 0rem 0rem 0rem",
              borderRadius: "5px",
              width: "8rem",
              height: "10rem",
              boxShadow: "-2px 4px 6px rgba(0, 0, 0, 0.3)",
              background: "linear-gradient(to bottom, #D9BFE0, #fff)",
            }}
          >
            <div
              style={{
                
                borderRadius: "5px",
                width: "5rem",
                height: "1rem",
                marginLeft: "1.8rem",
                marginTop: "1.4rem",
                color: "black",
              }}
            >
              This Week
            </div>
            <br />
            <div style={{ fontSize: "2.5rem", color: "black" }}>
              {newtrucker ? Number(newtrucker) : 0}
            </div>
            <br />
            <div style={{ fontSize: "1rem" }}>New Truckers</div>
          </div>
        </Link>
      </div>
    </>
  );
}
