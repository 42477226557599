import axios from "axios";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Validation from "./validation";
import classes from "./LoginPage.module.css";
import logoImage from "../../../assets/LOGO.png";
import { Content } from "antd/es/layout/layout";

import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";
import { InfoCircleOutlined, UserOutlined, EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { EyeOutlined } from '@ant-design/icons';
import { message, Input, Tooltip, Row, Col, Button } from "antd";

function Login(props) {
  // console.log(props);
  const [duplicateEmailError, setDuplicateEmailError] = useState(false);
  const [type, setType] = useState("trucker");
  const [values, setValues] = useState({
    email: "",
    password: "",
    userType: type,
  });
  const [email, setUserCode] = useState("");
  const [password, setPassword] = useState("");
  const [googleLoginError, setGoogleLoginError] = useState(null);

  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const [backendError, setBackendError] = useState([]);
  const [showPassword, setShowPassword] = useState(false);

  const handleInput = (event) => {
    setValues((prev) => ({ ...prev, [event.target.name]: event.target.value }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(values);
    console.log(props.url);
    const err = Validation(values);
    setErrors(err);
    if (err.email === "" && err.password === "") {
      axios
        .post(props.url + "/api/v1/type/login", values, {
          withCredentials: true,
          credentials: "include",
        })
        .then((res) => {
          console.log(res);
          if (res.data.errors) {
            setBackendError(res.data.errors);
          } else {
            setBackendError([]);
            console.log(type, "type");

            if (res.statusText === "OK") {
              localStorage.setItem("myId", res.data.data.user.account_code);

              props.setAccountId(res.data.data.user.account_code);
              console.log(res.data.data.user.email);
              localStorage.setItem("mail", res.data.data.user.email);
              props.setAccoountMail(res.data.data.user.email);
              if (
                res.data.data.user.account_type === "trucker" &&
                res.data.data.user.validation === null
              ) {
                //   axios
                //     .get(props.url + "/api/v1/dashboard/tobid", {
                //       withCredentials: true,
                //     })
                //     .then((response) => {
                //       console.log(response);
                //       props.setDanlysis(response.data.data);
                //     });
                //   axios
                //     .get(props.url + "/api/v1/dashboard/loads", {
                //       withCredentials: true,
                //     })
                //     .then((response) => {
                //       console.log(response);
                //       props.setAnalysis(response.data.data);
                //     });

                //   axios
                //     .get(props.url + "/api/v1/dashboard/Myloads", {
                //       withCredentials: true,
                //     })
                //     .then((response) => {
                //       console.log(response);
                //       props.setMyload(response.data.data);
                //     });
                axios
                  .get(props.url + "/api/v1/profile/check-profile", {
                    withCredentials: true,
                  })
                  .then((getResponse) => {
                    console.log(getResponse);
                    console.log("Status Code: ", getResponse.status);
                    if (getResponse.status === 200) {
                      console.log("this is the if ");
                      props.setCheck(true);
                    } else {
                      props.setCheck(false);
                      console.log("this is the else");
                    }
                  })
                  .catch((getErr) => {
                    console.log("Error in GET request:", getErr);
                  });

                navigate("/trucker/dashboard");
              } else {
                message.error({
                  content:
                    "Action Needed: Validate Your Email for Account Access",
                  className: "custom-class",
                  style: {
                    marginTop: "20vh",
                  },
                  duration: 4,
                });
              }
            } else {
              alert("No record existed");
            }
          }
        })
        .catch((err) => {
          if (err) {
            // alert("Wrong Email or Password")
            setDuplicateEmailError(true);
          }
          // console.log(err)
          console.log("hey there it is the error im printinfg out" + err);
        });
    }
  };

  const imageStyle = {
    // position:'absolute',

    marginTop: "20px",
    marginLeft: "20px",
    backgroundImage: `url(${logoImage})`,
    backgroundPosition: "top left",
    backgroundRepeat: "no-repeat",
    backgroundSize: "200px",
  };

  const style = {
    width: "100vw",
  };

  return (
    <div className={classes["container"]} style={style}>
      <div
        className={classes["form-container"]}
        style={{ boxShadow: "gray 5px 5px 2px ", height: "38rem" }}
      >
        <a style={{}} href="/">

          <div className={classes["logo"]} style={imageStyle}></div>
        </a>
        <h2
          style={{
            color: "#8A31A8",
            // textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)',
            // transform: 'rotateX(25deg) translateZ(20px)',

            marginTop: "30px",
          }}
        >
          Hi There
        </h2>
        <h2
          style={{
            fontSize: "17px",
            marginTop: "-1rem",
            color: "rgb(162 104 182)",

          }}
        >
          Welcome back!
        </h2>

        {backendError ? (
          backendError.map((e) => <p className="text-danger">{e.msg}</p>)
        ) : (
          <span></span>
        )}
        <form action="" onSubmit={handleSubmit}>

          <Row gutter={[16, 0]} align="middle" style={{ display: 'flex', alignItems: 'center' }}>
            <Col flex="auto">
              <Input
                placeholder="Enter Email"
                onChange={handleInput}
                name="email"
                // className="form-control rounded-0"
                // prefix={<UserOutlined className="site-form-item-icon" />}
                style={{
                  width: "20rem", // Adjust the width as needed
                  height: "2.5rem", // Adjust the height as needed
                  border: "1px solid black",
                  boxShadow: '10 2px 17px  rgba(0, 0, 0, 0.2)', // Box shadow style
                  // border:"2px solid red",
                  margin: "auto"
                }}
              />
            </Col>

          </Row>

          {errors.email && <span className="text-danger">{errors.email}</span>}

          <div
            className="mb-3"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "1rem",
            }}
          >
            <div
              style={{
                // border:"2px solid red",
                width: "100%",
                display: "flex",
                // backgroundImage: "linear-gradient(to bottom, #BEA1C6, white)",
                // alignItems:"center",
                justifyContent: "center"
                // padding:"auto"


              }}
            >
              <Input.Password
                placeholder="Enter Password"
                onChange={handleInput}
                name="password"
                // className="form-control rounded-0 custom-input"
                style={{
                  width: "20rem", // Adjust the width as needed
                  height: "2.5rem", // Adjust the height as needed
                  border: "1px solid black",
                  boxShadow: '10 2px 17px 20px rgba(0, 0, 0, 0.2)', // Box shadow style
                  // border:"2px solid red",
                  margin: "auto"
                }}
                iconRender={visible => (visible ? <EyeOutlined /> : <EyeInvisibleOutlined />)}
              />

            </div>
          </div>
          {errors.password && (
            <span className="text-danger">{errors.password}</span>
          )}
          <div className={classes["remember-forgot"]}>
            <Link
              className={classes["thisilink"]}
              to="/forget"
              style={{ textDecoration: "none", marginTop: "1rem" }}
            >
              <span style={{ color: "rgb(162 104 182)", fontWeight: "bold" }}>
                Forgot Password
              </span>
            </Link>
          </div>

          <button
            type="submit"
            // className='btn btn-success w-100 custom-btn'

            className={classes["custom-btn"]}
          >
            Log in
          </button>

          <div
            style={{
              // border: "solid black",
              display: "flex",
              justifyContent: "center",
              marginTop: "1rem",
            }}
          >
            <GoogleOAuthProvider clientId="66230657890-idgmm57a1a6jrbb82j7o7voefajcp9ea.apps.googleusercontent.com">
              <GoogleLogin
                onSuccess={async (credentialResponse) => {
                  try {
                    // const token = "eyJ0eXAiO.../// jwt token";
                    const decoded = jwtDecode(credentialResponse.credential);

                    // Log the decoded object
                    console.log(decoded);

                    // Set email and password for login
                    const email = decoded.email;
                    const password = "googleAuth";

                    // Send email and password to your login API
                    const response = await axios
                      .post(
                        props.url + "/api/v1/type/login",
                        { email, password, userType: "trucker" }, // Assuming you want to keep userType
                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )

                      .then((res) => {
                        console.log(res);
                        if (res.data.errors) {
                          setBackendError(res.data.errors);
                        } else {
                          setBackendError([]);
                          console.log(type, "type");

                          if (res.statusText === "OK") {
                            localStorage.setItem(
                              "myId",
                              res.data.data.user.account_code
                            );
                            console.log(localStorage);

                            props.setAccountId(res.data.data.user.account_code);
                            if (res.data.data.user.account_type === "trucker") {
                              axios
                                .get(props.url + "/api/v1/dashboard/tobid", {
                                  withCredentials: true,
                                })
                                .then((response) => {
                                  console.log(response);
                                  props.setDanlysis(response.data.data);
                                });
                              axios
                                .get(props.url + "/api/v1/dashboard/loads", {
                                  withCredentials: true,
                                })
                                .then((response) => {
                                  console.log(response);
                                  props.setAnalysis(response.data.data);
                                });

                              axios
                                .get(props.url + "/api/v1/dashboard/Myloads", {
                                  withCredentials: true,
                                })
                                .then((response) => {
                                  console.log(response);
                                  props.setMyload(response.data.data);
                                });
                              axios
                                .get(
                                  props.url + "/api/v1/profile/check-profile",
                                  {
                                    withCredentials: true,
                                  }
                                )
                                .then((getResponse) => {
                                  console.log(getResponse);
                                  console.log(
                                    "Status Code: ",
                                    getResponse.status
                                  );
                                  if (getResponse.status === 200) {
                                    console.log("this is the if ");
                                    props.setCheck(true);
                                  } else {
                                    props.setCheck(false);
                                    console.log("this is the else");
                                  }
                                })
                                .catch((getErr) => {
                                  console.log("Error in GET request:", getErr);
                                });

                              navigate("/trucker/dashboard");
                            }
                          } else {
                            alert("No record existed");
                          }
                        }
                      })
                      .catch((err) => {
                        if (err) {
                          // alert("Wrong Email or Password")
                          setDuplicateEmailError(true);
                        }
                        // console.log(err)
                        console.log(
                          "hey there it is the error im printinfg out" + err
                        );
                      });

                    // Handle the response from the login API as needed
                    console.log(response);

                    // Add logic to navigate or perform actions based on the API response
                    if (response.status === 200) {
                      navigate("/trucker/dashboard");
                      // ... (other logic)
                    } else {
                      setGoogleLoginError("Login failed. Please try again."); // Set error message if login fails
                    }
                  } catch (error) {
                    console.error("Error in Google login:", error);
                    setGoogleLoginError("Please Sign Up First OR  ");
                  }
                }}
                onFailure={(error) => {
                  console.error("Google login failed:", error);
                  setGoogleLoginError(
                    "Google login failed. Please try again." + " OR "
                  );
                }}
              />
            </GoogleOAuthProvider>
          </div>
          {/* ... (previous code) */}
          {googleLoginError && (
            <span className="text-danger">{googleLoginError}</span>
          )}

          {duplicateEmailError && (
            <span className="text-danger">Email or Password Incorrect.</span>
          )}

          <div className={classes["remember-font"]}>
            <p>
              Dosen’t have an account?{" "}
              <Link to="/sigup" style={{ textDecoration: "none" }}>
                {" "}
                <span
                  style={{
                    color: "#AF71C3",
                  }}
                >
                  {" "}
                  Sign up{" "}
                </span>{" "}
              </Link>
            </p>
          </div>
         
        </form>
      </div>
    </div>
  );
}

export default Login;
