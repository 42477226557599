import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import FreightTable from "../../FreightTable/FreightTable";
import DataContext from "../../../Context/dataContext";

const TotalLoadsPosted = () => {
  const [row, setRow] = useState();
  const [columns, setColumns] = useState();
  const employeeData = useContext(DataContext);

  useEffect(() => {
    // pending-bids
    console.log("admin testing is here");
    axios
      .get(employeeData.URL + "/api/v1/admin/total", {
        withCredentials: true,
      })
      .then((response) => {
        // setData(response.data.data.detailofmovingloads.rows);
        console.log(response);

        setRow((rows) => {
          let newRows = response.data.data.detailoftotalpostedloads.rows.map(
            (row, index) => {
              return {
                id: index + 1,
                ...row,
              };
            }
          );
          return newRows;
        });

        setColumns((columns) => {
          let newCols = response.data.data.detailoftotalpostedloads.fields.map(
            (col) => {
              return {
                field: col.name,
                headerName: col.name
                  .split("_")
                  .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                  .join(" "),

                width: 150,
              };
            }
          );
          return newCols;
        });
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  return (
    <>
      <div>
        <h1>This is Total Load Posted</h1>
      </div>

      {row && columns.length > 0 ? (
        <div style={{ display: "inline-block" }}>
          <FreightTable data={row} columns={columns} />
        </div>
      ) : (
        <div>No matching results found.</div>
      )}
    </>
  );
};

export default TotalLoadsPosted;
