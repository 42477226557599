import { Row, Col, Form, Input, Space, Button, Table, Select } from "antd";
import classes from "../Pages.module.css";
import { MinusCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { useForm } from "react-hook-form";
import { useEffect } from "react";
import HeaderTable from "./HeaderTable";
import { color } from "@mui/system";
import { useState } from "react";
import DataField from "./DataField";
const { Option } = Select;

const GradeDetailsEdit = (props) => {
  console.log(props);
  const [totalWeight, setTotalWeight] = useState("");
  const [amountUpValue, setAmountUpValue] = useState("");
  const [myWeight, setMyWeight] = useState(true);
  const [kg, setKg] = useState();
  const [lbs, setLbs] = useState();
  const [myValue, setMyValue] = useState(true);
  const [mynValue, setnMyValue] = useState(true);

  //  there we add validation for multiple item description

  const [selectedOption, setSelectedOption] = useState("option1");

  const handleRadioChange = (event) => {
    setSelectedOption(event.target.value);
    console.log("this  the radio");
    let myNewWeight 
    const value = event.target.value;
    console.log(value,"this is the value");

    
   

    console.log(props.fdata.weight, " thisis the testing");
    
    console.log(props, " okay thissisth e props we have");
    if(props.fdata.itemSize){
      myNewWeight = props.fdata.itemSize[0].load_wt;
    }else{
      myNewWeight =0;
    }
    props.setData((fdata) => {
      if (value === "option1") {
        if (props.fdata.itemSize.length < 2) {
          console.log(" this sit he small confition");

          if(props.fdata.itemSize[0].uom_code==1 ){
            console.log(props,"goodMorning");
            myNewWeight = myNewWeight * 0.453592 ;
            // setnMyValue(false)
            console.log("bro now you have this ");
          }
          // setTotalWeight(myNewWeight);
        } else {
          console.log(kg, " we are inthe kg");
          myNewWeight = kg;

          setTotalWeight(kg);
        }
      } else {
        if (props.fdata.itemSize.length < 2) {
          if(props.fdata.itemSize[0].uom_code == 1){
            myNewWeight = props.fdata.itemSize[0].load_wt
            return {
              ...fdata,
              weight: myNewWeight,
            };
          }


          console.log(" this sit he small confition");
          if(myNewWeight && myValue ){
            myNewWeight = myNewWeight * 2.20462;
       
            console.log("bro now you have this ");
          }
          if(props.fdata.itemSize==undefined){
            console.log(" hey there");
          }
          else{
            if(props.fdata.itemSize[0]==undefined){
              console.log(" hey there");
            }else{
              if (props.fdata.itemSize[0].uom_code == 2) {
                setTotalWeight(myNewWeight);
              }
             else {
              setTotalWeight(myNewWeight);
             }
              

            }
            
          }

           
        } else {
          console.log(lbs, " we are in the lbs");
          myNewWeight = lbs;
          setTotalWeight(lbs);
        }
      }
      return {
        ...fdata,
        weight: myNewWeight,
      };
    });
  };



  const handleLength = (e, lname, fieldKey) => {
    console.log("hi lenght");
    if (lname === "lenght") {
      const numericValue = e.target.value.replace(/\D/g, "");

      // Additional validation: Check if the field is supposed to be numeric
      if (!isNaN(numericValue) || e.target.value === "") {
        // Update the form field with the numeric or empty value
        props.setData((data) => {
          const updatedItemSize = data.itemSize.map((item, index) => {
            // Identify the item to update based on the name and fieldKey
            if (index === fieldKey && item.length !== undefined) {
              item.length = numericValue;
            }
            return item;
          });

          return {
            ...data,
            itemSize: updatedItemSize,
          };
        });
      }
    }
  };

  const handleWidth = (e, name, fieldKey) => {
    if (name === "width") {
      const numericValue = e.target.value.replace(/\D/g, "");

      // Additional validation: Check if the field is supposed to be numeric
      if (!isNaN(numericValue) || e.target.value === "") {
        // Update the form field with the numeric or empty value
        props.setData((data) => {
          const updatedItemSize = data.itemSize.map((item, index) => {
            // Identify the item to update based on the name and fieldKey
            if (index === fieldKey && item.width !== undefined) {
              item.width = numericValue;
            }
            return item;
          });

          return {
            ...data,
            itemSize: updatedItemSize,
          };
        });
      }
    }
  };

  const handleHeight = (e, name, fieldKey) => {
    if (name === "height") {
      const numericValue = e.target.value.replace(/\D/g, "");

      // Additional validation: Check if the field is supposed to be numeric
      if (!isNaN(numericValue) || e.target.value === "") {
        // Update the form field with the numeric or empty value
        props.setData((data) => {
          const updatedItemSize = data.itemSize.map((item, index) => {
            // Identify the item to update based on the name and fieldKey
            if (index === fieldKey && item.height !== undefined) {
              item.height = numericValue;
            }
            return item;
          });

          return {
            ...data,
            itemSize: updatedItemSize,
          };
        });
      }
    }
  };
  // it is for validation of weight
  const handleWeight2 = (e, name, fieldKey) => {
    // console.log(" this is the handleWeight 2");
    console.log(e, " thisis e");
    console.log(name, " thisis name");
    console.log(fieldKey, " thisis fieldKey");

    if (name === "load_wt") {
      const numericValue = e.target.value.replace(/\D/g, "");

      // Additional validation: Check if the field is supposed to be numeric
      if (!isNaN(numericValue) || e.target.value === "") {
        // Update the form field with the numeric or empty value
        props.setData((data) => {
          const updatedItemSize = data.itemSize.map((item, index) => {
            // Identify the item to update based on the name and fieldKey
            if (index === fieldKey && item.load_wt !== undefined) {
              item.load_wt = numericValue;
            }
            return item;
          });

          return {
            ...data,
            itemSize: updatedItemSize,
          };
        });
      }
    }
  };

  // This is where you can use the setAmountUpValue function also validation
  const handleInputChange = (e, propertyName) => {
    const { value } = e.target;
    // const newValue = value.replace(/^[0-9]+$/);

    var regexMap = {
      amount: /^[0-9]+$/,
    };

    // Check if the input matches the pattern
    if (!regexMap[propertyName].test(value)) {
      // Clear the input and show an error message
      e.target.value = "";
      e.target.setCustomValidity(
        "Invalid input. Please enter only numeric value ."
      );
      e.target.reportValidity();
    } else {
      // Input is valid, clear any previous error message
      e.target.setCustomValidity("");
    }

    if (!isNaN(value) || value === "") {
      props.setData((data) => ({
        ...data,
        [propertyName]: value,
      }));
    }
  };

  const handleValueChanges = (changedValues, allValues) => {
    // console.log(changedValues," this is th evalue check");
    // console.log(allValues," this is th evalue check");
    props.setData((data) => {
      return {
        ...data,
        itemSize: allValues.itemSize,
      };
    });
  };

  //  it is for calculate weight for both condition

  const handleWeight = (changedValues) => {
    console.log(changedValues, " check this out buddy");
    console.log(props.ad.UOM_CODE.rows, " this is the fdata");
    console.log(props, "hola");

    if (props.fdata.itemSize.length == 1) {
      // props.fdata.itemSize[0].load_wt
      props.setData((fdata) => {
        let wei = 0;
        console.log(fdata.itemSize[0].load_wt, " this isht e");
        const temp1 = fdata.itemSize[0].load_wt;
        console.log(changedValues,"this is the changed values");
        console.log(selectedOption,"this is the Selected Option");
        if(changedValues==1){

          if(selectedOption=="option1"){
            const temp_kg =temp1 * 0.453592
            return {
              ...fdata,
              weight:temp_kg, // or any default value you want
            };
          }
          if(selectedOption=="option2"){
            return {
              ...fdata,
              weight: temp1, // or any default value you want
            };
          }
        }
        if(changedValues==2){
          if(selectedOption=="option1"){
            return {
              ...fdata,
              weight: temp1, // or any default value you want
            };
          }
          if(selectedOption=="option2"){
           const temp_lbs=temp1 * 2.20462
            
            return {
              ...fdata,
              weight: temp_lbs, // or any default value you want
            };
          }
        }else{
        return {
          ...fdata,
          weight: fdata.itemSize[0].load_wt, // or any default value you want
        };
      }
      });
    } else {
      props.setData((fdata) => {
        if (fdata.itemSize[0] === undefined) {
          // console.log(props, "cool this is working");
          // props.setData(fdata.itemSize[0].load_wt=0)

           fdata.itemSize[0] = {
    load_wt: 0
  };
          // alert("please fill the at least one  weight")
          // fdata.itemSize[0].load_wt=0;
        }
        if (fdata.itemSize.length === 0 || fdata.itemSize[0] === undefined) {
          return {
            ...fdata,
            weight: 0, // or any default value you want
          };
        }
        // alert("hello")
        console.log("ytu");
        // console.log(data, "data after minus");
        console.log(fdata.itemSize);
        let loadWeight = 0.0;
        for (let t = 0; t < fdata.itemSize.length; t++) {
          let weight = 0.0;

          if (
            fdata.itemSize[t] !== undefined &&
            fdata.itemSize[t].load_wt !== undefined
          ) {
            console.log(fdata.itemSize[0], " well this is the dat ");

            let alls;
            let lbs = 0.0;
            let kg = 0.0;
            let advanceadd;
            let initialUomCode = fdata.itemSize[0].uom_code; // Assuming there's at least one item

            for (let t = 1; t < fdata.itemSize.length; t++) {
              if (fdata.itemSize[t] !== undefined) {
                if (fdata.itemSize[t].uom_code !== initialUomCode) {
                  alls = false;
                  console.log("we got the different");
                  break;
                } else {
                  alls = true;
                  console.log("allsame");
                }
              }
              if (fdata.itemSize[t] == undefined) {
                console.log(" are hold up");
              }
            }

            if (alls) {
              if (fdata.itemSize[t]  !== undefined) {
                if (fdata.itemSize[t].load_wt) {
                  console.log(" this is the same uom runniung");
                  weight = parseFloat(fdata.itemSize[t].load_wt, 10);
                  let nweight = weight * 2.23;
                  if (selectedOption == "option1") {
                    loadWeight += weight;
                  } else if (selectedOption == "option2") {
                    loadWeight += nweight;
                  }
                } else {
                  console.log("tjodso tjr case of nAN");
                  loadWeight += 0;
                }
              }
              if (fdata.itemSize[t] == undefined) {
                console.log(" are hold up");
              }
            } else {
              console.log(" okay we are in the else of different");
              for (let i = 0; i < fdata.itemSize.length; i++) {
                console.log(fdata.itemSize, " thi is the fadaata");
                if (fdata.itemSize[i] !== undefined) {
                  if (fdata.itemSize[i].load_wt) {
                    if (fdata.itemSize[i].uom_code === 1) {
                      console.log(
                        parseFloat(fdata.itemSize[i].load_wt),
                        " this is the 1"
                      );
                      lbs += parseFloat(fdata.itemSize[i].load_wt,10);
                    }
                    if (fdata.itemSize[i].uom_code === 2) {
                      console.log(
                        parseFloat(fdata.itemSize[i].load_wt,10),
                        " this is the 2"
                      );
                      kg += parseFloat(fdata.itemSize[i].load_wt,10);
                    }
                    if (fdata.itemSize[i].uom_code === undefined) {
                      console.log(
                        parseFloat(fdata.itemSize[i].load_wt),
                        " this is the undefined"
                      );
                      // Handle the case when uom_code is undefined
                      // For example, you can assume it's in a specific unit (lbs or kg) and add it accordingly
                      // Here, we'll assume it's in lbs
                      kg += parseFloat(fdata.itemSize[i].load_wt,10);
                    }
                  } else {
                    console.log("tjodso tjr case of nAN");
                    loadWeight += 0;
                  }
                }
                if (fdata.itemSize[i] == undefined) {
                  console.log(" are hold up");
                }

                // Convert lbs to kg and add to kg
                console.log(lbs, kg, " this ist dadd");
                let totalKg = lbs / 2.20462 + kg;

                // Convert kg to lbs and add to lbs
                let totalLbs = kg * 2.20462 + lbs;
                console.log("Total Weight in kg:", totalKg.toFixed(2)); // toFixed(2) limits decimals to 2
                console.log("Total Weight in lbs:", totalLbs.toFixed(2)); // toFixed(2) limits decimals to 2
                if (selectedOption == "option1") {
                  loadWeight = totalKg;
                } else if (selectedOption == "option2") {
                  loadWeight = totalLbs;
                }
                setKg(totalKg);

                setLbs(totalLbs);
              }
            }
          }
        }

        setTotalWeight(loadWeight);
        return {
          ...fdata,
          weight: loadWeight,
        };
      });
    }
  };

  ///   it for when on first page

  const handleWeight1 = (changedValues, allValues, index) => {
    console.log("hi this is the handleWeight1");
    let loadWeight = 0.0;
    let alls;
    let lbs = 0.0;
    let kg = 0.0;
    props.setData((data) => {
      if (
        data === undefined ||
        data.itemSize[0] === undefined ||
        data.itemSize.length === 0
      ) {
        // Handle the case where data or data.itemSize is undefined
        console.log(data.itemSize[0] === undefined);
        setTotalWeight("");
        // console.error("Data or data.itemSize is undefined");
        return {
          ...data,
          weight: 0, // or any default value you want
        };
      }

      const updatedItemSize = data.itemSize.filter((_, t) => t !== index);

      for (let t = 0; t < updatedItemSize.length; t++) {
        console.log(updatedItemSize, "this is the updatedItem");

        for (let p = 1; p < updatedItemSize.length; p++) {
          if (updatedItemSize[p] !== undefined) {
            if (updatedItemSize[p].uom_code !== updatedItemSize[0].uom_code) {
              alls = false;
              console.log("we got the different");
              break;
            } else {
              alls = true;
              console.log("allsame");
            }
          }
          if (updatedItemSize == undefined) {
            console.log(" are hold up");
          }
        }

        if (alls) {
          let i = 0;
          if (updatedItemSize[i] !== undefined) {
            let weight = 0.0;
            console.log(updatedItemSize, " we are checkin  gthee data");
            for (i; i < updatedItemSize.length; i++) {
              if (updatedItemSize[i] !== undefined) {
                if (updatedItemSize[i].load_wt) {
                  console.log(
                    " hey there this is the if condition of 352 line"
                  );
                  weight = parseFloat(updatedItemSize[i].load_wt);
                  // loadWeight += isNaN(weight) ? 0 : weight;
                  let nweight = weight * 2.23;
                  if (selectedOption == "option1") {
                    loadWeight += weight;
                  } else if (selectedOption == "option2") {
                    loadWeight += nweight;
                  }
                } else {
                  console.log("tjodso tjr case of nAN");
                  loadWeight += 0;
                }
              }
              if (updatedItemSize[i] == undefined) {
                console.log(" are hold up");
              }
            }
          }
          if (updatedItemSize[i] == undefined) {
            console.log(" are hold up");
          }
        } else {
          console.log(" okay we are in the else of different");
          for (let i = 0; i < updatedItemSize.length; i++) {
            console.log(updatedItemSize, " thi is the fadaata");
            if (updatedItemSize[i] !== undefined) {
              if (updatedItemSize[i].load_wt) {
                if (updatedItemSize[i].uom_code === 1) {
                  console.log(
                    parseFloat(updatedItemSize[i].load_wt,10),
                    " this is the 1"
                  );
                  lbs += parseFloat(updatedItemSize[i].load_wt,10);
                }
                if (updatedItemSize[i].uom_code === 2) {
                  console.log(
                    parseFloat(updatedItemSize[i].load_wt,10),
                    " this is the 2"
                  );
                  kg += parseFloat(updatedItemSize[i].load_wt,10);
                }
                if (updatedItemSize[i].uom_code === undefined) {
                  console.log(
                    parseFloat(updatedItemSize[i].load_wt),
                    " this is the undefined"
                  );
                  // Handle the case when uom_code is undefined
                  // For example, you can assume it's in a specific unit (lbs or kg) and add it accordingly
                  // Here, we'll assume it's in lbs
                  kg += parseFloat(updatedItemSize[i].load_wt,10);
                }
              } else {
                console.log("tjodso tjr case of nAN");
                loadWeight += 0;
              }
            }
            if (updatedItemSize[i] == undefined) {
              console.log(" are hold up");
            }

            // Convert lbs to kg and add to kg
            console.log(lbs, kg, " this ist dadd");
            let totalKg = lbs / 2.20462 + kg;

            // Convert kg to lbs and add to lbs
            let totalLbs = kg * 2.20462 + lbs;
            console.log("Total Weight in kg:", totalKg.toFixed(2)); // toFixed(2) limits decimals to 2
            console.log("Total Weight in lbs:", totalLbs.toFixed(2)); // toFixed(2) limits decimals to 2
            if (myWeight) {
              loadWeight = totalKg;
              if (selectedOption == "option1") {
                loadWeight = totalKg;
              } else if (selectedOption == "option2") {
                loadWeight = totalLbs;
              }
            }
            if (myWeight == false) {
              loadWeight = totalLbs;
              if (selectedOption == "option1") {
                loadWeight = totalKg;
              } else if (selectedOption == "option2") {
                loadWeight = totalLbs;
              }
            }
          }
        }
        console.log(loadWeight, " this isthe loadweight");

        return {
          ...data,
          itemSize: updatedItemSize,
          weight: loadWeight,
        };
      }
    });
  };

  // it is for second page to first page
  const handleWeight3 = (changedValues, allValues, index) => {
    console.log("hi thia i s handleWeight3");
    props.setData((fdata) => {
      if (fdata.itemSize[0] === undefined || fdata.itemSize.length === 0) {
        // Handle the case where data or data.itemSize is undefined
        console.log(fdata.itemSize[0] === undefined);
        setTotalWeight("");
        // console.error("Data or data.itemSize is undefined");
        return {
          ...fdata,
          weight: 0, // or any default value you want
        };
      }

      const updatedItemSize = fdata.itemSize.filter((_, t) => t !== index);

      let loadWeight = 0;
      for (let t = 0; t < updatedItemSize.length; t++) {
        const weight = parseInt(updatedItemSize[t].load_wt, 10);
        loadWeight += isNaN(weight) ? 0 : weight;
      }

      setTotalWeight(loadWeight);

      return {
        ...fdata,
        itemSize: updatedItemSize,
        weight: loadWeight,
      };
    });
  };

  // it is for second to first

  const handleWeight4 = (changedValues, allValues) => {
    console.log("ji");
    props.setData((fdata) => {
      if (fdata.itemSize.length === 0 || fdata.itemSize[0] === undefined) {
        // Handle the case where data or data.itemSize is undefined
        // console.log(data.itemSize[0] === undefined);
        // console.error("Data or data.itemSize is undefined");

        return {
          ...fdata,
          weight: 0, // or any default value you want
        };
      }
      // alert("hello")
      console.log("ytu");
      // console.log(data, "data after minus");
      console.log(fdata.itemSize);
      let loadWeight = 0;
      for (let t = 0; t < fdata.itemSize.length; t++) {
        let weight = 0;

        if (
          fdata.itemSize[t] !== undefined &&
          fdata.itemSize[t].load_wt !== undefined
        ) {
          weight = parseInt(fdata.itemSize[t].load_wt, 10);
        }

        loadWeight += weight;
      }

      setTotalWeight(loadWeight);
      return {
        ...fdata,
        weight: loadWeight,
      };
    });
  };

  // if (props.mode === false) {
  //   handleWeight4();
  // }

  // this is for the i=0 in when 1 to 0 page
  const handleWeight5 = (changedValues, allValues) => {
    console.log(" hi thsi is working fie");
    props.setData((fdata) => {
      if (fdata.itemSize.length === 0 || fdata.itemSize[0] === undefined) {
        // Handle the case where data or data.itemSize is undefined
        // console.log(data.itemSize[0] === undefined);
        // console.error("Data or data.itemSize is undefined");

        return {
          ...fdata,
          weight: 0, // or any default value you want
        };
      }
      console.log(" this is tghe valuie we have changed ", changedValues);
      // alert("hello")
      console.log("ytu");
      // console.log(data, "data after minus");
      console.log(fdata.itemSize);
      let loadWeight = 0;
      for (let t = 0; t < fdata.itemSize.length; t++) {
        let weight = 0;

        if (
          fdata.itemSize[t] !== undefined &&
          fdata.itemSize[t].load_wt !== undefined
        ) {
          weight = parseInt(fdata.itemSize[t].load_wt, 10);
        }

        loadWeight += weight;
      }

      setTotalWeight(loadWeight);
      return {
        ...fdata,
        weight: loadWeight,
      };
    });
  };





const  anotherFunction = ()=>{
  // console.log(selectedOption," okay so this si the another function");
  console.log(props, " newthing");
  let wt
  if(props.data.uom_code==2){
    wt =props.fdata.weight +  "KG"
  }if(props.data.uom_code==1){
    wt =props.fdata.weight + "LBS"
  }else
  {
    wt=props.fdata.weight + "KG"
  }
  props.setData((myData) => {
    return {
      ...myData, // Spread the existing data to keep other properties
      weight_type: wt
    };
  });
  
  
}


























  return props.mode ? (
    <Form
      layout="vertical"
      name="filter_form"
      onValuesChange={handleValueChanges}
      autoComplete="off"
    >
      <Form.List name="itemSize" initialValue={props.data}>
        {(fields, { add, remove }) => {
          return (
            <>
              {/* <HeaderTable /> */}

              {fields.map(({ key, name, fieldKey, ...restField }, index) => (
                <Space key={key} className={classes["Space"]} align="center">
                  <Row
                    gutter={16}
                    style={{
                      height: index === 0 ? "70px" : "45px",
                    }}
                  >
                    <p></p>

                    <Col lg={5} md={24}>
                      {index === 0 ? (
                        <Form.Item
                          {...restField}
                          name={[name, "item_name"]}
                          fieldKey={[fieldKey, "item_name"]}
                          label={
                            <div
                              style={{
                                padding: "0rem 0.5rem",
                                fontSize: "0.7rem",
                                fontWeight: "bold",
                                textTransform: "none",
                              }}
                              className={classes["Label"]}
                            >
                              Item Name <span style={{ color: "red" }}> *</span>
                            </div>
                          }
                          rules={[
                            {
                              // required: true,
                              message: "Field should not be blank!!",
                            },
                          ]}
                        >
                          <Input
                            style={{
                              width: "100%",
                              height: "100%",
                              float: "left",
                              backgroundColor: "#F0E5F3",

                              color: "#1777C4",
                              fontWeight: "bold",
                            }}
                            bordered={false}
                            textAlign="left"
                            placeholder="Item Name"
                          />
                        </Form.Item>
                      ) : (
                        <Form.Item
                          {...restField}
                          name={[name, "item_name"]}
                          fieldKey={[fieldKey, "item_name"]}
                          rules={[
                            {
                              required: true,
                              message: "Field should not be blank!!",
                            },
                          ]}
                        >
                          <Input
                            style={{
                              width: "100%",
                              height: "100%",
                              float: "left",
                              backgroundColor: "#F0E5F3",

                              color: "#1777C4",
                              fontWeight: "bold",
                            }}
                            bordered={false}
                            placeholder="Item Name"
                          />
                        </Form.Item>
                      )}
                    </Col>

                    <Col lg={3} md={24}>
                      {index === 0 ? (
                        <Form.Item
                          {...restField}
                          name={[name, "length"]}
                          fieldKey={[fieldKey, "length"]}
                          label={
                            <div
                              style={{
                                padding: "0rem 0.5rem",
                                fontSize: "0.7rem",
                                fontWeight: "bold",
                              }}
                              className={classes["Label"]}
                            >
                              Lenght <span style={{ color: "red" }}> *</span>
                            </div>
                          }
                          rules={[
                            {
                              // required: true,
                              message: "Field should not be blank!!",
                            },
                          ]}
                        >
                          <Input
                            type="number"
                            style={{
                              width: "100%",
                              height: "100%",
                              textAlign: "right",
                              backgroundColor: "#F0E5F3",
                              color: "#1777C4",
                              fontWeight: "bold",
                            }}
                            bordered={false}
                            placeholder="Enter Length"
                            onChange={(e) =>
                              handleLength(e, "length", fieldKey)
                            }
                          />
                        </Form.Item>
                      ) : (
                        <Form.Item
                          {...restField}
                          name={[name, "length"]}
                          fieldKey={[fieldKey, "length"]}
                          rules={[
                            {
                              required: true,
                              message: "Field should not be blank!!",
                            },
                          ]}
                        >
                          <Input
                            type="number"
                            style={{
                              width: "100%",
                              height: "100%",
                              textAlign: "right",
                              backgroundColor: "#F0E5F3",
                              color: "#1777C4",
                              fontWeight: "bold",
                            }}
                            bordered={false}
                            placeholder="Enter Length"
                            onChange={(e) => handleLength(e, name, fieldKey)}
                          />
                        </Form.Item>
                      )}
                    </Col>

                    <Col lg={3} md={24}>
                      {index === 0 ? (
                        <Form.Item
                          {...restField}
                          name={[name, "width"]}
                          fieldKey={[fieldKey, "width"]}
                          label={
                            <div
                              style={{
                                padding: "0rem 0.5rem",
                                fontSize: "0.7rem",
                                fontWeight: "bold",
                                textTransform: "none",
                              }}
                              className={classes["Label"]}
                            >
                              Width <span style={{ color: "red" }}> *</span>
                            </div>
                          }
                          rules={[
                            {
                              // required: true,
                              message: "Field should not be blank!!",
                            },
                          ]}
                        >
                          <Input
                            step="0.01"
                            type="number"
                            style={{
                              width: "100%",
                              height: "100%",
                              textAlign: "right",
                              backgroundColor: "#F0E5F3",
                              color: "#1777C4",
                              fontWeight: "bold",
                            }}
                            bordered={false}
                            placeholder="Enter Width"
                            onChange={(e) => handleWidth(e, name, fieldKey)}
                          />
                        </Form.Item>
                      ) : (
                        <Form.Item
                          {...restField}
                          name={[name, "width"]}
                          fieldKey={[fieldKey, "width"]}
                          rules={[
                            {
                              required: true,
                              message: "Field should not be blank!!",
                            },
                          ]}
                        >
                          <Input
                            step="0.01"
                            type="number"
                            style={{
                              width: "100%",
                              height: "100%",
                              textAlign: "right",
                              backgroundColor: "#F0E5F3",
                              color: "#1777C4",
                              fontWeight: "bold",
                            }}
                            bordered={false}
                            placeholder="Enter Width"
                            onChange={(e) => handleWidth(e, name, fieldKey)}
                          />
                        </Form.Item>
                      )}
                    </Col>

                    <Col lg={3} md={24}>
                      {index === 0 ? (
                        <Form.Item
                          {...restField}
                          name={[name, "height"]}
                          fieldKey={[fieldKey, "height"]}
                          label={
                            <div
                              style={{
                                padding: "0rem 0.5rem",
                                fontSize: "0.7rem",
                                fontWeight: "bold",
                                textTransform: "none",
                              }}
                              className={classes["Label"]}
                            >
                              Height <span style={{ color: "red" }}> *</span>
                            </div>
                          }
                          rules={[
                            {
                              // required: true,
                              message: "Field should not be blank!!",
                            },
                            // {
                            //     type: 'number',
                            //     min: 10,
                            //     message:"please enter minimum 10 characters"
                            //   },
                          ]}
                        >
                          <Input
                            type="number"
                            style={{
                              width: "100%",
                              height: "100%",
                              textAlign: "right",
                              backgroundColor: "#F0E5F3",
                              color: "#1777C4",
                              fontWeight: "bold",
                              // borderBottom: "2px solid #888888",
                            }}
                            bordered={false}
                            placeholder="Enter Height"
                            onChange={(e) => handleHeight(e, name, fieldKey)}
                          />
                        </Form.Item>
                      ) : (
                        <Form.Item
                          {...restField}
                          name={[name, "height"]}
                          fieldKey={[fieldKey, "height"]}
                          rules={[
                            {
                              required: true,
                              message: "Field should not be blank!!",
                            },
                            // {
                            //     type: 'number',
                            //     min: 10,
                            //     message:"please enter minimum 10 characters"
                            //   },
                          ]}
                        >
                          <Input
                            type="number"
                            style={{
                              width: "100%",
                              height: "100%",
                              textAlign: "right",
                              backgroundColor: "#F0E5F3",
                              color: "#1777C4",
                              fontWeight: "bold",
                              // borderBottom: "2px solid #888888",
                            }}
                            bordered={false}
                            placeholder="Enter Height"
                            onChange={(e) => handleHeight(e, name, fieldKey)}
                          />
                        </Form.Item>
                      )}
                    </Col>

                    <Col lg={3} md={24}>
                      {index === 0 ? (
                        <Form.Item
                          {...restField}
                          name={[name, "size_id"]}
                          fieldKey={[fieldKey, "size_id"]}
                          label={
                            <div
                              style={{
                                padding: "0rem 0.5rem",
                                fontSize: "0.7rem",
                                fontWeight: "bold",
                                textTransform: "none",
                              }}
                              className={classes["Label"]}
                            >
                              Unit <span style={{ color: "red" }}> *</span>
                            </div>
                          }
                          rules={[
                            {
                              // required: true,
                              message: "Field should not be blank!!",
                            },
                          ]}
                        >
                          <Select
                            showSearch
                            bordered={false}
                            dropdownStyle={{ textTransform: "capitalize" }}
                            style={{
                              width: "100%",
                              height: "100%",
                              float: "left",
                              backgroundColor: "#F0E5F3",
                              color: "#1777C4",
                              fontWeight: "bold",
                              borderBottom: "2px solid #888888",
                            }}
                            placeholder="Search to Select"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            filterSort={(optionA, optionB) =>
                              optionA.children
                                .toLowerCase()
                                .localeCompare(optionB.children.toLowerCase())
                            }
                            defaultValue={
                              props.ad.SIZE_ID.rows.find(
                                (option) =>
                                  option[props.ad.SIZE_ID.fields[0].name] ===
                                  props.sc.shipper.rows[0].size_id
                              )[props.ad.SIZE_ID.fields[1].name]
                            }
                          >
                            {props.ad.SIZE_ID.rows.map((option) => (
                              <Option
                                style={{
                                  textTransform: "capitalize",
                                  color: "#1777C4",
                                }}
                                key={option[props.ad.SIZE_ID.fields[0].name]}
                                value={option[props.ad.SIZE_ID.fields[0].name]}
                              >
                                {option[props.ad.SIZE_ID.fields[1].name]}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      ) : (
                        <Form.Item
                          {...restField}
                          name={[name, "size_id"]}
                          fieldKey={[fieldKey, "size_id"]}
                          rules={[
                            {
                              required: true,
                              message: "Field should not be blank!!",
                            },
                          ]}
                        >
                          <Select
                            showSearch
                            bordered={false}
                            dropdownStyle={{ textTransform: "capitalize" }}
                            style={{
                              width: "100%",
                              height: "100%",
                              float: "left",
                              backgroundColor: "#F0E5F3",
                              color: "#1777C4",
                              fontWeight: "bold",
                              borderBottom: "2px solid #888888",
                            }}
                            placeholder="Search to Select"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            filterSort={(optionA, optionB) =>
                              optionA.children
                                .toLowerCase()
                                .localeCompare(optionB.children.toLowerCase())
                            }
                            defaultValue={
                              props.ad.SIZE_ID.rows.find(
                                (option) =>
                                  option[props.ad.SIZE_ID.fields[0].name] ===
                                  props.sc.shipper.rows[0].size_id
                              )[props.ad.SIZE_ID.fields[1].name]
                            } // Set the defaultValue to size_name corresponding to size_id "4"
                            // defaultValue={props.sc.shipper.rows[0].size_id}
                          >
                            {props.ad.SIZE_ID.rows.map((option) => (
                              <Option
                                style={{
                                  textTransform: "capitalize",
                                  color: "#1777C4",
                                }}
                                key={option[props.ad.SIZE_ID.fields[0].name]}
                                value={option[props.ad.SIZE_ID.fields[0].name]}
                              >
                                {option[props.ad.SIZE_ID.fields[1].name]}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      )}
                    </Col>

                    <Col lg={3} md={24}>
                      {index === 0 ? (
                        <Form.Item
                          {...restField}
                          name={[name, "load_wt"]}
                          fieldKey={[fieldKey, "load_wt"]}
                          label={
                            <div
                              style={{
                                padding: "0rem 0.5rem",
                                fontSize: "0.7rem",
                                fontWeight: "bold",
                                textTransform: "none",
                              }}
                              className={classes["Label"]}
                            >
                              Weight <span style={{ color: "red" }}> *</span>
                            </div>
                          }
                          rules={[
                            {
                              // required: true,
                              message: "Field should not be blank!!",
                            },
                          ]}
                        >
                          <Input
                            type="number"
                            style={{
                              width: "100%",
                              height: "100%",
                              textAlign: "right",
                              backgroundColor: "#F0E5F3",
                              color: "#1777C4",
                              fontWeight: "bold",
                              borderBottom: "2px solid #888888",
                            }}
                            bordered={false}
                            placeholder="Enter Weight"
                            // defaultValue={0}
                            onChange={(e) => {
                              handleWeight2(e, name, fieldKey);
                              handleWeight();
                            }}
                          />
                        </Form.Item>
                      ) : (
                        <Form.Item
                          {...restField}
                          name={[name, "load_wt"]}
                          fieldKey={[fieldKey, "load_wt"]}
                          rules={[
                            {
                              required: true,
                              message: "Field should not be blank!!",
                            },
                          ]}
                        >
                          <Input
                            type="number"
                            style={{
                              width: "100%",
                              height: "100%",
                              textAlign: "right",
                              backgroundColor: "#F0E5F3",
                              color: "#1777C4",
                              fontWeight: "bold",
                              borderBottom: "2px solid #888888",
                            }}
                            bordered={false}
                            placeholder="Enter Weight"
                            onChange={(e) => {
                              handleWeight2(e, name, fieldKey);
                              handleWeight();
                            }}
                          />
                        </Form.Item>
                      )}
                    </Col>

                    <Col lg={2} md={24}>
                      {index === 0 ? (
                        <Form.Item
                          {...restField}
                          name={[name, "uom_code"]}
                          fieldKey={[fieldKey, "uom_code"]}
                          label={
                            <div
                              style={{
                                padding: "0rem 0.5rem",
                                fontSize: "0.7rem",
                                fontWeight: "bold",
                                textTransform: "none",
                              }}
                              className={classes["Label"]}
                            >
                              Uom <span style={{ color: "red" }}> *</span>
                            </div>
                          }
                        >
                          <Select
                            showSearch
                            onChange={(e) => {
                              handleWeight(e);
                            }}
                            bordered={false}
                            dropdownStyle={{ textTransform: "capitalize" }}
                            style={{
                              width: "100%",
                              height: "100%",
                              float: "left",
                              backgroundColor: "#F0E5F3",
                              color: "#1777C4",
                              fontWeight: "bold",
                              borderBottom: "2px solid #888888",
                            }}
                            placeholder="Search to Select"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            filterSort={(optionA, optionB) =>
                              optionA.children
                                .toLowerCase()
                                .localeCompare(optionB.children.toLowerCase())
                            }
                          >
                            {props.ad.UOM_CODE.rows.map((option) => (
                              <Option
                                style={{
                                  textTransform: "capitalize",
                                  color: "#1777C4",
                                }}
                                key={option[props.ad.UOM_CODE.fields[0].name]}
                                value={option[props.ad.UOM_CODE.fields[0].name]}
                              >
                                {option[props.ad.UOM_CODE.fields[1].name]}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      ) : (
                        <Form.Item
                          {...restField}
                          name={[name, "uom_code"]}
                          fieldKey={[fieldKey, "uom_code"]}
                          rules={[
                            {
                              required: true,
                              message: "Field should not be blank!!",
                            },
                          ]}
                        >
                          <Select
                            onChange={(e) => {
                              handleWeight(e);
                            }}
                            showSearch
                            bordered={false}
                            dropdownStyle={{ textTransform: "capitalize" }}
                            style={{
                              width: "100%",
                              height: "100%",
                              float: "left",
                              backgroundColor: "#F0E5F3",
                              color: "#1777C4",
                              fontWeight: "bold",
                              borderBottom: "2px solid #888888",
                            }}
                            placeholder="Search to Select"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            filterSort={(optionA, optionB) =>
                              optionA.children
                                .toLowerCase()
                                .localeCompare(optionB.children.toLowerCase())
                            }
                          >
                            {props.ad.UOM_CODE.rows.map((option) => (
                              <Option
                                style={{
                                  textTransform: "capitalize",
                                  color: "#1777C4",
                                }}
                                key={option[props.ad.UOM_CODE.fields[0].name]}
                                value={option[props.ad.UOM_CODE.fields[0].name]}
                              >
                                {option[props.ad.UOM_CODE.fields[1].name]}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      )}
                    </Col>

                    {/* <Col lg={21} md={0}></Col> */}
                    <Col lg={2} md={24}>
                      <MinusCircleOutlined
                        className={classes["Remove"]}
                        style={{ marginTop: index === 0 ? "35px" : "10px" }}
                        onClick={() => {
                          remove(name);
                          handleWeight1(index);
                        }}
                      />
                    </Col>

                    <Col lg={1} md={24}></Col>
                    {/* <Col lg = {24} md = {24}>
                                    <hr></hr>
                                </Col> */}
                  </Row>
                </Space>
              ))}

              <Row gutter={16}>
                <Col
                  lg={5}
                  md={7}
                  style={{ fontWeight: "bold", color: "black" }}
                ></Col>
                <Col lg={4} md={7}></Col>
                <Col className={classes["Col"]} lg={5} md={7}>
                  <Form.Item>
                    <Button
                      id="add_item"
                      style={{width:"8rem", backgroundColor: "#8A31A8", color: "white" }}
                      className={classes["DashedButton"]}
                      onClick={() => {
                        add();
                      }}
                    >
                      {/* <PlusCircleOutlined /> */}
                      Add Item
                    </Button>
                  </Form.Item>
                </Col>

                <Col className={classes["Col"]} lg={7} md={8} style={{}}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",

                      // border: "5px solid black",
                      marginRight: "3.5rem",
                    }}
                  >
                    <span
                      style={{
                        paddingRight: "15px",
                        fontSize: "0.7rem",
                        fontWeight: "bold",
                        width: "80%",
                        // border: "2px solid red"
                      }}
                    >
                      Total Weight:
                    </span>
                    <Input
                      type="text"
                      id="total_weight"
                      placeholder=" total weight"
                      value={props.fdata.weight}
                      readOnly
                      style={{
                        width: "68%",
                        backgroundColor: "#F0E5F3",
                        height: "100%",
                        color: "#1777C4",
                        fontWeight: "bold",
                        border: "none",
                        textAlign: "right",
                        borderTop: "2px solid #888888",
                        // border:"2px solid red"
                      }}
                    />
                  </div>
                </Col>

                <div>
                  <label>
                    <input
                      type="radio"
                      name="radioGroup"
                      value="option1"
                      checked={selectedOption === "option1"}
                      // checked
                      // onChange={handleRadioChange}
                      onChange={(event) => {
                        handleRadioChange(event);
                        anotherFunction();
                      }}
                    />
                    KG
                  </label>
                  <label>
                    <input
                      type="radio"
                      name="radioGroup"
                      value="option2"
                      // checked={!myWeight}
                      checked={selectedOption === "option2"}
                      // onChange={handleRadioChange}
                      onChange={(event) => {
                        handleRadioChange(event);
                        anotherFunction();
                      }}
                    />
                    LBS
                  </label>
                </div>
              </Row>
            </>
          );
        }}
      </Form.List>
    </Form>
  ) : (
    <>
      {
        <Form
          layout="vertical"
          name="filter_form"
          onValuesChange={handleValueChanges}
          autoComplete="off"
        >
          <Form.List name="itemSize" initialValue={props.fdata.itemSize}>
            {(fields, { add, remove }) => {
              return (
                <>
                  {/* <HeaderTable /> */}

                  {fields.map(
                    ({ key, name, fieldKey, ...restField }, index) => (
                      <Space
                        key={key}
                        className={classes["Space"]}
                        align="center"
                      >
                        <Row gutter={16}>
                          <p></p>

                          <Col lg={5} md={24}>
                            {index === 0 ? (
                              <Form.Item
                                {...restField}
                                name={[name, "item_name"]}
                                fieldKey={[fieldKey, "item_name"]}
                                label={
                                  <div
                                    style={{
                                      padding: "0rem 0.5rem",
                                      fontSize: "0.7rem",
                                      fontWeight: "bold",
                                    }}
                                    className={classes["Label"]}
                                  >
                                    Item Name <span style={{ color: "red" }}> *</span>
                                  </div>
                                }
                                rules={[
                                  {
                                    // required: true,
                                    message: "Field should not be blank!!",
                                  },
                                ]}
                              >
                                <Input
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    float: "left",
                                    backgroundColor: "#F0E5F3",
                                    color: "#1777C4",
                                    fontWeight: "bold",
                                    borderBottom: "2px solid #888888",
                                  }}
                                  bordered={false}
                                  placeholder="Item Name"
                                />
                              </Form.Item>
                            ) : (
                              <Form.Item
                                {...restField}
                                name={[name, "item_name"]}
                                fieldKey={[fieldKey, "item_name"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Field should not be blank!!",
                                  },
                                ]}
                              >
                                <Input
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    float: "left",
                                    backgroundColor: "#F0E5F3",
                                    color: "#1777C4",
                                    fontWeight: "bold",
                                  }}
                                  bordered={false}
                                  placeholder="Item Name"
                                />
                              </Form.Item>
                            )}
                          </Col>

                          <Col lg={3} md={24}>
                            {index === 0 ? (
                              <Form.Item
                                {...restField}
                                name={[name, "length"]}
                                fieldKey={[fieldKey, "length"]}
                                label={
                                  <div
                                    style={{
                                      padding: "0rem 0.5rem",
                                      fontSize: "0.7rem",
                                      fontWeight: "bold",
                                    }}
                                    className={classes["Label"]}
                                  >
                                    Length<span style={{ color: "red" }}> *</span>
                                  </div>
                                }
                                rules={[
                                  {
                                    // required: true,
                                    message: "Field should not be blank!!",
                                  },
                                ]}
                              >
                                <Input
                                  type="number"
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    textAlign: "right",
                                    backgroundColor: "#F0E5F3",
                                    color: "#1777C4",
                                    fontWeight: "bold",
                                    borderBottom: "2px solid #888888",
                                  }}
                                  bordered={false}
                                  placeholder="Enter Length"
                                  onChange={(e) =>
                                    handleLength(e, name, fieldKey)
                                  }
                                />
                              </Form.Item>
                            ) : (
                              <Form.Item
                                {...restField}
                                name={[name, "length"]}
                                fieldKey={[fieldKey, "length"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Field should not be blank!!",
                                  },
                                ]}
                              >
                                <Input
                                  type="number"
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    textAlign: "right",
                                    backgroundColor: "#F0E5F3",
                                    color: "#1777C4",
                                    fontWeight: "bold",
                                  }}
                                  bordered={false}
                                  placeholder="Enter Length"
                                  onChange={(e) =>
                                    handleLength(e, name, fieldKey)
                                  }
                                />
                              </Form.Item>
                            )}
                          </Col>

                          <Col lg={3} md={24}>
                            {index === 0 ? (
                              <Form.Item
                                {...restField}
                                name={[name, "width"]}
                                fieldKey={[fieldKey, "width"]}
                                label={
                                  <div
                                    style={{
                                      padding: "0rem 0.5rem",
                                      fontSize: "0.7rem",
                                      fontWeight: "bold",
                                    }}
                                    className={classes["Label"]}
                                  >
                                    Width <span style={{ color: "red" }}> *</span>
                                  </div>
                                }
                                rules={[
                                  {
                                    // required: true,
                                    message: "Field should not be blank!!",
                                  },
                                ]}
                              >
                                <Input
                                  type="number"
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    textAlign: "right",
                                    backgroundColor: "#F0E5F3",
                                    color: "#1777C4",
                                    fontWeight: "bold",
                                    borderBottom: "2px solid #888888",
                                  }}
                                  bordered={false}
                                  placeholder="Enter Width"
                                  onChange={(e) =>
                                    handleWidth(e, name, fieldKey)
                                  }
                                />
                              </Form.Item>
                            ) : (
                              <Form.Item
                                {...restField}
                                name={[name, "width"]}
                                fieldKey={[fieldKey, "width"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Field should not be blank!!",
                                  },
                                ]}
                              >
                                <Input
                                  type="number"
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    textAlign: "right",
                                    backgroundColor: "#F0E5F3",
                                    color: "#1777C4",
                                    fontWeight: "bold",
                                  }}
                                  bordered={false}
                                  placeholder="Enter Width"
                                  onChange={(e) =>
                                    handleWidth(e, name, fieldKey)
                                  }
                                />
                              </Form.Item>
                            )}
                          </Col>

                          {/* height */}
                          <Col lg={3} md={24}>
                            {index === 0 ? (
                              <Form.Item
                                {...restField}
                                name={[name, "height"]}
                                fieldKey={[fieldKey, "height"]}
                                label={
                                  <div
                                    style={{
                                      padding: "0rem 0.5rem",
                                      fontSize: "0.7rem",
                                      fontWeight: "bold",
                                    }}
                                    className={classes["Label"]}
                                  >
                                    Height <span style={{ color: "red" }}> *</span>
                                  </div>
                                }
                                rules={[
                                  {
                                    // required: true,
                                    message: "Field should not be blank!!",
                                  },
                                ]}
                              >
                                <Input
                                  type="number"
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    textAlign: "right",
                                    backgroundColor: "#F0E5F3",
                                    color: "#1777C4",
                                    fontWeight: "bold",
                                    borderBottom: "2px solid #888888",
                                  }}
                                  bordered={false}
                                  placeholder="Enter Height"
                                  onChange={(e) =>
                                    handleHeight(e, name, fieldKey)
                                  }
                                />
                              </Form.Item>
                            ) : (
                              <Form.Item
                                {...restField}
                                name={[name, "height"]}
                                fieldKey={[fieldKey, "height"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Field should not be blank!!",
                                  },
                                ]}
                              >
                                <Input
                                  type="number"
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    textAlign: "right",
                                    backgroundColor: "#F0E5F3",
                                    color: "#1777C4",
                                    fontWeight: "bold",
                                  }}
                                  bordered={false}
                                  placeholder="Enter Height"
                                  onChange={(e) =>
                                    handleHeight(e, name, fieldKey)
                                  }
                                />
                              </Form.Item>
                            )}
                          </Col>

                          <Col lg={3} md={24}>
                            {index === 0 ? (
                              <Form.Item
                                {...restField}
                                name={[name, "size_id"]}
                                fieldKey={[fieldKey, "size_id"]}
                                label={
                                  <div
                                    style={{
                                      padding: "0rem 0.5rem",
                                      fontSize: "0.7rem",
                                      fontWeight: "bold",
                                    }}
                                    className={classes["Label"]}
                                  >
                                    Unit <span style={{ color: "red" }}> *</span>
                                  </div>
                                }
                                rules={[
                                  {
                                    
                                    message: "Field should not be blank!!",
                                  },
                                ]}
                              >
                                <Select
                                  showSearch
                                  bordered={false}
                                  dropdownStyle={{
                                    textTransform: "capitalize",
                                  }}
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    float: "left",
                                    backgroundColor: "#F0E5F3",
                                    color: "#1777C4",
                                    fontWeight: "bold",
                                    borderBottom: "2px solid #888888",
                                  }}
                                  placeholder="Search to Select"
                                  optionFilterProp="children"
                                  filterOption={(input, option) =>
                                    option.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  }
                                  filterSort={(optionA, optionB) =>
                                    optionA.children
                                      .toLowerCase()
                                      .localeCompare(
                                        optionB.children.toLowerCase()
                                      )
                                  }
                                  defaultValue={
                                    props.ad.SIZE_ID.rows.find(
                                      (option) =>
                                        option[
                                          props.ad.SIZE_ID.fields[0].name
                                        ] === props.sc.shipper.rows[0].size_id
                                    )[props.ad.SIZE_ID.fields[1].name]
                                  }
                                >
                                  {props.ad.SIZE_ID.rows.map((option) => (
                                    <Option
                                      style={{
                                        textTransform: "capitalize",
                                        color: "#1777C4",
                                      }}
                                      key={
                                        option[props.ad.SIZE_ID.fields[0].name]
                                      }
                                      value={
                                        option[props.ad.SIZE_ID.fields[0].name]
                                      }
                                    >
                                      {option[props.ad.SIZE_ID.fields[1].name]}
                                    </Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            ) : (
                              <Form.Item
                                {...restField}
                                name={[name, "size_id"]}
                                fieldKey={[fieldKey, "size_id"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Field should not be blank!!",
                                  },
                                ]}
                              >
                                <Select
                                  showSearch
                                  bordered={false}
                                  dropdownStyle={{
                                    textTransform: "capitalize",
                                  }}
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    float: "left",
                                    backgroundColor: "#F0E5F3",
                                    color: "#1777C4",
                                    fontWeight: "bold",
                                    borderBottom: "2px solid #888888",
                                  }}
                                  placeholder="Search to Select"
                                  optionFilterProp="children"
                                  filterOption={(input, option) =>
                                    option.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  }
                                  filterSort={(optionA, optionB) =>
                                    optionA.children
                                      .toLowerCase()
                                      .localeCompare(
                                        optionB.children.toLowerCase()
                                      )
                                  }
                                  defaultValue={
                                    props.ad.SIZE_ID.rows.find(
                                      (option) =>
                                        option[
                                          props.ad.SIZE_ID.fields[0].name
                                        ] === props.sc.shipper.rows[0].size_id
                                    )[props.ad.SIZE_ID.fields[1].name]
                                  } // Set the defaultValue to size_name corresponding to size_id "4"
                                  // defaultValue={props.sc.shipper.rows[0].size_id}
                                >
                                  {props.ad.SIZE_ID.rows.map((option) => (
                                    <Option
                                      style={{
                                        textTransform: "capitalize",
                                        color: "#1777C4",
                                      }}
                                      key={
                                        option[props.ad.SIZE_ID.fields[0].name]
                                      }
                                      value={
                                        option[props.ad.SIZE_ID.fields[0].name]
                                      }
                                    >
                                      {option[props.ad.SIZE_ID.fields[1].name]}
                                    </Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            )}
                          </Col>
                          {/* uom */}

                          {/* wieight */}
                          <Col lg={3} md={24}>
                            {index === 0 ? (
                              <Form.Item
                                {...restField}
                                name={[name, "load_wt"]}
                                fieldKey={[fieldKey, "load_wt"]}
                                label={
                                  <div
                                    style={{
                                      padding: "0rem 0.5rem",
                                      fontSize: "0.7rem",
                                      fontWeight: "bold",
                                    }}
                                    className={classes["Label"]}
                                  >
                                    Weight <span style={{ color: "red" }}> *</span>
                                  </div>
                                }
                                
                              >
                                <Input
                                  type="number"
                                  onChange={(e) => {
                                    handleWeight();
                                    handleWeight2(e, name, fieldKey);
                                  }}
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    textAlign: "right",
                                    backgroundColor: "#F0E5F3",
                                    color: "#1777C4",
                                    fontWeight: "bold",
                                    borderBottom: "2px solid #888888",
                                    // border:"2px solid red"
                                  }}
                                  bordered={false}
                                  // defaultValue={0}
                                  placeholder="Enter Weight"
                                />
                              </Form.Item>
                            ) : (
                              <Form.Item
                                {...restField}
                                name={[name, "load_wt"]}
                                fieldKey={[fieldKey, "load_wt"]}
                               
                              >
                                <Input
                                  type="number"
                                  onChange={(e) => {
                                    handleWeight2(e, name, fieldKey);
                                    handleWeight();
                                  }}
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    textAlign: "right",
                                    backgroundColor: "#F0E5F3",
                                    color: "#1777C4",
                                    fontWeight: "bold",
                                  }}
                                  bordered={false}
                                  placeholder="Enter Weight"
                                />
                              </Form.Item>
                            )}
                          </Col>

                          <Col lg={2} md={24}>
                            {index === 0 ? (
                              <Form.Item
                                {...restField}
                                name={[name, "uom_code"]}
                                fieldKey={[fieldKey, "uom_code"]}
                                label={
                                  <div
                                    style={{
                                      padding: "0rem 0.5rem",
                                      fontSize: "0.7rem",
                                      fontWeight: "bold",
                                    }}
                                    className={classes["Label"]}
                                  >
                                    Uom <span style={{ color: "red" }}> *</span>
                                  </div>
                                }
                                rules={[
                                  {
                                    // required: true,
                                    // message: "Field should not be blank!!",
                                  },
                                ]}
                              >
                                <Select
                                  onChange={(e) => {
                                    handleWeight(e);
                                  }}
                                  showSearch
                                  bordered={false}
                                  dropdownStyle={{
                                    textTransform: "capitalize",
                                  }}
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    float: "left",
                                    backgroundColor: "#F0E5F3",
                                    color: "#1777C4",
                                    fontWeight: "bold",
                                    borderBottom: "2px solid #888888",
                                  }}
                                  placeholder="Search to Select"
                                  optionFilterProp="children"
                                  filterOption={(input, option) =>
                                    option.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  }
                                  filterSort={(optionA, optionB) =>
                                    optionA.children
                                      .toLowerCase()
                                      .localeCompare(
                                        optionB.children.toLowerCase()
                                      )
                                  }
                                >
                                  {props.ad.UOM_CODE.rows.map((option) => {
                                    return (
                                      <Option
                                        style={{
                                          textTransform: "capitalize",
                                          color: "#1777C4",
                                        }}
                                        key={
                                          option[
                                            props.ad.UOM_CODE.fields[0].name
                                          ]
                                        }
                                        value={
                                          option[
                                            props.ad.UOM_CODE.fields[0].name
                                          ]
                                        }
                                      >
                                        {
                                          option[
                                            props.ad.UOM_CODE.fields[1].name
                                          ]
                                        }
                                      </Option>
                                    );
                                  })}
                                </Select>
                              </Form.Item>
                            ) : (
                              <Form.Item
                                {...restField}
                                name={[name, "uom_code"]}
                                fieldKey={[fieldKey, "uom_code"]}
                                rules={[
                                  {
                                    // required: true,
                                    // message: "Field should not be blank!!",
                                  },
                                ]}
                              >
                                <Select
                                  onChange={(e) => {
                                    handleWeight(e);
                                  }}
                                  showSearch
                                  bordered={false}
                                  dropdownStyle={{
                                    textTransform: "capitalize",
                                  }}
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    float: "left",
                                    backgroundColor: "#F0E5F3",
                                    color: "#1777C4",
                                    fontWeight: "bold",
                                    // border:"2px solid red"
                                  }}
                                  placeholder="Search to Select"
                                  optionFilterProp="children"
                                  filterOption={(input, option) =>
                                    option.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  }
                                  filterSort={(optionA, optionB) =>
                                    optionA.children
                                      .toLowerCase()
                                      .localeCompare(
                                        optionB.children.toLowerCase()
                                      )
                                  }
                                >
                                  {props.ad.UOM_CODE.rows.map((option) => {
                                    return (
                                      <Option
                                        style={{
                                          textTransform: "capitalize",
                                          color: "#1777C4",
                                        }}
                                        key={
                                          option[
                                            props.ad.UOM_CODE.fields[0].name
                                          ]
                                        }
                                        value={
                                          option[
                                            props.ad.UOM_CODE.fields[0].name
                                          ]
                                        }
                                      >
                                        {
                                          option[
                                            props.ad.UOM_CODE.fields[1].name
                                          ]
                                        }
                                      </Option>
                                    );
                                  })}
                                </Select>
                              </Form.Item>
                            )}
                          </Col>

                          {/* <Col lg={21} md={0}></Col> */}
                          <Col lg={2} md={24}>
                            <MinusCircleOutlined
                              className={classes["Remove"]}
                              style={{
                                marginTop: index === 0 ? "35px" : "10px",
                              }}
                              onClick={() => {
                                remove(name);
                                handleWeight1(index);
                              }}
                            />
                          </Col>

                          {/* <Col lg={1} md={24}>
                          <PlusCircleOutlined
                            className={classes["Add"]}
                            onClick={() => {
                              add();
                            }}
                          />
                        </Col> */}
                          <Col lg={1} md={24}></Col>
                          {/* <Col lg = {24} md = {24}>
                                    <hr></hr>
                                </Col> */}
                        </Row>
                      </Space>
                    )
                  )}

                  <Row gutter={16}>
                    <Col lg={5} md={7}></Col>
                    <Col lg={5} md={7}></Col>

                    <Col className={classes["Col"]} lg={5} md={7}>
                      <Form.Item>
                        <Button
                          id="add_item"
                          style={{ width:"8rem", backgroundColor: "#8A31A8", color: "white" }}
                          className={classes["DashedButton"]}
                          onClick={() => {
                            add();
                          }}
                        >
                          <PlusCircleOutlined />
                          Add Item
                        </Button>
                      </Form.Item>
                    </Col>

                    <Col className={classes["Col"]} lg={7} md={8}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginRight: "7rem",
                        }}
                      >
                        <span
                          style={{
                            paddingRight: "15px",
                            fontSize: "0.7rem",
                            fontWeight: "bold",
                            // border:"2px solid red",
                            // border:"2px solid red",
                            width: "80%",
                          }}
                        >
                          Total Weight :
                        </span>
                        <Input
                          type="text"
                          id="total_weight"
                          placeholder="total weight"
                          value={props.fdata.weight}
                          readOnly
                          style={{
                            width: "80%",
                            backgroundColor: "#F0E5F3",
                            height: "100%",
                            color: "#1777C4",
                            fontWeight: "bold",
                            border: "none",
                            textAlign: "right",
                            borderTop: "2px solid #888888",
                          }}
                        />
                      </div>
                    </Col>

                    <label>
                      <input
                        type="radio"
                        name="radioGroup"
                        value="option1"
                        // onChange={handleRadioChange}
                        onChange={(event) => {
                          handleRadioChange(event);
                          anotherFunction();
                        }}
                      />
                      KG
                    </label>
                    <label>
                      <input
                        type="radio"
                        name="radioGroup"
                        value="option2"
                        // checked={!myWeight}
                        // onChange={handleRadioChange}
                        onChange={(event) => {
                          handleRadioChange(event);
                          anotherFunction();
                        }}
                      />
                      LBS
                    </label>
                  </Row>
                </>
              );
            }}
          </Form.List>
        </Form>
      }
    </>
  );
};

export default GradeDetailsEdit;
