import React, { useEffect, useState, useContext } from "react";
import ImageUpload from "./ImageUpload";
import { Link } from "react-router-dom";
import axios from "axios";
import "./truck.css";
import { useNavigate } from "react-router-dom";
import DataContext from "../../../Context/dataContext";

const TruckDetails = () => {
  const navigate = useNavigate();
  const employeeData = useContext(DataContext);

  const [boxes, setBoxes] = useState([
    {
      license_number: "",
      model: "",
      vin: "",
      engine_number: "",
      load_type_cd: "",
      mc_number: "",
      capacity: "",
      availibility_status: "",
      commodity_type_cd: "",
      vehicle_type_cd: "",
      fuel_efficiency: "",
      insurance_amount: "",
      gps_information: "",
      maintanence_record: "",
      isEditable: true, // set to true if you want it to be editable initially
      error: {},
    },
  ]);

  const lload = [];
  const [load, setLoad] = useState([]);
  const [vech, setVech] = useState([]);
  const [comm, setComm] = useState([]);
  const [deletedTrucks, setDeletedTrucks] = useState([]);
  const [final, setFinal] = useState([]);
  const [currentBox, setCurrentBox] = useState({});

  const [editMode, setEditMode] = useState(false);

  const handleEditClick = () => {
    setEditMode(true);
  };
  const handleSaveOrUpdateClick = (i) => {
    console.log(editMode, "this is the edit MODE pal");

    console.log(i, "this is the index");

    console.log(boxes.length, "this is the length of the boxes i have");
    console.log(final, "thisis the finallentgh ");
    if (i + 1 <= final) {
      console.log("Update is clicked ");
      handleUpdate(i);
    } else if (i + 1 > final) {
      console.log("insert is clicked");
      handleSubmit(i);
    }
  };

  useEffect(() => {
    // api to get the all form data
    axios
      .get(employeeData.URL + "/api/v1/truckD/mytruck", {
        withCredentials: true,
      })
      .then((response) => {
        console.log(response);

        // setBoxes(response.data.data.loads.rows);
        setBoxes(
          response.data.data.loads.rows.map((box, index) => ({
            ...box,
            isEditable: false, // set to true if you want it to be editable initially
            error: {},
          }))
        );
        setFinal(response.data.data.loads.rows.length);
        boxes.map((item, index) => {
          console.log(index, "this is the index");
          editMode(true);
        });
      })
      .catch((error) => {
        console.log("this is error");
      });

    // api to get the luv for Load
    axios
      .get(employeeData.URL + "/api/v1/profile/show-L-data", {
        withCredentials: true,
      })
      .then((response) => {
        console.log("this is the data for the LOAD");
        console.log(response);

        // lload = [response.data.data];

        setLoad(response.data.data);
        // console.log("__________________");
        // console.log(lload);
      })
      .catch((error) => {
        console.log("this is error");
      });

    // api to get the luv for Veichel
    axios
      .get(employeeData.URL + "/api/v1/profile/show-V-data", {
        withCredentials: true,
      })
      .then((response) => {
        console.log("this is the data for the Veichel");
        console.log(response.data.data);
        console.log(response);
        setVech(response.data.data);
      })
      .catch((error) => {
        console.log("this is error");
      });

    // api to get the luv for Commodity
    axios
      .get(employeeData.URL + "/api/v1/profile/show-C-data", {
        withCredentials: true,
      })
      .then((response) => {
        console.log("this is the data for the Commodity");
        console.log(response.data.data);
        console.log(response);
        setComm(response.data.data);
      })
      .catch((error) => {
        console.log("this is error");
      });
  }, []);

  const addTruckBox = () => {
    setBoxes((prevBoxes) => [
      ...prevBoxes,
      {
        license_number: "",
        model: "",
        vin: "",
        engine_number: "",
        load_type_cd: "",
        mc_number: "",
        capacity: "",
        availibility_status: "",
        commodity_type_cd: "",
        vehicle_type_cd: "",
        fuel_efficiency: "",
        insurance_amount: "",
        gps_information: "",
        maintanence_record: "",
        error: {},
      },
    ]);
  };

  const topost = [boxes[boxes.length - 1]];
  // console.log(topost);

  const handleInputChange = (index, event) => {
    const { name, value } = event.target;
    const newBoxes = [...boxes];
    newBoxes[index] = { ...newBoxes[index], [name]: value };

    const typeMap = {
      license_number: "string",
      model: "string",
      vin: "string",
      engine_number: "string",
      load_type_cd: "number",
      mc_number: "string",
      capacity: "number",
      availibility_status: "number",
      commodity_type_cd: "number",
      vehicle_type_cd: "number",
      fuel_efficiency: "number",
      insurance_amount: "number",
      gps_information: "string",
      maintanence_record: "number",
    };

    if (typeMap[name] === "string" && Number(value)) {
      newBoxes[index].error[name] = "Please Enter a String";
    } else if (typeMap[name] === "number" && isNaN(Number(value))) {
      newBoxes[index].error[name] = "Please Enter a number";
    } else {
      newBoxes[index].error[name] = "";
    }

    setBoxes(newBoxes);
    setCurrentBox(newBoxes[index]);

    // Log the current box being used
    console.log("Current Box:", newBoxes[index]);
  };

  // const handleInputChange = (index, event) => {
  //   const { name, value } = event.target;
  //   const newBoxes = [...boxes];
  //   newBoxes[index] = { ...newBoxes[index], [name]: value };

  //   const typeMap = {
  //     license_number: "string",
  //     model: "string",
  //     vin: "string",
  //     engine_number: "string",
  //     load_type_cd: "number",
  //     mc_number: "string",
  //     capacity: "number",
  //     availibility_status: "number",
  //     commodity_type_cd: "number",
  //     vehicle_type_cd: "number",
  //     fuel_efficiency: "number",
  //     insurance_amount: "number",
  //     gps_information: "string",
  //     maintanence_record: "number",
  //   };

  //   if (typeMap[name] === "string" && Number(value)) {
  //     newBoxes[index].error[name] = "Please Enter a String";
  //   } else if (typeMap[name] === "number" && isNaN(Number(value))) {
  //     newBoxes[index].error[name] = "Please Enter a number";
  //   } else {
  //     newBoxes[index].error[name] = ""; // Clear the error when the input is valid
  //   }

  //   setBoxes(newBoxes);
  // };

  const handleSubmit = () => {
    axios
      .post(
        employeeData.URL + "/api/v1/truckD/insert-truck",
        { truckdetails: topost },
        {
          headers: {
            "Content-Type": "application/json",
          },

          withCredentials: true,
          credentials: "include",
        }
      )
      .then((response) => {
        console.log("Data successfully sent to the server:", response.data);
        navigate("/trucker/profilet");

        // Perform any further actions after successful submission
      })
      .catch((error) => {
        console.error("Error sending data to the server:", error);
      });
  };

  const handleUpdate = (index) => {
    const updatedBox = boxes[index];
    axios
      .patch(
        employeeData.URL + "/api/v1/truckD/modify-truck",
        { truckdetails: updatedBox },
        {
          headers: {
            "Content-Type": "application/json",
          },

          withCredentials: true,
          credentials: "include",
        }
      )
      .then((response) => {
        console.log("Data successfully updated:", response.data);
        navigate("/trucker/profilet");

        // Perform any further actions after successful submission
      })
      .catch((error) => {
        console.error("Error sending data to the server:", error);
      });
  };

  const deleteTruck = (index) => {
    const newBoxes = [...boxes];
    newBoxes.splice(index, 1);
    setBoxes(newBoxes);
    const deletedBox = boxes[index];
    const dvin = deletedBox.vin;

    axios
      .delete(
        employeeData.URL + `/api/v1/truckD/delete-truck/'${dvin}'`,

        {
          headers: {
            "Content-Type": "application/json",
          },

          withCredentials: true,
          credentials: "include",
        }
      )
      .then((response) => {
        console.log("Data successfully sent to the server:", response.data);
        navigate("/trucker/profilet");

        // Perform any further actions after successful submission
      })
      .catch((error) => {
        console.error("Error sending data to the server:", error);
      });
  };

  return (
    <>
      <div className="App">
        <div
          style={{
            paddingLeft: "23px",
            display: "flex",
            justifyContent: "start",
          }}
        >
          <Link to="/trucker/profilet" style={{ textDecoration: "none" }}>
            <button
              type="button"
              className="form-control"
              style={{
                width: "12rem",
                height: "2.1rem",
                margin: "auto",
                color: "white",
                backgroundColor: "#8A31A8",
              }}
            >
              Back
            </button>
          </Link>
        </div>
        <h1 style={{}}>
          <span style={{ color: "#a769a7" }}>Truck Details</span>
        </h1>

        {boxes.map((boxData, index) => (
          <div key={index} className="box">
            <div
              className="F"
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-evenly",
              }}
            >
              <div style={{}}>
                <div style={{ display: "flex", justifyContent: "flex-start" }}>
                  <label htmlFor="license_number">License Number:</label>
                </div>

                <input
                  style={{ border: "1px solid white" }}
                  type="text"
                  placeholder="License Number"
                  name="license_number"
                  value={boxData.license_number}
                  onChange={(event) => handleInputChange(index, event)}
                  // disabled={!boxData.isNew}
                />
                {boxData.error && boxData.error.license_number && (
                  <div style={{ color: "red" }}>
                    {boxData.error.license_number}
                  </div>
                )}
              </div>
              <div>
                <div style={{ display: "flex", justifyContent: "flex-start" }}>
                  <label htmlFor="model">Model:</label>
                </div>
                <input
                  style={{ border: "1px solid white" }}
                  type="text"
                  placeholder="Model"
                  name="model"
                  value={boxData.model}
                  onChange={(event) => handleInputChange(index, event)}
                />
                {boxData.error && boxData.error.model && (
                  <div style={{ color: "red" }}>{boxData.error.model}</div>
                )}
              </div>
              <div>
                <div style={{ display: "flex", justifyContent: "flex-start" }}>
                  <label htmlFor="vin">VIN:</label>
                </div>

                <input
                  style={{ border: "1px solid white" }}
                  type="text"
                  placeholder="VIN"
                  name="vin"
                  value={boxData.vin}
                  onChange={(event) => handleInputChange(index, event)}
                />
                {boxData.error && boxData.error.vin && (
                  <div style={{ color: "red" }}>{boxData.error.vin}</div>
                )}
              </div>
              <div>
                <div style={{ display: "flex", justifyContent: "flex-start" }}>
                  <label htmlFor="engine_number">Engine Number:</label>
                </div>
                <input
                  style={{ border: "1px solid white" }}
                  type="text"
                  placeholder="Engine Number"
                  name="engine_number"
                  value={boxData.engine_number}
                  onChange={(event) => handleInputChange(index, event)}
                />
                {boxData.error && boxData.error.engine_number && (
                  <div style={{ color: "red" }}>
                    {boxData.error.engine_number}
                  </div>
                )}
              </div>

              <div>
                <div style={{ display: "flex", justifyContent: "flex-start" }}>
                  <label htmlFor="load_type_cd">Load Type:</label>
                </div>

                <select
                  name="load_type_cd"
                  value={boxData.load_type_cd}
                  onChange={(event) => handleInputChange(index, event)}
                >
                  <option value="">Select</option> {/* Default option */}
                  {/* <option value="1">Less Than Truckload</option>
                                    <option value="2">Full Truckload</option>
                                    <option value="3">Full Container Load</option>
                                    <option value="4">Less-Than-Container Load</option>
                                    <option value="5">Courier Express and Parcel</option> */}
                  {load
                    ? load.map((loadOption, optionIndex) => (
                        <option key={optionIndex} value={optionIndex + 1}>
                          {loadOption}
                        </option>
                      ))
                    : null}
                </select>
                {boxData.error && boxData.error.load_type_cd && (
                  <div style={{ color: "red" }}>
                    {boxData.error.load_type_cd}
                  </div>
                )}
              </div>
            </div>

            <div
              className="F"
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-evenly",
              }}
            >
              <div>
                <div style={{ display: "flex", justifyContent: "start" }}>
                  <label htmlFor="mc_number">MC Number:</label>
                </div>

                <input
                  style={{ border: "1px solid white" }}
                  type="text"
                  placeholder="MC Number"
                  name="mc_number"
                  value={boxData.mc_number}
                  onChange={(event) => handleInputChange(index, event)}
                />
                {boxData.error && boxData.error.mc_number && (
                  <div style={{ color: "red" }}>{boxData.error.mc_number}</div>
                )}
              </div>
              <div>
                <div style={{ display: "flex", justifyContent: "start" }}>
                  <label htmlFor="capacity">Capacity:</label>
                </div>

                <input
                  style={{ border: "1px solid white" }}
                  type="text"
                  placeholder="Capacity"
                  name="capacity"
                  value={boxData.capacity}
                  onChange={(event) => handleInputChange(index, event)}
                />
                {boxData.error && boxData.error.capacity && (
                  <div style={{ color: "red" }}>{boxData.error.capacity}</div>
                )}
              </div>
              <div>
                <div style={{ display: "flex", justifyContent: "start" }}>
                  <label htmlFor="availibility_status">
                    Availability Status:
                  </label>
                </div>

                <input
                  style={{ border: "1px solid white" }}
                  type="text"
                  placeholder="Availability Status"
                  name="availibility_status"
                  value={boxData.availibility_status}
                  onChange={(event) => handleInputChange(index, event)}
                />
                {boxData.error && boxData.error.availibility_status && (
                  <div style={{ color: "red" }}>
                    {boxData.error.availibility_status}
                  </div>
                )}
              </div>

              <div>
                <div style={{ display: "flex", justifyContent: "start" }}>
                  <label htmlFor="commodity_type_cd">Commodity Type:</label>
                </div>

                <select
                  name="commodity_type_cd"
                  value={boxData.commodity_type_cd}
                  onChange={(event) => handleInputChange(index, event)}
                >
                  <option value="">Select</option> {/* Default option */}
                  {comm.map((commodityOption, optionIndex) => (
                    <option key={optionIndex} value={optionIndex + 1}>
                      {commodityOption}
                    </option>
                  ))}
                </select>
                {boxData.error && boxData.error.commodity_type_cd && (
                  <div style={{ color: "red" }}>
                    {boxData.error.commodity_type_cd}
                  </div>
                )}
              </div>

              <div>
                <div style={{ display: "flex", justifyContent: "start" }}>
                  <label htmlFor="vehicle_type_cd">Vehicle Type:</label>
                </div>

                <select
                  name="vehicle_type_cd"
                  value={boxData.vehicle_type_cd}
                  onChange={(event) => handleInputChange(index, event)}
                >
                  <option value="">Select</option> {/* Default option */}
                  {/* <option value="1">Tanker Truck</option>
                                    <option value="2">Truck</option>
                                    <option value="3">Lorry</option>
                                    <option value="4">Pickup Truck</option> */}
                  {vech.map((vehicleOption, optionIndex) => (
                    <option key={optionIndex} value={optionIndex + 1}>
                      {vehicleOption}
                    </option>
                  ))}
                </select>
                {boxData.error && boxData.error.vehicle_type_cd && (
                  <div style={{ color: "red" }}>
                    {boxData.error.vehicle_type_cd}
                  </div>
                )}
              </div>
            </div>

            <div
              className="F"
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-evenly",
              }}
            >
              <div>
                <div style={{ display: "flex", justifyContent: "start" }}>
                  <label htmlFor="fuel_efficiency">Fuel Efficiency:</label>
                </div>

                <input
                  style={{ border: "1px solid white" }}
                  type="text"
                  placeholder="Fuel Efficiency"
                  name="fuel_efficiency"
                  value={boxData.fuel_efficiency}
                  onChange={(event) => handleInputChange(index, event)}
                />
                {boxData.error && boxData.error.fuel_efficiency && (
                  <div style={{ color: "red" }}>
                    {boxData.error.fuel_efficiency}
                  </div>
                )}
              </div>
              <div>
                <div style={{ display: "flex", justifyContent: "start" }}>
                  <label htmlFor="insurance_amount">Insurance Amount:</label>
                </div>

                <input
                  style={{ border: "1px solid white" }}
                  type="text"
                  placeholder="Insurance Amount"
                  name="insurance_amount"
                  value={boxData.insurance_amount}
                  onChange={(event) => handleInputChange(index, event)}
                />
                {boxData.error && boxData.error.insurance_amount && (
                  <div style={{ color: "red" }}>
                    {boxData.error.insurance_amount}
                  </div>
                )}
              </div>
              <div>
                <div style={{ display: "flex", justifyContent: "start" }}>
                  <label htmlFor="gps_information">GPS information:</label>
                </div>

                <input
                  style={{ border: "1px solid white" }}
                  type="text"
                  placeholder="GPS information"
                  name="gps_information"
                  value={boxData.gps_information}
                  onChange={(event) => handleInputChange(index, event)}
                />
                {boxData.error && boxData.error.gps_information && (
                  <div style={{ color: "red" }}>
                    {boxData.error.gps_information}
                  </div>
                )}
              </div>
              <div>
                <div style={{ display: "flex", justifyContent: "start" }}>
                  <label htmlFor="maintanence_record">
                    Maintenance Records:
                  </label>
                </div>

                <input
                  style={{ border: "1px solid white" }}
                  type="text"
                  placeholder="Maintenance Records"
                  name="maintanence_record"
                  value={boxData.maintanence_record}
                  onChange={(event) => handleInputChange(index, event)}
                />
                {boxData.error && boxData.error.maintanence_record && (
                  <div style={{ color: "red" }}>
                    {boxData.error.maintanence_record}
                  </div>
                )}
              </div>
              <div>
                <ImageUpload />
              </div>
            </div>

            <div key={index} style={{ display: "flex" }} className="box">
              <div className="slast">
                <button
                  style={{
                    backgroundColor: "#8A31A8",
                    color: "white",
                    borderRadius: "0.3rem",
                    height: "2rem",
                    width: "12rem",
                     paddingTop:"0.3rem",
                    
                  }}
                  id="DeleteT"
                  onClick={() => deleteTruck(index)}
                >
                  {" "}
                  Delete Truck
                </button>

                <button
                  id="SaveOrUpdate"
                  onClick={() => handleSaveOrUpdateClick(index)}
                  style={{
                    marginLeft:"1rem",
                    color: "white",
                    height:"2rem",
                    paddingTop:"0.3rem",
                    width: "12rem",
                    
                    backgroundColor: index + 1 <= final ? "#8A31A8" : "#8A31A8",
                    borderRadius: "0.3rem",
                    // Add other styles as needed
                  }}
                >
                  {index + 1 <= final ? "Update Details" : "Save Details"}
                </button>
              </div>
            </div>

            <br />

            <div className="line-with-shadow">
              <div className="line"></div>
            </div>
            {/* --------- */}
          </div>
        ))}

        <div className="slast">
          <button id="AddT" onClick={addTruckBox}>
            {" "}
            +Add Truck
          </button>
        </div>
      </div>
    </>
  );
};

export default TruckDetails;
