import React from "react";
import { Link } from "react-router-dom";
// import Validation from "../ValidationShipper/validation";
// import classes from "../../FzuluPageShipper/CheckProfilePageShipper/classes";
import classes from "./check.css";
import logoImage from "../../../assets/LOGO.png";
import { Content } from "antd/es/layout/layout";

function CheckProfile(props) {
  const imageStyle = {
    // position:'absolute',
    marginTop: "2rem",
    marginLeft: "2rem",
    // backgroundImage: `url(${logoImage})`,
    backgroundPosition: "top left",
    backgroundRepeat: "no-repeat",
    backgroundSize: "150px",
  };

  const style = {
    width: "100vw",
  };

  const showError = true; // Set this value to true when there's an error on the button

  return (
    <div className={classes["container"]} style={{ marginTop: "2rem" }}>
      <div className={classes["form-container"]}>
        <div className={classes["logo"]} style={imageStyle}></div>
        <p
          style={{
            fontWeight: "bold", // Making the text extra bold
            fontSize: "35px",
            color: "black",
            // marginTop: "-21px",
          }}
        >
          COMPLETE <br /> YOUR PROFILE <br /> BEFORE POSTING A LOAD{" "}
        </p>
        {/* <br/> */}
        {/* <p style={{  fontWeight: 'bold', // Making the text extra bold
          fontSize: '35px',
          color: 'black', marginTop: '-47px'}}>YOUR PROFILE</p>
        <p style={{  fontWeight: 'bold', // Making the text extra bold
          fontSize: '35px',
          color: 'black', marginTop: '-47px'}}>BEFORE POSTING A LOAD</p> */}

        {/* <form> */}
        <Link to="/trucker/profilet">
          <button type="submit" className={classes["custom-btn"]}>
            <span className={classes["arrow"]}>&#8594;</span>
          </button>
        </Link>
        {/* {showError && (
            <p style={{ fontSize: '15px', color: 'red' }}>Error message related to the button.</p>
          )} */}

        <p style={{ fontSize: "20px", color: "#ae78c1", marginTop: "66px" }}>
          Already Complete <br />
          <Link to="/home" style={{ textDecoration: "none", color: "#ae78c1" }}>
            {/* <span style={{color: '#ae78c1'
            }}></span> */}
            not now{" "}
          </Link>
        </p>

        {/* </form> */}
      </div>
    </div>
  );
}

export default CheckProfile;
