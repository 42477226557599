import { Layout } from "antd";
import { Link, Route, Routes } from "react-router-dom";
//import React from 'react';
import LoginPage from "../../../Pages/FzuluPageShipper/LoginPageShipper/LoginPage";
import ItemNew from "../ItemMaster/ItemNew";
import SiderContentF from "../SiderContentShipper/SiderContentF";
import LayoutWrapper from "../../LayoutWrapper/LayoutWrapper";
import { useLocation } from "react-router-dom";
import BasicDetails from "../ItemMaster/BasicDetails";
import ProfilePage from "../Profile/ProfilePage";
import LoadView from "../LoadView/LoadView";
import Payment from "../Payment/payment";
import Pendingload from "../PendingLoad/pendingload";
import PendingBids from "../PendingBids/PendingBids";
import Deliveredloads from "../Deliverdoad/delivered";
import Dashboard from "../Dashbord/DashBord";
import Tracking from "../Tracking/tracking";
import CheckProfile from "../../../Pages/FzuluPageShipper/CheckProfilePageShipper/CheckProfile";
import Ledger from "../Ledger/Ledger";
import LoadDetails from "../LoadDetails/LoadDetails";
import Ztofifteen from "../MyBillsClick/Ztofifteen";
import FtoFifteen from "../MyBillsClick/FtoFifteen";
import TtoSixty from "../MyBillsClick/TtoSixty";
import MtM from "../MyBillsClick/MtM";
import Loading from "../Loading/Loading"
import Unloading from "../Unloading/Unloading";

const FeildDataF = (fieldProps) => {
  const location = useLocation();

  console.log("Current path:", location.pathname);

  return (
    <>
      <Routes>
        {/* <Route path="/" element={<BasicDetails />}/> */}
        <Route path="/" element={<Dashboard />} />
        <Route path="/post-load" element={<ItemNew />} />
        <Route path="/profile" element={<ProfilePage />} />
        <Route path="/pending-bids" element={<PendingBids />} />
        <Route path="/load-view" element={<LoadView />} />
        <Route path="/payment" element={<Payment />} />
        <Route path="/pending-load-view" element={<Pendingload />} />


        <Route path="/Loading" element={<Loading />} />
        {/* <Route path="/unloading" element={<Loading />} /> */}
        <Route path="/unloading" element={<Unloading />} />


        <Route path="/delivered-loads" element={<Deliveredloads />} />
        <Route path="/tracking" element={<Tracking />} />
        <Route path="/Checking" element={<CheckProfile />} />
        <Route path="/ledger" element={<Ledger />} />
        <Route path="/zero-fifteen" element={<Ztofifteen />} />
        <Route path="/fifteen-thirty" element={<FtoFifteen />} />
        <Route path="/thirty-sixty" element={<TtoSixty />} />
        <Route path="/more-months" element={<MtM />} />
        <Route path="/load-Details/:load_id" element={<LoadDetails />} />
      </Routes>
    </>
  );
};

export default FeildDataF;
