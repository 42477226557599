import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import dayjs from "dayjs";

import "./pendbids.css";
import { Navigate, useNavigate } from "react-router-dom";
import { map } from "leaflet";
import DataContext from "../../../Context/dataContext";

const PendingBids = () => {
  // let accountCode;
  // let loadID;
  const [accountCode, setAccountCode] = useState();
  const [loadID, setLoadID] = useState();
  const [data, setData] = useState([]);
  const [iddata, setidData] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedLoadId, setSelectedLoadId] = useState(null); // New state to store selected load_id
  const employeeData = useContext(DataContext);

  const [alldataa, setAllData] = useState([]);
  const [selectedamt, setSelectedamt] = useState(null);
  const [countdown, setCountdown] = useState(0);
  const [ch, setCh] = useState(false);
  const [newDataArray, setNewDataArray] = useState([]);
  const [selectedTruckerId, setSelectedTruckerId] = useState(null);

  const navigate = useNavigate();

  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600000);
    const minutes = Math.floor((seconds % 3600000) / 60000);
    const formattedTime = `${hours} HOURS ${minutes} MINUTES LEFT`;

    return formattedTime;
  };

  const handleDivClick = () => {
    console.log("hey it is not working");
    const mystyle = document.getElementById("thisisthespan");
    // mystyle.style.backgroundColor = "rgb(250, 239, 252)";
    // accountCode = data.account_code;
    setAccountCode(data.accountCode);
    // loadID = data.load_id;
    setLoadID(data.load_id);
    // console.log(accountCode);
  };

  useEffect(() => {
    const apiUrl = employeeData.URL + "/api/v1/load/dgetPendingBids";
    axios
      .get(apiUrl, {
        withCredentials: true,
      })
      .then((response) => {
        console.log(response);
        const dataArray = response.data.data.myid.rows;
        setidData(dataArray);
        const alldata = response.data.data.getPendingBids.rows;
        setAllData(alldata);
        setData(alldata[0]);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const handleAcceptBidding = (accountCode, loadID) => {
    let id = accountCode;
    let lid = loadID;
    axios
      .post(
        employeeData.URL + `/api/v1/load/winning/${id}/${lid}`,
        {},
        {
          withCredentials: true,
        }
      )
      .then((response) => {
        alert("You Accepted the Bid");
        navigate("/home");
        console.log("Bidding accepted:", response.data);
      })
      .catch((error) => {
        console.error("Error accepting bidding:", error);
      });
  };
  if(data.weight_type !== null){
     console.log(data.weight_type," htis is something else" )
     
    }else{

      console.log(data.load_wt," this is sthe loadwt")
    } 
    

  const handleItemClick = (item) => {
    setSelectedItem(item);
    setSelectedLoadId(item.load_id);
    setSelectedTruckerId(null); // Reset selected trucker ID when load ID changes
    calculateCountdown();
    setData([]);
    setCh(true);
    for (let i = 0; i < alldataa.length; i++) {
      if (alldataa[i].load_id === item.load_id) {
        setData(alldataa[i]);
        setNewDataArray(...alldataa, alldataa[i]);
      }
    }
  };
  const handleTruckerClick = (truckerId) => {
    setSelectedTruckerId(truckerId);
    console.log(truckerId);
    // accountCode = truckerId.account_code;
    setAccountCode(truckerId.account_code);
    // loadID = truckerId.load_id
    setLoadID(truckerId.load_id);
    setSelectedTruckerId(truckerId.load_id);
    setSelectedamt(truckerId.bid_amt1);
  };

  useEffect(() => {
    console.log(" thisis the useEfect");
    calculateCountdown();
  }, []);

  const calculateCountdown = () => {
    const currentTime = new Date();
    const endTime = dayjs(data.bidding_end_time).valueOf();
    const timeDifference = endTime - currentTime;
    const remainingTime = Math.max(timeDifference, 0);
    setCountdown(remainingTime);
  };

  return (
    <>
      <h1 style={{ color: "#A769A7"}}>
        Open Bids
      </h1>
      <br />
      <br />
      <div id="theheadofbids">
        <div
          className="sameclassname"
          id="theonepd"
          style={{ overflow: "scroll" }}
        >
          <h4>Load ID</h4>
          <br />
          <ul>
            {iddata.map((item) => (
              <li
                key={item.load_id}
                className="thisisactualloadid"
                style={{
                  border:
                    selectedLoadId === item.load_id
                      ? "1px solid brown"
                      : "none", // Apply border based on selected load_id
                  boxShadow:
                    selectedItem === item
                      ? "inset 1px 3px 10px black "
                      : "1px 3px 10px gray inset",
                }}
                onClick={() => handleItemClick(item)}
              >
                load: {item.load_id}
              </li>
            ))}
          </ul>
        </div>

        <div className="sameclassname" id="thetwopd">
          <h3 style={{ textAlign: "start", paddingLeft: "2rem" }}>
            Specifications
          </h3>
          <div className="idkllnjh">
            <div>
              Origin :{" "}
              <span style={{ color: "black" }}>
                {data && data.origin_state}
              </span>
              {console.log(" this is checkinh", data)}
            </div>
            <div>
              Destination :{" "}
              <span style={{ color: "black" }}>
                {data && data.destination_state}
              </span>
            </div>
            <div>
              {/* Weight: <span style={{ color: "black" }}>{data && data.load_wt}</span> */}
              Weight:{" "}
              <span style={{ color: "black" }}>
                {data.weight_type !== null ? data.weight_type : data.load_wt}
              </span>
            </div>
            <div>
              Load Amount:{" "}
              <span style={{ color: "black" }}>{data && data.load_amount}</span>
            </div>
            <div>
              Distance :{" "}
              <span style={{ color: "black" }}> {data && data.distance}</span>
            </div>
            <span style={{ color: "rgb(128, 75, 181)", display: "flex" }}>
              --------------------------------------------------------------------------------------------------------------------------
            </span>
          </div>
          <h3
            style={{
              textAlign: "start",
              paddingLeft: "2rem",
              marginBottom: "0",
            }}
          >
            Bids
          </h3>
          <span
            style={{ marginTop: "0rem", textAlign: "end", display: "none" }}
          >
            {" "}
            Time Left For Bidding :{" "}
            <span id="countdown" style={{ color: "red" }}>
              {formatTime(countdown)}
            </span>
          </span>

          <div className="pedbidlb" style={{}}>
            <div
              id="togetaccountid"
              style={{
                marginLeft: "2rem",
                display: "flex",
                flexDirection: "row",
                // border: "2px solid red"
              }}
            >
              <div style={{ display: "none" }}>
                <span style={{ fontSize: "1rem" }}>Bid Amount</span>
                <div
                  style={{
                    width: "10rem",
                    height: "2.2rem",
                    boxShadow: "1px 3px 10px black",
                    fontSize: "1.3rem",
                    backgroundColor: "#270229",
                    color: "white",
                  }}
                >
                  {data && data.bid_amt1}
                </div>
              </div>
              <div
                className="pedbidlb"
                style={{ boxShadow: "1px 3px 10px black", width: "30%" }}
              >
                <span
                  style={{
                    fontSize: "1rem",
                    width: "100%",
                    color: "black",
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "0 1rem 0 1rem ",
                  }}
                >
                  {" "}
                  <span>$</span> <span>Trucker</span>{" "}
                </span>
                <span
                  style={{
                    width: "100%",
                    height: "2.2rem",
                    // marginLeft: "2rem",
                    fontSize: "1.3rem",
                    // border: "2px solid yellow",
                    height: "10rem",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {alldataa
                    .filter((item) => item.load_id === data.load_id)
                    .map((item) => {
                      console.log(item.trucker_name, "------------");
                      return (
                        <span
                          onClick={() => {
                            handleDivClick();
                            handleTruckerClick(item);
                          }}
                          id="thisisthespan"
                          style={{
                            color: "black",
                            width: "100%",
                            cursor: "pointer",
                            margin: "1px",
                            display: "flex",
                            justifyContent: "space-between",
                            backgroundColor:
                              selectedTruckerId === item.load_id &&
                              selectedamt === item.bid_amt1
                                ? "rgb(96, 44, 114)"
                                : selectedTruckerId === item.load_id
                                ? "transparent" // Background color when only selectedTruckerId matches
                                : selectedamt === item.bid_amt1
                                ? "transparent" // Background color when only selectedamt matches
                                : "transparent", // Default transparent background
                          }}
                          key={item.id}
                        >
                          <span style={{ marginLeft: "0.7rem" }}>
                            {" "}
                            {item.bid_amt1}
                          </span>{" "}
                          <span style={{ marginLeft: "8rem" }}>
                            {item.trucker_name}
                          </span>
                          <br />
                        </span>
                      );
                    })}
                </span>
              </div>
            </div>
            <br />
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "-10rem",
              width: "10rem",
              marginLeft: "60rem",
            }}
          >
            <button
              id="acceptthebiddingnow"
              onClick={() => handleAcceptBidding(accountCode, loadID)}
              style={{
                height: "2rem",
                width: "12rem",
                color: "white",
                backgroundColor: "#8A31A8",
                borderRadius: "6px",
                paddingTop: "0.3rem",
              }}
            >
              {" "}
              Accept
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default PendingBids;
