// // import React from "react";
// // import { Row, Col, Tabs, Skeleton, message } from "antd";
// // import { Link } from "react-router-dom";
// // import { Menu } from "antd";
// // import { FaSignOutAlt } from "react-icons/fa";
// // import axios from "axios";
// // import { useNavigate } from "react-router-dom";
// // const { Item: MenuItem, Divider } = Menu;

// // const SiderContentF = (props) => {
// //   const history = useNavigate();

// //   const handleLogout = (event) => {
// //     axios
// //       .get(props.url + "/api/v1/type/logout", {
// //         withCredentials: true,
// //       })
// //       .then((response) => {
// //         message.success({
// //           content: "Goodbye!!!! See You Again!!!",
// //           className: "custom-class",
// //           style: {
// //             marginTop: "20vh",
// //           },
// //         });
// //         history("/");
// //       })
// //       .catch((err) => {
// //         console.log(err);
// //       });
// //   };
// //   return (
// //     <>
// //       <Menu
// //         mode="inline"
// //         defaultSelectedKeys={["1"]}
// //         defaultOpenKeys={["dashboard"]}
// //         style={{
// //           height: "100%",
// //           borderRight: 0,
// //         }}
// //         theme="light"
// //       >
// //         <MenuItem key="1" style={{ justifyContent: "flex-start" }}>
// //           <Link to="/trucker/dashboard" style={{ textDecoration: "none" }}>
// //             Dashbord
// //           </Link>
// //         </MenuItem>
// //         <MenuItem key="2">
// //           <Link to="/trucker/view-bids" style={{ textDecoration: "none" }}>
// //             View Bids
// //           </Link>
// //         </MenuItem>
// //         <Divider
// //           style={{
// //             margin: "9px 0",
// //             borderBottom: "1px solid rgba(0, 0, 0, 0.2)",
// //             width: "100%",
// //           }}
// //         />
// //         {/* <MenuItem key="3" className="align-right">
// //           <Link to="/trucker/load-view" style={{ textDecoration: "none" }}>
// //             Load View
// //           </Link>
// //         </MenuItem>
// //         <Divider
// //           style={{
// //             margin: "9px 0",
// //             borderBottom: "1px solid rgba(0, 0, 0, 0.2)",
// //             width: "100%",
// //           }}
// //         /> */}
// //         {/* <MenuItem key="4">
// //           <Link to="/trucker/payment" style={{ textDecoration: "none" }}>
// //             My Bills
// //           </Link>
// //         </MenuItem> */}
// //         <MenuItem key="5">
// //           <Link to="/trucker/truck" style={{ textDecoration: "none" }}>
// //             My Truck
// //           </Link>
// //         </MenuItem>
// //         {/* <MenuItem key="6">Setting</MenuItem> */}
// //         <Divider
// //           style={{
// //             margin: "12px 0 40px",
// //             borderBottom: "1px solid rgba(0, 0, 0, 0.2)",
// //             width: "100%",
// //           }}
// //         />
// //         <MenuItem key="7" style={{ justifyContent: "center" }}>
// //           <Link to="/trucker/ledger" style={{ textDecoration: "none" }}>
// //             My ledger
// //           </Link>
// //         </MenuItem>
// //         {/* <MenuItem key="8">Message</MenuItem> */}
// //         <MenuItem key="9">
// //           <Link to="/trucker/profilet" style={{ textDecoration: "none" }}>
// //             Profile
// //           </Link>
// //         </MenuItem>
// //         <Divider
// //           style={{
// //             margin: "12px 0 12px",
// //             borderBottom: "1px solid rgba(0, 0, 0, 0.2)",
// //             width: "100%",
// //           }}
// //         />

// //         <MenuItem
// //           key="11"
// //           style={{ color: "#b23b3b" }}
// //           onClick={(event) => handleLogout(event)}
// //           icon={<FaSignOutAlt style={{ color: "red", opacity: "0.7" }} />}
// //         >
// //           <Link to="/trucker-login" style={{ textDecoration: "none" }}>
// //             Logout
// //           </Link>
// //         </MenuItem>
// //       </Menu>
// //     </>
// //   );
// // };

// // export default SiderContentF;

// import React from "react";
// import { useEffect, useState } from "react";
// import { Row, Col, Tabs, Skeleton, message } from "antd";
// import { Link } from "react-router-dom";
// import { Menu } from "antd";
// import { FaSignOutAlt } from "react-icons/fa";
// import axios from "axios";
// import { useNavigate } from "react-router-dom";
// const { Item: MenuItem, Divider } = Menu;

// const SiderContentF = (props) => {
//   const [check, setCheck] = useState(true);
//   const history = useNavigate();

//   const handleLogout = (event) => {
//     axios
//       .get(props.url + "/api/v1/type/logout", {
//         withCredentials: true,
//       })
//       .then((response) => {
//         message.success({
//           content: "Goodbye!!!! See You Again!!!",
//           className: "custom-class",
//           style: {
//             marginTop: "20vh",
//           },
//         });
//         history("/");
//       })
//       .catch((err) => {
//         console.log(err);
//       });
//   };

//   useEffect(() => {
//     axios
//       .get("http://yzulu.com/api/v1/profile/check-profile", {
//         withCredentials: true,
//       })
//       .then((response) => {
//         const toCheck = response.data.profile.rows[0];
//         console.log(response.data.profile.rows[0]);

//         // Define a function to check the object properties asynchronously
//         const checkProperties = async (obj) => {
//           let midCheck = true;
//           for (const key in obj) {
//             if (obj.hasOwnProperty(key) && !obj[key]) {
//               midCheck = false;
//               return false; // If any key is empty, return false
//             }
//           }
//           return midCheck;
//         };

//         // Use the function and set the result asynchronously
//         checkProperties(toCheck)
//           .then((result) => {
//             if (result === true) {
//               console.log(result);
//               setCheck(result);
//             }
//             if (result === false) {
//               setCheck(result);
//               console.log(result);
//               console.log("setCheckValue:" + check);
//             }

//             console.log("waah bete moj kardi");
//           })
//           .catch((error) => {
//             console.error("abe yarr ab ye kya ho gya");
//           });
//       })
//       .catch((error) => {
//         console.error("Error fetching data:", error);
//       });
//   }, []);

//   return (
//     <>
//       <Menu
//         mode="inline"
//         defaultSelectedKeys={["1"]}
//         defaultOpenKeys={["dashboard"]}
//         style={{
//           height: "100%",
//           borderRight: 0,
//         }}
//         theme="light"
//       >
//         <MenuItem key="1" style={{ justifyContent: "flex-start" }}>
//           <Link to="/trucker/dashboard" style={{ textDecoration: "none" }}>
//             Dashbord
//           </Link>
//         </MenuItem>

//         {check ? (
//           <MenuItem key="2">
//             <Link to="/trucker/view-bids" style={{ textDecoration: "none" }}>
//               View Bids
//             </Link>
//           </MenuItem>
//         ) : (
//           <MenuItem key="2">
//             <Link to="Checking" style={{ textDecoration: "none" }}>
//               View Bids
//             </Link>
//           </MenuItem>
//         )}

//         {/*
//         <MenuItem key="2">
//             <Link to="/home/post-load" style={{ textDecoration: "none" }}>
//             View Bids
//           </Link>
//         </MenuItem> */}
//         <Divider
//           style={{
//             margin: "9px 0",
//             borderBottom: "1px solid rgba(0, 0, 0, 0.2)",
//             width: "100%",
//           }}
//         />
//         {/* <MenuItem key="3" className="align-right">
//           <Link to="/trucker/load-view" style={{ textDecoration: "none" }}>
//             Load View
//           </Link>
//         </MenuItem>
//         <Divider
//           style={{
//             margin: "9px 0",
//             borderBottom: "1px solid rgba(0, 0, 0, 0.2)",
//             width: "100%",
//           }}
//         /> */}
//         {/* <MenuItem key="4">
//           <Link to="/trucker/payment" style={{ textDecoration: "none" }}>
//             My Bills
//           </Link>
//         </MenuItem> */}
//         <MenuItem key="5">
//           <Link to="/trucker/truck" style={{ textDecoration: "none" }}>
//             My Truck
//           </Link>
//         </MenuItem>
//         {/* <MenuItem key="6">Setting</MenuItem> */}
//         <Divider
//           style={{
//             margin: "12px 0 40px",
//             borderBottom: "1px solid rgba(0, 0, 0, 0.2)",
//             width: "100%",
//           }}
//         />
//         <MenuItem key="7" style={{ justifyContent: "center" }}>
//           <Link to="/trucker/ledger" style={{ textDecoration: "none" }}>
//             My ledger
//           </Link>
//         </MenuItem>
//         {/* <MenuItem key="8">Message</MenuItem> */}
//         <MenuItem key="9">
//           <Link to="/trucker/profilet" style={{ textDecoration: "none" }}>
//             Profile
//           </Link>
//         </MenuItem>
//         <Divider
//           style={{
//             margin: "12px 0 12px",
//             borderBottom: "1px solid rgba(0, 0, 0, 0.2)",
//             width: "100%",
//           }}
//         />

//         <MenuItem
//           key="11"
//           style={{ color: "#b23b3b" }}
//           onClick={(event) => handleLogout(event)}
//           icon={<FaSignOutAlt style={{ color: "red", opacity: "0.7" }} />}
//         >
//           <Link to="/trucker-login" style={{ textDecoration: "none" }}>
//             Logout
//           </Link>
//         </MenuItem>
//       </Menu>
//     </>
//   );
// };

// export default SiderContentF;

import React from "react";
import { useEffect, useState } from "react";
import { Row, Col, Tabs, Skeleton, message } from "antd";
import { Link } from "react-router-dom";
import { Menu } from "antd";
import { FaSignOutAlt } from "react-icons/fa";
import axios from "axios";
import { useNavigate } from "react-router-dom";
const { Item: MenuItem, Divider } = Menu;

const SiderContentF = (props) => {
  const [check, setCheck] = useState(true);
  const history = useNavigate();

  console.log(props);

  const handleLogout = (event) => {
    axios
      .get(props.url + "/api/v1/type/logout", {
        withCredentials: true,
      })
      .then((response) => {
        message.success({
          content: "Goodbye!!!! See You Again!!!",
          className: "custom-class",
          style: {
            marginTop: "20vh",
          },
        });
        history("/");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    axios
      .get("http://yzulu.com/api/v1/profile/check-profile", {
        withCredentials: true,
      })
      .then((response) => {
        const toCheck = response.data.profile.rows[0];
        console.log(response.data.profile.rows[0]);

        // Define a function to check the object properties asynchronously
        const checkProperties = async (obj) => {
          let midCheck = true;
          for (const key in obj) {
            if (obj.hasOwnProperty(key) && !obj[key]) {
              midCheck = false;
              return false; // If any key is empty, return false
            }
          }
          return midCheck;
        };

        // Use the function and set the result asynchronously
        checkProperties(toCheck)
          .then((result) => {
            if (result === true) {
              console.log(result);
              setCheck(result);
            }
            if (result === false) {
              setCheck(result);
              console.log(result);
              console.log("setCheckValue:" + check);
            }

            console.log("waah bete moj kardi");
          })
          .catch((error) => {
            console.error("abe yarr ab ye kya ho gya");
          });
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  return (
    <>
      <Menu
        mode="inline"
        defaultSelectedKeys={["1"]}
        defaultOpenKeys={["dashboard"]}
        style={{
          height: "100%",
          borderRight: 0,
        }}
        theme="light"
      >
        <MenuItem key="1" style={{ justifyContent: "flex-start" }}>
          <Link to="/trucker/dashboard" style={{ textDecoration: "none" }}>
            Dashbord
          </Link>
        </MenuItem>

        {/* <MenuItem key="2">
          {props.check ? (
            <Link to="/trucker/view-bids" style={{ textDecoration: "none" }}>
              View Bids
            </Link>
          ) : (
            <Link to="Checking" style={{ textDecoration: "none" }}>
              View Bids
            </Link>
          )}
        </MenuItem> */}

        {/* {check ? (
          <MenuItem key="2">
            <Link to="/trucker/view-bids" style={{ textDecoration: "none" }}>
              View Bids
            </Link>
          </MenuItem>
        ) : (
          <MenuItem key="2">
            <Link to="Checking" style={{ textDecoration: "none" }}>
              View Bids
            </Link>
          </MenuItem>
        )} */}

        {/* 
        <MenuItem key="2">
            <Link to="/home/post-load" style={{ textDecoration: "none" }}>
            View Bids
          </Link>
        </MenuItem> */}
        <Divider
          style={{
            margin: "9px 0",
            borderBottom: "1px solid rgba(0, 0, 0, 0.2)",
            width: "100%",
          }}
        />
        {/* <MenuItem key="3" className="align-right">
          <Link to="/trucker/load-view" style={{ textDecoration: "none" }}>
            Load View
          </Link>
        </MenuItem>
        <Divider
          style={{
            margin: "9px 0",
            borderBottom: "1px solid rgba(0, 0, 0, 0.2)",
            width: "100%",
          }}
        /> */}
        {/* <MenuItem key="4">
          <Link to="/trucker/payment" style={{ textDecoration: "none" }}>
            My Bills
          </Link>
        </MenuItem> */}
        <MenuItem key="5">
          <Link to="/trucker/truck" style={{ textDecoration: "none" }}>
            My Truck
          </Link>
        </MenuItem>
        {/* <MenuItem key="6">Setting</MenuItem> */}
        <Divider
          style={{
            margin: "4px 0 4px",
            borderBottom: "1px solid rgba(0, 0, 0, 0.2)",
            width: "100%",
          }}
        />
        <MenuItem key="7" style={{ justifyContent: "center" }}>
          <Link to="/trucker/ledger" style={{ textDecoration: "none" }}>
            My ledger
          </Link>
        </MenuItem>
        {/* <MenuItem key="8">Message</MenuItem> */}
       
        <Divider
          style={{
            margin: "12px 0 12px",
            borderBottom: "1px solid rgba(0, 0, 0, 0.2)",
            width: "100%",
          }}
        />

        <MenuItem
          key="11"
          style={{ color: "#b23b3b" }}
          onClick={(event) => handleLogout(event)}
          icon={<FaSignOutAlt style={{ color: "red", opacity: "0.7" }} />}
        >
          <Link to="/trucker-login" style={{ textDecoration: "none" }}>
            Logout
          </Link>
        </MenuItem>
      </Menu>
    </>
  );
};

export default SiderContentF;
