import React from 'react'
import './head.css'


const Headbox = (props) => {
    return (
        <>

            <div className='headbox' style={{
                border: '1px solid #ddd', width: '17rem', boxShadow: '-2px 4px 6px rgba(0, 0, 0, 0.3)',
                height: '10rem', marginTop: '1.2rem', background: 'linear-gradient(to bottom, #D9BFE0, #fff)', overflow:"hidden"
            }}>
                <div style={{borderRadius:"5px", width:"5rem", height:"1rem", marginLeft:"10rem", marginTop:'0.6rem', color:"black"}}>This Week</div>

                <div className='inner'>
                    <span id='cominghead'>
                   {props.data}

                    </span>

                    <span className='tle'>{props.abc}</span>

                </div>



            </div>


        </>
    )
}

export default Headbox