import React, { useState, useEffect, useContext } from "react";
import DataContext from "../../../Context/dataContext";
import axios from "axios";

const TopFiveUser = () => {
  const [data, setData] = useState([]);
  const employeeData = useContext(DataContext);

  useEffect(() => {
    // pending-bids
    console.log("admin testing is here");
    axios
      .get(employeeData.URL + "/api/v1/admin/top-five", {
        withCredentials: true,
      })
      .then((response) => {
        setData(response.data.data.fivetrucker.rows);
        console.log(response.data.data.fivetrucker.rows);
        // setNewData(response.data.data.TodaysBookingDetails.rows);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  return (
    <>
      <h1>
        <div>Top 5 user</div>
      </h1>

      <div>
        {" "}
        <table style={{ borderSpacing: "0 40px" }}>
          <thead>
            <tr>
              <th>Account</th>
              <th>Shipper</th>
              <th>load_id</th>
            </tr>
          </thead>

          <tbody>
            {data.map((item) => (
              <React.Fragment key={item.load_id}>
                <tr
                  style={{
                    padding: "0.3em 1em 0.3em 0em",
                    borderRadius: "5px",
                    boxShadow: "0px 2px 5px rgb(94, 90, 91) inset",
                    backgroundColor: "#c99cd8",
                    backgroundImage:
                      "linear-gradient(to bottom, #c99cd8, rgb(254, 252, 254))",
                  }}
                >
                  <td>{item.account_code}</td>
                  <td>{item.shipper}</td>
                  <td>{item.id}</td>
                </tr>
                <tr>
                  <td colSpan="3" style={{ height: "0.5rem" }}></td>
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default TopFiveUser;
