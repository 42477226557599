import React from "react";
import { Row, Col, Skeleton, Tooltip } from "antd";
import { RiUserSearchFill } from "react-icons/ri";
import axios from "axios";
import { useState, useEffect, useContext } from "react";
import DataContext from "../../../Context/dataContext";
import FreightTable from "../../FreightTable/FreightTable";
import { UilSearch } from "@iconscout/react-unicons";

const Template = (props) => {
  const [columns, setColumns] = useState();
  const [rows, setRows] = useState();
  const employeeData = useContext(DataContext);

  const number = props.case;

  useEffect(() => {
    axios
      .get(employeeData.URL + `/api/v1/load/dashclickamount`, {
        withCredentials: true,
      })
      .then((response) => {
        console.log(response);

        if (number === 1) {
          console.log(response.data.zto15);
          const zto15 = response.data.zto15;
          const arrayofo = zto15;
          console.log(typeof zto15);


          setRows((rows) => {
            let newRows = zto15.map((rows, index) => {
              return {
                id: index + 1,
                ...rows,
              };
            });
            return newRows;
          });

          setColumns((columns) => {
            if (zto15.length === 0) {
              return []; // Return an empty array if zto15 is empty
            }
          
            let firstObject = zto15[0]; // Get the first object in the array
            let newCols = Object.keys(firstObject).map((key) => {
              let headerName = key.replace(/_/g, " ");
              return {
                field: key,
                headerName: headerName,
                width: 150,
              };
            });
          
            const newNewCols = [...newCols];
            return newNewCols;
          });
          
          
          

        }

        if (number === 2) {
          console.log(response.data.fto30);
          const fto30 = response.data.fto30;
          const arrayofo = [fto30];
          console.log(typeof fto30);

          const rowsFromObject = Object.values(fto30);

          console.log(arrayofo);
          // setRows(rowsFromObject)

          setRows((rows) => {
            let newRows = fto30.map((rows, index) => {
              return {
                id: index + 1,
                ...rows,
              };
            });
            return newRows;
          });

          const columnsFromObject = Object.keys(fto30);
          console.log(columnsFromObject);
          // setColumns(columnsFromObject)

          setColumns((columns) => {
            if (fto30.length === 0) {
              return []; // Return an empty array if zto15 is empty
            }
            let firstObject = fto30[0]; // Get the first object in the array
            let newCols = Object.keys(firstObject).map((key) => {
              let headerName = key.replace(/_/g, " ");
              return {
                field: key,
                headerName: headerName,
                width: 150,
              };
            });
            
            const newNewCols = [...newCols];
            return newNewCols;
          });
        }

        if (number === 3) {
          console.log(response.data.tt60);
          const tt60 = response.data.tt60;
          const arrayofo = [tt60];
          console.log(typeof tt60);

          const rowsFromObject = Object.values(tt60);

          console.log(arrayofo);
          // setRows(rowsFromObject)

          setRows((rows) => {
            let newRows = tt60.map((rows, index) => {
              return {
                id: index + 1,
                ...rows,
              };
            });
            return newRows;
          });

          const columnsFromObject = Object.keys(tt60);
          console.log(columnsFromObject);

          setColumns((columns) => {
            if (tt60.length === 0) {
              return []; // Return an empty array if zto15 is empty
            }
          
            let firstObject = tt60[0]; // Get the first object in the array
            let newCols = Object.keys(firstObject).map((key) => {
              let headerName = key.replace(/_/g, " ");
              return {
                field: key,
                headerName: headerName,
                width: 150,
              };
            });
            const newNewCols = [...newCols];
            return newNewCols;
          });
        }

        if (number === 4) {
          console.log(response.data.mtmonth);
          const mtmonth = response.data.mtmonth;
          const arrayofo = [mtmonth];
          console.log(typeof mtmonth);

          const rowsFromObject = Object.values(mtmonth);

          console.log(arrayofo);
          // setRows(rowsFromObject)

          setRows((rows) => {
            let newRows = mtmonth.map((rows, index) => {
              return {
                id: index + 1,
                ...rows,
              };
            });
            return newRows;
          });

          const columnsFromObject = Object.keys(mtmonth);
          console.log(columnsFromObject);
          // setColumns(columnsFromObject)

          setColumns((columns) => {
            if (mtmonth.length === 0) {
              return []; // Return an empty array if zto15 is empty
            }
          
            let firstObject = mtmonth[0]; // Get the first object in the array
            let newCols = Object.keys(firstObject).map((key) => {
              let headerName = key.replace(/_/g, " ");
              return {
                field: key,
                headerName: headerName,
                width: 150,
              };
            });
          

            const newNewCols = [...newCols];
            return newNewCols;
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <>
      <form>
        <div
          id="section"
          className="d-flex flex-row justify-content-between"
          style={{ height: "10rem" }}
        >
          <h1 style={{ color: "#531967", marginRight: "2rem", margin: "auto" }}>
            {props.name}
          </h1>
        </div>

        {rows && columns.length > 0 ? (
          <div style={{ display: "inline-block" }}>
            <FreightTable data={rows} columns={columns} />
          </div>
        ) : (
          <div>No matching results found.</div>
        )}
      </form>
    </>
  );
};

export default Template;
