
import React, { useState, useRef, useEffect, useContext } from "react";
import { useJsApiLoader, Autocomplete } from "@react-google-maps/api";
import { message } from "antd";

import "./Aform.css";
import axios from "axios";
import { Navigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import DataContext from "../../../Context/dataContext";



const filledInputs = new Set();
function highlightBorder(inputId) {
  const inputElement = document.getElementById(inputId);
  if (inputElement.value.trim() === "") {
    inputElement.classList.remove("highlight-border");
    filledInputs.delete(inputId);
  } else {
    document.getElementById(inputId).style.border = "2px solid #f0e5f3";
  }
}

const Aform = (props) => {
  const originAutocompleteRef = useRef(null);
  console.log("this is the props", props);
  const employeeData = useContext(DataContext);

  const [industryOptions, setIndustryOptions] = useState([]);
  const [loadTypeOptions, setLoadTypeOptions] = useState([]);
  const [vehicleTypeOptions, setVehicleTypeOptions] = useState([]);
  const [rating, setRating] = useState([]);
  const [Currency, setCurrency] = useState([]);
  const[thisis,setThisistheNew] = useState()

  const [Unit, setUnit] = useState([])

  const [formData, setFormData] = useState({
    account_name: props.data[0].account_name || "",
    contact_name: props.data[0].contact_name || "",
    company_add: props.data[0].company_add || "",
    state_code: props.data[0].state_code || "",
    city_code: props.data[0].city_code || "",
    pin_code: props.data[0].pin_code || "",
    company_registration_no: props.data[0].company_registration_no || "",
    company_tax_no: props.data[0].company_tax_no || "",
    company_size: props.data[0].company_size || "",
    industry_type_cd: props.data[0].industry_type_cd || "",
    annual_revenue: props.data[0].annual_revenue || "",
    vechile_type_cd: props.data[0].vechile_type_cd || "",
    age_of_mc: props.data[0].age_of_mc || "",
    start_location: props.data[0].start_location || "",
    load_type_cd: props.data[0].load_type_cd || "",
    carrier_rating_cd: props.data[0].carrier_rating_cd || "",
    end_location: props.data[0].end_location || "",
    currency_id: props.data[0].currency_id || "",
    size_id: props.data[0].size_id || "",
  });

  const [errors, setErrors] = useState({});

  const [countryCode, setCountryCode] = useState("+1"); // Default country code

  const history = useNavigate();

  // List of country codes with their respective names
  const countryCodes = [
    { code: "+1", name: "USA" },
    { code: "+91", name: "India" },
    { code: "+44", name: "United Kingdom" },
    { code: "+86", name: "China" },
    { code: "+81", name: "Japan" },
    { code: "+33", name: "France" },
    { code: "+49", name: "Germany" },
    { code: "+7", name: "Russia" },
    { code: "+55", name: "Brazil" },
    { code: "+61", name: "Australia" },
    { code: "+52", name: "Mexico" },
    { code: "+82", name: "South Korea" },
    { code: "+34", name: "Spain" },
    { code: "+39", name: "Italy" },
    { code: "+1", name: "Canada" },
    { code: "+31", name: "Netherlands" },
    { code: "+46", name: "Sweden" },
    { code: "+91", name: "Pakistan" },
    { code: "+20", name: "Egypt" },
    { code: "+64", name: "New Zealand" },
    // You can continue to add more country codes as needed
  ];

  // Handle change in the country code dropdown
  const handleCountryCodeChange = (event) => {
    setCountryCode(event.target.value);
  };

  const handleCompanySizeChange = (event) => {
    const { name, value } = event.target;
    const numericValue = value.replace(/\D/g, ""); // Remove non-numeric characters

    // Validate if the input is a valid numeric value and within the length limit
    const isValidNumber = !isNaN(numericValue) && numericValue.length <= 15;

    if (!isValidNumber || value.length > 16) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]:
          "Please enter a valid numeric value for Company Size (up to 10 digits).",
      }));
    } else {
      // Clear the error message if the input is a valid numeric value
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    }

    // Update the form data for Company Size
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: isValidNumber ? numericValue : "",
    }));
  };

  const handleAnnualRevenueChange = (event) => {
    const { name, value } = event.target;
    const numericValue = value.replace(/\D/g, ""); // Remove non-numeric characters

    // Validate if the input is a valid numeric value and within the length limit
    const isValidNumber = !isNaN(numericValue) && numericValue.length <= 16;

    if (!isValidNumber || value.length > 16) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]:
          "Please enter a valid numeric value for Company Size (up to 10 digits).",
      }));
    } else {
      // Clear the error message if the input is a valid numeric value
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    }

    // Update the form data for Company Size
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: isValidNumber ? numericValue : "",
    }));
  };

  // const handleCompanySizeChange = (event) => {
  //   const { name, value } = event.target;
  //   const numericValue = value.replace(/\D/g, ""); // Remove non-numeric characters

  //   // Validate if the input is a valid numeric value and within the length limit
  //   const isValidNumber = !isNaN(numericValue) && numericValue.length <= 10;

  //   if (!isValidNumber) {
  //     setErrors((prevErrors) => ({
  //       ...prevErrors,
  //       [name]:
  //         "Please enter a valid numeric value for Company Size (up to 10 digits).",
  //     }));
  //   } else {
  //     // Clear the error message if the input is a valid numeric value
  //     setErrors((prevErrors) => ({
  //       ...prevErrors,
  //       [name]: "",
  //     }));
  //   }

  //   // Update the form data for Company Size
  //   setFormData((prevFormData) => ({
  //     ...prevFormData,
  //     [name]: isValidNumber ? numericValue : "",
  //   }));
  // };

  // const handleCompanySizeChange = (event) => {
  //   const { name, value } = event.target;
  //   const numericValue = value.replace(/\D/g, ""); // Remove non-numeric characters

  //   // Validate if the input is a valid numeric value
  //   const isValidNumber = !isNaN(numericValue);

  //   if (!isValidNumber) {
  //     setErrors((prevErrors) => ({
  //       ...prevErrors,
  //       [name]: "Please enter a valid numeric value for Company Size.",
  //     }));
  //   } else {
  //     // Clear the error message if the input is a valid numeric value
  //     setErrors((prevErrors) => ({
  //       ...prevErrors,
  //       [name]: "",
  //     }));
  //   }

  //   // Update the form data for Company Size
  //   setFormData((prevFormData) => ({
  //     ...prevFormData,
  //     [name]: isValidNumber ? numericValue : "",
  //   }));
  // };

  // Handle change in the contact field
  const handleContactChange = (event) => {
    const { name, value } = event.target;
    // Remove any non-numeric characters from the input
    const numericValue = value.replace(/\D/g, "");
    // Limit the input to 10 digits
    const limitedValue = numericValue.slice(0, 10);
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: limitedValue,
    }));

    // Validate the contact number length
    if (limitedValue.length !== 10) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "Please enter a valid 10-digit contact number.",
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "", // Clear the error message if the contact number is valid
      }));
    }
  };
  // __________________________________________________
  console.log(props);

  const validateCompanyRegistrationNo = (value) => {
    const numericValue = value.replace(/\D/g, ""); // Remove non-numeric characters
    const isValidLength =
      numericValue.length >= 18 && numericValue.length <= 23;
    return isValidLength
      ? null
      : "Please enter a valid Company Registration Number between 18 and 23 digits.";
  };

  const handleCompanyRegistrationNoBlur = (event) => {
    const { name, value } = event.target;
    const validationError = validateCompanyRegistrationNo(value);

    if (validationError) {
      // Display the error message
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: validationError,
      }));
    } else {
      // Clear the error message if the input is valid
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));

      // Update the form data
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };

  const handleCompanyRegistrationChange = (event) => {
    const { name, value } = event.target;
    // Remove any non-numeric characters from the input
    const numericValue = value;
    // Limit the input to 20 to 24 digits
    const limitedValue = numericValue.slice(0, 24);
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: limitedValue,
    }));

    // Validate the input length for Company Registration Number
    if (limitedValue.length < 20 || limitedValue.length > 24) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]:
          "Please enter a valid Company Registration Number between 20 to 23 ",
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "", // Clear the error message if the input is valid
      }));
    }
  };

  // Function to handle numeric input for "Age of MC" field
  const handleAgeOfMCChange = (value) => {
    // Check if the entered value is numeric or an empty string
    const isNumeric = /^[0-9]*$/.test(value);

    if (!isNumeric && value !== "") {
      // Display an error message for invalid input
      setErrors((prevErrors) => ({
        ...prevErrors,
        age_of_mc: "Please enter a valid numeric value for Age of MC.",
      }));
      return false; // Indicate that the input is not numeric
    }

    // Clear the error message for the "Age of MC" field
    setErrors((prevErrors) => ({
      ...prevErrors,
      age_of_mc: "",
    }));

    return true; // Indicate that the input is numeric
  };

  const handleChange = (event, event1) => {

    console.log(formData);
    const { name, value } = event.target;

    if (name === "age_of_mc") {
      // Call the handleAgeOfMCChange function for the "Age of MC" field
      const isNumeric = handleAgeOfMCChange(value);

      if (!isNumeric) {
        // Stop further processing if the input is not numeric
        return;
      }
    }

    if (
      name === "company_size" ||
      name === "annual_revenue" ||
      name === "age_of_mc"
    ) {
      const numericValue = value.replace(/\D/g, ""); // Remove non-numeric characters
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: numericValue,
      }));

      // Validate the input length for Company Size and Annual Revenue
      if (numericValue.length === 0 || numericValue.length > 10) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "Please enter a valid numeric value up to 10 digits.",
        }));
      }
    }

    if (name === "company_registration_no") {
      // Handle the Company Registration Number field
      // Validate the value and set an error message if it's not valid
      const validationError = validateCompanyRegistrationNo(value);

      if (validationError) {
        // Display the error message
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: validationError,
        }));
      } else {
        // Clear the error message if the input is valid
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "",
        }));
      }
    }

    if (name === "company_tax_no") {
      // Handle the Company Tax Number field
      // Remove any non-numeric characters from the input
      const numericValue = value;
      // Limit the input to 15 digits
      const limitedValue = numericValue.slice(0, 15);

      if (limitedValue.length < 11 || limitedValue.length > 15) {
        // Display an error message for invalid tax number
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "Please enter a valid 11 to 15-digit Tax number.",
        }));
      } else {
        // Clear the error message if the tax number is valid
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "",
        }));
      }

      // Update the form data for Company Tax Number
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: limitedValue,
      }));
    } else {
      // Handle other fields here
      // Update the form data for other fields by name
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
      // Clear the error message for the current field (if any)
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    }
  };

  // useEffect(() => {
  //   let a;
  //   if (originAutocompleteRef.current) {
  //     const originAutocomplete = new window.google.maps.places.Autocomplete(
  //       originAutocompleteRef.current
  //     );

  //     originAutocomplete.addListener("place_changed", () => {
  //       console.log("thisis originAutocomplete MAIN", originAutocompleteRef);
  //       console.log("thisis originAutocomplete", originAutocomplete);
  //       const place = originAutocomplete.getPlace();
  //       console.log(place.formatted_address, " tjios is the formated address");
  //       const newCompleteAdd = place.formatted_address;
  //       console.log(newCompleteAdd, " this si the formated address");
  //       console.log("this is place", place);
  //       console.log("this is the name  place", place.name);
  //       const addressComponents = place.address_components || [];
  //       console.log("this is address Components", addressComponents);
  //       console.log(addressComponents[0].types.includes('sublocality', 'sublocality_leve_l'))
  //       let one, two, twoo, three,threee, four, five;
  //       for (let i = 0; i < addressComponents.length; i++) {
  //         if (addressComponents[i].types) {
  //           // Strings to check for
  //           const stringsToCheck = ["locality", "sublocality", "sublocality_level_1"];

  //           // Loop through each string to check
  //           for (let j = 0; j < 3; j++) {
  //             const stringToCheck = stringsToCheck[j];

  //             // Check if the stringToCheck is present in addressComponents[i].types
  //             if (addressComponents[i].types.includes('route', 'sublocality')) {
  //               if (addressComponents[i].long_name != place.name) {
  //                 console.log(`this is the first`, addressComponents[i].long_name);
  //                 one = addressComponents[i].long_name;
  //               }
  //             }
  //             if (addressComponents[i].types.includes('sublocality', 'sublocality_leve_l')) {

               
  //               if (!addressComponents[i+1].types.includes('locality')) {
  //                 console.log(" hii all good");
  //                 // Code to execute if 'locality' is not included in addressComponents[i+1].types
  //                 two = addressComponents[i].long_name;
  //                 console.log(`second: ${addressComponents[i]}`, addressComponents[i].long_name);
  //                 twoo = addressComponents[i+1].long_name;
  //                 console.log(`secondoo: ${addressComponents[i+1]}`, addressComponents[i+1].long_name);
  //             }
              
  //             }
  //             if (addressComponents[i].types.includes('locality')) {
  //               console.log(`third:`, addressComponents[i].long_name);
  //               three = addressComponents[i].long_name;
  //             }
  //             if (addressComponents[i].types.includes('administrative_area_level_1')) {
  //               console.log(`thirdee:`, addressComponents[i].long_name);
  //               threee = addressComponents[i].long_name;
  //             }
  //             if (addressComponents[i].types.includes('postal_code')) {
  //               console.log(`fourth:`, addressComponents[i].long_name);
  //               four = addressComponents[i].long_name;
  //             }
  //             if (addressComponents[i].types.includes('country')) {
  //               console.log(`fifth:`, addressComponents[i].long_name);
  //               five = addressComponents[i].long_name;
  //             } else {
  //               console.log(`${stringToCheck} is NOT present in addressComponents[${i}].types`);
  //             }
  //           }
  //         }
  //       }

  //       // const myfullAddress = `${place.name}, ${one}, ${two}, ${three}, ${four}, ${five}`;
  //       let myfullAddress = `${place.name}`;
  //       const addedStrings = new Set(); // Set to store added strings
        
  //       // Function to add unique strings to the address
  //       function addToAddressIfUnique(value) {
  //         if (value !== undefined && !addedStrings.has(value)) {
  //           myfullAddress += `, ${value}`;
  //           addedStrings.add(value);
  //         }
  //       }
        
  //       addToAddressIfUnique(one);
  //       addToAddressIfUnique(two);
  //       addToAddressIfUnique(twoo);
  //       addToAddressIfUnique(three);
  //       addToAddressIfUnique(threee);
  //       addToAddressIfUnique(four);
  //       addToAddressIfUnique(five);
        
  //       console.log(myfullAddress, "hi there this is the full address");
        
        



  //       a = addressComponents[0].long_name;
  //       console.log("thisis a", a);

  //       let state = "";
  //       let city = "";
  //       let zip = "";
  //       let streetAddress = "";

  //       addressComponents.forEach((component) => {
  //         if (component.types.includes("administrative_area_level_1")) {
  //           state = component.long_name;
  //         } else if (component.types.includes("locality")) {
  //           city = component.long_name;
  //         } else if (component.types.includes("postal_code")) {
  //           zip = component.long_name;
  //         } else if (component.types.includes("street_number")) {
  //           streetAddress += component.long_name + " ";
  //         } else if (component.types.includes("route")) {
  //           streetAddress += component.long_name;
  //         }
  //       });

  //       const completeAddress = `${a},${streetAddress}, ${city}, ${state}, ${zip}`;
  //       console.log("this is scomplete Address", completeAddress);

  //       // Update the fields with the extracted values for the destination
  //       setFormData((prevFormData) => ({
  //         ...prevFormData,
  //         state_code: state,
  //         city_code: city,
  //         pin_code: zip,
  //         company_add: myfullAddress,
  //       }));

  //       // You can also set these values using querySelector if needed
  //       document.querySelector('[name="state_code"]').value = state;
  //       document.querySelector('[name="city_code"]').value = city;
  //       document.querySelector('[name="pin_code"]').value = zip;
  //     });
  //   }

    // axios
    //   .get(employeeData.URL + "/api/v1/profile/get-Data-lov", {
    //     withCredentials: true,
    //   })
    //   .then((response) => {
    //     console.log(response);
    //     console.log("just checking");
    //     setIndustryOptions(response.data.IndustryType);
    //     setLoadTypeOptions(response.data.LoadType);
    //     setVehicleTypeOptions(response.data.VecheilType);
    //     setRating(response.data.Rating);
    //     setCurrency(response.data.Currency)
    //     setUnit(response.data.Unit)
    //     //
    //   })
    //   .catch((errors) => {
    //     console.log(errors);
    //   });
  // }, []);

  const handleSubmit = () => {
    const mandatoryFields = [
      "account_name",
      "contact_name",
      "company_add",
      "state_code",
      "city_code",
      "pin_code",
      "company_registration_no",
      "company_tax_no",
      "vechile_type_cd",
      "size_id",
      "currency_id"


    ];
    const theID = [
      "companyName",
      "contact",
      "Address",
      "Registration",
      "Registration",
      "Tax",
      "state",
      "city",
      "zip",
    ];
    const errors = {};

    // Check for empty mandatory fields
    mandatoryFields.forEach((field) => {
      if (!formData[field]) {
        errors[field] = "Please Enter the data in mendetory Fields";
      }
    });

    theID.forEach((fields) => {
      const inputElement = document.getElementById(fields);
      if (inputElement.value.trim() === "") {
        document.getElementById(fields).style.border = "2px solid red";
      }
    });

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }

    console.log(formData);
    const postdata = {
      profile: [formData],
    };

    axios
      .post(employeeData.URL + "/api/v1/profile/update-account", postdata, {
        withCredentials: true,
        credentials: "include",
      })
      .then((response) => {
        console.log("Data successfully sent to the server:", response.data);
        console.log(props);
        props.employeeData.setCheck(true);
        message.success({
          content: "Profile Filled Successfully",
          className: "custom-class",
          style: {
            marginTop: "2vh",
          },
        });
        history("/home");

        // Perform any further actions after successful submission
      })
      .catch((error) => {
        console.error("Error sending data to the server:", error);
      });
  };




  const inputRef = useRef(null);
  const autocompleteRef = useRef(null);
  const observerRef = useRef(null);

  // useEffect(() => {
  //   const initializeAutocomplete = () => {
  //     const input = originAutocompleteRef.current;
  //     const autocomplete = new window.google.maps.places.Autocomplete(input, {
  //       fields: ["formatted_address", "geometry", "name"],
  //       strictBounds: false,
  //     });

  //     function setupClickListener(id, types) {
  //       const radioButton = document.getElementById(id);
  //       if (radioButton) {
  //         radioButton.addEventListener("click", () => {
  //           autocomplete.setTypes(types);
  //           input.value = "";
  //         });
  //       }
  //     }

  //     setupClickListener("changetype-all", []);
  //     setupClickListener("changetype-address", ["address"]);
  //     setupClickListener("changetype-establishment", ["establishment"]);
  //     setupClickListener("changetype-geocode", ["geocode"]);
  //     setupClickListener("changetype-cities", ["(cities)"]);
  //     setupClickListener("changetype-regions", ["(regions)"]);

  //     const biasInputElement = document.getElementById("use-location-bias");
  //     const strictBoundsInputElement = document.getElementById("use-strict-bounds");

  //     if (biasInputElement) {
  //       biasInputElement.addEventListener("change", () => {
  //         if (biasInputElement.checked) {
  //           autocomplete.bindTo("bounds", map);
  //         } else {
  //           autocomplete.unbind("bounds");
  //           autocomplete.setBounds({ east: 180, west: -180, north: 90, south: -90 });
  //           strictBoundsInputElement.checked = biasInputElement.checked;
  //         }

  //         input.value = "";
  //       });
  //     }

  //     if (strictBoundsInputElement) {
  //       strictBoundsInputElement.addEventListener("change", () => {
  //         autocomplete.setOptions({
  //           strictBounds: strictBoundsInputElement.checked,
  //         });
  //         if (strictBoundsInputElement.checked) {
  //           biasInputElement.checked = strictBoundsInputElement.checked;
  //           autocomplete.bindTo("bounds", map);
  //         }

  //         input.value = "";
  //       });
  //     }
  //     setThisistheNew(autocomplete)

  //     // autocompleteRef.current = autocomplete;
  //     console.log(autocomplete, " hi this is the autocomplete");
  //     originAutocompleteRef.current = autocomplete
  //   };

  //   initializeAutocomplete();




   
    
  // }, []);

 
  
  







  // useEffect(() => {
  //   let a;
  //   if (originAutocompleteRef.current) {
  //     const originAutocomplete = new window.google.maps.places.Autocomplete(
  //       originAutocompleteRef.current
  //     );
  
  //     originAutocomplete.addListener("place_changed", () => {
  //       const place = originAutocomplete.getPlace();
  
  //       // Extracting state, city, and zip
  //       let state = "";
  //       let city = "";
  //       let zip = "";
  
  //       place.address_components.forEach((component) => {
  //         if (component.types.includes("administrative_area_level_1")) {
  //           state = component.long_name;
  //         } else if (component.types.includes("locality")) {
  //           city = component.long_name;
  //         } else if (component.types.includes("postal_code")) {
  //           zip = component.long_name;
  //         }
  //       });
  
  //       console.log("State:", state);
  //       console.log("City:", city);
  //       console.log("Zip:", zip);
  
  //       // Now you can do whatever you want with the extracted values
  //       // For example, update the form state with the new values
  
  //       // Update the fields with the extracted values for the destination
  //       setFormData((prevFormData) => ({
  //         ...prevFormData,
  //         state_code: state,
  //         city_code: city,
  //         pin_code: zip,
  //         // You can also set the company address here if needed
  //         // company_add: place.formatted_address,
  //       }));
  
  //       // You can also set these values using querySelector if needed
  //       // document.querySelector('[name="state_code"]').value = state;
  //       // document.querySelector('[name="city_code"]').value = city;
  //       // document.querySelector('[name="pin_code"]').value = zip;
  //     });
  //   }
  // }, []);
  
  
  useEffect(() => {
    const initializeAutocomplete = () => {
      const input = originAutocompleteRef.current;
      const autocomplete = new window.google.maps.places.Autocomplete(input, {
        fields: ["formatted_address", "address_components", "geometry", "name"],
        strictBounds: false,
      });
  
      function setupClickListener(id, types) {
        const radioButton = document.getElementById(id);
        if (radioButton) {
          radioButton.addEventListener("click", () => {
            autocomplete.setTypes(types);
            input.value = "";
          });
        }
      }
  
      setupClickListener("changetype-all", []);
      setupClickListener("changetype-address", ["address"]);
      setupClickListener("changetype-establishment", ["establishment"]);
      setupClickListener("changetype-geocode", ["geocode"]);
      setupClickListener("changetype-cities", ["(cities)"]);
      setupClickListener("changetype-regions", ["(regions)"]);
  
      const biasInputElement = document.getElementById("use-location-bias");
      const strictBoundsInputElement = document.getElementById("use-strict-bounds");
  
      if (biasInputElement) {
        biasInputElement.addEventListener("change", () => {
          if (biasInputElement.checked) {
            autocomplete.bindTo("bounds", map);
          } else {
            autocomplete.unbind("bounds");
            autocomplete.setBounds({ east: 180, west: -180, north: 90, south: -90 });
            strictBoundsInputElement.checked = biasInputElement.checked;
          }
  
          input.value = "";
        });
      }
  
      if (strictBoundsInputElement) {
        strictBoundsInputElement.addEventListener("change", () => {
          autocomplete.setOptions({
            strictBounds: strictBoundsInputElement.checked,
          });
          if (strictBoundsInputElement.checked) {
            biasInputElement.checked = strictBoundsInputElement.checked;
            autocomplete.bindTo("bounds", map);
          }
  
          input.value = "";
        });
      }
  
      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        if (!place.geometry) {
          console.log("Place details not found for input: ", input.value);
          return;
        }
  
        const address = place.formatted_address;
        const addressComponents = place.address_components;
        // console.log();
        // console.log(addressComponents, " thisis the adress component");

        let city = "";
        let state = "";
        let zip = "";
  
        // Loop through address components to find city, state, and zip
        addressComponents.forEach(component => {
          const types = component.types;
          if (types.includes("locality")) {
            city = component.long_name;
          } else if (types.includes("administrative_area_level_1")) {
            // console.log(addressComponents, " thisis the adress component");
            state = component.long_name;
          } else if (types.includes("postal_code")) {
            zip = component.long_name;
          }
        });

             
        const selectedAddress = document.getElementById('Address').value;
        console.log("this is the thing", selectedAddress);
        
        
        console.log("Address:", address);
        console.log(autocomplete, " thisis the adress component");
        console.log("City:", city);
        console.log("State:", state);
        console.log("ZIP Code:", zip);
                setFormData((prevFormData) => ({
          ...prevFormData,
          state_code: state,
          city_code: city,
          pin_code: zip,
          company_add: selectedAddress,
        }));

      });
  
      // Set the autocomplete instance to state or ref if needed
      setThisistheNew(autocomplete);
      originAutocompleteRef.current = autocomplete;
  
      console.log(originAutocompleteRef.current, " hi newthe autocomplete");
    };
  
    initializeAutocomplete();










 axios
      .get(employeeData.URL + "/api/v1/profile/get-Data-lov", {
        withCredentials: true,
      })
      .then((response) => {
        console.log(response);
        console.log("just checking");
        setIndustryOptions(response.data.IndustryType);
        setLoadTypeOptions(response.data.LoadType);
        setVehicleTypeOptions(response.data.VecheilType);
        setRating(response.data.Rating);
        setCurrency(response.data.Currency)
        setUnit(response.data.Unit)
        //
      })
      .catch((errors) => {
        console.log(errors);
      });
  






  
  }, []);
  













//  useEffect(() => {
//     let a;
//     // if (originAutocompleteRef.current) {
//     //   const originAutocomplete = new window.google.maps.places.Autocomplete(
//     //     originAutocompleteRef.current
//     //   );

//     //   originAutocomplete.addListener("place_changed", () => {
//     //     console.log("thisis originAutocomplete MAIN", originAutocompleteRef);
//     //     console.log("thisis originAutocomplete", originAutocomplete);
//     //     const place = originAutocomplete.getPlace();
//     //     console.log(place.formatted_address, " tjios is the formated address");
//     //     const newCompleteAdd = place.formatted_address;
//     //     console.log(newCompleteAdd, " this si the formated address");
//     //     console.log("this is place", place);
//     //     console.log("this is the name  place", place.name);
//     //     const addressComponents = place.address_components || [];
//     //     console.log("this is address Components", addressComponents);
//     //     console.log(addressComponents[0].types.includes('sublocality', 'sublocality_leve_l'))
//     //     let one, two, twoo, three,threee, four, five;
//     //     for (let i = 0; i < addressComponents.length; i++) {
//     //       if (addressComponents[i].types) {
//     //         // Strings to check for
//     //         const stringsToCheck = ["locality", "sublocality", "sublocality_level_1"];

//     //         // Loop through each string to check
//     //         for (let j = 0; j < 3; j++) {
//     //           const stringToCheck = stringsToCheck[j];

//     //           // Check if the stringToCheck is present in addressComponents[i].types
//     //           if (addressComponents[i].types.includes('route', 'sublocality')) {
//     //             if (addressComponents[i].long_name != place.name) {
//     //               console.log(`this is the first`, addressComponents[i].long_name);
//     //               one = addressComponents[i].long_name;
//     //             }
//     //           }
//     //           if (addressComponents[i].types.includes('sublocality', 'sublocality_leve_l')) {

               
//     //             if (!addressComponents[i+1].types.includes('locality')) {
//     //               console.log(" hii all good");
//     //               // Code to execute if 'locality' is not included in addressComponents[i+1].types
//     //               two = addressComponents[i].long_name;
//     //               console.log(`second: ${addressComponents[i]}`, addressComponents[i].long_name);
//     //               twoo = addressComponents[i+1].long_name;
//     //               console.log(`secondoo: ${addressComponents[i+1]}`, addressComponents[i+1].long_name);
//     //           }
              
//     //           }
//     //           if (addressComponents[i].types.includes('locality')) {
//     //             console.log(`third:`, addressComponents[i].long_name);
//     //             three = addressComponents[i].long_name;
//     //           }
//     //           if (addressComponents[i].types.includes('administrative_area_level_1')) {
//     //             console.log(`thirdee:`, addressComponents[i].long_name);
//     //             threee = addressComponents[i].long_name;
//     //           }
//     //           if (addressComponents[i].types.includes('postal_code')) {
//     //             console.log(`fourth:`, addressComponents[i].long_name);
//     //             four = addressComponents[i].long_name;
//     //           }
//     //           if (addressComponents[i].types.includes('country')) {
//     //             console.log(`fifth:`, addressComponents[i].long_name);
//     //             five = addressComponents[i].long_name;
//     //           } else {
//     //             console.log(`${stringToCheck} is NOT present in addressComponents[${i}].types`);
//     //           }
//     //         }
//     //       }
//     //     }

//     //     // const myfullAddress = `${place.name}, ${one}, ${two}, ${three}, ${four}, ${five}`;
//     //     let myfullAddress = `${place.name}`;
//     //     const addedStrings = new Set(); // Set to store added strings
        
//     //     // Function to add unique strings to the address
//     //     function addToAddressIfUnique(value) {
//     //       if (value !== undefined && !addedStrings.has(value)) {
//     //         myfullAddress += `, ${value}`;
//     //         addedStrings.add(value);
//     //       }
//     //     }
        
//     //     addToAddressIfUnique(one);
//     //     addToAddressIfUnique(two);
//     //     addToAddressIfUnique(twoo);
//     //     addToAddressIfUnique(three);
//     //     addToAddressIfUnique(threee);
//     //     addToAddressIfUnique(four);
//     //     addToAddressIfUnique(five);
        
//     //     console.log(myfullAddress, "hi there this is the full address");
        
        



//     //     a = addressComponents[0].long_name;
//     //     console.log("thisis a", a);

//     //     let state = "";
//     //     let city = "";
//     //     let zip = "";
//     //     let streetAddress = "";

//     //     addressComponents.forEach((component) => {
//     //       if (component.types.includes("administrative_area_level_1")) {
//     //         state = component.long_name;
//     //       } else if (component.types.includes("locality")) {
//     //         city = component.long_name;
//     //       } else if (component.types.includes("postal_code")) {
//     //         zip = component.long_name;
//     //       } else if (component.types.includes("street_number")) {
//     //         streetAddress += component.long_name + " ";
//     //       } else if (component.types.includes("route")) {
//     //         streetAddress += component.long_name;
//     //       }
//     //     });

//     //     const completeAddress = `${a},${streetAddress}, ${city}, ${state}, ${zip}`;
//     //     console.log("this is scomplete Address", completeAddress);

//     //     // Update the fields with the extracted values for the destination
//     //     setFormData((prevFormData) => ({
//     //       ...prevFormData,
//     //       state_code: state,
//     //       city_code: city,
//     //       pin_code: zip,
//     //       company_add: myfullAddress,
//     //     }));

//     //     // You can also set these values using querySelector if needed
//     //     document.querySelector('[name="state_code"]').value = state;
//     //     document.querySelector('[name="city_code"]').value = city;
//     //     document.querySelector('[name="pin_code"]').value = zip;
//     //   });
//     // }
//     console.log(" hey there");

   
//   }, [originAutocompleteRef.current]);




















  

  return (
    <>
      <form className="hereForme" name="myForm">
        <div className="mb-3" style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ width: "40%" }}>
            <div>
              <label
                htmlFor="companyName"
                className="d-flex justify-content-start"
              >
                Company Name <span style={{ color: "red" }}>*</span>
              </label>
            </div>
            <input
              name="account_name"
              type="text"
              id="companyName"
              className={`form-control ${errors["account_name"] ? "highlight-red" : ""
                }`}
              placeholder="Company Name:"
              value={formData.account_name}
              onChange={handleChange}
              style={{ backgroundColor: "#f0e5f3" }}
              onInput={() => highlightBorder("companyName")}
            />
            {errors["account_name"] && (
              <div style={{ color: "red" }} className="error-message">
                {errors["account_name"]}
              </div>
            )}
          </div>





          <div style={{ width: "30%" }}>
            <div>
              <label htmlFor="contact" className="d-flex justify-content-start">
                Contact <span style={{ color: "red" }}>*</span>
              </label>
            </div>

            <div className="d-flex" style={{}}>
              <select
                name="countryCode"
                id="countryCode"
                className="form-control"
                style={{
                  height: "1.5rem",
                  width: "8rem",
                  padding: "0.1rem 0",
                  backgroundColor: "#f0e5f3",
                  // other styles...
                }}
                value={countryCode}
                onChange={handleCountryCodeChange}
              >
                {countryCodes.map((country) => (
                  <option
                    key={country.code}
                    value={country.code}
                    style={{ fontSize: "1rem" }} // Adjust the font size as needed
                  >
                    {`${country.name} (${country.code})`}
                  </option>
                ))}
              </select>
              <input
                name="contact_name"
                type="text"
                id="contact"
                className={`form-control ${errors["contact_name"]}`}
                placeholder="Contact:"
                value={formData.contact_name}
                onChange={handleContactChange}
                style={{
                  backgroundColor: "#f0e5f3",
                  width: "70%",
                  marginLeft: "0.6rem",
                }}
                onInput={() => highlightBorder("contact")}
              />
            </div>

            {errors["contact_name"] && (
              <div style={{ color: "red" }} className="error-message">
                {errors["contact_name"]}
              </div>
            )}
          </div>

        </div>



        <div class="d-flex justify-content-start">
          <h5>Company Address</h5>
        </div>

        <div className="Company-Address">
          <div className="mb-3">
            <div>
              <label htmlFor="Address" class="d-flex justify-content-start">
                {" "}
                Address <span style={{ color: "red" }}>*</span>
              </label>
            </div>
            {/* <Autocomplete> */}

            <input
              name="company_add"
              type="text"
              id="Address"
              className={`form-control ${errors["company_add"]}`}
              placeholder="Address:"
              ref={originAutocompleteRef}
              value={formData.company_add}
              onChange={handleChange}
              style={{ backgroundColor: "#f0e5f3", textTransformation: "none" }}
              onInput={() => highlightBorder("Address")}

            />
            {/* </Autocomplete> */}
            {errors["company_add"] && (
              <div style={{ color: "red" }} className="error-message">
                {errors["company_add"]}
              </div>
            )}
          </div>

          <div className=" d-flex justify-content-between mb-3 " id="first3">
            <div style={{  }}>
              <div className="d-flex">
                <label htmlFor="state">
                  State <span style={{ color: "red" }}>*</span>
                </label>
              </div>
              <input
                name="state_code"
                type="text"
                id="state"
                className={`form-control ${errors["state_code"]}`}
                placeholder="State:"
                value={formData.state_code}
                onChange={handleChange}
                style={{ backgroundColor: "#f0e5f3", width: "17em" }}
                onInput={() => highlightBorder("state")}
              />
              {errors["state_code"] && (
                <div style={{ color: "red" }} className="error-message">
                  {errors["state_code"]}
                </div>
              )}
            </div>

            <div style={{ }}>
              <div className="d-flex">
                <label htmlFor="city">
                  City <span style={{ color: "red" }}>*</span>
                </label>
              </div>
              <input
                name="city_code"
                type="text"
                id="city"
                className={`form-control ${errors["city_code"]}`}
                placeholder="City:"
                value={formData.city_code}
                onChange={handleChange}
                style={{ backgroundColor: "#f0e5f3", width: "17rem" }}
                onInput={() => highlightBorder("city")}
              />
              {errors["city_code"] && (
                <div style={{ color: "red" }} className="error-message">
                  {errors["city_code"]}
                </div>
              )}
            </div>

            <div style={{ }}>
              <div className="d-flex ">
                <label htmlFor="zipCode">
                  Zip code <span style={{ color: "red" }}>*</span>
                </label>
              </div>
              <input
                name="pin_code"
                type="text"
                id="zip"
                className={`form-control ${errors["pin_code"]}`}
                placeholder="Zip code:"
                value={formData.pin_code}
                onChange={handleChange}
                style={{ backgroundColor: "#f0e5f3", width: "17rem" }}
                onInput={() => highlightBorder("zip")}
              />
              {errors["pin_code"] && (
                <div style={{ color: "red" }} className="error-message">
                  {errors["pin_code"]}
                </div>
              )}
            </div>
          </div>

          <div style={{display:"flex", justifyContent:"space-between" }} className="d-flex justify-content-between mb-3 " id="second2">
            <div style={{ }}>
              <div className="d-flex">
                <label htmlFor="Registration">
                  Company Registration Number{" "}
                  <span style={{ color: "red" }}>*</span>
                </label>
              </div>

              <input
                name="company_registration_no"
                type="text"
                id="Registration"
                style={{ width: "17rem", backgroundColor: "#f0e5f3" }}
                className={`form-control ${errors["company_registration_no"]}`}
                placeholder="Company Registration Number:"
                value={formData.company_registration_no}
                // onChange={handleChange}
                onChange={handleCompanyRegistrationChange}
                // onBlur={handleCompanyRegistrationNoBlur}
                onInput={() => highlightBorder("Registration")}
              />

              {errors["company_registration_no"] && (
                <div style={{ color: "red" }} className="error-message">
                  {errors["company_registration_no"]}
                </div>
              )}
            </div>

            <div style={{  }}>
              <div className="d-flex">
                <label htmlFor="Tax">
                  Company Tax Number <span style={{ color: "red" }}>*</span>
                </label>
              </div>
              <input
                name="company_tax_no"
                type="text"
                id="Tax"
                className={`form-control ${errors["company_tax_no"]}`}
                style={{ width: "17rem", backgroundColor: "#f0e5f3" }}
                placeholder="Company Tax Number:"
                value={formData.company_tax_no}
                onChange={handleChange}
                onInput={() => highlightBorder("Tax")}
              />
              {errors["company_tax_no"] && (
                <div style={{ color: "red" }} className="error-message">
                  {errors["company_tax_no"]}
                </div>
              )}
            </div>
          </div>

          <div className="d-flex justify-content-between mb-3" id="third3">
            <div style={{ width: "18rem" }}>
              <div className="d-flex">
                <label htmlFor="Size">Company Size</label>
              </div>
              <input
                name="company_size"
                type="text"
                id="Size"
                className="form-control"
                style={{ width: "17rem", backgroundColor: "#f0e5f3" }}
                placeholder="Company Size:"
                value={formData.company_size}
                onChange={handleCompanySizeChange}
                // onChange={handleChange}
                onInput={() => highlightBorder("Size")}
              />
            </div>

            <div style={{ width: "18rem" }}>
              <div className="d-flex">
                <label htmlFor="Industry">Industry Type</label>
              </div>

              <select
                name="industry_type_cd"
                id="Industry"
                style={{
                  width: "17rem",
                  backgroundColor: "#f0e5f3",
                  height: "1.5rem",
                  padding: "0rem 0rem 0rem 0.8rem",
                  fontSize: "0.9rem",
                  // border:"2px solid red"
                }}
                className={`form-control`}
                onChange={handleChange}
                value={formData.industry_type_cd}
                onInput={() => highlightBorder("Industry")}
              >
                {industryOptions.map((commodityOption, optionIndex) => (
                  <option key={optionIndex} value={optionIndex + 1}>
                    {commodityOption}
                  </option>
                ))}
              </select>
            </div>

            <div style={{ width: "17rem" }}>
              <div className="d-flex">
                <label htmlFor="Revenue">Annual Revenue $</label>
              </div>
              <input
                name="annual_revenue"
                type="text"
                id="Revenue"
                className="form-control"
                style={{ width: "17rem", backgroundColor: "#f0e5f3" }}
                placeholder="Annual Revenue:"
                value={formData.annual_revenue}
                onChange={handleAnnualRevenueChange}
                onInput={() => highlightBorder("Revenue")}
              />
            </div>
          </div>

          <div class="d-flex justify-content-start">
            <h4>My Preferences</h4>  <span className="bi bi-info-circle"  title={"Please specify your preferences for the trucker so that we can match you with the most suitable truck."} style={{ marginLeft: "5px", cursor: "help" }}>ℹ️</span>
     
          </div>

          <div className="d-flex justify-content-between mb-3" id="fourth3">
            <div style={{ width: "16rem" }}>
              <div className="d-flex">
                <label htmlFor="vehicle-type">Vehicle Type</label>
              </div>

              <select
                name="vechile_type_cd"
                id="vehicle-type"
                style={{
                  width: "17rem",
                  backgroundColor: "#f0e5f3",
                  height: "1.5rem",
                  padding: "0rem 0 0rem 0.8rem",
                  fontSize: "0.9rem",
                }}
                className={`form-control`}
                onChange={handleChange}
                value={formData.vechile_type_cd}
                onInput={() => highlightBorder("vehicle-type")}
              >
                {vehicleTypeOptions.map((commodityOption, optionIndex) => (
                  <option key={optionIndex} value={optionIndex + 1}>
                    {commodityOption}
                  </option>
                ))}
              </select>
              {errors["vechile_type_cd"] && (
                <div style={{ color: "red" }} className="error-message">
                  {errors["vechile_type_cd"]}
                </div>
              )}
            </div>

            <div style={{ width: "16rem" }}>
              <div className="d-flex" style={{}}>
                <label htmlFor="HCage">Age of MC
                <span className="bi bi-info-circle"  title={"Age of MC is the time since a carrier received its MC Number from the FMCSA, showing how long it has operated under that designation."} style={{ marginLeft: "5px", cursor: "help" }}>ℹ️</span>
     
                </label>
              </div>
              <input
                name="age_of_mc"
                type="text"
                id="HCage"
                className="form-control"
                style={{ width: "17rem", backgroundColor: "#f0e5f3" }}
                placeholder="Age of MC:"
                value={formData.age_of_mc}
                onChange={handleChange}
                onInput={() => highlightBorder("HCage")}
              />
            </div>

            <div style={{ width: "17rem" }}>
              <div className="d-flex">
                <label htmlFor="Slocation">Start Location</label>
              </div>
              <input
                name="start_location"
                type="text"
                id="Slocation"
                className="form-control"
                style={{ width: "17rem", backgroundColor: "#f0e5f3" }}
                placeholder="Start Location:"
                value={formData.start_location}
                onChange={handleChange}
                onInput={() => highlightBorder("Slocation")}
              />
            </div>
          </div>

          <div className="d-flex justify-content-between mb-3" id="fifth3">
            <div style={{ width: "17rem" }}>
              <div className="d-flex">
                <label htmlFor="load">Load Type</label>
              </div>

              <select
                name="load_type_cd"
                id="load"
                style={{
                  width: "17rem",
                  backgroundColor: "#f0e5f3",
                  height: "1.5rem",
                  padding: "0rem 0 0rem 0.8rem",
                  fontSize: "0.9rem",
                }}
                className={`form-control`}
                onChange={handleChange}
                value={formData.load_type_cd}
                onInput={() => highlightBorder("load")}
              >
                {loadTypeOptions.map((commodityOption, optionIndex) => (
                  <option key={optionIndex} value={optionIndex + 1}>
                    {commodityOption}
                  </option>
                ))}
              </select>
            </div>

            <div style={{ width: "17rem" }}>
              <div className="d-flex">
                <label htmlFor="Rating">Carrier Rating</label>
              </div>

              <select
                name="carrier_rating_cd"
                id="Rating"
                style={{
                  width: "17rem",
                  backgroundColor: "#f0e5f3",
                  height: "1.5rem",
                  padding: "0.1rem 0 0rem 0.8rem",
                }}
                className={`form-control`}
                onChange={handleChange}
                value={formData.carrier_rating_cd}
                onInput={() => highlightBorder("Rating")}
              >
                {rating.map((commodityOption, optionIndex) => (
                  <option key={optionIndex} value={commodityOption}>
                    {commodityOption}
                  </option>
                ))}
              </select>
            </div>

            <div style={{ width: "17rem" }}>
              <div className="d-flex">
                <label htmlFor="Elocation">End Location</label>
              </div>
              <input
                name="end_location"
                type="text"
                id="Elocation"
                className="form-control"
                style={{ width: "17rem", backgroundColor: "#f0e5f3" }}
                placeholder="End Location:"
                value={formData.end_location}
                onChange={handleChange}
                onInput={() => highlightBorder("Elocation")}
              />
            </div>
          </div>

          <div style={{ width: "100%", height: "6rem", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>


            <div style={{ width: "18rem" }}>
              <div className="d-flex">
                <label htmlFor="Industry">Currency</label>
              </div>

              <select
                name="currency_id"
                id="Currency"
                style={{
                  width: "17rem", backgroundColor: "#f0e5f3",
                  height: "1.5rem",
                  padding: "0rem 0 0rem 0.8rem",
                  fontSize: "0.9rem",
                }}
                className={`form-control`}
                onChange={handleChange}
                value={formData.currency_id}
                onInput={() => highlightBorder("Currency")}
              >
                {Currency.map((commodityOption, optionIndex) => (
                  <option key={optionIndex} value={optionIndex + 1}>
                    {commodityOption}
                  </option>
                ))}
              </select>
              {errors["currency_id"] && (
                <div style={{ color: "red" }} className="error-message">
                  {errors["currency_id"]}
                </div>
              )}
            </div>



            <div style={{  }}>
              <div className="d-flex">
                <label htmlFor="Industry">Unit</label>
              </div>

              <select
                name="size_id"
                id="Unit"
                style={{
                  width: "17rem", backgroundColor: "#f0e5f3",
                  height: "1.5rem",
                  padding: "0rem 0 0rem 0.8rem",
                  fontSize: "0.9rem",
                }}
                className={`form-control`}
                onChange={handleChange}
                value={formData.size_id}
                onInput={() => highlightBorder("Unit")}
              >
                {Unit.map((commodityOption, optionIndex) => (
                  <option key={optionIndex} value={optionIndex + 1}>
                    {commodityOption}
                  </option>
                ))}
              </select>
              {errors["size_id"] && (
                <div style={{ color: "red" }} className="error-message">
                  {errors["size_id"]}
                </div>
              )}
            </div>

          </div>
        </div>

       

        <input
          type="button"
          value="Update"
          style={{
            width: "12rem",
            height: "2rem",
            color: "white",
            transition: 'width 0.5s',
            backgroundColor: "#8A31A8",
            border: "none",
            borderRadius: "5px",
          }}
          onClick={handleSubmit}
        />
      </form>


      <div style={{display:"none"}}>
      <div id="pac-container">
        <input id="pac-input" type="text" placeholder="Enter a location" ref={inputRef} />
      </div>
    </div>



    </>
  );
};

export default Aform;
