// use
import React, { useState, useRef, useEffect, useContext } from "react";
import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  HStack,
  IconButton,
  Input,
  Skeleton,
  SkeletonText,
  Text,
} from "@chakra-ui/react";
import {
  useJsApiLoader,
  GoogleMap,
  Marker,
  Autocomplete,
  DirectionsRenderer,
} from "@react-google-maps/api";
import geolib from "geolib"; // Import geolib library

// not
import { FaLocationArrow, FaTimes } from "react-icons/fa";
import { clear } from "@testing-library/user-event/dist/clear";
import { Row, Col, Tooltip } from "antd";
import { RiUserSearchFill } from "react-icons/ri";
import classes from "../Pages.module.css";
import { AiFillDelete } from "react-icons/ai";
import "./LoadView.css";
import axios from "axios";
import DataContext from "../../../Context/dataContext";
import FreightTable from "../../FreightTable/FreightTable";
import { UilSearch } from "@iconscout/react-unicons";
import { Link } from "react-router-dom";

// yes
const center = { lat: 48.8584, lng: 2.2945 };
let citiesAndStates = [];

function LoadView(props) {
  // yes
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyDw3ocRegmGnHSlbFlH_T5qB64ut4JdBcA", // I have created two keys, and both are working fine
    libraries: ["places"],
  });
  const [map, setMap] = useState(/** @type google.maps.Map */ (null));
  const [directionsResponse, setDirectionsResponse] = useState(null);
  const [distance, setDistance] = useState(null);
  const [duration, setDuration] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  // Define a global array to store place data
  const placesData = [];

  // no
  console.log(props);
  // const [filteredResults, setFilteredResults] = useState([]);

  // no
  const employeeData = useContext(DataContext);
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState(null);
  const [drows, setDRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [nrow, setNrow] = useState([]);

  // // try not to embede
  // const searchHere = (e) => {
  //   console.log(nrow);
  //   const searchTerm = e.target.value.toLowerCase();
  //   const filteredRows = nrow.filter((row) => {
  //     return Object.values(row).some((value) => {
  //       if (value === null || typeof value === 'undefined') {
  //         return false;
  //       }
  //       return value.toString().toLowerCase().indexOf(searchTerm) !== -1;
  //     });
  //   });
  //   setRows(filteredRows);
  // };

  // no
  useEffect(() => {
    setDRows(null);
    setRows(null);
    setColumns([]);

    axios
      .get(employeeData.URL + "/api/v1/loadt/load-view", {
        withCredentials: true,
      })
      .then((response) => {
        console.log(response);

        setColumns((columns) => {
          let newCols = response.data.data.loads.fields.map((col) => {
            return {
              field: col.name,
              headerName: col.name
                .split("_")
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(" "),

              width: 150,
            };
          });

          const newNewCols = [...newCols];
          return newNewCols;
        });

        setNrow((rows) => {
          let newRows = response.data.data.loads.rows.map((row, index) => {
            return {
              id: index + 1,
              ...row,
            };
          });
          return newRows;
        });

        setRows((rows) => {
          let newRows = response.data.data.loads.rows.map((row, index) => {
            return {
              id: index + 1,
              ...row,
            };
          });
          return newRows;
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const deleteHandler = (event, index, newrows, code) => {
    console.log(index);
    console.log(code);

    setRows(null);
    axios
      .delete(employeeData.URL + "/api/v1/items/" + code.toString(), {
        withCredentials: true,
      })
      .then((response) => {
        console.log(response);
        const rowvals = newrows.filter((row) => row["item_code"] !== code);
        setRows((rows) => {
          let newDRows = rowvals.map((row, index) => {
            return {
              D: (
                <Tooltip placement="bottom" title="Delete" color="red">
                  <Link to="#" style={{ color: "red", fontWeight: "bolder" }}>
                    <AiFillDelete
                      onClick={(event) =>
                        deleteHandler(event, index, rowvals, row["item_code"])
                      }
                      style={{
                        color: "red",
                        fontWeight: "bolder",
                        fontSize: "0.8rem",
                      }}
                    />
                  </Link>
                </Tooltip>
              ),
              V: (
                <Tooltip placement="bottom" title="View" color="#1777C4">
                  <Link
                    to={
                      "/sales/itemMaster/item-details-view/" + row["item_code"]
                    }
                    style={{ color: "#1777C4", fontWeight: "bolder" }}
                  >
                    <RiUserSearchFill
                      style={{
                        color: "#1777C4",
                        fontWeight: "bolder",
                        fontSize: "0.9rem",
                      }}
                    />
                  </Link>
                </Tooltip>
              ),

              SNO: index + 1,
              ...row,
            };
          });
          return newDRows;
        });
      })
      .catch((error) => {
        setRows((rows) => {
          let newDRows = newrows.map((row, index) => {
            return {
              D: (
                <Tooltip placement="bottom" title="Delete" color="red">
                  <Link to="#" style={{ color: "red", fontWeight: "bolder" }}>
                    <AiFillDelete
                      onClick={(event) =>
                        deleteHandler(event, index, newrows, row["item_code"])
                      }
                      style={{
                        color: "red",
                        fontWeight: "bolder",
                        fontSize: "0.8rem",
                      }}
                    />
                  </Link>
                </Tooltip>
              ),
              V: (
                <Tooltip placement="bottom" title="View" color="#1777C4">
                  <Link
                    to={
                      "/sales/itemMaster/item-details-view/" + row["item_code"]
                    }
                    style={{ color: "#1777C4", fontWeight: "bolder" }}
                  >
                    <RiUserSearchFill
                      style={{
                        color: "#1777C4",
                        fontWeight: "bolder",
                        fontSize: "0.9rem",
                      }}
                    />
                  </Link>
                </Tooltip>
              ),

              SNO: index + 1,
              ...row,
            };
          });
          return newDRows;
        });
        console.log(error);
      });
  };

  // yes
  let cityStateValues;
  /** @type React.MutableRefObject<HTMLInputElement> */
  const originRef = useRef(null);
  /** @type React.MutableRefObject<HTMLInputElement> */
  const destinationRef = useRef(null);
  if (!isLoaded) {
    return console.log("LOADING"); // Skeleton loading while map loads
  }

  // yes
  function calculateRoute() {
    if (originRef.current.value === "" || destinationRef.current.value === "") {
      return; // If origin or destination is empty, return  nothing
    }
    const directionsService = new window.google.maps.DirectionsService();
    directionsService.route(
      {
        origin: { query: originRef.current.value },
        destination: { query: destinationRef.current.value },
        travelMode: window.google.maps.TravelMode.DRIVING,
      },
      (response, status) => {
        if (status === window.google.maps.DirectionsStatus.OK) {
          setDirectionsResponse(response);
          setDistance(response.routes[0].legs[0].distance.text);
          setDuration(response.routes[0].legs[0].duration.text);

          response.routes[0].legs[0].steps.forEach((step) => {
            const stepLocation = step.end_location;
            const stepLatLng = {
              lat: stepLocation.lat(),
              lng: stepLocation.lng(),
            };

            let places = [];

            const geocoder = new window.google.maps.Geocoder();
            geocoder.geocode({ location: stepLatLng }, (results, status) => {
              if (status === "OK") {
                const placeData = {
                  formattedAddress: results[0].formatted_address,
                  city: "",
                  state: "",
                  zipCode: "",
                };

                // Extract city, state, and zip code from address components
                const addressComponents = results[0].address_components;
                for (const component of addressComponents) {
                  if (component.types.includes("locality")) {
                    placeData.city = component.long_name;
                  } else if (
                    component.types.includes("administrative_area_level_1")
                  ) {
                    placeData.state = component.short_name;
                  } else if (component.types.includes("postal_code")) {
                    placeData.zipCode = component.short_name;
                  }
                }

                placesData.push(placeData);
                console.log("Places data:", placesData);

                const filteredLoad = nrow.filter((loadItem) => {
                  const hasMatchingOrigin = placesData.some(
                    (placeItem) => placeItem.city === loadItem.origin_city
                  );

                  const hasMatchingDestination = placesData.some(
                    (placeItem) => placeItem.city === loadItem.destination_city
                  );

                  return hasMatchingOrigin && hasMatchingDestination;
                });

                setRows(filteredLoad);

                setRows(filteredLoad);
              }
            });
          });

          console.log("Cities and States along the route:", citiesAndStates);
          console.log(typeof citiesAndStates);

          const filteredRows = nrow.filter(
            (row) =>
              citiesAndStates.includes(row.city) ||
              citiesAndStates.includes(row.state)
          );

          setFilteredRows(filteredRows);
        } else {
          alert("Could not fetch directions");
        }
      }
    );
  }
  function clearRoute() {
    setDirectionsResponse(null);
    setDistance(null);
    setDuration(null);
    originRef.current.value = "";
    destinationRef.current.value = "";
  }

  // console.log('Cities and States along the route:', citiesAndStates);
  // console.log(typeof(citiesAndStates));

  return (
    <>
      <h1
        style={{
          color: "#531967",
          display: "inline-block",
          width: "25rem",
          marginRight: "5rem",
        }}
      >
        LOAD VIEW
      </h1>

      <div
        id="section"
        className="d-flex flex-row justify-content-between"
        style={{ height: "3rem" }}
      >
        <Link to="/home/post-load">
          <button
            type="button"
            style={{
              backgroundColor: "#531967",
              color: "white",
              height: "3em",
              width: "9em",
              fontSize: "small",
              padding: "0",
            }}
            class="btn "
          >
            + Post a Load
          </button>
        </Link>
      </div>

      <Flex
        position="relative"
        flexDirection="column"
        alignItems="center"
        h="vh"
        w="vw"
        className="App"
      >
        <Box position="absolute" left={0} top={0} h="0%" w="0%">
          {/* My Google MAP */}
          <GoogleMap
            center={center}
            zoom={15}
            mapContainerStyle={{ width: "100%", height: "100%" }}
            options={{
              zoomControl: true,
              mapTypeControl: true,
              scaleControl: true,
              streetViewControl: true,
              rotateControl: true,
              fullscreenControl: true,
            }}
            onLoad={(map) => setMap(map)}
          >
            <Marker position={center} />
            {directionsResponse && (
              <DirectionsRenderer directions={directionsResponse} />
            )}
          </GoogleMap>
        </Box>

        <Box
          p={4}
          borderRadius="lg"
          mt={4}
          bgColor="white"
          shadow="base"
          minW="container.md"
          zIndex="1"
        >
          <HStack spacing={3} justifyContent="space-between">
            <Box flexGrow={1}>
              <Autocomplete>
                <Input
                  style={{ width: "30rem" }}
                  type="text"
                  placeholder="Origin"
                  ref={originRef}
                />
              </Autocomplete>
            </Box>

            <Box flexGrow={1}>
              <Autocomplete>
                <Input
                  style={{ width: "30rem" }}
                  type="text"
                  placeholder="Destination"
                  ref={destinationRef}
                />
              </Autocomplete>
            </Box>

            <Button colorScheme="pink" type="submit" onClick={calculateRoute}>
              Calculate Route
            </Button>

            <span onClick={clearRoute}>CLEAR</span>
          </HStack>

          <HStack spacing={4} mt={4} justifyContent="space-between">
            <Text>Distance: {distance}</Text>
            <Text>Duration:{duration} </Text>
          </HStack>
        </Box>
      </Flex>

      <form>
        <br />

        <div
          className="table"
          style={{ display: "inline-block", width: "100%" }}
        >
          {rows && columns.length > 0 ? (
            <div style={{ display: "inline-block" }}>
              <FreightTable
                data={filteredRows.length > 0 ? filteredRows : rows}
                columns={columns}
                val={true}
              />
              {/* <FreightTable data={rows} columns={columns} handelChange={searchHere} val={true} /> */}
            </div>
          ) : (
            <div>No matching results found.</div>
          )}
        </div>
      </form>
    </>
  );
}

export default LoadView;
