import { Col, Card, Input, Form, Checkbox, Select } from "antd";
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import classes from "../Pages.module.css";
import { TextField } from "@mui/material";
import moment from "moment";
import dayjs from "dayjs";
import { styled } from "@mui/material/styles";
import  { useState } from "react";

const { Option } = Select;

const DataField = (props) => {
 

  // const[mymax,setMyMax]=useState()
  const isNumericInput = props.numericInput;
  const currentDate = dayjs();
  console.log(props);
  
  

  console.log(currentDate," thisis the current");
  return (
    <Col
      lg={props.lg}
      md={props.md}
      // id={props.id ? props.id : ""}
      className={classes["Col"]}
    >
      {props.editMode ? (
        <Form layout="vertical">
          <Form.Item
            colon={false}
            style={{ margin: "0", padding: "0" }}
            label={
              props.type !== "Checkbox" && ( // Show the label only for non-checkbox inputs
                <div
                  style={{
                    padding: "0rem 0.5rem",
                    fontSize: "0.7rem",
                    fontWeight: "bold",
                  }}
                  className={classes["Label"]}
                >
                  {props.name}
                  {props.required && <span style={{ color: "red" }}> *</span>}
                </div>
              )
            }
          >
            {props.type === "Date" ? (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  value={
                    props.value === null
                      ? null
                      : dayjs(props.value, "DD-MM-YYYY")
                  }
                  format="DD-MM-YYYY"
                  inputProps={{
                    id: `date-picker-${props.param}`, // Add your desired ID here
                  }}
                  sx={{
                    width: "100%",
                    float: "left",
                    backgroundColor: "#F0E5F3",
                    color: "#1777C4",
                    fontWeight: "bold",
                    borderBottom: "2px solid #888888",
                    height: "32px",
                    "& .MuiInputBase-root": {
                      height: "100%",
                      padding: "5px 8px",
                    },
                  }}
                  bordered={false}
                  onChange={(date, param) =>
                    props.handleChange(date, props.param, props.id)
                  }
                  label={props.name}
                  slotProps={{ textField: { size: "small" } }}
                  minDate={(props.current)?currentDate:(props.mydatee)?currentDate:props.mydate}
                  maxDate={(props.anotherCheckrr)?undefined:props.anotherCheckr}
                />
                {/* {console.log(minDatee,"ola")} */}
                {console.log((props.anotherCheckrr)?props.anotherCheckr:"yo")}
              </LocalizationProvider>
            ) : props.type === "Time" ? (
              <div id={props.id}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TimePicker
                    id={props.id}
                    value={
                      props.value === null
                        ? null
                        : dayjs(props.value, "HH:mm:ss")
                    }
                    ampm={true}
                    onChange={(time) => props.handleChange(time, props.param)}
                    label={props.name}
                    ref={props.ref}
                    style={{
                      width: "100%",
                      float: "left",
                      backgroundColor: "#F0E5F3",
                      color: "#1777C4",
                      fontWeight: "bold",
                      boxShadow: "#888888  2px 7px 20px ",
                    }}
                    sx={{
                      width: "100%",
                      float: "left",
                      backgroundColor: "#F0E5F3",
                      color: "#1777C4",
                      fontWeight: "bold",
                      borderBottom: "2px solid #888888",
                      height: "32px",
                      "& .MuiInputBase-root": {
                        height: "100%",
                        padding: "5px 8px",
                      },
                    }}
                    slotProps={{ textField: { size: "small" } }}
                  />
                </LocalizationProvider>
              </div>
            ) : props.type === "TimewithDate" ? (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  value={
                    props.value
                      ? dayjs(props.value, "DD-MM-YYYY HH:mm:ss")
                      : null
                  }
                  onChange={(dateTime) =>
                    props.handleChange(dateTime, props.param)
                  }
                  label={props.name}
                  format="DD-MM-YYYY HH:mm:ss"
                  sx={{
                    width: "100%",
                    height: "40px",
                    float: "left",
                    backgroundColor: "#F0E5F3",
                    color: "#1777C4",
                    boxShadow: "#888888  2px 7px 20px ",
                    border: "none", // Hide the border
                    outline: "none",
                  }}
                  
                  minDate={currentDate}
                  slotProps={{ textField: { size: "small" } }}
                />
              </LocalizationProvider>
            ) : props.type === "Select" ? (
              <Select
                value={props.value === null ? null : props.value}
                id={props.id}
                showSearch
                onChange={(value) => props.handleChange(value, props.param)}
                bordered={false}
                dropdownStyle={{ textTransform: "capitalize" }}
                style={{
                  width: "90%",
                  textAlign: "left",
                  backgroundColor: "#F0E5F3",
                  color: "#1777C4",
                  boxShadow: "#888888  2px 3px 10px",
                  textTransform: "capitalize",
                  color: "#1777C4",
                  fontWeight: "bold",
                }}
                placeholder="Search to Select"
                optionFilterProp="children"
                filterSort={(optionA, optionB) => optionA.children}
                defaultValue={props.options.rows
                  .filter(
                    (option) =>
                      option.type_code ===
                      props.sc.shipper1.rows[0].vechile_type_cd
                  )
                  .map((filteredOption) => filteredOption.type_name)}
              >
                {props.options.rows.map((option) => {
                  return (
                    <Option
                      style={{ textTransform: "capitalize", color: "#1777C4" }}
                      key={option[props.options.fields[0].name]}
                      value={option[props.options.fields[0].name]}
                    >
                      {option[props.options.fields[1].name]}
                    </Option>
                  );
                })}
              </Select>
            ) : props.type === "Checkbox" ? (
              <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    backgroundColor: "#F0E5F3",
                    borderBottom: "2px solid #888888",
                  }}
                >
                  <span style={{ fontWeight: "bold" }}>{props.name}</span>
                  <div style={{ paddingRight: "10rem" }}>
                    <Checkbox
                      checked={props.value === "Y"}
                      onChange={() => props.handleChange("Y", props.param)}
                      style={{
                        backgroundColor: "#F0E5F3",
                        fontWeight: "bold",
                        marginRight: "15rem",
                      }}
                    >
                      Yes
                    </Checkbox>
                    <Checkbox
                      checked={props.value === "N"}
                      onChange={() => props.handleChange("N", props.param)}
                      style={{ backgroundColor: "#F0E5F3", fontWeight: "bold" }}
                    >
                      No
                    </Checkbox>
                  </div>
                </div>
              </>
            ) : (
              <Input
                placeholder={props.name}
                type={isNumericInput ? "number" : "text"}
                value={props.value ? props.value : null}
                bordered={false}
                id={props.id}
                onChange={(e) => {
                  if (
                    props.param === "origin_address" ||
                    props.param === "des_address"
                  ) {
                    props.handleGChange(
                      e,
                      props.param,
                      props.inputRef,
                      props.id
                    ); // Call handleGChange only when param is 'origin_address'
                  }
                  props.handleChange(e, props.param, props.id);
                }}
                style={{
                  width: "100%",
                  textAlign:
                    props.param === "load_amount" ||
                    props.param === "insurance_amt"
                      ? "right"
                      : "left", // Conditionally apply textAlign
                  backgroundColor: "#F0E5F3",
                  height: "100%",
                  color: "#1777C4",
                  fontWeight: "bold",
                  borderBottom: "2px solid #888888",
                }}
                ref={
                  props.param === "origin_address" || "des_address"
                    ? props.inputRef
                    : null
                }
              />
            )}
          </Form.Item>
        </Form>
      ) : (
        <Card
          bodyStyle={{ padding: "0.5rem 1rem" }}
          bordered={false}
          className={classes["Card"]}
        >
          {props.type === "Select" ? (
            <p className={classes["Label"]}>
              {props.name}
              <span className={classes["Data"]}>
                {props.value
                  ? props.options.rows[
                      props.options.rows.findIndex(
                        (element) =>
                          element[props.options.fields[0].name] === props.value
                      )
                    ][props.options.fields[1].name]
                  : null}
              </span>
            </p>
          ) : (
            <p className={classes["Label"]}>
              {props.name}{" "}
              <span className={classes["Data"]}>
                {props.value ? props.value : null}
              </span>
            </p>
          )}
        </Card>
      )}
    </Col>
  );
};

export default DataField;
