import React from "react";
// import { LoadTemplate } from "../ComponentTemplate/LoadTemplate";
import Template from "./template";

const TtoSixty = () => {
  return (
    <>
      <Template name={"Thirty to Sixtey"} case={3} />
    </>
  );
};

export default TtoSixty;
