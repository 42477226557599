// import React, { useState, useEffect, useRef } from "react";
// import "./Modal.css";
// import axios from "axios";
// import { useNavigate } from "react-router-dom";

// const Modalmy = ({ iid, status, closeModal }) => {
//   const [startTime, setStartTime] = useState(null);
//   const [newstatus, setNewstatus] = useState(null);
//   const [endTime, setEndTime] = useState(null);
//   const [statusIndex, setStatusIndex] = useState(0); // Track the current index
//   const history = useNavigate();
//   console.log("-------------------------------------");
//   console.log(status);
//   // console.log(props.id);
//   console.log("_______________________________________________________");

//   // Create refs for the buttons
//   const startVTNRef = useRef(null);
//   const endVTNRef = useRef(null);
//   const stVTNRef = useRef(null);
//   const eVTNRef = useRef(null);

//   const [Pstart, setPstart] = useState(true);
//   const [Pend, setPend] = useState(true);
//   const [Dstart, setDstart] = useState(true);
//   const [Dend, setDend] = useState(true);

//   const B1 = document.getElementById("startVTN");
//   const B2 = document.getElementById("endVTN");
//   const B3 = document.getElementById("stVTN");
//   const B4 = document.getElementById("eVTN");

//   useEffect(() => {
//     if (startVTNRef.current) {
//       if (status === null || "B") {
//         setPstart(false);
//         startVTNRef.current.classList.remove("disabled-style");
//       }
//     }
//     if (endVTNRef.current) {
//       if (status === "Loading") {
//         setPend(false);
//         endVTNRef.current.classList.remove("disabled-style");
//       }
//     }
//     if (stVTNRef.current) {
//       if (status === "ON Route") {
//         setDstart(false);
//         stVTNRef.current.classList.remove("disabled-style");
//       }
//     }
//     if (eVTNRef.current) {
//       if (status === "Delivering") {
//         setDend(false);
//         eVTNRef.current.classList.remove("disabled-style");
//       }
//     }
//   }, [startVTNRef, endVTNRef, stVTNRef, eVTNRef]);

//   const handleStartClick = () => {
//     //
//     console.log("start button btn");
//     setPstart(true);
//     setPend(false);
//     startVTNRef.current.classList.add("disabled-style");
//     endVTNRef.current.classList.remove("disabled-style");

//     const currentDate = new Date();
//     const year = currentDate.getFullYear();
//     const month = String(currentDate.getMonth() + 1).padStart(2, "0");
//     const day = String(currentDate.getDate()).padStart(2, "0");
//     const hours = String(currentDate.getHours()).padStart(2, "0");
//     const minutes = String(currentDate.getMinutes()).padStart(2, "0");
//     const seconds = String(currentDate.getSeconds()).padStart(2, "0");
//     const milliseconds = String(currentDate.getMilliseconds()).padStart(3, "0");
//     const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`;

//     console.log(formattedDate);
//     let id = iid;
//     let CheckValue = "actl_pickup_stym";
//     let StsTime = formattedDate;

//     axios
//       .post(
//         employeeData.URL + `/api/v1/dashboard/${id}/${CheckValue}/${StsTime}`,
//         {},
//         {
//           withCredentials: true,
//         }
//       )
//       .then((response) => {
//         console.log(response);
//         // Handle the response from the API, e.g., show a success message
//         alert("your PickUp Time is started");
//         // console.log('Bidding accepted:', response.data);
//       })
//       .catch((error) => {
//         // Handle errors from the API, e.g., show an error message
//         console.error("Error accepting bidding:", error);
//       });

//     // closeModal();
//     // history("/trucker");
//     window.location.reload();
//   };

//   const handleStartClic = () => {
//     const currentDate = new Date();
//     const year = currentDate.getFullYear();
//     const month = String(currentDate.getMonth() + 1).padStart(2, "0");
//     const day = String(currentDate.getDate()).padStart(2, "0");
//     const hours = String(currentDate.getHours()).padStart(2, "0");
//     const minutes = String(currentDate.getMinutes()).padStart(2, "0");
//     const seconds = String(currentDate.getSeconds()).padStart(2, "0");
//     const milliseconds = String(currentDate.getMilliseconds()).padStart(3, "0");
//     const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`;
//     console.log(formattedDate);

//     let id = iid;
//     let CheckValue = "actl_dropoff_stymm";
//     let StsTime = formattedDate;
//     axios
//       .post(
//         employeeData.URL + `/api/v1/dashboard/${id}/${CheckValue}/${StsTime}`,
//         {},
//         {
//           withCredentials: true,
//         }
//       )
//       .then((response) => {
//         // Handle the response from the API, e.g., show a success message
//         alert("your PickUp Time is started");
//         // console.log('Bidding accepted:', response.data);
//       })
//       .catch((error) => {
//         // Handle errors from the API, e.g., show an error message
//         console.error("Error accepting bidding:", error);
//       });

//     // closeModal();
//     window.location.reload();
//   };

//   const handleEndClick = () => {
//     console.log("hey there this is the endclick");

//     const currentDate = new Date();
//     const year = currentDate.getFullYear();
//     const month = String(currentDate.getMonth() + 1).padStart(2, "0");
//     const day = String(currentDate.getDate()).padStart(2, "0");
//     const hours = String(currentDate.getHours()).padStart(2, "0");
//     const minutes = String(currentDate.getMinutes()).padStart(2, "0");
//     const seconds = String(currentDate.getSeconds()).padStart(2, "0");
//     const milliseconds = String(currentDate.getMilliseconds()).padStart(3, "0");

//     // Create the formatted date string
//     const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`;

//     console.log(formattedDate);

//     let id = iid;
//     let CheckValue = "actl_pickup_etym";
//     let StsTime = formattedDate;
//     console.log(StsTime);

//     axios
//       .post(
//         employeeData.URL + `/api/v1/dashboard/${id}/${CheckValue}/${StsTime}`,
//         {},
//         {
//           withCredentials: true,
//         }
//       )
//       .then((response) => {
//         // Handle the response from the API
//         alert("your PickUp Time is started");
//         closeModal();
//         // console.log('Bidding accepted:', response.data);
//       })
//       .catch((error) => {
//         // Handle errors from the API
//         console.error("Error:", error);
//       });
//     window.location.reload();
//   };

//   const handleEndClic = () => {
//     const currentDate = new Date();

//     // Get the year, month, day, hours, minutes, seconds, and milliseconds
//     const year = currentDate.getFullYear();
//     const month = String(currentDate.getMonth() + 1).padStart(2, "0");
//     const day = String(currentDate.getDate()).padStart(2, "0");
//     const hours = String(currentDate.getHours()).padStart(2, "0");
//     const minutes = String(currentDate.getMinutes()).padStart(2, "0");
//     const seconds = String(currentDate.getSeconds()).padStart(2, "0");
//     const milliseconds = String(currentDate.getMilliseconds()).padStart(3, "0");

//     // Create the formatted date string
//     const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`;

//     console.log(formattedDate);

//     let id = iid;
//     let CheckValue = "actl_dropoff_etymm";
//     let StsTime = formattedDate;

//     axios
//       .post(
//         employeeData.URL + `/api/v1/dashboard/${id}/${CheckValue}/${StsTime}`,
//         {},
//         {
//           withCredentials: true,
//         }
//       )
//       .then((response) => {
//         // Handle the response from the API, e.g., show a success message
//         alert("your PickUp Time is started");
//         // console.log('Bidding accepted:', response.data);
//       })
//       .catch((error) => {
//         // Handle errors from the API, e.g., show an error message
//         console.error("Error accepting bidding:", error);
//       });

//     // closeModal();

//     // setEndTime(currentTime);
//     // eVTNRef.current.classList.add('disabled-style');

//     // setDend(true)

//     window.location.reload();
//   };

//   return (
//     <>
//       <div className="vinayak">
//         <h2> Select the Status of your Loads</h2>

//         <br />

//         <div id="qwert">
//           <h5 className="pickupcls" style={{ color: "white" }}>
//             Pick Up
//           </h5>
//           <button
//             ref={startVTNRef}
//             id="startVTN"
//             className="btnofmoadl disabled-style"
//             onClick={handleStartClick}
//             disabled={Pstart}
//           >
//             Start
//           </button>

//           <button
//             ref={endVTNRef}
//             id="endVTN"
//             className="btnofmoadl disabled-style"
//             onClick={handleEndClick}
//             disabled={Pend}
//           >
//             End
//           </button>
//         </div>

//         <div id="yuiop">
//           <h5 className="pickupcls" style={{ color: "white" }}>
//             Drop Off
//           </h5>
//           <button
//             ref={stVTNRef}
//             id="stVTN"
//             className="btnofmoadl disabled-style"
//             onClick={handleStartClic}
//             disabled={Dstart}
//           >
//             Start
//           </button>

//           <button
//             ref={eVTNRef}
//             id="eVTN"
//             className="btnofmoadl disabled-style"
//             onClick={handleEndClic}
//             disabled={Dend}
//           >
//             End
//           </button>
//         </div>
//       </div>
//     </>
//   );
// };

// export default Modalmy;

import React, { useState, useEffect, useRef,useContext } from "react";
import DataContext from "../../../Context/dataContext";
import "./Modal.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { message } from 'antd';

const Modalmy = ({ iid, status, closeModal }) => {
  const employeeData = useContext(DataContext);
  const [startTime, setStartTime] = useState(null);
  const [newstatus, setNewstatus] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [statusIndex, setStatusIndex] = useState(0); // Track the current index
  const history = useNavigate();
  console.log("-------------------------------------");
  console.log(iid, status, closeModal);
  // console.log(status);
  // console.log(props.id);
  console.log("_______________________________________________________");

  // Create refs for the buttons
  const startVTNRef = useRef(null);
  const endVTNRef = useRef(null);
  const stVTNRef = useRef(null);
  const eVTNRef = useRef(null);

  const [Pstart, setPstart] = useState(true);
  const [Pend, setPend] = useState(true);
  const [Dstart, setDstart] = useState(true);
  const [Dend, setDend] = useState(true);

  const B1 = document.getElementById("startVTN");
  const B2 = document.getElementById("endVTN");
  const B3 = document.getElementById("stVTN");
  const B4 = document.getElementById("eVTN");

  useEffect(() => {
    if (startVTNRef.current) {
      if (status === "Posted" || status === "Bidding" || status === "Awarded") {
        setPstart(false);
        startVTNRef.current.classList.remove("disabled-style");
      } else {
        setPstart(true);
        startVTNRef.current.classList.add("disabled-style");
      }
    }
    if (endVTNRef.current) {
      if (status === "Loading") {
        setPstart(true);
        setPend(false);
        startVTNRef.current.classList.add("disabled-style");
        endVTNRef.current.classList.remove("disabled-style");
      }
    }
    if (stVTNRef.current) {
      if (status === "ON Route") {
        setDstart(false);
        stVTNRef.current.classList.remove("disabled-style");
      }
    }
    if (eVTNRef.current) {
      if (status === "Delivering") {
        setDend(false);
        eVTNRef.current.classList.remove("disabled-style");
      }
    }
  }, [startVTNRef, endVTNRef, stVTNRef, eVTNRef]);

  const handleStartClick = () => {
    //
    console.log("start button btn");
    // setPstart(true);
    // setPend(false);
    startVTNRef.current.classList.add("disabled-style");
    endVTNRef.current.classList.remove("disabled-style");

    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");
    const hours = String(currentDate.getHours()).padStart(2, "0");
    const minutes = String(currentDate.getMinutes()).padStart(2, "0");
    const seconds = String(currentDate.getSeconds()).padStart(2, "0");
    const milliseconds = String(currentDate.getMilliseconds()).padStart(3, "0");
    const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`;

    console.log(formattedDate);
    let id = iid;
    let CheckValue = "actl_pickup_stym";
    let StsTime = formattedDate;

    axios
      .post(
        employeeData.URL + `/api/v1/dashboard/${id}/${CheckValue}/${StsTime}`,
        {},
        {
          withCredentials: true,
        }
      )
      .then((response) => {
        console.log(response);
        // Handle the response from the API, e.g., show a success message
        message.info({
          content: 'Your pickUp time is started',
          duration: 3, // Duration to show the message in seconds
          style: {
            marginTop: '20px', // Customize the position
          },
        });
        setTimeout(() => {
          window.location.reload();
          // Your next line of code here, for example:
          console.log('Bidding accepted:', response.data);
        }, 3000);
    
       
        // console.log('Bidding accepted:', response.data);
      })
      .catch((error) => {
        // Handle errors from the API, e.g., show an error message
        console.error("Error accepting bidding:", error);
      });

    // closeModal();
    // history("/trucker");
  };

  const handleStartClic = () => {
    stVTNRef.current.classList.add("disabled-style");
    eVTNRef.current.classList.remove("disabled-style");
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");
    const hours = String(currentDate.getHours()).padStart(2, "0");
    const minutes = String(currentDate.getMinutes()).padStart(2, "0");
    const seconds = String(currentDate.getSeconds()).padStart(2, "0");
    const milliseconds = String(currentDate.getMilliseconds()).padStart(3, "0");
    const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`;
    console.log(formattedDate);

    let id = iid;
    let CheckValue = "actl_dropoff_stymm";
    let StsTime = formattedDate;
    axios
      .post(
        employeeData.URL + `/api/v1/dashboard/${id}/${CheckValue}/${StsTime}`,
        {},
        {
          withCredentials: true,
        }
      )
      .then((response) => {
        // Handle the response from the API, e.g., show a success message
        message.info({
          content: 'Please start unloading the loads',
          duration: 3, // Duration to show the message in seconds
          style: {
            marginTop: '20px', // Customize the position
          },
        });
    
        setTimeout(() => {
          window.location.reload();
          // Your next line of code here, for example:
          console.log('Bidding accepted:', response.data);
        }, 3000);
        // console.log('Bidding accepted:', response.data);
      })
      .catch((error) => {
        // Handle errors from the API, e.g., show an error message
        console.error("Error accepting bidding:", error);
      });

    // closeModal();
  };

  const handleEndClick = () => {
    setPstart(true);
    endVTNRef.current.classList.add("disabled-style");
    stVTNRef.current.classList.remove("disabled-style");
    console.log("hey there this is the endclick");

    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");
    const hours = String(currentDate.getHours()).padStart(2, "0");
    const minutes = String(currentDate.getMinutes()).padStart(2, "0");
    const seconds = String(currentDate.getSeconds()).padStart(2, "0");
    const milliseconds = String(currentDate.getMilliseconds()).padStart(3, "0");

    // Create the formatted date string
    const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`;

    console.log(formattedDate);

    let id = iid;
    let CheckValue = "actl_pickup_etym";
    let StsTime = formattedDate;
    console.log(StsTime);

    axios
      .post(
        employeeData.URL + `/api/v1/dashboard/${id}/${CheckValue}/${StsTime}`,
        {},
        {
          withCredentials: true,
        }
      )
      .then((response) => {
        // Handle the response from the API
        message.info({
          content: 'You are good to transport now',
          duration: 3, // Duration to show the message in seconds
          style: {
            marginTop: '20px', // Customize the position
          },
        });
    
        closeModal();
        setTimeout(() => {
          window.location.reload();
          // Your next line of code here, for example:
          console.log('Bidding accepted:', response.data);
        }, 3000);
        // console.log('Bidding accepted:', response.data);
      })
      .catch((error) => {
        // Handle errors from the API
        console.error("Error:", error);
      });
  };

  const handleEndClic = () => {
    stVTNRef.current.classList.add("disabled-style");
    // stVTNRef.current.classList.remove("disabled-style");
    const currentDate = new Date();

    // Get the year, month, day, hours, minutes, seconds, and milliseconds
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");
    const hours = String(currentDate.getHours()).padStart(2, "0");
    const minutes = String(currentDate.getMinutes()).padStart(2, "0");
    const seconds = String(currentDate.getSeconds()).padStart(2, "0");
    const milliseconds = String(currentDate.getMilliseconds()).padStart(3, "0");

    // Create the formatted date string
    const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`;

    console.log(formattedDate);

    let id = iid;
    let CheckValue = "actl_dropoff_etymm";
    let StsTime = formattedDate;

    axios
      .post(
        employeeData.URL + `/api/v1/dashboard/${id}/${CheckValue}/${StsTime}`,
        {},
        {
          withCredentials: true,
        }
      )
      .then((response) => {
        // Handle the response from the API, e.g., show a success message
        message.success({
          content: 'You have successfully delivered the load!!',
          duration: 3, // Duration to show the message in seconds
          style: {
            marginTop: '20px', // Customize the position
          },
        });
      
    
        // console.log('Bidding accepted:', response.data);
      })
      .catch((error) => {
        // Handle errors from the API, e.g., show an error message
        console.error("Error accepting bidding:", error);
      });

    // closeModal();

    // setEndTime(currentTime);
    // eVTNRef.current.classList.add('disabled-style');

    setTimeout(() => {
      window.location.reload();
      // Your next line of code here, for example:
      console.log('Bidding accepted:', response.data);
    }, 3000);
  };

  return (
    <>
      <div className="vinayak">
        <h2> Select the Status of your Loads</h2>

        <br />

        <div id="qwert">
          <h5 className="pickupcls" style={{ color: "white" }}>
            Pick Up
          </h5>
          <button
            ref={startVTNRef}
            id="startVTN"
            className="btnofmoadl disabled-style"
            onClick={handleStartClick}
            disabled={Pstart}
          >
            Start
          </button>

          <button
            ref={endVTNRef}
            id="endVTN"
            className="btnofmoadl disabled-style"
            onClick={handleEndClick}
            disabled={Pend}
          >
            End
          </button>
        </div>

        <div id="yuiop">
          <h5 className="pickupcls" style={{ color: "white" }}>
            Drop Off
          </h5>
          <button
            ref={stVTNRef}
            id="stVTN"
            className="btnofmoadl disabled-style"
            onClick={handleStartClic}
            disabled={Dstart}
          >
            Start
          </button>

          <button
            ref={eVTNRef}
            id="eVTN"
            className="btnofmoadl disabled-style"
            onClick={handleEndClic}
            disabled={Dend}
          >
            End
          </button>
        </div>
      </div>
    </>
  );
};

export default Modalmy;
