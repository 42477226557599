import React from 'react'
import LoadView from '../LoadView/LoadView'
import { LoadTemplate } from '../ComponentTemplate/LoadTemplate'

 const deliveredloads = () => {
  return (
    <>
 
    <LoadTemplate name={"DELIVERED LOADS"} pendingapi={"delivered-load-view"} status={"Completed"}  />
   
    </>
  )
}

export default deliveredloads;


