// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import "./ledger.css";
// import FreightTable from "../../FreightTable/FreightTable";

// const Ledger = () => {
//   const [startingDate, setStartingDate] = useState("");
//   const [endingDate, setEndingDate] = useState("");
//   // const [balance, setBalance] = useState(0)
//   const [selectedFromDate, setSelectedFromDate] = useState(null);
//   const [selectedToDate, setSelectedToDate] = useState(null);

//   const [Range, setRange] = useState(false);

//   const [colLD, setColLD] = useState([]);
//   const [loadsData, setLoadsData] = useState([]);

//   const [selectedRow, setSelectedRow] = useState(null); // Track selected row
//   let balance = 0;
//   let Balance = 0;
//   let CLosingBalance;
//   let totalDebit = 0;
//   let totalCredit = 0;

//   useEffect(() => {
//     let updatedRows = [];
//     // Define the URL for your GET request
//     const apiUrl = employeeData.URL + "/api/v1/payment/getPaymentDetails"; // Replace with your actual API URL

//     axios
//       .get(apiUrl, { withCredentials: true })
//       .then((response) => {
//         console.log(response);
//         const loadsRows = response.data.data.loads.rows;
//         setLoadsData(loadsRows);

//         setColLD((columns) => {
//           const newCols = response.data.data.loads.fields
//             .filter(
//               (col) =>
//                 col.name !== "trucker_account" &&
//                 col.name !== "effect" &&
//                 col.name !== "payment_code" &&
//                 col.name !== "amount" &&
//                 col.name !== "bid_amt"
//             )
//             .map((col) => ({
//               field: col.name,
//               headerName: col.name
//                 .split("_")
//                 .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
//                 .join(" "),
//               width: 150,
//             }));

//           // Add two new columns
//           newCols.push({
//             field: "Debit",
//             headerName: "Debit",
//             width: 150,
//           });

//           newCols.push({
//             field: "Credit",
//             headerName: "Credit",
//             width: 150,
//           });

//           newCols.push({
//             field: "Balance",
//             headerName: "Balance",
//             width: 150,
//           });

//           let currentBalance = 0; // Initialize current balance variable

//           const updatedRows = response.data.data.loads.rows.map((row) => {
//             if (row.effect === "+") {
//               currentBalance = Number(currentBalance);
//               row.amount = Number(row.amount);

//               // Add row.amount to currentBalance
//               currentBalance -= row.amount;
//             } else if (row.effect === "-") {
//               currentBalance = Number(currentBalance);
//               row.amount = Number(row.amount);

//               // Add row.amount to currentBalance
//               currentBalance += row.amount;
//             }
//             // console.log(currentBalance);
//             const datePortion = row.payment_date
//               ? row.payment_date.split("T")[0]
//               : ""; // Corrected property name

//             return {
//               ...row,
//               Debit: row.effect === "+" ? row.amount : 0,
//               Credit: row.effect === "-" ? row.amount : 0,
//               Balance: currentBalance,
//               payment_date: datePortion,
//             };
//           });

//           setLoadsData(updatedRows);

//           return [...newCols];
//         });
//       })
//       .catch((error) => {
//         console.error("There was a problem with the get operation:", error);
//       });

//     console.log(loadsData);

//     const currentDate = new Date();
//     const currentYear = currentDate.getFullYear();
//     const currentMonth = currentDate.getMonth() + 1; // Note: Month is zero-based
//     const currentDay = currentDate.getDate();
//     const formattedCurrentDate = `${currentYear}-${currentMonth
//       .toString()
//       .padStart(2, "0")}-${currentDay.toString().padStart(2, "0")}`;
//     const formattedStartOfMonth = `${currentYear}-${currentMonth
//       .toString()
//       .padStart(2, "0")}-01`;

//     const defaultSelectedFromDate = formattedStartOfMonth;
//     const defaultSelectedToDate = formattedCurrentDate;
//     setSelectedFromDate(defaultSelectedFromDate);
//     setSelectedToDate(defaultSelectedToDate);
//   }, []);



//   const filterDataByDateRange = () => {
//     if (selectedFromDate !== null && selectedToDate !== null) {
//       let filteredRows = [];
//       let currentBalance = 0;
//       const fromDate1 = new Date(selectedFromDate);
//       const f = fromDate1.toISOString().split("T")[0]
//       console.log(f);
//       const toDate1 = new Date(selectedToDate);
//       const t = toDate1.toISOString().split("T")[0]
//       console.log(t);
//       // console.log(fromDate);
//       // console.log(toDate);
//       const filterDataByDateRange = (fromDate, toDate, data) => {
//         const f = new Date(fromDate.split('-').reverse().join('-'));
//         const t = new Date(toDate.split('-').reverse().join('-'));
      
//         // Set time part to the end of the day for toDate
//         t.setHours(23, 59, 59, 999);
      
//         return data.filter((row) => {
//           const paymentDate = new Date(row.payment_date.split('-').reverse().join('-'));
//           paymentDate.setHours(0, 0, 0, 0);
      
//           return paymentDate >= f && paymentDate <= t;
//         });
//       };
//       const reverseDateFormat = (dateString) => {
//         const [year, month, day] = dateString.split('-');
//         return `${day}-${month}-${year}`;
//       };
//       const fromDate = reverseDateFormat(f);
//       const toDate = reverseDateFormat(t);
//       console.log(fromDate)
//       const filteredData = filterDataByDateRange(fromDate, toDate, loadsData);
      
//       return(filteredData);
        
      
//     }
     
//   };
//   // const filterDataByDateRange = () => {
//   //   // previousBalance = balance; // Initialize with the current balance

//   //   if (selectedFromDate !== null && selectedToDate !== null) {
//   //     console.log(selectedFromDate !== null && selectedToDate !== null);
//   //     console.log(selectedFromDate);
//   //     console.log(selectedToDate);

//   //     const filteredRows = loadsData.filter((row) => {
//   //       const paymentDate = new Date(row.payment_date);
//   //       return (
//   //         paymentDate >= new Date(selectedFromDate) &&
//   //         paymentDate <= new Date(selectedToDate)
//   //       );
//   //     });

//   //     // Calculate the new balance
//   //     for (let i = 0; i < loadsData.length; i++) {
//   //       console.log(Balance);

//   //       // console.log(loadsData[i].Balance);

//   //       if (loadsData[i] === filteredRows[0]) {
//   //         console.log("hey there u r just about to do it");
//   //         // setBalance(Balance)
//   //         balance = Math.abs(Balance);
//   //         // balance=Balance
//   //         Balance = loadsData[i].Balance;
//   //       }
//   //       Balance = loadsData[i].Balance;
//   //       console.log(Balance);
//   //     }

//   //     return filteredRows;
//   //   } else {
//   //     // If no date range is selected, return all data
//   //     return loadsData;
//   //   }
//   //   // Update the balance state outside the loop
//   // };

//   // Use the filteredData for rendering the table
//   const filteredData = filterDataByDateRange();
//   const myRange = () => {
//     setRange(true);
//   };
//   const CmyRange = () => {
//     console.log("thisish clear Range");
//     setRange(false);
//     balance = 0;
//   };

//   // for the closing balance
//   if (loadsData.length > 0) {
//     const CL= Range ? filteredData : loadsData
//     console.log(CL);
//     // Access the last object in the array
//     const lastObject = CL[CL.length - 1];

//     console.log(lastObject);

//     // Access the value by using the common name in the object
//     const lastValue = Math.abs(lastObject.Balance);

//     // Print the last value
//     console.log("Last value:", lastValue);
//     CLosingBalance = lastValue;
//   } else {
//     console.log("The array is empty.");
//   }

//   // for total Debit and Credit
//   loadsData.forEach((row) => {
//     totalDebit += row.Debit;
//     totalCredit += row.Credit;
//   });

//   console.log(totalDebit);
//   console.log(totalCredit);

//   return (
//     <>
//       <br />
//       <h1 style={{ color: "purple" }}>LEDGER</h1>
//       <br />
//       <br />

//       {/* remember this btngrp is styled from different comoponent i.e dashboard */}
     

//       <br />
//       <br />

//       <div
//         style={{
//           margin: "auto",
//           height: "2rem",
//           display: "flex",
//           justifyContent: "space-around",
//           width: "50%",
//         }}
//       >
//         <label htmlFor="startingDate" style={{ fontWeight: "bolder" }}>
//           From Date:{" "}
//         </label>
//         <input
//           name="startingDate"
//           type="Date"
//           value={selectedFromDate}
//           onChange={(e) => setSelectedFromDate(e.target.value)}
//           defaultValue={startingDate}
//         />
//       </div>
//       <br />

//       <div
//         style={{
//           margin: "auto",
//           height: "2rem",
//           display: "flex",
//           justifyContent: "space-around",
//           width: "50%",
//         }}
//       >
//         <label htmlFor="EndinggDate" style={{ fontWeight: "bolder" }}>
//           To Date:{" "}
//         </label>
//         <input
//           name="EndinggDate"
//           type="Date"
//           value={selectedToDate}
//           onChange={(e) => setSelectedToDate(e.target.value)}
//           defaultValue={endingDate}
//         />
//       </div>

//       <br />

//       <div
//         style={{
//           display: "flex",
//           justifyContent: "space-around",
//           flexDirection: "row",
//         }}
//       >
//         <button
//           onClick={myRange}
//           style={{
//             color: "white",
//             backgroundColor: "black",
//             borderRadius: "5px",
//           }}
//         >
//           RANGE
//         </button>

//         <button
//           onClick={CmyRange}
//           style={{ color: "white", borderRadius: "5px" }}
//         >
//           CLEAR
//         </button>
//       </div>

//       <br />

//       <div
//         style={{ color: "purple", fontWeight: "bolder", fontSize: "1.5rem" }}
//       >
//         Opening Balance: {balance}
//       </div>

//       <br />
//       <br />

//       <div className="heretable">
//         <FreightTable data={Range ? filteredData : loadsData} columns={colLD} />
//       </div>

//       <br />

//       <div className="totalv">
//         <div className="total">
//           <span>Total</span>
//           <span>{totalDebit} </span>
//           <span style={{}}>{totalCredit} </span>
//         </div>
//       </div>

//       <br />

//       <br />

//       <div className="theend">Closing Balance: {CLosingBalance}</div>
//       <br />
//       <br />
//     </>
//   );
// };

// export default Ledger;






import React, { useState, useEffect, useContext } from "react";
import DataContext from "../../../Context/dataContext";
import axios from "axios";
import "./ledger.css";
import FreightTable from "./fz";

const Ledger = () => {
  const [startingDate, setStartingDate] = useState("");
  const [endingDate, setEndingDate] = useState("");
  // const [balance, setBalance] = useState(0)
  const [selectedFromDate, setSelectedFromDate] = useState(null);
  const [selectedFromDat, setSelectedFromDat] = useState(null);
  const [selectedToDate, setSelectedToDate] = useState(null);
  const employeeData = useContext(DataContext);

  const [Range, setRange] = useState(false);
  const [check, setCheck] = useState(false);
  const [CLosin, seClosing] = useState("");
  const [lastTime,setLastTime] = useState([]);

  const [colLD, setColLD] = useState([]);
  const [loadsData, setLoadsData] = useState([]);
  const [loadsDat, setLoadsDat] = useState([]);
  const [checkV, setCheckk] = useState(0);

  const [opbal,setOpBal] = useState(0);
  // const [opbalc,setOpBalc] = useState(false);



  const [selectedRow, setSelectedRow] = useState(null); // Track selected row
  const [balance, setbalance] = useState(0); // Track selected row
  const [openingRows, setOpeningRows] = useState([]); // Track selected row


  const [minSelectableDate, setMinSelectableDate] = useState(null);
  const [maxSelectableDate, setMaxSelectableDate] = useState(null);


  let Balance = 0;
  let CLosingBalance;
  let totalDebit = 0;
  let totalCredit = 0;


  const handleFromDateChange = (e) => {
    const selectedDate = e.target.value;
    console.log(e.target.value);
    console.log(selectedToDate," thisis selected to date");
    setMinSelectableDate(selectedDate);

  };

  const handleToDateChange = (e) => {
    const selectedDate = e.target.value;
    // If selectedToDate is before selectedFromDate, update selectedFromDate
    setMaxSelectableDate(selectedDate);
  };




  const op = (e) => {
    console.log(" hello world");
    // setTimeout(() => {
    //   console.log(e, "this is the e");
    //   // console.log("this is working");
    //   console.log(loadsData, "this is the loadsData in the op");
    //   // console.log(filteredData, "this is the filtered data of OP");
    //   // console.log(loadsDat, "hopefully it works");
      


      
      
    //   let filteredData = [];
    //   let matched = false;
      
    //   loadsData.forEach(item => {
    //     // Split payment_date into day, month, year assuming the format is "DD-MM-YYYY"
    //     let [day, month, year] = item.payment_date.split("-");
        
    //     // Create a new Date object with the components
    //     let itemDate = new Date(year, month - 1, day); // Note: month is 0-indexed in Date
        
    //     // Create targetDate in the same format
    //     let [targetYear, targetMonth, targetDay] = e.target.value.split("-");
    //     let targetDate = new Date(targetYear, targetMonth - 1, targetDay);
      
    //     console.log("Comparing:");
    //     console.log("Target Date:", targetDate);
    //     console.log("Item Date:", itemDate);
        
    //     if (
    //       itemDate.getFullYear() === targetDate.getFullYear() &&
    //       itemDate.getMonth() === targetDate.getMonth() &&
    //       itemDate.getDate() === targetDate.getDate()
    //     ) {
    //       // If condition matches, set matched to true
    //       matched = true;
    //     } else {
    //       // If condition doesn't match, add the item to filteredData
    //       filteredData.push(item);
    //     }
      
    //     // Break the loop if matched
    //     if (matched) {
    //       return;
    //     }
    //   });
      
    //   if (filteredData.length > 0) {
    //     let mybalance = filteredData[filteredData.length - 1].Balance;
    //     console.log(mybalance);
    //     setOpBal(mybalance)
    //   } else {
    //     console.log("No matching item found");
    //   }
      
   



    // }, 500); // 1000 milliseconds = 1 second
  };
  



  useEffect(() => {


      setOpBal(0)
    
    // Define the URL for your GET request
    const apiUrl = employeeData.URL + "/api/v1/payment/getPaymentDetails"; // Replace with your actual API URL

    axios
      .get(apiUrl, { withCredentials: true })
      .then(async(response) => {
        console.log(response);
        let loadsRows = response.data.data.loads.rows;
        setLoadsDat(response.data.data.loads.rows);
        // await new Promise((resolve) => setTimeout(resolve, 2000));
        

        if (response.data.data.loads.rows[0].entity_type === "DR") {
          setbalance(response.data.data.loads.rows[0].amount);
        }

        setColLD((columns) => {
          const newCols = response.data.data.loads.fields
            .filter(
              (col) =>
                col.name !== "effect" &&
                col.name !== "payment_code" &&
                col.name !== "amount" &&
                col.name !== "bid_amt" &&
                col.name !== "payment_code1" &&
                col.name !== "entity_type"
            )
            .map((col) => {
              if (col.name === "account") {
                return {
                  field: col.name,
                  headerName: "Account Name",
                  width: 150,
                };
              }
              return {
                field: col.name,
                headerName: col.name
                  .split("_")
                  .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                  .join(" "),
                width: 150,
              };
            });

          newCols.push({
            field: "Debit",
            headerName: "$ Debit ",
            width: 150,
          });
          newCols.push({
            field: "Credit",
            headerName: "$ Credit",
            align: "left",
            width: 150,
          });
          newCols.push({
            field: "Balance",
            headerName: "$ Balance",
            width: 150,
          });

          let currentBalance = 0;

          const updatedRows = response.data.data.loads.rows.map((row) => {
            const debitCreditPrefix =
              row.entity_type === "DR"
                ? "Dr."
                : row.entity_type === "CR"
                  ? "Cr."
                  : "";

            const datePortion = row.payment_date
              ? row.payment_date.split("T")[0]
              : "";

            const debit = row.entity_type === "DR" ? parseFloat(row.amount) : "";
            const credit = row.entity_type === "CR" ? parseFloat(row.amount) : "";

            currentBalance = currentBalance + debit - credit;

            const displayBalance =
              currentBalance > 0
                ? `${currentBalance.toFixed(2)} Dr.`
                : `${Math.abs(currentBalance).toFixed(2)} Cr.`;

            console.log(currentBalance);

            return {
              ...row,
              Debit: debit,
              Credit: credit,
              Balance: displayBalance,
              payment_date: datePortion,
            };
          });

          console.log(currentBalance);

          setLoadsDat(updatedRows);
         


          return [...newCols];
        });

        // Filter the data here
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth() + 1; // Note: Month is zero-based
        const currentDay = currentDate.getDate();
        const formattedCurrentDate = `${currentYear}-${currentMonth
          .toString()
          .padStart(2, "0")}-${currentDay.toString().padStart(2, "0")}`;
        const formattedStartOfMonth = `${currentYear}-${currentMonth
          .toString()
          .padStart(2, "0")}-01`;

        console.log("hey there rhtis stopfs");

        const defaultSelectedFromDate = formattedStartOfMonth;
        const defaultSelectedToDate = formattedCurrentDate;
        

        console.log(defaultSelectedFromDate, " this is the date of from date");
        console.log(defaultSelectedToDate, " this is the to daate");
        setSelectedFromDat(defaultSelectedFromDate);
        setSelectedFromDate(defaultSelectedFromDate);
        setSelectedToDate(defaultSelectedToDate);
      })
      .catch((error) => {
        console.error("There was a problem with the get operation:", error);
      });

  }, []);

  useEffect(() => {
    const convertDate = (dateString) => {
      if (!dateString) return ""; // Return empty string if dateString is null or undefined
      const [year, month, day] = dateString.split("-");
      return `${day}-${month}-${year}`;
    };
  
    const fetchData = () => {
      let adults;
      console.log(loadsDat, "hi this is the loadsDat");
      // console.log(selectedToDate, " this is the toDate");
      // console.log(selectedFromDate, "this is the form date");
      // console.log(loadsDat[0].payment_date, " this is the date in the state");
      if (loadsDat.length > 0) {
        console.log(" there is in the if condition");
        // Assuming loadsDat is your array of objects
        if (loadsDat.length > 0) {
          const fromDate = convertDate(selectedFromDate);
          const toDate = convertDate(selectedToDate);
          console.log(fromDate);

       
      const startDate = new Date(fromDate.split('-').reverse().join('-'));
      
      const endDate = new Date(toDate.split('-').reverse().join('-'));
          adults = loadsDat.filter(
            (person) =>
            new Date(person.payment_date.split('-').reverse().join('-')) <= endDate && new Date(person.payment_date.split('-').reverse().join('-')) >= startDate
          );
  
          console.log(adults, " this is adult");
          setLoadsData(adults);
          setLastTime(adults)


          let numToRemove = adults.length;

          // Remove items from loadsData based on numToRemove
          loadsData.splice(-numToRemove, numToRemove);
          if (loadsData[loadsData.length - 1]) {
            
            console.log(loadsData[loadsData.length - 1].Balance);
            setOpBal(loadsData[loadsData.length - 1].Balance);
          }  if (loadsData[loadsData.length - 1]==undefined) {
            console.log(" yo this is the undefined condition");
            
            setOpBal(0);
          }



        }
      }
    };
  
    // Call fetchData whenever loadsDat changes
    fetchData();
  }, [loadsDat, selectedFromDate, selectedToDate]);
  
 







  const filterDataByDateRange = () => {
    if (selectedFromDate !== null && selectedToDate !== null) {
      let currentBalance = 0;
      const fromDate1 = new Date(selectedFromDate);
      fromDate1.setDate(fromDate1.getDate() - 1); // Subtract one day
      const f = fromDate1.toISOString().split("T")[0];

      const t = selectedToDate; // Keep the toDate as is without modification

      const filterDataByDateRange = (fromDate, toDate, data) => {
        const f = new Date(fromDate.split("-").reverse().join("-"));
        const t = new Date(toDate.split("-").reverse().join("-"));

        console.log(f, "we are checking again");
        console.log(t, "we are checking again");

        return data.filter((row) => {
          const paymentDate = new Date(row.payment_date.split("-").reverse().join("-"));
          paymentDate.setHours(0, 0, 0, 0);

          return paymentDate >= f && paymentDate <= t;
        });
      };

      const reverseDateFormat = (dateString) => {
        const [year, month, day] = dateString.split("-");
        return `${day}-${month}-${year}`;
      };

      const fromDate = reverseDateFormat(f);
      const toDate = reverseDateFormat(t);

      const filteredData = filterDataByDateRange(fromDate, toDate, loadsDat);
      if (filteredData.length > 0) {
        // Get the last object from filteredData
        console.log("hey rh");
        const lastObject = filteredData[filteredData.length - 1];
        console.log(lastObject, " this  is the last one");
        CLosingBalance = lastObject.Balance

      } else {
        // Handle case where filteredData is empty
        console.error("filteredData is empty");
      }

      return filteredData;
    }
  };


  useEffect(() => {
    const filteredData = filterDataByDateRange();

    // Convert selectedFromDate to the same format as payment_date
    const formattedFromDate = formatDate(selectedFromDate);

    // Log the selectedFromDate and formattedFromDate
    console.log("Selected From Date:", selectedFromDate);
    console.log("Formatted From Date:", formattedFromDate);

    // Log all the payment_date values in loadsData
    console.log("Payment Dates in loadsData:");
    loadsData.forEach((row) => {
      console.log(row.payment_date);
      console.log(formatDate(row.payment_date, " this is detailed checking"))
    });

    // Find the previous row based on the selectedFromDate
    const previousRowIndex = loadsData.findIndex(
      (row) => (row.payment_date) == formattedFromDate
    );
    const actualnum = previousRowIndex - 1;
    console.log(loadsData[actualnum], "this is the index we are printing it out");


    console.log("Previous Row Index:", actualnum);

    // If a previous row exists, add it to openingRows
    if (actualnum > -1) {

      setCheck(true)
      const previousRow = loadsData[actualnum];
      setOpeningRows([previousRow, ...filteredData]);
    } else {
      setCheck(false)


      console.log(" you are not getting the data");
    }
  }, [selectedFromDate, selectedToDate,]);

  // Function to format date to YYYY-MM-DD
  const formatDate = (dateString) => {
    if (!dateString) return ""; // Check if dateString is null or undefined

    const [day, month, year] = dateString.split("-");
    return `${year}-${month}-${day}`;
  };


  useEffect(() => {
    const filteredData = filterDataByDateRange();
    setOpeningRows(filteredData);
  }, []);
  // Update openingRows when selectedFromDate or selectedToDate changes


  const openingBalanceHandle = () => {
    console.log(openingRows, " thus su rtge case for opening");
    console.log(check);
    if (check) {

      setbalance(openingRows[0].Balance);
    } else {
      setbalance(0)
    }

  }










  const filteredData = filterDataByDateRange();
  const myRange = () => {
    console.log(" hey there this is ");
    setRange(true);
    openingBalanceHandle()
  };
  
  




  const CmyRange = () => {
    console.log("thisish clear Range");
    setRange(false);
    setbalance(0);
    setCheckk(1);
    console.log(selectedFromDat," testing Date");
    document.getElementById('frmdt').value = selectedFromDat;
    if (loadsData.length > 0) {
      console.log("Loads Data:", loadsData);
      console.log("Last Data:", lastTime);

      setLoadsData(lastTime)
      console.log(Range," this is the Range");

      const CL = Range ? filteredData : loadsData;

      if (CL.length > 0) {
        // Access the last object in the array
        const lastObject = CL[CL.length - 1];
        console.log(lastObject, " this is the last object");

        // Access the value by using the common name in the object
        const lastValue = lastObject.Balance;

        // Print the last value
        console.log("Last value:", lastValue);
        seClosing(lastValue)
      } else {
        console.log("The array is empty.");
      }
    } else {
      console.log("The loadsData array is empty.");
    }

  };

  // for the closing balance
  if (loadsData.length > 0) {
    const CL = Range ? filteredData : loadsData;

    if (CL.length > 0) {
      // Access the last object in the array
      const lastObject = CL[CL.length - 1];

      // Access the value by using the common name in the object
      const lastValue = Math.abs(lastObject.Balance);

      // Print the last value
      console.log("Last value:", lastValue);
      // CLosingBalance = lastValue;
    } else {
      console.log("The array is empty.");
    }
  } else {
    console.log("The loadsData array is empty.");
  }

  // for total Debit and Credit
  loadsData.forEach((row) => {
    totalDebit += parseFloat(row.Debit) || 0;
    totalCredit += parseFloat(row.Credit) || 0;
  });

  console.log(totalDebit);
  console.log(totalCredit);

  return (
    <>
      <div className="thisisprime " style={{}}>
        <br />
        <h1
          style={{
            color:"#A769A7",
            width: "100%",
          }}
        >
          Ledger
        </h1>

        <div style={{ display: "flex" }}>
          <div
            style={{
              display: "flex",
              // flexDirection: "row",
              justifyContent:"center",
              width: "100%",
              flexDirection: "row",
              // border:"2px solid red",
            }}
          >
            <div
              style={{
                // margin: "auto",
                height: "2rem",
                display: "flex",
                // flexDirection: "column",
                justifyContent: "space-around",
                width: "20%",
                // border: "2px solid red"
              }}
            >
              <label htmlFor="startingDate" style={{ fontWeight: "bolder" }}>
                From Date:{" "}
              </label>
              <input
                name="startingDate"
                type="Date"
                id="frmdt"
                value={setRange ? selectedFromDate : selectedFromDate || ""}
                max={maxSelectableDate}
                onChange={(e) => {
                  console.log(" this is the testing");
                  // setOpBalc(true)
                  handleFromDateChange(e)

                  op(e);
                  setSelectedFromDate(e.target.value);
                }}
             
                defaultValue={startingDate}
                style={{
                  borderRadius: "5px",
                  backgroundImage:
                    "linear-gradient(to Left, #f4d1f6, rgb(254, 252, 254))",
                }}
              />
            </div>

            <div
              style={{
                // margin: "auto",
                height: "2rem",
                display: "flex",
                // flexDirection: "column",
                justifyContent: "space-around",
                width: "20%",
                // border: "2px solid red"
              }}
            >
              <label htmlFor="EndinggDate" style={{ fontWeight: "bolder" }}>
                To Date:{" "}
              </label>
              <input
                name="EndinggDate"
                type="Date"
                value={selectedToDate}
                min={minSelectableDate}
                onChange={(e) => {setSelectedToDate(e.target.value); handleToDateChange(e)}}
                defaultValue={endingDate}
                style={{
                  borderRadius: "5px",
                  backgroundImage:
                    "linear-gradient(to Left, #f4d1f6, rgb(254, 252, 254))",
                }}
              />
            </div>
          </div>

          <div
            style={{
              display: "none",
              justifyContent: "space-between",
              flexDirection: "row",
              width: "20%",
              // border:"2px solid green",
              marginRight: "20px",
              textAlign: "center",
            }}
          >
            <button
              onClick={myRange}
              style={{
                color: "white",
                backgroundColor: "#2b122c",
                borderRadius: "5px",
                width: "4.1rem",
                height: "2.4rem",
                textAlign: "center",
                // border:"2px solid red",
                margin: "0 auto",
                fontSize: "0.7rem",
              }}
            >
              Range
            </button>

            <button
              onClick={CmyRange}
              style={{
                color: "white",
                backgroundColor: "#81baea",
                borderRadius: "5px",
                width: "4.1rem",
                height: "2.4rem",
                textAlign: "center",
                margin: "0 auto",
                fontSize: "0.7rem",
              }}
            >
              Clear
            </button>
          </div>
        </div>

        <br />

        <div
          style={{ color: "purple", fontWeight: "bolder", fontSize: "1.5rem" }}
        >
          
       
            <span>Opening Balance:$ {opbal}</span>
         
        </div>

     

        <div
          className="heretable"
          style={{
            // border: "2px solid black",
            height: "15rem",
            // overflow: "hidden",
          }}
        >
          {loadsData ? (
            <FreightTable data={Range ? filteredData : loadsData} columns={colLD} />
          ) : (
            <p>No table available</p>
          )}

        </div>

        <br />

        <div className="totalv" style={{}}>
          <div style={{ margin: "0rem " }} className="total">
            <span>Total</span>
            <span style={{margin: "0rem -25rem 0rem -5rem"}}>{totalDebit} </span>
            <span >{totalCredit} </span>
          </div>
        </div>

        <br />

        <br />
        {Range ? (
          <div className="theend">Closing Balance: ${CLosingBalance}</div>
        ) : (
          <div className="theend">
            {checkV === 0 ? `Closing Balance:$ ${CLosingBalance}` : `Closing Balance:$ ${CLosin}`}
          </div>

        )}


        <br />
        <br />
      </div>
    </>
  );
};

export default Ledger;
