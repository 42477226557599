import React from 'react'
import LoadView from '../LoadView/LoadView'
import { LoadTemplate } from '../ComponentTemplate/LoadTemplate'

 const Unloading = () => {
  return (
    <>
   
 
    <LoadTemplate name={"Unloading"} pendingapi={"uloading-view"} status={"Completed"}  />
   
    </>
  )
}



export default Unloading;


