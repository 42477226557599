

import React, { useState, useRef, useEffect, useContext } from "react";
import axios from "axios";
import "./dashboard.css";
// import { useEffect, useContext } from "react";
import { Row, Col, Skeleton, Tooltip, Button } from "antd";
import { Link } from "react-router-dom";
import DataContext from "../../../Context/dataContext";
import FreightTable from "../../FreightTable/FreightTable";
import {
  Box,
  ButtonGroup,
  Flex,
  HStack,
  IconButton,
  Input,
  SkeletonText,
  Text,
} from "@chakra-ui/react";
import {
  useJsApiLoader,
  GoogleMap,
  Marker,
  Autocomplete,
  DirectionsRenderer,
} from "@react-google-maps/api";

const Dashboard2 = (props) => {
  console.log(props," this is the props of d2");


let oorrii;
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyDw3ocRegmGnHSlbFlH_T5qB64ut4JdBcA", // I have created two keys, and both are working fine
    libraries: ["places"],
  });


  const [filteredRowsn, setFilteredRowsn] = useState([]);
  const [alldataRows, setAllDataRows] = useState([])
  const [check, setCheck] = useState(true);
  const center = { lat: 48.8584, lng: 2.2945 };
  const [filteredRows, setFilteredRows] = useState([]);
  const [row, setRows] = useState(null);
  const [origin, setOrigin] = useState()
  const [destinationn, setDestinationn] = useState()
  const [finaldata, setFinalData] = useState()

  const [showProfileMessage, setShowProfileMessage] = useState(false); // State to control the profile message visibility
  const [filteredLoadsWithoutDisabledBids, setFilteredLoadsWithoutDisabledBids] = useState()

  const [columns, setColumns] = useState([]);
  const [map, setMap] = useState(/** @type google.maps.Map */(null));
  const [directionsResponse, setDirectionsResponse] = useState(null);
  const [distance, setDistance] = useState(null);
  const [duration, setDuration] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [nrow, setNrow] = useState([]);
  const employeeData = useContext(DataContext);
  // console.log(FreightData);
  const filteredLoads = [];
  const filteredLoadss = [];
  const rows = props.data;
  console.log(rows, "thois is the row");
  for (let i = 0; i < rows.length; i++) {
    const loadRow = rows[i];
    const biddingEndTime = new Date(loadRow.bidding_end_time);

    // Calculate the time left in milliseconds
    const currentTime = new Date();
    const timeLeft = biddingEndTime - currentTime;



    // Check if timeLeft is negative (bidding_end_time is in the past)
    let timeLeftText;
    let isBidDisabled = false;
    let buttonStyle = {}; // Define a style object for the button
    if (timeLeft <= 0) {
      timeLeftText = "No time left";
      isBidDisabled = true;

      // Define the style for the disabled button (e.g., grayed out)
      buttonStyle = {
        width: "80px",
        backgroundColor: "#ccc", // Gray background color
        color: "#888", // Gray text color
        fontWeight: "bolder",
        borderRadius: "4px",
        marginTop: "10px",
      };
    } else {
      // Calculate the absolute value of timeLeft
      const absTimeLeft = Math.abs(timeLeft);

      // Convert absTimeLeft to a human-readable format, e.g., hours and minutes
      const hoursLeft = Math.floor(absTimeLeft / (60 * 60 * 1000));
      const minutesLeft = Math.floor(
        (absTimeLeft % (60 * 60 * 1000)) / (60 * 1000)
      );

      timeLeftText = `${hoursLeft} hours ${minutesLeft} minutes`;
    }

    // Create an object that includes the existing loadRow data along with timeLeft and isBidDisabled
    const updatedLoadRow = {
      ...loadRow,
      time_left: timeLeftText,
      isBidDisabled: isBidDisabled,
    };

    // Add the "bid_now" column to the row
    updatedLoadRow.bid_now = (
      <Tooltip title="Bid" color="#1777C4">
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Link
            to={"/trucker/load-data/load-view/" + loadRow["load_id"]}
            style={{ color: "#1777C4", fontWeight: "bolder" }}
          >
            <Button
              style={buttonStyle} // Apply the button style
              disabled={isBidDisabled}
            >
              Bid
            </Button>
          </Link>
        </div>
      </Tooltip>
    );

    // Add the updatedLoadRow object to the filteredLoads array
    filteredLoads.push(updatedLoadRow);
  }

  useEffect(() => {

    axios
      .get(employeeData.URL + "/api/v1/profile/check-profile", {
        withCredentials: true,
      })
      .then((response) => {
        console.log(response);

        if (response.data.message === "Some fields are empty.") {
          // console.log("heyyyyyyy there what is gong on");
          setShowProfileMessage(true);
          console.log("inside the message");
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });

    axios
      .get(employeeData.URL + "/api/v1/dashboard/MyAll-Loads", {
        withCredentials: true,
      })
      .then((response) => {

        console.log(response);
        const my_alltherows = response.data.data.loads.rows

        for (let i = 0; i < my_alltherows.length; i++) {
          const loadRow = my_alltherows[i];
          const biddingEndTime = new Date(loadRow.bidding_end_time);

          // Calculate the time left in milliseconds
          const currentTime = new Date();
          const timeLeft = biddingEndTime - currentTime;



          // Check if timeLeft is negative (bidding_end_time is in the past)
          let timeLeftText;
          let isBidDisabled = false;
          let buttonStyle = {}; // Define a style object for the button
          if (timeLeft <= 0) {
            timeLeftText = "No time left";
            isBidDisabled = true;

            // Define the style for the disabled button (e.g., grayed out)
            buttonStyle = {
              width: "80px",
              backgroundColor: "#ccc", // Gray background color
              color: "#888", // Gray text color
              fontWeight: "bolder",
              borderRadius: "4px",
              marginTop: "10px",
            };
          } else {
            // Calculate the absolute value of timeLeft
            const absTimeLeft = Math.abs(timeLeft);

            // Convert absTimeLeft to a human-readable format, e.g., hours and minutes
            const hoursLeft = Math.floor(absTimeLeft / (60 * 60 * 1000));
            const minutesLeft = Math.floor(
              (absTimeLeft % (60 * 60 * 1000)) / (60 * 1000)
            );

            timeLeftText = `${hoursLeft} hours ${minutesLeft} minutes`;
          }

          // Create an object that includes the existing loadRow data along with timeLeft and isBidDisabled
          const updatedLoadRow = {
            ...loadRow,
            time_left: timeLeftText,
            isBidDisabled: isBidDisabled,
          };

          // Add the "bid_now" column to the row
          updatedLoadRow.bid_now = (
            <Tooltip title="Bid" color="#1777C4">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Link
                  to={"/trucker/load-data/load-view/" + loadRow["load_id"]}
                  style={{ color: "#1777C4", fontWeight: "bolder" }}
                >
                  <Button
                    style={buttonStyle} // Apply the button style
                    disabled={isBidDisabled}
                  >
                    Bid
                  </Button>
                </Link>
              </div>
            </Tooltip>
          );

          // Add the updatedLoadRow object to the filteredLoads array
          filteredLoadss.push(updatedLoadRow);
        }
        setAllDataRows(filteredLoadss)








      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });






  }, []);

  useEffect(() => {
    const mydatafortheloadstobid = filteredLoads.filter(loadRow => !loadRow.isBidDisabled);
    setFinalData(mydatafortheloadstobid)
    setFilteredLoadsWithoutDisabledBids(mydatafortheloadstobid);
  }, []);


  useEffect(() => {
    setColumns((columns) => {
      const newCols = props.columns.map((col) => ({
        field: col.name,
        headerName: col.field
          .split("_")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" "),
        width: 150,
      }));

      return [
        { field: "load_id", headerName: "LOAD ID" },
        { field: "load_date", headerName: "LOAD DATE" },
        { field: "origin_city_cd", headerName: "ORIGIN CITY" },
        { field: "des_city_cd", headerName: "DESTINATION CITY" },
        { field: "time_left", headerName: "TIME LEFT" },
        { field: "bid_now", headerName: "BID" },
      ];
    });
  }, []);






  let cityStateValues;
  /** @type React.MutableRefObject<HTMLInputElement> */
  const originRef = useRef(null);
  /** @type React.MutableRefObject<HTMLInputElement> */
  const destinationRef = useRef(null);
  if (!isLoaded) {
    return console.log("LOADING"); // Skeleton loading while map loads
  }




  const placesData = [];
  let citiesAndStates = [];


  // yes





  function calculateRoute() {
    // Part 1: Retrieve origin and destination values
    const ori = originRef.current.value;
    const desti = destinationRef.current.value;
  
    // Part 2: Geocode the origin
    const geocodeOrigin = new Promise((resolve, reject) => {
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode({ 'address': ori }, function (results, status) {
        if (status === google.maps.GeocoderStatus.OK) {
          console.log("Geocoding origin successful");
          let city = "";
          let state = "";
          // Try to find city and state from geocoding results
          for (let i = 0; i < results[0].address_components.length; i++) {
            const addressType = results[0].address_components[i].types[0];
            if (addressType === "locality") {
              city = results[0].address_components[i].long_name;
            } else if (addressType === "administrative_area_level_1") {
              state = results[0].address_components[i].long_name;
            }
          }
  
          if (city && state) {
            oorrii=""
            console.log(" setting the OriginState");
            setOrigin({
              state: state,
              city: city
            });
            console.log("Origin State:", state);
            console.log("Origin City:", city);
          } else {
            console.log("Origin City or State not found, assuming the first part is the city.");
            
            // If city or state is not found, assume the first part is the city
            const handleOriginAsync = async () => {
              const parts = ori.split(","); // Splitting the ori string by comma
          
              // Wrap the logic inside a Promise
              await new Promise((resolve) => {
                console.log(parts);
                if (parts.length > 1) {
                  console.log("Now setting up the data in the Origin");
                  const city = parts[0].trim(); // Trim leading/trailing spaces from the first part
                  setOrigin({
                    state: "",
                    city: parts[0].trim()
                  });
                  console.log("Origin City (Assumed):", city);
                  oorrii = parts[0].trim()
                }
                resolve();
              });
            };
          
            // Call the asynchronous function
            handleOriginAsync();
          }
          
          resolve();
        } else {
          console.log("Failed to fetch origin state or city. Please try again.");
          reject();
        }
      });
    });
  
    // Part 3: Geocode the destination after origin is geocoded
    const geocodeDestination = geocodeOrigin.then(() => {
      return new Promise((resolve, reject) => {
        const geocoder = new google.maps.Geocoder();
        geocoder.geocode({ 'address': desti }, function (results, status) {
          if (status === google.maps.GeocoderStatus.OK) {
            console.log("Geocoding destination successful");
            let state = "";
            // Try to find state from geocoding results
            for (let i = 0; i < results[0].address_components.length; i++) {
              const addressType = results[0].address_components[i].types[0];
              if (addressType === "administrative_area_level_1") {
                state = results[0].address_components[i].long_name;
                break;
              }
            }
  
            if (state) {
              setDestinationn(state);
              console.log("Destination State:", state);
            } else {
              console.log("Destination State not found.");
            }
            resolve();
          } else {
            console.log("Failed to fetch destination state. Please try again.");
            // Proceed even if destination state is not found
            resolve();
          }
        });
      });
    });
  
    // Part 4: Process data after geocoding
    geocodeDestination.then(() => {
      console.log("All geocoding completed");
  
      console.log(alldataRows, "this is all the data");
      console.log("after geolocation");
      console.log(destinationn, "this is my destination");
      console.log(origin, "this is the origin");
      console.log(oorrii," plan B");
  
      // Filtering based on origin and destination
      if ((origin || oorrii) && (!destinationn || destinationn === "" || destinationn === null || destinationn === undefined)) {
        console.log("Destination is empty");
        const filteredRows = alldataRows.filter(row =>
          (row.origin_city_cd === origin.state || row.origin_city_cd === origin.city || row.origin_city_cd === oorrii || row.origin_state_cd === origin.state || row.origin_state_cd === origin.state || row.origin_state_cd === oorrii )
        );
        // Update state only after all filtering operations
        setFilteredLoadsWithoutDisabledBids(filteredRows);
      } else if ((origin || oorrii) && destinationn) {
        console.log("Destination is not empty");
        const filteredRows = alldataRows.filter(row =>
          (row.origin_city_cd === origin.state || row.origin_city_cd === origin.city || row.origin_city_cd === oorrii || row.origin_state_cd === origin.state || row.origin_state_cd === origin.state || row.origin_state_cd === oorrii ) &&
          (row.des_city_cd === destinationn)
        );
        // Update state only after all filtering operations
        setFilteredLoadsWithoutDisabledBids(filteredRows);
      }
  
      console.log(filteredRows, "this is the new data to be printed");
    }).catch(() => {
      console.log("Error in geocoding. Unable to proceed.");
    });
  }
  
  
  



  


  
  // function calculateRoute() {

  //   const ori = originRef.current.value;
  //   const desti = destinationRef.current.value;
  //   const geocoder = new google.maps.Geocoder();
  //   console.log(ori, " thisis the ori we get");
  //   console.log(desti, " thisis the dest we get");

  //   geocoder.geocode({ 'address': ori }, function (results, status) {
  //     if (status === google.maps.GeocoderStatus.OK) {
  //       console.log("ok geo");
  //       const state = results[0].address_components.find(component => component.types.includes("administrative_area_level_1"));
  //       const city = results[0].address_components.find(component => component.types.includes("locality"));

  //       if (state && city) {
  //         setOrigin({
  //           state: state.long_name,
  //           city: city.long_name
  //         });
  //         console.log("State:", state.long_name);
  //         console.log("City:", city.long_name);
  //       } else {
  //         console.log("State or City not found.");
  //       }
  //     } else {
  //       console.log("Failed to fetch state or city. Please try again.");
  //     }
  //   });

  //   geocoder.geocode({ 'address': desti }, function (results, status) {
  //     if (status === google.maps.GeocoderStatus.OK) {
  //       const state = results[0].address_components.find(component => component.types.includes("administrative_area_level_1"));
  //       if (state) {

  //         setDestinationn(state.long_name)
  //         console.log(state.long_name, "here we are getting the state");
  //       } else {
  //         // document.getElementById("stateResult").innerText = "State not found.";
  //         console.log("notfound");
  //       }
  //     } else {
  //       // document.getElementById("stateResult").innerText = "Failed to fetch state. Please try again.";
  //       console.log("failed");
  //     }
  //   });



  //   console.log(alldataRows, "trhis is the all data");
  //   console.log("after feoilocation");
  //   console.log(destinationn, " thisis my dst");
  //   console.log(origin," thissit he the origin");

  //   if (origin && (!destinationn || destinationn === "" || destinationn === null || destinationn === undefined)) {
  //     console.log("when destination is empty");
  //     const filteredRows = alldataRows.filter(row =>
  //       (row.origin_city_cd === origin.state || row.origin_city_cd === origin.city)
  //     );
  //     // Update state only after all filtering operations
  //     setFilteredLoadsWithoutDisabledBids(filteredRows);
  //   } else if (origin && destinationn) {
  //     console.log(" when destinantin is not empty");
  //     const filteredRows = alldataRows.filter(row =>
  //       (row.origin_city_cd === origin.state || row.origin_city_cd === origin.city) &&
  //       (row.des_city_cd === destinationn)
  //     );
  //     // Update state only after all filtering operations
  //     setFilteredLoadsWithoutDisabledBids(filteredRows);
  //   }

  //   console.log(filteredRows, "this is the new data to be printed");

  // }








  function clearRoute() {
    setDirectionsResponse(null);
    setDistance(null);
    setDuration(null);
    originRef.current.value = "";
    destinationRef.current.value = "";
    setFilteredLoadsWithoutDisabledBids(finaldata);

  }


  return (
    <>
      <br />
      <div style={{ paddingLeft: "23px", display: "flex", justifyContent: "start" }}>
        <Link to="/trucker/preference" style={{ textDecoration: "none" }}>
          <button
            type="button"
            className="form-control"
            style={{
              // width: "4rem",
              height: "2.1rem",
              margin: "auto",
              color: "white",
              backgroundColor: "#669999",
            }}
          >Change your Route Preference
          </button>
        </Link>
      </div>
      <br />
      <br />


      <Flex
        position="relative"
        flexDirection="column"
        alignItems="center"
        h="vh"
        w="vw"
        className="App"

      >
        <Box position="absolute" left={0} top={0} h="0%" w="0%">
          {/* My Google MAP */}
          <GoogleMap
            center={center}
            zoom={15}
            mapContainerStyle={{ width: "100%", height: "100%" }}
            options={{
              zoomControl: true,
              mapTypeControl: true,
              scaleControl: true,
              streetViewControl: true,
              rotateControl: true,
              fullscreenControl: true,
            }}
            onLoad={(map) => setMap(map)}
          >
            <Marker position={center} />
            {directionsResponse && (
              <DirectionsRenderer directions={directionsResponse} />
            )}
          </GoogleMap>
        </Box>

        <Box
          p={4}
          borderRadius="lg"
          mt={4}
          bgColor="white"
          shadow="base"
          minW="container.md"
          zIndex="1"
        >
          <HStack spacing={3} justifyContent="space-between">
            <Box flexGrow={1}>
              <Autocomplete>
                <Input
                  style={{ width: "15rem" }}
                  type="text"
                  placeholder="Origin"
                  ref={originRef}
                />
              </Autocomplete>
            </Box>

            <Box flexGrow={1}>
              <Autocomplete>
                <Input
                  style={{ width: "15rem" }}
                  type="text"
                  placeholder="Destination"
                  ref={destinationRef}
                />
              </Autocomplete>
            </Box>

            <Button type="primary" onClick={calculateRoute}>
              Search
            </Button>
            <Button type="primary">
              <span onClick={clearRoute}>CLEAR</span>
            </Button>
          </HStack>


        </Box>
      </Flex>

      <br />
      <br />
      {showProfileMessage ? ( // Conditional rendering based on the condition
        <div className="profile-message">
          Please fill your Profile first
          <Link to="/trucker/profilet">
            <i className="arrow-icon">➜</i>
          </Link>
        </div>
      ) : (
        <div className="big">
          {filteredLoadsWithoutDisabledBids ? (
            <FreightTable
              data={filteredLoadsWithoutDisabledBids}
              columns={columns}
              val={true}
            />
          ) : (
            <div>No data available</div>
          )}

        </div>
      )}
    </>
  );
};

export default Dashboard2;
