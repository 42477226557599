import classes from "../Pages.module.css";
import { Row, Col, Tabs, Skeleton, message } from "antd";
import axios from "axios";
import DataContext from "../../../Context/dataContext";
import { useState, useEffect, useContext } from "react";
import BasicDetails from "./BasicDetails";
// import GradeDetailsEdit from "./GradeDetailsEdit";
import GradeDetailsEdit from "./GradeDetailsEdit";

import GradeDetails from "./GradeDetails";
import DataField from "./DataField";
import AccountDetails from "./AccountDetails";
import SyncLoader from "react-spinners/SyncLoader";
import { useNavigate } from "react-router-dom";
import { StepBackwardOutlined, StepForwardOutlined } from "@ant-design/icons";

const identifiers = ["itemSize"];
const FormTitles = ["Post Load", "More Details"];
const ItemNew = (props) => {
  const [editMode, setEditMode] = useState(true);
  const [Mode, setMode] = useState(true);
  // const [DataNew, setDataNew] = useState(null);
  const [page, setPage] = useState(0);
  var [distance, setDistance] = useState(null);

  const employeeData = useContext(DataContext);
  const history = useNavigate();

  const [loading, setLoading] = useState(false);

  const [allData, setAllData] = useState({
    postingheader: [
      {
        loading_date: null,
        unloading_date: null,
        origin_address: null,
        des_address: null,
        origin_state_cd: null,
        origin_city_cd: null,
        origin_zip_cd: null,
        des_state_cd: null,
        des_city_cd: null,
        des_zip_cd: null,
        hazmat: null,
        ltgr__code: null,
        un_tgr_code: null,
        lar_code: null,
        un_ar_code: null,
        insurance: null,
        insurance_amt: null,
        preferred_route: null,
        load_wt: null,
        uom_code: null,
        type_code: null,
        load_length: null,
        load_width: null,
        load_height: null,
        load_code: null,
        loading_time: null,
        unloading_time: null,
        bidding_end_time: null,
        load_amount: null,
        weight_type: null
        
      },
    ],
    itemSize: [],
  });

  const [ad, setAD] = useState(null);
  const [sc, setSC] = useState(null);

  useEffect(() => {
    console.log("hi");
    setEditMode(true);
    setAD(null);
    setLoading(false);

    const fetchData = async () => {
      try {
        const response = await axios.get(
          employeeData.URL + "/api/v1/load/additional-data",
          {
            withCredentials: true,
          }
        );
        console.log(response);
        setAD(response.data.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    console.log("hi");
    setEditMode(true);
    setAD(null);
    setLoading(false);
    axios
      .get(employeeData.URL + "/api/v1/load/additional-data-of-shipper", {
        withCredentials: true,
      })
      .then((response) => {
        console.log(response);
        setSC((sc) => {
          let newad = response.data.data;
          return {
            ...newad,
          };
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const getDistance = () => {
    const origin = document.getElementById("goo").value;
    console.log(origin);
    const destination = document.getElementById("doo").value;
    if (origin === "" || destination === "") {
      return; // If origin or destination is empty, return nothing
    }
    return new Promise((resolve, reject) => {
      const directionsService = new window.google.maps.DirectionsService();
      directionsService.route(
        {
          origin: { query: origin },
          destination: { query: destination },
          travelMode: window.google.maps.TravelMode.DRIVING,
        },
        (response, status) => {
          if (status === window.google.maps.DirectionsStatus.OK) {
            const calculatedDistance = response.routes[0].legs[0].distance.text;
            resolve(calculatedDistance);
          } else {
            reject(new Error("Failed to calculate distance"));
          }
        }
      );
    });
  };

  ///////////////////
  const getDistance1 = () => {
    const origin = allData.postingheader[0].origin_address;
    console.log(origin);
    const destination = allData.postingheader[0].des_address;
    if (origin === "" || destination === "") {
      return; // If origin or destination is empty, return nothing
    }
    return new Promise((resolve, reject) => {
      const directionsService = new window.google.maps.DirectionsService();
      directionsService.route(
        {
          origin: { query: origin },
          destination: { query: destination },
          travelMode: window.google.maps.TravelMode.DRIVING,
        },
        (response, status) => {
          if (status === window.google.maps.DirectionsStatus.OK) {
            const calculatedDistance = response.routes[0].legs[0].distance.text;
            resolve(calculatedDistance);
          } else {
            reject(new Error("Failed to calculate distance"));
          }
        }
      );
    });
  };

  const handleSave = async (event) => {
    if (page === 1) {
      console.log(allData);
      const calculatedDistance = await getDistance1();
      distance = calculatedDistance;
      var amountUpValue = allData.amount;

      // Get the value of the input field with id "total_weight"
      var totalWeightValue = allData.weight;
    } else {
      try {
        console.log(allData.postingheader[0]);
        const calculatedDistance = await getDistance();
        distance = calculatedDistance; // You can now use the calculated distance here
        console.log(distance);
        var amountUpValue = document.getElementById("amount_up").value;

        // Get the value of the input field with id "total_weight"
        var totalWeightValue = document.getElementById("total_weight").value;

        // You can now use these values as needed
        console.log("Amount Up Value: " + amountUpValue);
        console.log("Total Weight Value: " + totalWeightValue);
        console.log(allData.postingheader[0]);
        // Perform other actions with the distance value
      } catch (error) {
        console.error(error);
        // Handle the error, e.g., display an error message
      }
    }
    // Get the value of the input field with id "amount_up"

    let formValid = true;
    let count = 0;
    ////////////////////////basic start //////////////
    if (!allData.postingheader[0].origin_address) {
      const element = document.getElementById("goo");
      // element.getElementsByTagName("input")[0].focus();
      // element.focus();
      // element.style.border = "2px solid #0033BB";
      message.error({
        content: "Origin Address can not be Blank",
        className: "custom-class",
        style: {
          marginTop: "1vh",
        },
      });
      setLoading(false);
      return;
    } else {
      const element = document.getElementById("goo");
      // console.log("Removing border");
      // element.style.border = "none";
    }

    if (!allData.postingheader[0].loading_date) {
      message.error({
        content: "Pick Up Date can not be Blank",
        className: "custom-class",
        style: {
          marginTop: "1vh",
        },
      });
      setLoading(false);
      return;
    }

    // if (!allData.postingheader[0].unloading_date) {
    //   const element = document.getElementById("unloaddate");
    //   element.getElementsByTagName("input")[0].focus();
    //   message.error({
    //     content: "Unloading Date cannot be null",
    //     className: "custom-class",
    //     style: {
    //       marginTop: "1vh",
    //     },
    //   });

    //   setLoading(false);
    //   return;
    // }
    if (!allData.postingheader[0].loading_time) {
      const element = document.getElementById("loadintime");

      console.log("Setting border to #0033BB");
      // element.style.border = "2px solid #0033BB";

      message.error({
        content: "Pick Up Time can not be Blank",
        className: "custom-class",
        style: {
          marginTop: "1vh",
        },
      });

      setLoading(false);
      return;
    } else {
      const element = document.getElementById("loadintime");
      // console.log("Removing border");
      // element.style.border = "none";
    }

    if (!allData.postingheader[0].unloading_time) {
      message.error({
        content: "Drop-Off Time can not be Blank",
        className: "custom-class",
        style: {
          marginTop: "1vh",
        },
      });
      setLoading(false);
      return;
    }

    if (!allData.postingheader[0].bidding_end_time) {
      message.error({
        content: "Bidding End Time can not be Blank",
        className: "custom-class",
        style: {
          marginTop: "1vh",
        },
      });
      setLoading(false);
      return;
    }

    if (!allData.postingheader[0].des_address) {
      const element = document.getElementById("doo");
      // element.getElementsByTagName("input")[0].focus();
      // element.focus();
      // element.style.border = "2px solid #0033BB";
      message.error({
        content: "Destination Address can not be Blank",
        className: "custom-class",
        style: {
          marginTop: "1vh",
        },
      });
      setLoading(false);
      return;
    } else {
      const element = document.getElementById("doo");
      // console.log("Removing border");
      // element.style.border = "none";
    }

    if (!allData.postingheader[0].origin_state_cd) {
      const element = document.getElementById("state");
      // element.getElementsByTagName("input")[0].focus();
      element.focus();
      element.style.border = "2px solid #0033BB";
      message.error({
        content: "Origin State  can not be Blank",
        className: "custom-class",
        style: {
          marginTop: "1vh",
        },
      });
      setLoading(false);
      return;
    } else {
      const element = document.getElementById("state");
      // console.log("Removing border");
      // element.style.border = "none";
    }
    if (!allData.postingheader[0].origin_city_cd) {
      const element = document.getElementById("city");
      // element.getElementsByTagName("input")[0].focus();
      // element.focus();
      // element.style.border = "2px solid #0033BB";
      message.error({
        content: "Origin City  can not be Blank",
        className: "custom-class",
        style: {
          marginTop: "1vh",
        },
      });
      setLoading(false);
      return;
    } else {
      const element = document.getElementById("city");
      // console.log("Removing border");
      // element.style.border = "none";
    }

    if (!allData.postingheader[0].origin_zip_cd) {
      const element = document.getElementById("zipcode");
      // element.getElementsByTagName("input")[0].focus();
      // element.focus();
      // element.style.border = "2px solid #0033BB";
      message.error({
        content: "Origin Zip Code can not be Blank",
        className: "custom-class",
        style: {
          marginTop: "1vh",
        },
      });
      setLoading(false);
      return;
    } else {
      const element = document.getElementById("zipcode");
      // console.log("Removing border");
      // element.style.border = "none";
    }
    if (!allData.postingheader[0].des_state_cd) {
      const element = document.getElementById("dstate");
      // element.getElementsByTagName("input")[0].focus();
      // element.focus();
      // element.style.border = "2px solid #0033BB";
      message.error({
        content: "Destination State can not be Blank",
        className: "custom-class",
        style: {
          marginTop: "1vh",
        },
      });
      setLoading(false);
      return;
    } else {
      const element = document.getElementById("dstate");
      // console.log("Removing border");
      // element.style.border = "none";
    }
    if (!allData.postingheader[0].des_city_cd) {
      const element = document.getElementById("dcity");
      // element.getElementsByTagName("input")[0].focus();
      // element.focus();
      // element.style.border = "2px solid #0033BB";
      message.error({
        content: "Destination City  can not be Blank",
        className: "custom-class",
        style: {
          marginTop: "1vh",
        },
      });
      setLoading(false);
      return;
    } else {
      const element = document.getElementById("dcity");
      // console.log("Removing border");
      // element.style.border = "none";
    }
    if (!allData.postingheader[0].des_zip_cd) {
      const element = document.getElementById("dzipcode");
      // element.getElementsByTagName("input")[0].focus();
      // element.focus();
      // element.style.border = "2px solid #0033BB";
      message.error({
        content: "Destination Zip Code can not be Blank",
        className: "custom-class",
        style: {
          marginTop: "1vh",
        },
      });
      setLoading(false);
      return;
    } else {
      const element = document.getElementById("dzipcode");
      // console.log("Removing border");
      // element.style.border = "none";
    }
    ////////////////////////basic end
    // if (!allData.postingheader[0].type_code) {
    //   // element.getElementsByTagName("input")[0].focus();

    //   // element.style.border = "2px solid #0033BB";
    //   message.error({
    //     content: "Vehicle Type can not be Blank",
    //     className: "custom-class",
    //     style: {
    //       marginTop: "1vh",
    //     },
    //   });
    //   setLoading(false);
    //   return;
    // }

    if (!allData.postingheader[0].load_amount) {
      const element = document.getElementById("amount_up");
      // element.getElementsByTagName("input")[0].focus();

      // element.style.border = "2px solid #0033BB";
      message.error({
        content: "amount can not be Blank",
        className: "custom-class",
        style: {
          marginTop: "1vh",
        },
      });
      setLoading(false);
      return;
    }

    identifiers.forEach((val) => {
      if (!allData[val] || allData[val].length === 0) {
        // Check if the array is null or empty
        formValid = false;
        if (count === 0) {
          console.log(
            "Empty field detected in " + val.toUpperCase() + " Tab!!!"
          );
          message.error({
            content: "Load Details could not be blank!!!",
            className: "custom-class",
            style: {
              marginTop: "1vh",
            },
          });
        }
        count = count + 1;
      } else {
        allData[val].forEach((data) => {
          console.log(data);
          if (
            !data ||
            data["uom_code"] == null ||
            data["item_name"] == null ||
            data["length"] == null ||
            data["width"] == null ||
            data["height"] == null ||
            data["load_wt"] == null
          ) {
            console.log(!data);
            formValid = false;
            var error1 =
              data == undefined
                ? "Load Detail can not be blank"
                : data["length"] == null
                ? "Length can not be blank"
                : data["item_name"] == null
                ? "ITEM can not be null"
                : data["height"] == null
                ? "height can not be blank"
                : data["width"] == null
                ? "width can not be blank"
                : data["uom_code"] == null
                ? "uom can not be blank"
                : data["load_wt"] == null
                ? "Load Weight can not be blank"
                : null;
            // var error= data['qty']==null ? "quantity field should be field": data['item_code'] ==null ? "item code should not be null" : " InvoiceSize" ;
            //  if(count === 0){
            message.error({
              content: error1,
              className: "custom-class",
              style: {
                marginTop: "1vh",
              },
            });
          }
          //  else if ((!allData.amount_up))
          //   {
          //   const element = document.getElementById("amount_up"); // Replace "amount_up" with the actual ID of the input field
          //   element.focus();
          //   element.style.border = "2px solid #0033BB";
          //   message.error({
          //     content: "Load Amount can not be Blank",
          //     className: "custom-class",
          //     style: {
          //       marginTop: "1vh",
          //     },
          //   });
          //   formValid = false;
          //   return;
          // }
          //  else if(allData.amount_up){
          //   formValid = true;
          //  }
          // if (!data) {
          //   formValid = false;
          //   if (count === 0) {
          //     console.log(
          //       "Empty field detected in " + val.toUpperCase() + " Tab!!!"
          //     );
          //     message.error({
          //       content: "Empty Fields In Load Details Tab!!!",
          //       className: "custom-class",
          //       style: {
          //         marginTop: "1vh",
          //       },
          //     });
          //   }
          //   count = count + 1;
          // }
        });
      }
    });

    if (!formValid) {
      setLoading(false);
      console.log("Form not valid, data not submitted.");
      return; // If formValid is false, return early and prevent data submission
    }

    {
      setLoading(true);
      // const postData = {
      //   ...allData,
      //   itemSize: allData.itemSize.map((dep) => {
      //     return {
      //       ...dep,
      //     };
      //   }),
      //   total_weight: totalWeightValue,
      //   amount_up: amountUpValue,
      //   distance: distance,
      // };
      ////////////////////////////////////////////////second/////////////////////
      // const postData = {
      //   ...allData,
      //   itemSize: allData.itemSize.map((dep) => {
      //     // Check if size_id exists, if not, add it with the value from props.data.size_code
      //     if (!dep.hasOwnProperty("size_id")) {
      //       return {
      //         ...dep,
      //         size_id: sc.shipper.rows[0].size_id, // assuming size_code exists in props.data
      //       };
      //     }
      //     return dep;
      //   }),
      //   // Add truck_type if it doesn't exist
      //   ...(allData.postingheader[0].type_code ? {} : { type_code: props.data.type_code }),
      //   ...(allData.postingheader[0].type_code
      //     ? {}
      //     : { type_code: sc.shipper1.rows[0].vechile_type_cd }),
      //   total_weight: totalWeightValue,
      //   amount_up: amountUpValue,
      //   distance: distance,
      // };

      const updatedPostingHeader = [
        {
          ...allData.postingheader[0],
          type_code:
            allData.postingheader[0].type_code ||
            sc.shipper1.rows[0].vechile_type_cd,
        },
      ];

      const postData = {
        ...allData,
        postingheader: updatedPostingHeader,
        itemSize: allData.itemSize.map((dep) => {
          // Check if size_id exists, if not, add it with the value from props.data.size_code
          if (!dep.hasOwnProperty("size_id")) {
            return {
              ...dep,
              size_id: sc.shipper.rows[0].size_id, // assuming size_code exists in props.data
            };
          }
          return dep;
        }),
        total_weight: totalWeightValue,
        amount_up: amountUpValue,
        distance: distance,
      };

      console.log(postData);
      console.log(sc.shipper.rows[0].size_id);
      console.log(sc.shipper1.rows[0].vechile_type_cd);
      // console.log(eererer);
      axios
        .post(employeeData.URL + "/api/v1/load", postData, {
          withCredentials: true,
          credentials: "include",
        })
        .then((response) => {
          message.success({
            content: "Load Created Successfully!!!",
            className: "custom-class",
            style: {
              marginTop: "2vh",
            },
          });
          setLoading(false);
          console.log(history);
          history("/home/load-view");
        })
        .catch((err) => {
          message.error({
            content: "An Error Occurred!!!!",
            className: "custom-class",
            style: {
              marginTop: "2vh",
            },
          });
          setLoading(false);
        });
    }
  };

  const handleCancel = (event) => {
    setLoading(false);
    history.replace("/sales/itemMaster/item-details");
  };

  const PageDisplay = () => {
    if (page === 0 && ad && sc) {
      return (
        <>
          <GradeDetailsEdit
            ad={ad}
            sc={sc}
            create={true}
            setData={setAllData}
            data={allData.itemSize[0]}
            editMode={true} // Set editMode to true to open all DataField components by default
            mode={Mode}
            fdata={allData}
            // newdta={{ t: amountUpValue, k: totalWeightValue }}
          />

          <BasicDetails
            // create={true}
            // setData={setAllData}
            // data={allData.postingheader[0]}
            // editMode={editMode}
            create={true}
            setData={setAllData}
            data={allData.postingheader[0]} // Pass the data to child component
            editMode={editMode}
            ad={ad}
            sc={sc}
          />
        </>
      );
    } else if (page === 1) {
      return (
        <AccountDetails
          create={true}
          setData={setAllData}
          data={allData.postingheader[0]}
          editMode={editMode}
        />
      );
    }
  };

  return (
    <>
      {
        <Row className={classes["RowP"]}>
          {/* <GradeDetailsEdit  /> */}
          <Col lg={1} md={1}></Col>
          <Col lg={24} md={24} className={classes["Col"]}>
            <div className={classes["EditView"]}>
              <div className={classes["header"]}>
                <h1 style={{ color: "#A769A7"  }}>{FormTitles[page]}</h1>
              </div>
              {PageDisplay()}
            </div>
          </Col>

          {/* ////////////////////////////add more details button////////////////// */}
          <Col md={4}>
            <button
              style={{
                backgroundColor: "#8A31A8",
                color: "white",
                borderRadius: "8px",
                width: "12rem",
                height: "2rem",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                // padding: "1px 16px",
                border: "3px solid transparent",
              }}
              onClick={() => {
                if (page === FormTitles.length - 1) {
                  setPage((currPage) => currPage - 1);
                  setMode(false);
                  console.log("Mode set to false");
                } else {
                  setPage((currPage) => currPage + 1);
                }
                if (page === FormTitles.length - 1) {
                  setMode(false);
                }
              }}
            >
              <span>
                {page === FormTitles.length - 1
                  ? "Previous Detail"
                  : "Add More Details"}
              </span>
              {page === FormTitles.length - 1 ? (
                <StepBackwardOutlined />
              ) : (
                <StepForwardOutlined />
              )}
            </button>
          </Col>
          {/* ////////////////////////////post load button ////////////////// */}
          <Col md={4}>
            <button
              style={{
                backgroundColor: "#8A31A8",
                color: "white",
                borderRadius: "8px",
                width: "12rem",
                border: "3px solid transparent",
                height:"2rem",
                paddingTop:"0.1rem"
                // textAlign: page === FormTitles.length - 1 ? 'left' : 'center',
              }}
              onClick={(event) => handleSave(event)}
            >
              Post Load
            </button>
          </Col>
        </Row>
      }
    </>
  );
};

export default ItemNew;
