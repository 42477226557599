





import React, { useState, useEffect, useRef,useContext } from "react";
import { useNavigate } from "react-router-dom";
import "./prefer.css";
import { Link } from "react-router-dom";
import axios from "axios";
import { message } from "antd";
import { MinusCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";
import DataContext from "../../../Context/dataContext";


const Preference = () => {







  const navigate = useNavigate();
  const employeeData = useContext(DataContext);
  const [formData, setFormData] = useState([{ start_location: "", end_location: "", p_id: "", country_code: "", start_details: "", end_details: "" }]);
  const [country, setCountry] = useState([]);
  const [state, setState] = useState([]);
  const [staten, setStaten] = useState([]);
  const [city, setCity] = useState([]);
  const [cityn, setCityn] = useState([]);
  const [mycountry, setMyCountry] = useState('');
  const [mystate, setMyState] = useState('');
  const [origin, setOrigin] = useState()
  const [destinationn, setDestinationn] = useState()

  const [c, sc] = useState(true)
  const [s, ss] = useState(true)
  const [ci, sci] = useState(true)






  const [selectedIndex, setSelectedIndex] = useState(null); // State to hold the selected index

  const handleCountryChange = (e) => {
    const index = e.target.selectedIndex - 1; // Subtracting 1 to account for the default "Select" option
    setSelectedIndex(index);

  }

  useEffect(() => {
    // Fetch country data
    axios
      .get(employeeData.URL + "/api/v1/profile/show-country", { withCredentials: true })
      .then((response) => {
        console.log(response);
        setCountry(response.data.data.countries);
      })
      .catch((error) => {
        console.log("Error fetching country data:", error);
      });

    // Fetch preference data
    axios
      .get(employeeData.URL + "/api/v1/profile/get-preferenc", { withCredentials: true })
      .then((response) => {
        const preferences = response.data.loads.rows;
        console.log(" hi i am checking this ", response.data.loads.rows);
        const myCountryFromPreference = preferences.length > 0 ? preferences[0].country_code : '';
        if(preferences.length == 0){
         
          message.warning({
            content: 'Please add the routes you preffered most!',
            duration: 5,
            style: {
              // color: 'yellow',
            },
          });
        }
        setMyCountry(myCountryFromPreference);
        setFormData(preferences.map(preference => ({
          start_location: preference.start_location,
          end_location: preference.end_location,
          p_id: preference.p_id,
          country_code: preference.country_code,
          start_details: preference.start_details,
          end_details: preference.end_details

        })));
      })
      .catch((error) => {
        console.log("Error fetching preference data:", error);
      });

  }, []);







  const handleInputChange = (index, event) => {
    // console.log( event.target.value," this ist he test");
    const { name, value } = event.target;
    console.log(name, index, value, " cool checking");
    const list = [...formData];
    console.log(list, " this is the previous list");
    list[index][name] = value;
    console.log(list, " this isthe list");
    setFormData(list);
  };





  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData, "this is the formData");
    const modifiedFormData = formData.map((item, index) => {
      if (item.p_id) {
        return { ...item };
      } else {
        // Include selectedIndex if the action is 'insert'
        return { ...item, selectedIndex: index };
      }
    });
    console.log(modifiedFormData, " this is the modified");
    axios
      .post(employeeData.URL + "/api/v1/profile/update-preference", { profile: modifiedFormData }, {
        withCredentials: true,
        credentials: "include",
      })
      .then((response) => {
        console.log("Data successfully sent to the server:", response.data);
        message.success({
          content: "Your Preference is added successfully",
          className: "custom-class",
          style: {
            marginTop: "1vh",
          },
        });
        // navigate('/trucker/profilet')
      })
      .catch((error) => {
        console.error("Error sending data to the server:", error);
      });
  };


  const addForm = () => {
    setFormData([...formData, { start_location: "", end_location: "" }]);
  };


  const idelete = (tobedelete) => {
    console.log(tobedelete);
    console.log("inside the deleete");
    const myidtd = tobedelete.p_id;
    axios
      .delete(employeeData.URL + `/api/v1/profile/preference-delete/${myidtd}`, {
        withCredentials: true,
        credentials: "include",
      })
      .then((response) => {
        console.log("Data successfully sent to the server:", response.data);
        message.success({
          content: "Your Preference is deleted successfully",
          className: "custom-class",
          style: {
            marginTop: "1vh",
          },
        });

      })
      .catch((error) => {
        console.error("Error sending data to the server:", error);
      });

  }





  useEffect(() => {
    console.log(" this is thr log i have created");
    axios.get(employeeData.URL + `/api/v1/profile/show-country-withSC`, {
      withCredentials: true,
      credentials: "include",
    }).then((response) => {
      setCountry(response.data.data.countries)
      setState(response.data.data.states)
      setCity(response.data.data.cities)

      console.log("Data successfully sent to the server:", response.data.data);

    })
      .catch((error) => {
        console.error("Error sending data to the server:", error);
      });

  }, [])








  return (
    <>
      <h1 style={{ margin: "auto", color: "#A769A7" }}>Preferred Route</h1>
      <br />
      <div style={{ paddingLeft: "2.3rem",paddingRight:"18rem", display: "flex", justifyContent: "space-between"  }}>
        <div>
          <Link to="/trucker/profilet" style={{ textDecoration: "none" }}>
            <button
              type="button"
              className="form-control"
              style={{
                width: "12  rem",
                height: "2.1rem",
                margin: "auto",
                color: "white",
                backgroundColor: "#8A31A8",
                paddingTop:"0.2rem"
              }}
            >
              Back
            </button>
          </Link>
        </div>

        <div>
          <select
            name="country_code"
            id="cntry"
            style={{
              height: "1.78rem",
              width: "17rem",
              padding: "0.1rem 1.5rem",
              backgroundColor: "white",
              boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.2)",
              textAlign: "end"

            }}
            className={`form-control`}
            value={mycountry} // Update the value to mycountry
            onChange={(e) => {
              // handleInputChange(e);
              sc(false)
              const selectedCountryCode = e.target.value;
              setMyCountry(selectedCountryCode);
              console.log(country, " this sit he country");
              setFormData([0].country_code)

              const news = state.filter((selectstate) => selectstate.country_code == selectedCountryCode);
              setStaten(news);
              console.log(mycountry, " this isthe code");
              console.log(e.target.value, " this siht e actual one");
              setFormData([{ ...formData[0], country_code: e.target.value }])

              console.log(news, " this is the news");
            }}
          >
            <option value="">Select Country</option>
            {country.map((countryObj) => (
              <option key={countryObj.code} value={countryObj.code}>
                {countryObj.name}
              </option>
            ))}
          </select>

        </div>
      </div>

      <br />


      <div style={{
        width: "95%",
        height: "1.5rem",
        // border: "2px solid red",
        margin: "auto",
        borderRadius: "0.4rem",
        marginBottom: "0.3rem",
        // background: "linear-gradient(to bottom, #fbfafc 0%,transparent 100%)"
        boxShadow: " 0px 4px 8px rgba(0, 0, 0, 0.1)",
        display: "flex",
        // justifyContent: "space-between",
        // border:"2px solid red"
      }}
      >
        <div style={{ display: "flex", justifyContent: "space-around", width: "40%" }}>

          <label style={{ marginLeft: "-5%" }} htmlFor="">Origin State</label>
          <label style={{
            marginRight: "12%"
          }} htmlFor="">Origin Nearby Landmarks</label>
        </div>

        <div style={{ display: "flex", justifyContent: "space-around", width: "40%", marginLeft: "1rem" }}>

          <label style={{ marginLeft: "-5%" }} htmlFor="">Destination State</label>
          <label style={{ marginRight: "17%" }} htmlFor="">Destination Nearby Landmarks</label>
        </div>

      </div>

      {formData.map((form, index) => (
        <>
          <div key={index} className="hereForm" style={{ marginBottom: "1rem", width: "95%" }}>

            <div style={{ display: "flex", flexDirection: "row" }}>
              <div style={{ display: "flex", width: "85%", marginTop: "0.4rem" }}>
                <div style={{ width: "80%", height: "4.5rem", display: "flex", justifyContent: "start", flexDirection: "row" }}>
                  <div style={{ height: "2rem", width: "50%", display: "flex", justifyContent: "start" }}>




                    <select
                      name="start_location"
                      id="state"
                      style={{
                        height: "1.78rem",

                        padding: "0.1rem 0",
                        width: "100%",
                        backgroundColor: "transparent",
                        boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.2)"
                      }}
                      value={form.start_location}



                      onChange={(e) => {
                        handleInputChange(index, e)
                        sci(false)
                        const selectedStateCode = e.target.value;
                        console.log(selectedStateCode, " this is the what it is ");
                        setMyState(selectedStateCode);

                        const news = city.filter((selectstate) => selectstate.state_code == selectedStateCode);
                        setCityn(news);
                        // console.log(mycountry, " this isthe code");

                        console.log(news, " this is the news");
                        console.log(formData[index].state, " thissi the tezt");
                        console.log(form.start_location, " this si the super cool thing forn the start state");
                      }}
                    >
                      {c ? (
                        state
                          .filter((selectstate) => selectstate.country_code === mycountry)
                          .map((stateObj) => (
                            <option key={stateObj.state_code} >
                              {stateObj.state_name}
                            </option>
                          ))
                      ) : (
                        staten.map((stateObj) => (
                          <option key={stateObj.state_code} >
                            {stateObj.state_name}
                          </option>
                        ))
                      )}

                    </select>


                  </div>
                  <div style={{ width: "88%", height: "2rem", display: "flex", justifyContent: "start", marginLeft: "1rem" }}>
                    <input
                      value={form.start_details} style={{
                        width: "100%",
                        backgroundColor: "transparent",
                        boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.2)",
                        padding: "0.89rem"
                      }} name="start_details" type="text" placeholder="city or landmark"
                      onChange={(e) => handleInputChange(index, e)}
                    />



                  </div>


                </div>

                <div style={{ width: "80%", height: "4.5rem", display: "flex", justifyContent: "start", flexDirection: "row", marginLeft: "1rem" }}>
                  <div style={{ width: "50%", height: "2rem", display: "flex", justifyContent: "end" }}>



                    <select
                      name="end_location"
                      id="state"
                      style={{
                        height: "1.78rem",

                        padding: "0.1rem 0",
                        width: "100%",
                        // textAlign: "end",
                        backgroundColor: "transparent",
                        boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.2)"
                      }}

                      // value={formData[index].start_location}
                      // onChange={(e) => handleChang(e, index)}
                      value={form.end_location}

                      onChange={(e) => {
                        handleInputChange(index, e)
                        sci(false)
                        const selectedStateCode = e.target.value;
                        console.log(selectedStateCode, " this is the what it is ");
                        setMyState(selectedStateCode);

                        const news = city.filter((selectstate) => selectstate.state_code == selectedStateCode);
                        setCityn(news);
                        // console.log(mycountry, " this isthe code");

                        console.log(news, " this is the news");
                        console.log(formData[index].state, " thissi the tezt");
                      }}
                    >
                      {c ? (
                        state
                          .filter((selectstate) => selectstate.country_code === mycountry)
                          .map((stateObj) => (
                            <option key={stateObj.state_code} value={stateObj.state_name}>
                              {stateObj.state_name}
                            </option>
                          ))
                      ) : (
                        staten.map((stateObj) => (
                          <option key={stateObj.state_code} value={stateObj.state_name}>
                            {stateObj.state_name}
                          </option>
                        ))
                      )}

                    </select>


                  </div>
                  <div style={{ height: "2rem", width: "88%", display: "flex", marginLeft: "1rem" }}>
                    <input
                      value={form.end_details}
                      style={{
                        width: "100%",
                        backgroundColor: "transparent",
                        boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.2)",
                        padding: "0.89rem"
                      }} type="text" placeholder="city or landmark" name="end_details"
                      onChange={(e) => handleInputChange(index, e)}
                      required  
                    />



                  </div>


                </div>
              </div>





















              {index > 0 && (
                <MinusCircleOutlined style={{ marginLeft: "2rem", marginTop: "1rem" }}
                  onClick={() => {
                    const removedData = formData[index]; // Get the data you're removing
                    setFormData(formData.filter((_, i) => i !== index));
                    // Call your additional function here with the removed data
                    idelete(removedData);
                  }}
                />
              )}

            </div>
          </div>

        </>
      ))
      }
      <div className="lastbtns">
        <button
          name="addPre"
          type="button"
          // className="form-control"
          style={{
            width: "12rem",
            height: "2.4rem",
            margin: "auto",
            color: "white",
            backgroundColor: "#8a31a8",
            fontSize: "0.8rem",
            borderRadius: "4px"

          }}
          onClick={addForm}

        >
          Add Preference
          <PlusCircleOutlined style={{ marginLeft: "1rem" }} />
        </button>
        <button
          name="addPre"
          type="submit"
          id="addPRE"
          className="foupbtn"
          style={{
            width: "12rem",
            height: "2.4rem",
            margin: "auto",
            color: "white",
            backgroundColor: "#8a31a8",
            fontSize: "0.8rem",
            borderRadius: "4px"
          }}
          onClick={handleSubmit}
        >
          Update Preference
        </button>
      </div>
    </>
  );
};

export default Preference;
