import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import FreightTable from "../../FreightTable/FreightTable";
import { Link, useNavigate } from "react-router-dom";
import { Tooltip } from "antd";
import DataContext from "../../../Context/dataContext";

const Truckers = () => {
  const [row, setRow] = useState();
  const [columns, setColumns] = useState();
  const employeeData = useContext(DataContext);

  const navigate = useNavigate();

  useEffect(() => {
    // pending-bids
    console.log("allLoads testing is here");
    axios
      .get(employeeData.URL + "/api/v1/admin/alltrucker", {
        withCredentials: true,
      })
      .then((response) => {
        setRow((rows) => {
          let newRows = response.data.data.trucker.rows.map((row, index) => {
            // Convert timestamp to formatted date
            let memberSince = "";
            if (row.member_since !== null) {
              // Convert timestamp to formatted date
              memberSince = new Date(row.member_since).toLocaleString("en-US", {
                year: "numeric",
                month: "numeric",
                day: "numeric",
              });
            }

            let id = row.trucker_code; // Declare id using let

            return {
              id: index + 1,
              ...row,
              member_since: memberSince,
              View: (
                <Tooltip title="View Profile" color="#1777C4">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontSize: "24px",
                    }}
                    onClick={() => handleViewClick(id)} // Use id directly here
                  >
                    &#128100;
                  </div>
                </Tooltip>
              ),
            };
          });
          return newRows;
        });

        setColumns((columns) => {
          let newCols = response.data.data.trucker.fields.map((col) => {
            return {
              field: col.name,
              headerName: col.name
                .split("_")
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(" "),
              width: 150,
            };
          });

          // Add a "View" column
          newCols.push({
            field: "View",
            headerName: "View",
            width: 150,
            renderCell: (params) => (
              <span style={{}} onClick={() => handleViewClick(params.row.id)}>
                {params.row.View}
              </span>
            ),
          });

          return newCols;
        });
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);
  // Define a function to handle the click on the profile icon
  const handleViewClick = (id) => {
    // Add logic to open the link or perform any action
    console.log(`View clicked for row with ID ${id}`);
    navigate(`/admin/profile/${id}`);
    // {{<Link to="/shipper-profile">Go to Another Page</Link>}}
  };

  return (
    <>
      <div>
        <h1>All Truckers</h1>
      </div>

      {row && columns.length > 0 ? (
        <div style={{ display: "inline-block" }}>
          <FreightTable data={row} columns={columns} />
        </div>
      ) : (
        <div>No matching results found.</div>
      )}
    </>
  );
};

export default Truckers;
