import React from 'react'
import './Readmore.css'


const ReadMore = (props) => {
  return (
    <>
    <div className="mydata">
    <ul>
        {props.data.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
      <div className='readmoreimg'><img src={props.pic} alt="thisisIMG" /></div>
    </div>

    </>
    
  )
}

export default ReadMore