import React, { useState } from "react";
import "./FTable.css";
import Modal from "react-modal";
import Modalmy from "./modal";
import { useEffect, useContext } from "react";
import DataContext from "../../../Context/dataContext";
import FreightTable from "./FRTable";

const Dashboard3 = (props) => {
  let status_model;
  console.log(props);
  const { data, columns } = props;

  const [sortInfo, setSortInfo] = useState({
    field: null,
    order: "asc",
  });

  const [dataforId, setDataforId] = useState();
  const [dataforstatus, setDataforstatus] = useState();
  const closeModalFromParent = () => {
    setModalIsOpen(false);
  };

  const [modalIsOpen, setModalIsOpen] = useState(false); // State for modal

  const [selectedRow, setSelectedRow] = useState(null); // State to store the selected row data

  const yourFunction = (row) => {
    setSelectedRow(row);
    setModalIsOpen(true); // Open the modalc0a1ee
    console.log("-hey there this is the sselected ROW");
    console.log(selectedRow);
  };

  const closeModal = () => {
    setSelectedRow(null);
    setModalIsOpen(false); // Close the modal
  };

  const handleSort = (field) => {
    setSortInfo((prevSortInfo) => {
      if (prevSortInfo.field === field) {
        return {
          ...prevSortInfo,
          order: prevSortInfo.order === "asc" ? "desc" : "asc",
        };
      }
      return {
        field,
        order: "asc",
      };
    });
  };

  const sortedData = [...data].sort((a, b) => {
    const aValue = a[sortInfo.field];
    const bValue = b[sortInfo.field];

    if (aValue < bValue) {
      return sortInfo.order === "asc" ? -1 : 1;
    }
    if (aValue > bValue) {
      return sortInfo.order === "asc" ? 1 : -1;
    }
    return 0;
  });

  return (
    <>
      <table>

        <div id="head">
          <thead>
            <tr>
              {columns.map((col, index) => (
                <th
                  key={index}
                  style={{}}
                  onClick={() => handleSort(col.field)}
                >
                  {col.headerName}

                  {sortInfo.field === col.field && (
                    <span>{sortInfo.order === "asc" ? " ▲" : " ▼"}</span>
                  )}
                </th>
              ))}
            </tr>
          </thead>
        </div>

        <tbody style={{}}>
          {sortedData.map((row, rowIndex) => (
            <tr key={rowIndex} style={{}}>
              <div id="row">
                {columns.map((col, colIndex) => (
                  <td
                    id="thisiscstmbtn"
                    key={colIndex}
                    style={{
                      textAlign: "center",
                      width: "11rem",
                      cursor: "pointer",
                      border:
                        row[col.field] === "Take Action" ? "none" : "none",
                      boxShadow:
                        row[col.field] === "Take Action"
                          ? "0px 2px 10px black"
                          : "none",
                      backgroundColor:
                        row[col.field] === "Take Action"
                          ? "#c0a1ee"
                          : "none",
                          color:
                          row[col.field] === "Take Action"
                            ? "white"
                            : "none",
                          borderRadius: row[col.field] ==="Take Action" ? "6px" : "none"
                    }}
                    onClick={() => {
                      if (row[col.field] === "Take Action") {
                        console.log(row);
                        setDataforId(row.load_id);
                        setDataforstatus(row.actual_status);

                        console.log("blah blah b;ahg");
                        yourFunction(row);
                      }
                    }}
                  >
                    {row[col.field]}
                  </td>
                ))}
              </div>
            </tr>
          ))}
        </tbody>
      </table>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
        style={{
          overlay: {
            backgroundColor: "rgba(0.4, 1, 1, 0.3bv)", // Background overlay color
          },
          content: {
            width: "50rem", // Set the desired width of the modal
            height: "25rem", // Set the desired height of the modal
            margin: "auto", // Center the modal horizontally
            marginTop: "100px", // Adjust the top margin to center vertically

            borderRadius: "10px", // Border radius
          },
        }}
      >
        <Modalmy
          iid={dataforId}
          status={dataforstatus}
          closeModal={closeModalFromParent}
        />
      </Modal>
    </>
  );
};

export default Dashboard3;
