// // import React from "react";
// // import axios from "axios";
// // import { useEffect, useState } from "react";
// // import { Scrollbars } from "react-custom-scrollbars-2";
// // import {
// //   LaptopOutlined,
// //   NotificationOutlined,
// //   LogoutOutlined,
// //   SettingOutlined,
// //   SearchOutlined,
// //   UploadOutlined,
// //   UserOutlined,
// //   VideoCameraOutlined,
// // } from "@ant-design/icons";

// // import "./PayrollPage.css";
// // import { Breadcrumb, Input, Layout, Menu, theme } from "antd";
// // import logoImage from "../../../assets/LOGO.png";
// // import SiderContentF from "../../../components/FreightModuleTrucker/SiderContentTrucker/SiderContentF";
// // import FeildDataF from "../../../components/FreightModuleTrucker/FeildDataTrucker/FeildDataTrucker";
// // //import LoginPage from '../LoginPage/LoginPage';
// // import { DataProvider } from "../../../Context/dataContext";
// // import { Link, Route, Routes } from "react-router-dom";
// // // import ItemNew from "../../components/FreightModule/ItemMaster/ItemNew";
// // // import WebSocket from "ws";

// // const { Header, Content, Sider } = Layout;
// // const { Item: MenuItem } = Menu;
// // const { Search } = Input;

// // // const ws = new WebSocket("ws://yzulu.com");
// // // console.log(ws);
// // const items1 = [
// //   {
// //     key: "notification",
// //     label: (
// //       <MenuItem key="notification">
// //         <Link to="/notification">
// //           <NotificationOutlined style={{ fontSize: 20, color: "#000" }} />
// //         </Link>
// //       </MenuItem>
// //     ),
// //     onClick: () => {
// //       // Handle click for Notification
// //       console.log("Notification clicked");
// //     },
// //   },
// //   {
// //     key: "setting",
// //     label: (
// //       <MenuItem key="setting">
// //         <Link to="/setting">
// //           <SettingOutlined style={{ fontSize: 20, color: "#000" }} />
// //         </Link>
// //       </MenuItem>
// //     ),
// //     onClick: () => {
// //       // Handle click for Setting
// //       console.log("Setting clicked");
// //     },
// //   },
// //   {
// //     key: "profile",
// //     label: (
// //       <MenuItem key="profile">
// //         <Link to="/trucker/profilet">
// //           <UserOutlined style={{ fontSize: 20, color: "#000" }} />
// //         </Link>
// //       </MenuItem>
// //     ),
// //     onClick: () => {
// //       // Handle click for Profile
// //       console.log("Profile clicked");
// //     },
// //   },
// // ];
// // console.log("manoj");
// // const FreightModule = (props) => {
// //   const [notification, setNotification] = useState("");
// //   const [websocketMessage, setWebsocketMessage] = useState("");
// //   const ws = new WebSocket("ws://yzulu.com:8080");
// //   console.log(props);
// //   const FreightData = {
// //     URL: props.url,
// //     analysis: props.analysis,
// //     danlysis: props.danlysis,
// //     myload: props.myload,
// //   };

// //   // useEffect(() => {
// //   //   console.log("hi");
// //   //   axios
// //   //     .get(props.url + "/api/v1/dashboard/tobid", { withCredentials: true })
// //   //     .then((response) => {
// //   //       console.log(response);
// //   //       props.setDanlysis(response.data.data);
// //   //     })

// //   //     .catch((err) => {
// //   //       console.log(err.response);
// //   //     });
// //   // }, []);

// //   // useEffect(() => {
// //   //   const interval = setInterval(() => {
// //   //     axios
// //   //       .get(props.url + "/api/v1/dashboard/loads", { withCredentials: true })
// //   //       .then((response) => {
// //   //         console.log(response);
// //   //         props.setAnalysis(response.data.data);
// //   //       })

// //   //       .catch((err) => {
// //   //         console.log(err.response);
// //   //       });
// //   //   }, 80000);

// //   //   return () => clearInterval(interval);
// //   // }, []);

// //   // useEffect(() => {
// //   //   axios
// //   //     .get(props.url + "/api/v1/dashboard/loads", { withCredentials: true })
// //   //     .then((response) => {
// //   //       console.log(response);
// //   //       props.setAnalysis(response.data.data);
// //   //     })
// //   //     .catch((err) => {
// //   //       console.log(err.response);
// //   //     });
// //   // }, []);
// //   const handleWebSocketMessage = (event) => {
// //     const message = event.data;
// //     console.log("Received message from WebSocket:", message);

// //     // Update the state with the received message
// //     setWebsocketMessage(message);

// //     // Check the type of message and handle it accordingly
// //     // if (message.channel === "new_data_channel") {
// //     //   // Handle the notification payload as needed
// //     //   setNotification(message.payload);
// //     // }
// //   };
// //   useEffect(() => {
// //     ws.addEventListener("open", () => {
// //       console.log("WebSocket connection is open.");
// //     });
// //     ws.addEventListener("message", handleWebSocketMessage);

// //     // Clean up the event listener when the component unmounts
// //     return () => {
// //       ws.removeEventListener("message", handleWebSocketMessage);
// //     };
// //   }, []);
// //   useEffect(() => {
// //     // Add event listeners and WebSocket connection here

// //     return () => {
// //       // Cleanup: Remove event listeners and close WebSocket connection
// //       ws.removeEventListener("message", handleWebSocketMessage);
// //       ws.close();
// //     };
// //   }, []);
// //   useEffect(() => {
// //     // This useEffect will be triggered whenever websocketMessage changes
// //     if (websocketMessage === "New data inserted") {
// //       // Perform any actions or updates you need when a new message is received
// //       // For example, you can refresh the component or update some state here
// //       // You can add your own logic to handle the refresh or update
// //       console.log("Refresh or update component here");
// //     }
// //   }, [websocketMessage]);

// //   const {
// //     token: { colorBgContainer },
// //   } = theme.useToken();

// //   return (
// //     <Layout>
// //       <Header
// //         style={{
// //           background: "linear-gradient(to right, #F0E5F3, #FFFFFF)",
// //           padding: "0rem",
// //           height: "100%",
// //         }}
// //       >
// //         <div
// //           style={{
// //             display: "flex",
// //             alignItems: "center",
// //             justifyContent: "space-between",
// //           }}
// //         >
// //           {/* Insert your logo here */}
// //           <img src={logoImage} style={{ height: "24px", width: "192px" }} />
// //           {/* <div style={{ flex: '1', textAlign: 'center', display: 'flex', justifyContent: 'center' }}>
// //             <Search
// //               placeholder="Search"
// //               onSearch={(value) => {
// //                 console.log(value);
// //               }}
// //               style={{ maxWidth: '400px', backgroundColor: '#F0E5F3' }}
// //             />
// //           </div> */}
// //           <div style={{ marginLeft: "auto", marginRight: "20px" }}>
// //             <Menu theme="" mode="horizontal">
// //               {items1.map((item) => (
// //                 <MenuItem key={item.key} onClick={item.onClick}>
// //                   {item.label}
// //                 </MenuItem>
// //               ))}
// //             </Menu>
// //           </div>
// //         </div>
// //       </Header>
// //       <Layout>
// //         <Sider
// //           className="sidebar"
// //           style={{ background: colorBgContainer, width: "10rem" }}
// //         >
// //           <SiderContentF url={props.url} />
// //         </Sider>
// //         <Layout
// //           style={{
// //             padding: "3.5rem 3rem 1rem 3rem",
// //             background: "#F0E5F3",
// //             width: "100%",
// //           }}
// //         >
// //           <DataProvider value={FreightData}>
// //             <Content
// //               style={{
// //                 padding: "0rem 1rem 1rem 1rem",
// //                 minHeight: "45rem",
// //                 background: colorBgContainer,
// //                 overflow: "auto",
// //               }}
// //             >
// //               <FeildDataF />
// //             </Content>
// //           </DataProvider>
// //         </Layout>
// //       </Layout>
// //     </Layout>
// //   );
// // };

// // export default FreightModule;

// import React from "react";
// import axios from "axios";
// import { useEffect, useState } from "react";
// import { Scrollbars } from "react-custom-scrollbars-2";
// import {
//   LaptopOutlined,
//   NotificationOutlined,
//   LogoutOutlined,
//   SettingOutlined,
//   SearchOutlined,
//   UploadOutlined,
//   UserOutlined,
//   VideoCameraOutlined,
// } from "@ant-design/icons";

// import "./PayrollPage.css";
// import { Breadcrumb, Input, Layout, Menu, theme } from "antd";
// import logoImage from "../../../assets/LOGO.png";
// import SiderContentF from "../../../components/FreightModuleTrucker/SiderContentTrucker/SiderContentF";
// import FeildDataF from "../../../components/FreightModuleTrucker/FeildDataTrucker/FeildDataTrucker";
// //import LoginPage from '../LoginPage/LoginPage';
// import { DataProvider } from "../../../Context/dataContext";
// import { Link, Route, Routes } from "react-router-dom";
// // import ItemNew from "../../components/FreightModule/ItemMaster/ItemNew";
// // import WebSocket from "ws";

// const { Header, Content, Sider } = Layout;
// const { Item: MenuItem } = Menu;
// const { Search } = Input;

// // const ws = new WebSocket("ws://yzulu.com");
// // console.log(ws);
// const items1 = [
//   {
//     key: "notification",
//     label: (
//       <MenuItem key="notification">
//         <Link to="/notification">
//           <NotificationOutlined style={{ fontSize: 20, color: "#000" }} />
//         </Link>
//       </MenuItem>
//     ),
//     onClick: () => {
//       // Handle click for Notification
//       console.log("Notification clicked");
//     },
//   },
//   {
//     key: "setting",
//     label: (
//       <MenuItem key="setting">
//         <Link to="/setting">
//           <SettingOutlined style={{ fontSize: 20, color: "#000" }} />
//         </Link>
//       </MenuItem>
//     ),
//     onClick: () => {
//       // Handle click for Setting
//       console.log("Setting clicked");
//     },
//   },
//   {
//     key: "profile",
//     label: (
//       <MenuItem key="profile">
//         <Link to="/trucker/profilet">
//           <UserOutlined style={{ fontSize: 20, color: "#000" }} />
//         </Link>
//       </MenuItem>
//     ),
//     onClick: () => {
//       // Handle click for Profile
//       console.log("Profile clicked");
//     },
//   },
// ];
// console.log("manoj");
// const FreightModule = (props) => {
//   const [notification, setNotification] = useState("");
//   const [websocketMessage, setWebsocketMessage] = useState("");
//   const ws = new WebSocket("ws://localhost:8080");
//   console.log(props);
//   const FreightData = {
//     URL: props.url,
//     analysis: props.analysis,
//     danlysis: props.danlysis,
//     myload: props.myload,
//     check: props.check,
//     setCheck: props.setCheck,
//   };

//   // useEffect(() => {
//   //   console.log("hi");
//   //   axios
//   //     .get(props.url + "/api/v1/dashboard/tobid", { withCredentials: true })
//   //     .then((response) => {
//   //       console.log(response);
//   //       props.setDanlysis(response.data.data);
//   //     })

//   //     .catch((err) => {
//   //       console.log(err.response);
//   //     });
//   // }, []);

//   // useEffect(() => {
//   //   const interval = setInterval(() => {
//   //     axios
//   //       .get(props.url + "/api/v1/dashboard/loads", { withCredentials: true })
//   //       .then((response) => {
//   //         console.log(response);
//   //         props.setAnalysis(response.data.data);
//   //       })

//   //       .catch((err) => {
//   //         console.log(err.response);
//   //       });
//   //   }, 80000);

//   //   return () => clearInterval(interval);
//   // }, []);

//   // useEffect(() => {
//   //   axios
//   //     .get(props.url + "/api/v1/dashboard/loads", { withCredentials: true })
//   //     .then((response) => {
//   //       console.log(response);
//   //       props.setAnalysis(response.data.data);
//   //     })
//   //     .catch((err) => {
//   //       console.log(err.response);
//   //     });
//   // }, []);
//   const handleWebSocketMessage = (event) => {
//     const message = event.data;
//     console.log("Received message from WebSocket:", message);

//     // Update the state with the received message
//     setWebsocketMessage(message);

//     // Check the type of message and handle it accordingly
//     // if (message.channel === "new_data_channel") {
//     //   // Handle the notification payload as needed
//     //   setNotification(message.payload);
//     // }
//   };
//   useEffect(() => {
//     ws.addEventListener("open", () => {
//       console.log("WebSocket connection is open.");
//     });
//     ws.addEventListener("message", handleWebSocketMessage);

//     // Clean up the event listener when the component unmounts
//     return () => {
//       ws.removeEventListener("message", handleWebSocketMessage);
//     };
//   }, []);
//   useEffect(() => {
//     // Add event listeners and WebSocket connection here

//     return () => {
//       // Cleanup: Remove event listeners and close WebSocket connection
//       ws.removeEventListener("message", handleWebSocketMessage);
//       ws.close();
//     };
//   }, []);
//   useEffect(() => {
//     // This useEffect will be triggered whenever websocketMessage changes
//     if (websocketMessage === "New data inserted") {
//       // Perform any actions or updates you need when a new message is received
//       // For example, you can refresh the component or update some state here
//       // You can add your own logic to handle the refresh or update
//       console.log("Refresh or update component here");
//     }
//   }, [websocketMessage]);

//   const {
//     token: { colorBgContainer },
//   } = theme.useToken();

//   return (
//     <Layout>
//       <Header
//         style={{
//           background: "linear-gradient(to right, #F0E5F3, #FFFFFF)",
//           padding: "0rem",
//           height: "100%",
//         }}
//       >
//         <div
//           style={{
//             display: "flex",
//             alignItems: "center",
//             justifyContent: "space-between",
//           }}
//         >
//           {/* Insert your logo here */}
//           <img src={logoImage} style={{ height: "24px", width: "192px" }} />
//           {/* <div style={{ flex: '1', textAlign: 'center', display: 'flex', justifyContent: 'center' }}>
//             <Search
//               placeholder="Search"
//               onSearch={(value) => {
//                 console.log(value);
//               }}
//               style={{ maxWidth: '400px', backgroundColor: '#F0E5F3' }}
//             />
//           </div> */}
//           <div style={{ marginLeft: "auto", marginRight: "20px" }}>
//             <Menu theme="" mode="horizontal">
//               Account Code:{props.acountCode}
//               {items1.map((item) => (
//                 <MenuItem key={item.key} onClick={item.onClick}>
//                   {item.label}
//                 </MenuItem>
//               ))}
//             </Menu>
//           </div>
//         </div>
//       </Header>
//       <Layout>
//         <Sider
//           className="sidebar"
//           style={{ background: colorBgContainer, width: "10rem" }}
//         >
//           <SiderContentF url={props.url} check={props.check} />
//         </Sider>
//         <Layout
//           style={{
//             padding: "3.5rem 3rem 1rem 3rem",
//             background: "#F0E5F3",
//             width: "100%",
//           }}
//         >
//           <DataProvider value={FreightData}>
//             <Content
//               style={{
//                 padding: "0rem 1rem 1rem 1rem",
//                 minHeight: "45rem",
//                 background: colorBgContainer,
//                 overflow: "auto",
//               }}
//             >
//               <FeildDataF />
//             </Content>
//           </DataProvider>
//         </Layout>
//       </Layout>
//     </Layout>
//   );
// };

// export default FreightModule;

// import React from "react";
// import axios from "axios";
// import { useEffect, useState } from "react";
// import { Scrollbars } from "react-custom-scrollbars-2";
// import {
//   LaptopOutlined,
//   NotificationOutlined,
//   LogoutOutlined,
//   SettingOutlined,
//   SearchOutlined,
//   UploadOutlined,
//   UserOutlined,
//   VideoCameraOutlined,
// } from "@ant-design/icons";

// import "./PayrollPage.css";
// import { Breadcrumb, Input, Layout, Menu, theme } from "antd";
// import logoImage from "../../../assets/LOGO.png";
// import SiderContentF from "../../../components/FreightModuleTrucker/SiderContentTrucker/SiderContentF";
// import FeildDataF from "../../../components/FreightModuleTrucker/FeildDataTrucker/FeildDataTrucker";
// //import LoginPage from '../LoginPage/LoginPage';
// import { DataProvider } from "../../../Context/dataContext";
// import { Link, Route, Routes } from "react-router-dom";
// // import ItemNew from "../../components/FreightModule/ItemMaster/ItemNew";
// // import WebSocket from "ws";

// const { Header, Content, Sider } = Layout;
// const { Item: MenuItem } = Menu;
// const { Search } = Input;

// // const ws = new WebSocket("ws://yzulu.com");
// // console.log(ws);
// const items1 = [
//   {
//     key: "notification",
//     label: (
//       <MenuItem key="notification">
//         <Link to="/notification">
//           <NotificationOutlined style={{ fontSize: 20, color: "#000" }} />
//         </Link>
//       </MenuItem>
//     ),
//     onClick: () => {
//       // Handle click for Notification
//       console.log("Notification clicked");
//     },
//   },
//   {
//     key: "setting",
//     label: (
//       <MenuItem key="setting">
//         <Link to="/setting">
//           <SettingOutlined style={{ fontSize: 20, color: "#000" }} />
//         </Link>
//       </MenuItem>
//     ),
//     onClick: () => {
//       // Handle click for Setting
//       console.log("Setting clicked");
//     },
//   },
//   {
//     key: "profile",
//     label: (
//       <MenuItem key="profile">
//         <Link to="/trucker/profilet">
//           <UserOutlined style={{ fontSize: 20, color: "#000" }} />
//         </Link>
//       </MenuItem>
//     ),
//     onClick: () => {
//       // Handle click for Profile
//       console.log("Profile clicked");
//     },
//   },
// ];
// console.log("manoj");
// const FreightModule = (props) => {
//   const [notification, setNotification] = useState("");
//   const [websocketMessage, setWebsocketMessage] = useState("");
//   const ws = new WebSocket("ws://yzulu.com:8080");
//   console.log(props);
//   const FreightData = {
//     URL: props.url,
//     analysis: props.analysis,
//     danlysis: props.danlysis,
//     myload: props.myload,
//   };

//   // useEffect(() => {
//   //   console.log("hi");
//   //   axios
//   //     .get(props.url + "/api/v1/dashboard/tobid", { withCredentials: true })
//   //     .then((response) => {
//   //       console.log(response);
//   //       props.setDanlysis(response.data.data);
//   //     })

//   //     .catch((err) => {
//   //       console.log(err.response);
//   //     });
//   // }, []);

//   // useEffect(() => {
//   //   const interval = setInterval(() => {
//   //     axios
//   //       .get(props.url + "/api/v1/dashboard/loads", { withCredentials: true })
//   //       .then((response) => {
//   //         console.log(response);
//   //         props.setAnalysis(response.data.data);
//   //       })

//   //       .catch((err) => {
//   //         console.log(err.response);
//   //       });
//   //   }, 80000);

//   //   return () => clearInterval(interval);
//   // }, []);

//   // useEffect(() => {
//   //   axios
//   //     .get(props.url + "/api/v1/dashboard/loads", { withCredentials: true })
//   //     .then((response) => {
//   //       console.log(response);
//   //       props.setAnalysis(response.data.data);
//   //     })
//   //     .catch((err) => {
//   //       console.log(err.response);
//   //     });
//   // }, []);
//   const handleWebSocketMessage = (event) => {
//     const message = event.data;
//     console.log("Received message from WebSocket:", message);

//     // Update the state with the received message
//     setWebsocketMessage(message);

//     // Check the type of message and handle it accordingly
//     // if (message.channel === "new_data_channel") {
//     //   // Handle the notification payload as needed
//     //   setNotification(message.payload);
//     // }
//   };
//   useEffect(() => {
//     ws.addEventListener("open", () => {
//       console.log("WebSocket connection is open.");
//     });
//     ws.addEventListener("message", handleWebSocketMessage);

//     // Clean up the event listener when the component unmounts
//     return () => {
//       ws.removeEventListener("message", handleWebSocketMessage);
//     };
//   }, []);
//   useEffect(() => {
//     // Add event listeners and WebSocket connection here

//     return () => {
//       // Cleanup: Remove event listeners and close WebSocket connection
//       ws.removeEventListener("message", handleWebSocketMessage);
//       ws.close();
//     };
//   }, []);
//   useEffect(() => {
//     // This useEffect will be triggered whenever websocketMessage changes
//     if (websocketMessage === "New data inserted") {
//       // Perform any actions or updates you need when a new message is received
//       // For example, you can refresh the component or update some state here
//       // You can add your own logic to handle the refresh or update
//       console.log("Refresh or update component here");
//     }
//   }, [websocketMessage]);

//   const {
//     token: { colorBgContainer },
//   } = theme.useToken();

//   return (
//     <Layout>
//       <Header
//         style={{
//           background: "linear-gradient(to right, #F0E5F3, #FFFFFF)",
//           padding: "0rem",
//           height: "100%",
//         }}
//       >
//         <div
//           style={{
//             display: "flex",
//             alignItems: "center",
//             justifyContent: "space-between",
//           }}
//         >
//           {/* Insert your logo here */}
//           <img src={logoImage} style={{ height: "24px", width: "192px" }} />
//           {/* <div style={{ flex: '1', textAlign: 'center', display: 'flex', justifyContent: 'center' }}>
//             <Search
//               placeholder="Search"
//               onSearch={(value) => {
//                 console.log(value);
//               }}
//               style={{ maxWidth: '400px', backgroundColor: '#F0E5F3' }}
//             />
//           </div> */}
//           <div style={{ marginLeft: "auto", marginRight: "20px" }}>
//             <Menu theme="" mode="horizontal">
//               {items1.map((item) => (
//                 <MenuItem key={item.key} onClick={item.onClick}>
//                   {item.label}
//                 </MenuItem>
//               ))}
//             </Menu>
//           </div>
//         </div>
//       </Header>
//       <Layout>
//         <Sider
//           className="sidebar"
//           style={{ background: colorBgContainer, width: "10rem" }}
//         >
//           <SiderContentF url={props.url} />
//         </Sider>
//         <Layout
//           style={{
//             padding: "3.5rem 3rem 1rem 3rem",
//             background: "#F0E5F3",
//             width: "100%",
//           }}
//         >
//           <DataProvider value={FreightData}>
//             <Content
//               style={{
//                 padding: "0rem 1rem 1rem 1rem",
//                 minHeight: "45rem",
//                 background: colorBgContainer,
//                 overflow: "auto",
//               }}
//             >
//               <FeildDataF />
//             </Content>
//           </DataProvider>
//         </Layout>
//       </Layout>
//     </Layout>
//   );
// };

// export default FreightModule;

import React from "react";
import axios from "axios";
import { useEffect, useState } from "react";
import { Scrollbars } from "react-custom-scrollbars-2";
import {
  LaptopOutlined,
  NotificationOutlined,
  LogoutOutlined,
  SettingOutlined,
  SearchOutlined,
  UploadOutlined,
  UserOutlined,
  VideoCameraOutlined,
} from "@ant-design/icons";

import "./PayrollPage.css";
import { Breadcrumb, Input, Layout, Menu, theme } from "antd";
import logoImage from "../../../assets/LOGO.png";
import SiderContentF from "../../../components/FreightModuleTrucker/SiderContentTrucker/SiderContentF";
import FeildDataF from "../../../components/FreightModuleTrucker/FeildDataTrucker/FeildDataTrucker";
//import LoginPage from '../LoginPage/LoginPage';
import { DataProvider } from "../../../Context/dataContext";
import { Link, Route, Routes } from "react-router-dom";
// import ItemNew from "../../components/FreightModule/ItemMaster/ItemNew";
// import WebSocket from "ws";

const { Header, Content, Sider } = Layout;
const { Item: MenuItem } = Menu;
const { Search } = Input;

// const ws = new WebSocket("ws://yzulu.com");
// console.log(ws);
const items1 = [
  // {
  //   key: "notification",
  //   label: (
  //     <MenuItem key="notification">
  //       <Link to="/notification">
  //         <NotificationOutlined style={{ fontSize: 20, color: "#000" }} />
  //       </Link>
  //     </MenuItem>
  //   ),
  //   onClick: () => {
  //     // Handle click for Notification
  //     console.log("Notification clicked");
  //   },
  // },
  // {
  //   key: "setting",
  //   label: (
  //     <MenuItem key="setting">
  //       <Link to="/setting">
  //         <SettingOutlined style={{ fontSize: 20, color: "#000" }} />
  //       </Link>
  //     </MenuItem>
  //   ),
  //   onClick: () => {
  //     // Handle click for Setting
  //     console.log("Setting clicked");
  //   },
  // },
  {
    key: "profile",
    label: (
      <MenuItem key="profile">
        <Link to="/trucker/profilet">
          <UserOutlined style={{ fontSize: 20, color: "#000" }} />
        </Link>
      </MenuItem>
    ),
    onClick: () => {
      // Handle click for Profile
      console.log("Profile clicked");
    },
  },
];
console.log("manoj");
const FreightModule = (props) => {
  const [notification, setNotification] = useState("");
  const [websocketMessage, setWebsocketMessage] = useState("");
  const [nameC, setNameC] = useState(null);
  // const ws = new WebSocket("ws://localhost:8080");
  console.log(props);
  const FreightData = {
    URL: props.url,
    analysis: props.analysis,
    danlysis: props.danlysis,
    myload: props.myload,
    check: props.check,
    acountCode: props.acountCode,
    setCheck: props.setCheck,
    mail: props.mail
  };
  let n;
  console.log(FreightData);
  console.log(FreightData.acountCode);
  if (FreightData.acountCode == null) {
    console.log("NO");
  } else {
    n = FreightData.acountCode;
  }
  console.log(n);
  console.log(FreightData.acountCode);
  // useEffect(() => {
  //   console.log("hi");
  //   axios
  //     .get(props.url + "/api/v1/dashboard/tobid", { withCredentials: true })
  //     .then((response) => {
  //       console.log(response);
  //       props.setDanlysis(response.data.data);
  //     })

  //     .catch((err) => {
  //       console.log(err.response);
  //     });
  // }, []);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     axios
  //       .get(props.url + "/api/v1/dashboard/loads", { withCredentials: true })
  //       .then((response) => {
  //         console.log(response);
  //         props.setAnalysis(response.data.data);
  //       })

  //       .catch((err) => {
  //         console.log(err.response);
  //       });
  //   }, 80000);

  //   return () => clearInterval(interval);
  // }, []);

  // useEffect(() => {
  //   axios
  //     .get(props.url + "/api/v1/dashboard/loads", { withCredentials: true })
  //     .then((response) => {
  //       console.log(response);
  //       props.setAnalysis(response.data.data);
  //     })
  //     .catch((err) => {
  //       console.log(err.response);
  //     });
  // }, []);
  // const handleWebSocketMessage = (event) => {
  //   const message = event.data;
  //   console.log("Received message from WebSocket:", message);

  //   // Update the state with the received message
  //   setWebsocketMessage(message);

  //   // Check the type of message and handle it accordingly
  //   // if (message.channel === "new_data_channel") {
  //   //   // Handle the notification payload as needed
  //   //   setNotification(message.payload);
  //   // }
  // };



  // useEffect(() => {
  //   ws.addEventListener("open", () => {
  //     console.log("WebSocket connection is open.");
  //   });
  //   ws.addEventListener("message", handleWebSocketMessage);

  //   // Clean up the event listener when the component unmounts
  //   return () => {
  //     ws.removeEventListener("message", handleWebSocketMessage);
  //   };
  // }, []);
  // useEffect(() => {
  //   // Add event listeners and WebSocket connection here

  //   return () => {
  //     // Cleanup: Remove event listeners and close WebSocket connection
  //     ws.removeEventListener("message", handleWebSocketMessage);
  //     ws.close();
  //   };
  // }, []);
  // useEffect(() => {
  //   // This useEffect will be triggered whenever websocketMessage changes
  //   if (websocketMessage === "New data inserted") {
  //     // Perform any actions or updates you need when a new message is received
  //     // For example, you can refresh the component or update some state here
  //     // You can add your own logic to handle the refresh or update
  //     console.log("Refresh or update component here");
  //   }
  // }, [websocketMessage]);



  useEffect(() => {
    
      axios
      .get(props.url + "/api/v1/commonThings/getCompanyName", { withCredentials: true })
      .then((response) => {
        console.log(response);
      // setNameC(response.data.data.getName.rows[0].account_name);
      let fetchedName = response.data.data.getName.rows[0].account_name;
        
      // Split the fetchedName string by spaces
      let nameParts = fetchedName.split(" ");

      // Take the first two words (or less if there are fewer than two words)
      let truncatedName = nameParts.slice(0, 2).join(" ");
      console.log(truncatedName," there is the name");
      
      setNameC(truncatedName);
      })

      .catch((err) => {
        console.log(err.response);
      });
  }, []);


































  const {
    token: { colorBgContainer },
  } = theme.useToken();

  return (
    <Layout>
      <Header
        style={{
          background: "linear-gradient(to right, #F0E5F3, #FFFFFF)",
          padding: "0rem",
          height: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {/* Insert your logo here */}
          <img src={logoImage} style={{ height: "24px", width: "192px" }} />
          {/* <div style={{ flex: '1', textAlign: 'center', display: 'flex', justifyContent: 'center' }}>
            <Search
              placeholder="Search"
              onSearch={(value) => {
                console.log(value);
              }}
              style={{ maxWidth: '400px', backgroundColor: '#F0E5F3' }}
            />
          </div> */}
          <div style={{ marginLeft: "auto", marginRight: "20px",  width:"25rem" }}>
            <Menu theme="" mode="horizontal">
              <div style={{ display:"flex", flexDirection:"row", justifyContent:"space-around", width:"40rem"}}>

                <span style={{ fontSize: "1.4rem", fontWeight: "bolder" }}>
                  Trucker ID:{n}

                </span>
                {nameC!=null? <div>
                Hello, {nameC}
              </div>:"Please Fill Your Profile" }
              </div>
              {items1.map((item) => (
                <MenuItem key={item.key} onClick={item.onClick}>
                  {item.label}
                </MenuItem>
              ))}
            </Menu>
          </div>
        </div>
      </Header>
      <Layout>
        <Sider
          width={150}
          className="sidebar"
          style={{ background: colorBgContainer, width: "10rem"}}
        >
          <SiderContentF url={props.url} check={props.check} />
        </Sider>
        <Layout
          style={{
            padding: "1rem 1rem 1rem 1rem",
            background: "#F0E5F3",
            width: "100%",
            // border:"2px solid red"
          }}
        >
          <DataProvider value={FreightData}>
            <Content
              style={{
                padding: "0rem 1rem 1rem 1rem",
                minHeight: "45rem",
                background: colorBgContainer,
                overflow: "auto",
              }}
            >
              <FeildDataF />
            </Content>
          </DataProvider>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default FreightModule;
