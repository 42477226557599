import React from 'react'
import { UilCheckCircle } from '@iconscout/react-unicons'
import { UilEdit } from '@iconscout/react-unicons'
import { useState } from 'react'
import "./payment.css"
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';
import { blue } from '@mui/material/colors';
import TextField from '@mui/material/TextField';
import GooglePayButton from '@google-pay/button-react';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import { PayPalButtons } from '@paypal/react-paypal-js';

export default function Payment() {
  const CLIENT_ID = 'test';
  return (
    <>

      <div className='outer'>

        <UilCheckCircle size="60" color="#531967"/>
        --------
        <UilCheckCircle size="60" color="#531967"/>
        --------
        <UilEdit size="60" color="#531967"/>
      </div>

      <div className='adiv' >
        <h1 style={{ color: '#531967', fontWeight: 'normal', marginLeft: '1.5rem', marginTop: '-1rem' }} >
          CHOOSE PAYMENT METHOD
        </h1>
        <h3 style={{ color: '#A476B5', fontWeight: 'normal', fontSize: '1.6rem', marginLeft: '2.6rem', marginTop: '-1.5rem' }} >
          Choose the payment method you prefer
        </h3>
        <select style={{
          marginTop: '2rem', width: '20rem', height: '3.5rem', marginLeft: '2rem', textAlign: 'center', border: '1px solid #ddd',
          backgroundColor: '#F2E0F8', fontFamily: 'Arial', fontSize: '1.5rem',boxShadow: '-2px 4px 6px rgba(0, 0, 0, 0.3)'
        }}>
          <option value="Load-Id">LOAD ID</option>
          {/* Add more options as needed */}
        </select>
      </div>

      <div style={{ width: "vw", height: "vh", display: "flex" }}>
        <div style={{ width: "20rem", height: "40rem" }} >
          <div className='paymentButtons' >
            <button type="button" class="btn" style={{
              backgroundColor: '#C5A7CF', height: '4rem', borderRadius: '0',
              border: '0px solid #dddd', fontWeight: 'bold',boxShadow: '-2px 4px 6px rgba(0, 0, 0, 0.3)'
            }} disabled >
              Prefered payment
            </button>

            <button type="button" class="btn" style={{
              backgroundColor: '#F0E5F3', height: '5rem', borderRadius: '0', border: '0px solid #ddd'
              , fontFamily: 'Arial, sans-serif',boxShadow: '-2px 4px 6px rgba(0, 0, 0, 0.3)',borderLeft: '5px solid #531967'
            }}>
              Credit/Debit Card
              <br />
              <p style={{ color: '#A476B5', fontWeight: 'normal' }}>Visa,Matercard,Rupay & more </p>
            </button>


            {/* <button type="button" class="btn" style={{
              background: 'linear-gradient(to bottom, #D9BFE0, #fff)', height: '4rem',
              borderRadius: '0', border: '1px solid #ddd', fontFamily: 'Arial, sans-serif',boxShadow: '-2px 4px 6px rgba(0, 0, 0, 0.3)'
            }}>
              Paypal
            </button> */}

<PayPalScriptProvider options={{ "client-id": CLIENT_ID }}>
         <PayPalButtons
            style={{
              layout: 'horizontal', // Use 'vertical' for vertical layout
              color: 'gold', // Choose the color, options: 'gold', 'blue', 'silver', 'white', 'black'
              shape: 'rect', // Options: 'rect', 'pill'
              label: 'pay', // Options: 'pay', 'checkout', 'buynow', 'paypal'
            }}
          //   createOrder={(data, actions) => {
          //     // Add your createOrder logic here
          //   }}
          //   onApprove={(data, actions) => {
          //     // Add your onApprove logic here
          //   }}
           />
      
    
    </PayPalScriptProvider>





            <button type="button" class="btn" style={{
              background: 'linear-gradient(to bottom, #D9BFE0, #fff)', height: '4rem',
              borderRadius: '0', border: '1px solid #ddd', fontFamily: 'Arial, sans-serif',boxShadow: '-2px 4px 6px rgba(0, 0, 0, 0.3)'
            }}>
              Apply Pay
            </button>
            <GooglePayButton
        environment="TEST"
        paymentRequest={{
          apiVersion: 2,
          apiVersionMinor: 0,
          allowedPaymentMethods: [
            {
              type: 'CARD',
              parameters: {
                allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
                allowedCardNetworks: ['MASTERCARD', 'VISA'],
              },
              tokenizationSpecification: {
                type: 'PAYMENT_GATEWAY',
                parameters: {
                  gateway: 'example',
                  gatewayMerchantId: 'exampleGatewayMerchantId',
                },
              },
            },
          ],
          merchantInfo: {
            merchantId: '12345678901234567890',
            merchantName: 'Demo Merchant',
          },
          transactionInfo: {
            totalPriceStatus: 'FINAL',
            totalPriceLabel: 'Total',
            totalPrice: '1',
            currencyCode: 'USD',
            countryCode: 'US',
          },
          shippingAddressRequired: true,
          callbackIntents: ['SHIPPING_ADDRESS', 'PAYMENT_AUTHORIZATION'],
        }}
        onLoadPaymentData={paymentRequest => {
          console.log('Success', paymentRequest);
        }}
        onPaymentAuthorized={paymentData => {
            console.log('Payment Authorised Success', paymentData)
            return { transactionState: 'SUCCESS'}
          }
        }
        onPaymentDataChanged={paymentData => {
            console.log('On Payment Data Changed', paymentData)
            return { }
          }
        }
        existingPaymentMethodRequired='false'
        buttonColor="default"
  buttonType="pay"
  buttonLocale="en"
  buttonSizeMode="fill"
  style={{width: 320, height: 55}}
      />  

            {/* <button type="button" class="btn" style={{
              background: 'linear-gradient(to bottom, #D9BFE0, #fff)', height: '4rem',
              borderRadius: '0', border: '1px solid #ddd', fontFamily: 'Arial, sans-serif',boxShadow: '-2px 4px 6px rgba(0, 0, 0, 0.3)'
            }}>
              Google Pay
            </button> */}

            <button type="button" class="btn" style={{
              background: 'linear-gradient(to bottom, #D9BFE0, #fff)', height: '4rem',
              borderRadius: '0', border: '1px solid #ddd', fontFamily: 'Arial, sans-serif',boxShadow: '-2px 4px 6px rgba(0, 0, 0, 0.3)'
            }}>
              Online Bank <br /> Transfer
            </button>

          </div>
        </div>

        <div style={{
          width: "40.1rem", height: "24.4rem", marginTop: "3rem", marginLeft: '4rem',boxShadow: '-2px 4px 6px rgba(0, 0, 0, 0.3)'
          , backgroundImage: 'linear-gradient(to bottom, #FBF0FF, #FFFFFF)'
        }}>

          <button
            style={{ width: '40rem', height: '5rem', border: 'gray', backgroundColor: '#D2B3DD', fontWeight: 'bold', fontSize: '2rem', marginTop: '0.5rem' }}>
            ENTER YOUR CARD DETAILS
          </button>
          <input
            type="text"
            placeholder="Card Number"
            style={{
              width: '30.7rem', height: '3.5rem', marginTop: '1.5rem', fontSize: '1.5rem', paddingLeft: '0.5rem', backgroundColor: '#F0E5F3', border: '0px solid #ccc',
              textAlign: 'center', fontWeight: 'bold', color: 'black', borderradius: '5rem',boxShadow: '-2px 3px 3px rgba(0, 0, 0, 0.3)'
            }}
          />
          <div style={{marginLeft:'1.6rem',marginRight:'1.6rem',marginTop:'2rem'}} class="row justify-content-around">
            <div style={{border: '0px solid red', height: '3.5rem', backgroundColor: '#F0E5F3', display: 'flex', alignItems: 'center', justifyContent: 'center' 
          , fontWeight: 'bold', color: 'black',boxShadow: '-2px 3px 3px rgba(0, 0, 0, 0.3)'}} class="col-4">
              EXPIRY (MM/YY)
            </div>
            <div  style={{border: '0px solid red', height: '3.5rem', backgroundColor: '#F0E5F3', display: 'flex', alignItems: 'center', justifyContent: 'center' 
          , fontWeight: 'bold', color: 'black',boxShadow: '-2px 3px 3px rgba(0, 0, 0, 0.3)'}} class="col-4">
              CVV
            </div>
          </div>
          <button
            style={{ width: '21rem', height: '4.2rem', border: 'gray', backgroundColor: '#531967', fontWeight: 'bold', fontSize: '1.8rem',
             marginTop: '3.7rem' , borderRadius: '1rem',color: 'white',boxShadow: '-2px 3px 3px rgba(0, 0, 0, 0.3)'}}>
            Pay $200
          </button>

        </div>

      </div>

    </>
  )
}
