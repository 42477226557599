// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { UilUserCircle } from '@iconscout/react-unicons'




const IconPage = () => {
  return (
    <>
    <div className="d-flex flex-column align-items-start ">
      <h1 style={{ margin: "auto", color:"#A769A7"}}>PROFILE</h1>
     <UilUserCircle size="90" color="#A769A7" style={{ margin: "auto" }} />
      {/* <FontAwesomeIcon icon="fa-regular fa-user" size="2xl" /> */}
    </div>
    <br></br>
    </>
  )
}



export default IconPage