import React, { useState, useEffect } from "react";
import { Skeleton } from "antd";
import Template from "./template";

const Ztofifteen = () => {
  

  return (
    <>
      
        <Template name={"Zero to Fifteen"} case={1} />
     
    </>
  );
};

export default Ztofifteen;
