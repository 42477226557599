

// import React, { useEffect } from 'react';
// import './amap.css';
// import 'leaflet.markercluster';
// import L from 'leaflet'; // Import the Leaflet library

// const Amap = () => {
//   // Sample location data (latitude and longitude)
//   const locations = [
//     { name: 'Location 1', lat: 40.7128, lng: -74.0060 },
//     { name: 'Location 2', lat: 34.0522, lng: -118.2437 },
//     // {name:'NSP',lat:28.6914066, lng:77.150548}
//     // Add more locations as needed
//   ];

//   useEffect(() => {
//     // Initialize the map
//     const map = L.map('map').setView([locations[0].lat, locations[0].lng], 4);

//     // Add a tile layer to the map
//     L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png').addTo(map);

//     // Marker cluster group for efficient handling of multiple markers
//     const markers = L.markerClusterGroup();

//     // Add markers for each location
//     locations.forEach(location => {
//       const marker = L.marker([location.lat, location.lng]);
//       marker.bindPopup(location.name);
//       markers.addLayer(marker);
//     });

//     // Add marker cluster group to the map
//     map.addLayer(markers);
//   }, []); // Empty dependency array to run the effect only once

//   return (
//     <>

//       <div id="map" style={{ width: '100%', height: '400px' }}></div>
//       </>

//   );
// };

// export default Amap;


import React, { useEffect } from 'react';
import './amap.css';
import 'leaflet';
import 'leaflet.markercluster/dist/leaflet.markercluster';
import L from 'leaflet';

const Amap = () => {
  const locations = [
    { name: 'Location 1', lat: 40.7128, lng: -74.0060 },
    { name: 'Location 2', lat: 34.0522, lng: -118.2437 },
    // Add more locations as needed
  ];

  useEffect(() => {
    const map = L.map('map').setView([locations[0].lat, locations[0].lng], 4);

    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png').addTo(map);

    const markers = L.markerClusterGroup();

    locations.forEach(location => {
      const marker = L.marker([location.lat, location.lng]);
      marker.bindPopup(location.name);
      markers.addLayer(marker);
    });

    map.addLayer(markers);
  }, []);

  return (
    <>
      <div id="map" style={{ width: '100%', height: '400px' }}></div>
    </>
  );
};

export default Amap;
