import React from 'react'
import './whatsay.css'


const WhatSay = (props) => {
  return (
    <>
      <div className="whatsaybox">
        <p style={{ padding: "1rem" }}>
          {props.text}
        </p>

        <br />

        <div className="ohman">
          <div className='fortheimginthediv' ><img className='thisismyimgforthesay'  src={props.pic} alt="" /></div>
          <div style={{ marginLeft: '2rem' }}>

            <h4 >{props.type}</h4>
            <span>{props.subtype}</span>
          </div>
        </div>

        <br /><br />


      </div>
    </>

  )
}

export default WhatSay