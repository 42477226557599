import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import FreightTable from "../../FreightTable/FreightTable";
import DataContext from "../../../Context/dataContext";

const AllLoads = () => {
  const [row, setRow] = useState();
  const [columns, setColumns] = useState();
  const employeeData = useContext(DataContext);

  


  useEffect(() => {
    // pending-bids
    console.log("allLoads testing is here");
    axios
      .get(employeeData.URL + "/api/v1/admin/allloads", {
        withCredentials: true,
      })
      .then((response) => {
        // setData(response.data.data.detailofmovingloads.rows);
        console.log(response);

        setRow((rows) => {
          let newRows = response.data.data.Loads.rows.map((row, index) => {
            // Format the 'Load Date' to display only the date part
            let formattedDate = new Date(row['load_date']).toLocaleDateString('en-US');
            
            return {
              id: index + 1,
              ...row,
              load_date: formattedDate, // Assuming 'load_date' is the field you want to format
            };
          });
          return newRows;
        });

        // setRow((rows) => {
        //   let newRows = response.data.data.Loads.rows.map((row, index) => {
        //     return {
        //       id: index + 1,
        //       ...row,
        //     };
        //   });
        //   return newRows;
        // });
      

        setColumns((columns) => {
          let newCols = response.data.data.Loads.fields.map((col) => {
            let headerName =
              col.name === "origin_city_cd"
                ? "Origin City"
                : col.name === "jhg"
                  ? "mnb"
                  : col.name === "des_city_cd"
                    ? "Des City"
                    : col.name === "origin_zip_cd"
                    ? "Origin Zip"
                    : col.name === "des_zip_cd"
                    ? "Des Zip"
                  : col.name
                      .split("_")
                      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                      .join(" ");
  
            return {
              field: col.name,
              headerName: headerName,
              width: 150,
            };
          });
  
          return newCols;
        });
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  return (
    <>
      <div>
        <h1>All Loads</h1>
      </div>

      {row && columns.length > 0 ? (
        <div style={{ display: "inline-block" }}>
          <FreightTable data={row} columns={columns} />
        </div>
      ) : (
        <div>No matching results found.</div>
      )}
    </>
  );
};

export default AllLoads;
