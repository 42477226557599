import React from "react";
// import { LoadTemplate } from "../ComponentTemplate/LoadTemplate";
import Template from "./template";

const MtM = () => {
  return (
    <>
      <Template name={"More than Months"} case={4} />
    </>
  );
};

export default MtM;
