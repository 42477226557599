import React from 'react'
import './head.css'


const Headbox = (props) => {
    return (
        <>

            <div className='headbox' style={{
             width: '10rem',borderRadius:"1rem", boxShadow: '-2px 4px 6px rgba(0, 0, 0, 0.3)',
                height: '8rem', marginTop: '0.7rem', background: 'linear-gradient(to bottom,rgb(153, 79, 179),white)', overflow:"hidden"
            }}>
                <div style={{}} className='inner'>
                    <span style={{marginLeft:"0.4rem"}} id='cominghead'>
                    {props.data}

                    </span>

                    <span style={{ alignContent:"end", alignItems:"end", fontSize:"1rem", marginLeft:"0.4rem", display:"flex", justifyContent:"center", flexDirection:"row"}} className='tle'>{props.abc}</span>

                </div>



            </div>


        </>
    )
}

export default Headbox