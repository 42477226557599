import React from "react";
import { Row, Col, Skeleton, Tooltip } from "antd";
import axios from "axios";
import "./tracking.css";
import { useState, useEffect, useContext } from "react";
import { RiUserSearchFill } from "react-icons/ri";
import classes from "../Pages.module.css";
import DataContext from "../../../Context/dataContext";
import FreightTable from "../../FreightTable/FreightTable";
import { AiFillDelete } from "react-icons/ai";
import Amap from "../MyMap/amap";
import { UilSearch } from "@iconscout/react-unicons";
import { Link } from "react-router-dom";

function Tracking() {
  const employeeData = useContext(DataContext);
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState(null);
  const [drows, setDRows] = useState([]);

  useEffect(() => {
    setDRows(null);
    setRows(null);
    setColumns([]);

    axios
      .get(employeeData.URL + `/api/v1/load/ongoing-load-view`, {
        withCredentials: true,
      })
      .then((response) => {
        console.log(response);

        setColumns((columns) => {
          let newCols = response.data.data.loads.fields.map((col) => {
            return {
              field: col.name,
              headerName: col.name.split("_").join(" ").toLowerCase(),
              width: 150,
            };
          });

          const newNewCols = [...newCols];
          return newNewCols;
        });

        setRows((rows) => {
          let newRows = response.data.data.loads.rows.map((row, index) => {
            if (row.load_status === null) {
              row.load_status = "Tracking";
            }
            return {
              id: index + 1,
              ...row,
              load_status:
                row.load_status === "Tracking" ? (
                  <span style={{ color: "yellow", fontWeight: "bold" }}>
                    Tracking
                  </span>
                ) : (
                  row.load_status
                ),
            };
          });
          return newRows;
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const deleteHandler = (event, index, newrows, code) => {
    console.log(index);
    console.log(code);

    setRows(null);
    axios
      .delete(employeeData.URL + "/api/v1/items/" + code.toString(), {
        withCredentials: true,
      })
      .then((response) => {
        console.log(response);
        const rowvals = newrows.filter((row) => row["item_code"] !== code);
        setRows((rows) => {
          let newDRows = rowvals.map((row, index) => {
            return {
              D: (
                <Tooltip placement="bottom" title="Delete" color="red">
                  <Link to="#" style={{ color: "red", fontWeight: "bolder" }}>
                    <AiFillDelete
                      onClick={(event) =>
                        deleteHandler(event, index, rowvals, row["item_code"])
                      }
                      style={{
                        color: "red",
                        fontWeight: "bolder",
                        fontSize: "0.8rem",
                      }}
                    />
                  </Link>
                </Tooltip>
              ),
              V: (
                <Tooltip placement="bottom" title="View" color="#1777C4">
                  <Link
                    to={
                      "/sales/itemMaster/item-details-view/" + row["item_code"]
                    }
                    style={{ color: "#1777C4", fontWeight: "bolder" }}
                  >
                    <RiUserSearchFill
                      style={{
                        color: "#1777C4",
                        fontWeight: "bolder",
                        fontSize: "0.9rem",
                      }}
                    />
                  </Link>
                </Tooltip>
              ),

              SNO: index + 1,
              ...row,
            };
          });
          return newDRows;
        });
      })
      .catch((error) => {
        setRows((rows) => {
          let newDRows = newrows.map((row, index) => {
            return {
              D: (
                <Tooltip placement="bottom" title="Delete" color="red">
                  <Link to="#" style={{ color: "red", fontWeight: "bolder" }}>
                    <AiFillDelete
                      onClick={(event) =>
                        deleteHandler(event, index, newrows, row["item_code"])
                      }
                      style={{
                        color: "red",
                        fontWeight: "bolder",
                        fontSize: "0.8rem",
                      }}
                    />
                  </Link>
                </Tooltip>
              ),
              V: (
                <Tooltip placement="bottom" title="View" color="#1777C4">
                  <Link
                    to={
                      "/sales/itemMaster/item-details-view/" + row["item_code"]
                    }
                    style={{ color: "#1777C4", fontWeight: "bolder" }}
                  >
                    <RiUserSearchFill
                      style={{
                        color: "#1777C4",
                        fontWeight: "bolder",
                        fontSize: "0.9rem",
                      }}
                    />
                  </Link>
                </Tooltip>
              ),

              SNO: index + 1,
              ...row,
            };
          });
          return newDRows;
        });
        console.log(error);
      });
  };

  return (
    <>
      <h1 style={{ color: "#A769A7" }}>
       Active Shipment
      </h1>
      {/* <h4 style={{ color: "rgb(206 146 223)" }}>Choose a load to track</h4> */}

      <div className="table" style={{ display: "inline-block", width: "100%" }}>
        {rows && columns.length > 0 ? (
          <div style={{ display: "inline-block" }}>
            <FreightTable data={rows} columns={columns} />
          </div>
        ) : (
          <div>No matching results found.</div>
        )}
      </div>

      <Amap />
    </>
  );
}

export default Tracking;
