
import "./Aform.css";
import { useState, useEffect, useContext } from "react";
import { Button, message } from "antd";
import DataContext from "../../../Context/dataContext";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
const BiddingForm = (props) => {
  // console.log(props);
  const employeeData = useContext(DataContext);
  var data = props.data ? props.data : null;
  const [loadAmount, setLoadAmount] = useState("");
  const [estimatedDeliveryTime, setEstimatedDeliveryTime] = useState("");
  const [countdown, setCountdown] = useState(0);

  const [bidValue, setBidValue] = useState("B"); // Default value is "B" for bidding
  const [checkT, setCheckT] = useState(false)

  const navigate = useNavigate();

  const calculateCountdown = () => {
    const endTime = new Date(data.bidding_end_time).getTime();
    // console.log(endTime);
    const startTime = new Date(data.load_post_time).getTime();
    // console.log(startTime);
    const timeDifference = endTime - startTime;
    const currentTime = new Date().getTime();
    const remainingTime = Math.max(timeDifference - currentTime + startTime, 0);
    setCountdown(remainingTime);

    if (remainingTime === 0) {
      // Redirect to the previous page and show an alert
      alert("The bidding has ended. You cannot bid on this load.");
      navigate(-1); // Go back to the previous page
    }
  };

  useEffect(() => {
    calculateCountdown();
    const interval = setInterval(() => {
      calculateCountdown();
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    // Check if the user is bidding again
    const isBiddingAgain = props.extraData.some(
      (extra) => extra.load_id === props.data.load_id
    );

    if (isBiddingAgain) {
      setBidValue("E"); // Change the value to "E" for editing
    }
  }, [props.extraData, props.data.load_id]);

  useEffect(() => {
    // axios.get
    console.log(props.extraData);
    let l = props.extraData.length;

    for (let i = 0; i < l; i++) {
      if (props.extraData[i].load_id == props.data.load_id) {
        console.log("You are Bidding Again");
        setCheckT(true)
      } else {
        console.log("First time han");
      }
    }
  }, []);

  const updateLowestBidStatus = async () => {
    try {
      const response = await axios.post(
        employeeData.URL + "/api/v1/load/update-lowest-bid-status",
        {},
        {
          withCredentials: true,
        }
      );

      if (response.data.status === "success") {
        console.log("Lowest bid status updated.");
      } else {
        console.error("Failed to update lowest bid status.");
      }
    } catch (error) {
      console.error("Error updating lowest bid status:", error);
    }
  };

  // ... Rest of your component code ...

  const load_post_time = props.data.load_post_time;
  // console.log(load_post_time);
  const bidding_end_time = props.data.bidding_end_time;

  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600000);
    const minutes = Math.floor((seconds % 3600000) / 60000);
    const formattedTime = `${hours} HOURS ${minutes} MINUTES LEFT`;
    return formattedTime;
  };

  const handleBid = async () => {
    if (!loadAmount || !estimatedDeliveryTime) {
      message.error("Please fill the required fields first.");
      return;
    }

    const postData = {
      load_id: data ? data.load_id : "",
      load_date: data ? data.load_date : "",
      origin_address: data ? data.origin_address : "",
      origin_state: data ? data.origin_state_cd : "",
      origin_city: data ? data.origin_city_cd : "",
      origin_zip: data ? data.origin_zip_cd : "",
      destination_address: data ? data.des_address : "",
      destination_state: data ? data.des_state_cd : "",
      destination_city: data ? data.des_city_cd : "",
      destination_zip: data ? data.des_zip_cd : "",
      load_amount: loadAmount,
      estimated_delivery_time: estimatedDeliveryTime,
    };
    if (!checkT) {
      try {
        await axios
          .post(employeeData.URL + "/api/v1/loadt/create-bid", postData, {
            withCredentials: true,
          })
          .then((response) => {
            console.log(" thisis the response",response);
            if (response.data.status === "success") {
              message.success("Your bidding for the load is done!!", 3);
              // alert(" testing ")
              navigate("/trucker/dashboard"); 
              // Refresh the page
              window.location.reload();
            } else {
              message.error("Bidding submission failed. Please try again.");
            }
          });
        } catch (error) {
          console.error(error);
        }
      } else {
        
        try {
          const additionalTextData = "re";
          
          await axios
          .post(employeeData.URL + "/api/v1/loadt/create-bid", {
            ...postData,
            additionalTextData: additionalTextData,
          }, {
            withCredentials: true,
          })
          .then((response) => {
            if (response.data.status === "success") {
              message.success("Your bidding for the load is done!!", 3);
              alert(" testing ")
              navigate("/trucker/dashboard");
              // Refresh the page
              window.location.reload();
            } else {
              message.error("Bidding submission failed. Please try again.");
            }
          });
      } catch (error) {
        console.error(error);
      }

    }
  };



  return (
    <>
      <div style={{ padding: "0rem 4rem 0rem 4rem" }}>
        <h1  style={{ color: "#A769A7", fontWeight: "bold", fontSize:"3rem" }}>Bidding</h1>

        <div className="firr">
          <label
            htmlFor="load_id"
            style={{ display: "block", textAlign: "left" }}
          >
            Load Id
          </label>
          <input
            type="text"
            placeholder="Load id:"
            value={data ? data.load_id : ""}
            readOnly
          />
          <br />
          <label
            htmlFor="load_id"
            style={{ display: "block", textAlign: "left" }}
          >
            Load Date
          </label>
          <input
            type="text"
            placeholder="Load Date:"
            value={data ? data.load_date : ""}
            readOnly
          />
        </div>
        <br />

        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            flexDirection: "column",
          }}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ display: "flex" }}>
              <label htmlFor="origin" style={{ fontWeight: "bolder" }}>
                ORIGIN
              </label>
            </div>
            <input
              type="text"
              name="origin"
              placeholder="origin"
              value={data ? data.origin_address : ""}
              readOnly
            />
            <br />
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <label
                htmlFor="load_id"
                style={{ display: "block", textAlign: "left" }}
              >
                State
              </label>
              <input
                type="text"
                name="state"
                placeholder="state:"
                value={data ? data.origin_state_cd : ""}
                style={{ flexGrow: "4", marginRight: "3rem" }}
                readOnly
              />
              <label
                htmlFor="load_id"
                style={{ display: "block", textAlign: "left" }}
              >
                City
              </label>
              <input
                type="text"
                name="city"
                placeholder="city:"
                value={data ? data.origin_city_cd : ""}
                style={{ flexGrow: "4", marginRight: "3rem" }}
                readOnly
              />
              <label
                htmlFor="load_id"
                style={{ display: "block", textAlign: "left" }}
              >
                Zip Code
              </label>
              <input
                type="text"
                name="zip"
                placeholder="zip:"
                value={data ? data.origin_zip_cd : ""}
                style={{ flexGrow: "4" }}
                readOnly
              />
            </div>
          </div>
          <br />
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ display: "flex" }}>
              <label htmlFor="destination" style={{ fontWeight: "bolder" }}>
                Destination
              </label>
            </div>
            <input
              type="text"
              name="destination"
              placeholder="destination"
              value={data ? data.des_address : ""}
              readOnly
            />
            <br />
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <label
                htmlFor="load_id"
                style={{ display: "block", textAlign: "left" }}
              >
                State
              </label>
              <input
                type="text"
                name="dstate"
                placeholder="state"
                value={data ? data.des_state_cd : ""}
                style={{ flexGrow: "4", marginRight: "3rem" }}
                readOnly
              />
              <label
                htmlFor="load_id"
                style={{ display: "block", textAlign: "left" }}
              >
                City
              </label>
              <input
                type="text"
                name="dcity"
                placeholder="city"
                value={data ? data.des_city_cd : ""}
                style={{ flexGrow: "4", marginRight: "3rem" }}
                readOnly
              />
              <label
                htmlFor="load_id"
                style={{ display: "block", textAlign: "left" }}
              >
                Zip Code
              </label>
              <input
                type="text"
                name="dzip"
                placeholder="zip"
                value={data ? data.des_zip_cd : ""}
                style={{ flexGrow: "4" }}
                readOnly
              />
            </div>
          </div>
        </div>
        <br />
        <div>
          <div style={{ display: "flex", justifyContent: "space-evenly" }}>
            <span
              style={{
                color: "#8A31A8",
                fontWeight: "bolder",
                fontSize: "1.4rem",
              }}
            >
              Estimated Cost: ${" "}
              <input
                type="text"
                // placeholder="Load Date:"
                value={data ? data.load_amount : ""}
                readOnly
              />
            </span>
            <span
              style={{
                color: "#8A31A8",
                fontWeight: "bolder",
                fontSize: "1.4rem",
              }}
            >
              Recommended Bid Amt : ${" "}
              <input
                type="text"
                // placeholder="Load Date:"
                value={data ? data.load_amount : ""}
                readOnly
              />
            </span>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              backgroundColor: "#F0E5F3",
              height: "8rem",
              justifyContent: "space-evenly",
            }}
          >
            <div style={{ display: "flex" }}>
              <label
                htmlFor="theone"
                style={{ marginLeft: "5rem", fontWeight: "bolder" }}
              >
                Bid Amount
              </label>
              <div
                style={{
                  marginLeft: "7rem",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <span style={{ marginRight: "0.5rem", fontWeight: "bold" }}>
                  $
                </span>
                <input
                  type="text"
                  placeholder="Enter the amount "
                  style={{ backgroundColor: "white" }}
                  value={loadAmount}
                  onChange={(e) => setLoadAmount(e.target.value)}
                />
              </div>
            </div>
            <div style={{ display: "flex" }}>
              <label
                htmlFor="theone"
                style={{ marginLeft: "5rem", fontWeight: "bolder" }}
              >
                Estimated Delivery Time
              </label>
              <input
                type="text"
                placeholder="Enter hours (e.g., 1, 2, 3, ...)"
                style={{ marginLeft: "3.5rem", backgroundColor: "white" }}
                value={estimatedDeliveryTime}
                onChange={(e) => setEstimatedDeliveryTime(e.target.value)}
              />
            </div>

            <div style={{ display: "flex" }}>
              <label style={{ marginLeft: "5rem", fontWeight: "bolder" }}>
                Time Left for Bid
              </label>
              <input
                type="text"
                value={formatTime(countdown)}
                // value={formatTime(timeLeft)}
                style={{
                  marginLeft: "6.5rem",
                  backgroundColor: "white",
                  color: "black", // Change the text color here
                  fontWeight: "bold", // Make the text bold
                }}
                readOnly
              />
            </div>
          </div>
        </div>
        <br />
        <br />
        <button
          style={{
            width: "15rem",
            color: "white",
            fontWeight: "bolder",
            fontSize: "1rem",
            backgroundColor: "#8A31A8",
          }}
          onClick={handleBid}
        >
          {bidValue === "B" ? "Bid" : "Update"}
        </button>
      </div>
    </>
  );
};

export default BiddingForm;
