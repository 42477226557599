import React from 'react'
import LoadView from '../LoadView/LoadView'
import { LoadTemplate } from '../ComponentTemplate/LoadTemplate'

 const pendingload = () => {
  return (
    <>
  
    <LoadTemplate name={"Scheduled Loads"} pendingapi={"pending-load-view"} />
    </>
  )
}

export default pendingload;


