// import React, { useEffect, useState } from "react";
// import axios from "axios";
// import FreightTable from "../../FreightTable/FreightTable";

// const Transactions = () => {
//   const [rows, setRow] = useState();
//   const [columns, setColumns] = useState();


//   useEffect(() => {
//     console.log("admin testing is here");
//     axios
//       .get("http://yzulu.com/api/v1/admin/transactiotable", {
//         withCredentials: true,
//       })
//       .then((response) => {
//         setRow((rows) => {
//           let newRows = response.data.data.Transactiondata.rows.map(
//             (rows, index) => {
//               return {
//                 id: index + 1,
//                 ...rows,
//               };
//             }
//           );
//           return newRows;
//         });

//         setColumns(
//           response.data.data.Transactiondata.fields.map((col) => ({
//             field: col.name,
//             headerName: col.name
//               .split("_")
//               .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
//               .join(" "),
//             width: 150,
//           }))
//         );
//       })
//       .catch((error) => {
//         console.error("Error fetching data:", error);
//       });
//   }, []);



//   return (
//     <>
//       <h1>
//         <div>Completed Loads</div>
//       </h1>
// <div style={{border:"2px solid red"}}>
//       {rows && columns.length > 0 ? (
//         <div style={{ display: "inline-block" }}>
//           <style>
//             {`
//             .centered-header {
//               text-align: center !important;
//               border-bottom: 2px solid red !important;
//             }
//           `}
//           </style>

//           <FreightTable
//             data={rows}
//             columns={columns.map((col) => ({
//               ...col,
//               headerClassName:
//                 col.field === "load_id" || col.field === "load_status"
//                   ? "centered-header"
//                   : "",
//             }))}
//           />
//         </div>
//       ) : (
//         <div>No matching results found.</div>
//       )}
//       </div>
//     </>
//   );
// };

// export default Transactions;

import React, { useEffect, useState } from "react";
import axios from "axios";
import DataContext from "../../../Context/dataContext";

const Transactions = () => {
  const [rows, setRow] = useState([]);
  const [columns, setColumns] = useState([]);
  const employeeData = useContext(DataContext);

  useEffect(() => {
    console.log("admin testing is here");
    axios
      .get(employeeData.URL + "/api/v1/admin/transactiotable", {
        withCredentials: true,
      })
      .then((response) => {
        setRow((rows) => {
          let newRows = response.data.data.Transactiondata.rows.map(
            (rows, index) => {
              return {
                id: index + 1,
                ...rows,
              };
            }
          );
          return newRows;
        });

        setColumns(
          response.data.data.Transactiondata.fields.map((col) => ({
            field: col.name,
            headerName: col.name
              .split("_")
              .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
              .join(" "),
            width: 150,
          }))
        );
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  return (
    <>
      <h1>
        <div>Completed Loads</div>
      </h1>
      <div style={{}}>
        {rows && columns.length > 0 ? (
          <div style={{ display: "inline-block" }}>
            <table style={{ width: "100%" }}>
              <thead>
                <tr>
                  {columns.map((col) => (
                    <th
                      key={col.field}
                      style={{
                        textAlign: "center",
                        borderBottom: "2px solid black",
                        height: "3em",
                        backgroundImage:
                          "linear-gradient(to bottom, #c99cd8, rgb(254, 252, 254))",
                        boxShadow: "5px 8px 18px #888888",
                        marginBottom: "5rem",
                      }}
                    >
                      {col.headerName}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {rows.map((rowData, index) => (
                  <tr
                    key={rowData.id}
                    style={{
                      height: "1.5em",
                        backgroundImage:
                          "linear-gradient(to bottom, #c99cd8, rgb(254, 252, 254))",
                        boxShadow: "5px 8px 18px #888888",
                        marginBottom: "2rem",
                    }}
                  >
                    {columns.map((col) => (
                      <td key={col.field}>{rowData[col.field]}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div>No matching results found.</div>
        )}
      </div>
    </>
  );
};

export default Transactions;
