import { Row, Col, Tabs, Card, Skeleton, message } from "antd";
import "./dashboard.css";
import DataContext from "../../../Context/dataContext";
import { useState, useEffect, useContext } from "react";
import axios from "axios";
import Dashboard2 from "./dashboard2";
import Dashboard1 from "./Dashboard1";
import Dashboard3 from "./dashboard3";
import { useNavigate } from "react-router-dom";


const { TabPane } = Tabs;

const identifiers = ["itemSize", "gradeDetails", "accountDetails"];

const Maindash = (props) => {
  console.log(" hey thterf");
  const [size, setSize] = useState("large");
  const [columns, setColumns] = useState([]);
  const [column, setColumn] = useState([]);
  const [loadsData, setLoadsData] = useState([]);
  // const [checkMessage, setcheckMessage] = useState(true);
  // const [rows, setRows] = useState(null);
  const FreightData = useContext(DataContext);
  let [myNewData, setMyNewData] = useState([]);
  
  // const rows = FreightData.analysis.loads.rows;
  
  const [dashRows, setDashRows] = useState([]);
  const [myloadDash, setMyloadDash] = useState([]);
  const navigate = useNavigate()

   const [mybill, setmybill] = useState();
  const [fifteen, setfifteen] = useState();
  const [thirty, setthirty] = useState();
  const [sixtey, setsixtey] = useState();
  const [month, setmonth] = useState();

  
  
  console.log(FreightData);


  useEffect(() => {

    axios
    .get(FreightData.URL + "/api/v1/dashboard/loads", {
      withCredentials: true,
    })
    .then((response) => {
      console.log("hi there this is workinh");
      console.log(response);
      setDashRows(response.data.data.loads.rows)

      setColumns((columns) => {
      const newCols = response.data.data.loads.fields .map((col) => ({
          field: col.name,
          headerName: col.name
            .split("_")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" "),
          width: 150,
        }));

      return [...newCols];
    });
      
    
    })
    .catch((err) => {
      console.log(err);
    });

  axios
    .get(FreightData.URL + "/api/v1/dashboard/tobid", {
      withCredentials: true,
    })
    .then((response) => {
      console.log(response);
      setMyloadDash(response.data.data.loads.rows)
      
    });

  axios
    .get(FreightData.URL + "/api/v1/dashboard/Myloads", {
      withCredentials: true,
    })
    .then((response) => {
      console.log(response);
      console.log(" hey thtere");
      // setMyNewData(response.data.data.loads.rows)
      const myNewData = response.data.data.loads.rows.map((row) => {
        if (row.status === "won") {
          return { ...row, status: "Take Action" };
        } else {
          return row;
        }
      });
      setMyNewData(myNewData); // Update myNewData state
      console.log(myNewData);
      setColumn((columns) => {
        const newCols = response.data.data.loads.fields
          .filter((col) => col.name !== "actual_status") // Exclude 'Actual_status' column
          .map((col) => ({
            field: col.name,
            headerName: col.name
              .split("_")
              .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
              .join(" "),
            width: 150,
          }));
  
        return [...newCols];
      });

    
    })

    .catch((err) => {
      console.log(err.response);
    });







axios
      .get(FreightData.URL + "/api/v1/dashboard/dashamountTrucker", {
        withCredentials: true,
      })
      .then((response) => {
        // const toCheck = response.data.profile.rows[0];
        console.log(response.data);
        setmybill(response.data.mybill.balance);
        setfifteen(response.data.zerotofifteen.final_result);
        setthirty(response.data.fifteentothirty.final_result);
        setsixtey(response.data.thirtytosixteen.final_result);
        setmonth(response.data.morethantwomonth.final_result);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      })






    }








  , [])
  

  let showMessage = true;
  for (let i = 0; i < dashRows.length; i++) {
 
  
    const row =  dashRows[i];
    const { status } = row; // Assuming 'status' is the name of the column
    
    // Determine the arrow symbol based on the 'status' value
    let arrow;
    let textColor;
    let fontWeight;
    let onClickFunction = null; // Initialize onClick function as null
    
    if (status === "winning") {
      arrow = "↑";
      textColor = "green";
    } else if (status === "losing") {
      arrow = "↓";
      textColor = "red";
      
      onClickFunction = () => handleLosingClick(row); // Assign onClick handler for "losing"
      if(  showMessage){
        // setcheckMessage(false)
        showMessage = false;
        message.warning('Loosing bid? Re-bid now by clicking on Losing Bid!');
      }
  
    
    } else if (status === "won") {
      arrow = "";
      textColor = "green";
      fontWeight = "bolder";
    } else if (status === "lost") {
      arrow = "";
      textColor = "red";
      fontWeight = "bolder";
    } else {
      // Handle other cases if needed
      arrow = ""; // You can set a default value or leave it empty
      textColor = "";
    }
  
    // Update the 'status' property with the combined value
    row.status = (
      <span
        style={{ color: textColor, fontWeight: fontWeight, cursor: onClickFunction ? "pointer" : "default" }}
        onClick={onClickFunction}
      >
        {status}
        {arrow}
      </span>
    );
  }
  
  
  // Define the onClick function for "losing"
  function handleLosingClick(row) {
    // Do something when "losing" status is clicked
    console.log("Losing status clicked:", row);
    const id = row.load_id;
    navigate(`/trucker/load-data/load-view/${id}`)
    // Add your logic here
  }
  







 

  
   


  

  return (
    <>
     <div id="messageContainer" class="message-container"></div>
      <Row gutter={16} className="SiteLayoutBackground" style={{}}>
        <Col lg={19} md={19} className="ColD">
          <Card className="CardD">
            <div>
              <Tabs
                defaultActiveKey="1"
                centered
                style={{ height: "100%", margin: "10px 10px" }}
                tabPosition={"top"}
                type="card"
                size={size}
                tabBarGutter="0"
              >
                <TabPane tab={<span>To Bid</span>} key="0">
                  {/* <Dashboard2 data={rows} columns={columns} /> */}
                  <Dashboard2 data={myloadDash} columns={columns} />

                </TabPane>
                <TabPane tab={<span>Bidding</span>} key="1">
                <Dashboard1 data={dashRows.length ? dashRows : "loading"} columns={columns} />
                  {/* <Dashboard1 data={rows} columns={columns} /> */}
                </TabPane>
                <TabPane tab={<span>My Load</span>} key="2">
                  {/* <Dashboard3 data={myNewData} columns={column} /> */}
                  <Dashboard3 data={ myNewData} columns={column} />
          
                </TabPane>
              </Tabs>
            </div>
          </Card>
        </Col>

        <Col lg={5} md={5} className="ColC" style={{ }}>
          <Card className="Card" style={{background: "linear-gradient(to Bottom,rgb(153, 79, 179),white)"}}>
            <div>
              <h3 style={{ color: "purple",fontSize:'1rem', fontWeight:'bold'  }}>My Earnings {mybill? mybill: 0 }</h3>
              <br />
              <h3 style={{ fontSize:"0.8rem",color: "#8A31A8", marginTop: "0.3rem" }}> Total Amount Recivable</h3>
         
              <br />
         
              <div
                style={{
                  fontSize: "1rem",
                  fontWeight: "bold",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                }}
              >
                <div style={{fontSize:"0.7rem", display: "inline-block", width: "11rem " }}>
                  0-15  <span className="amt"> {fifteen? fifteen: 0}</span>
                </div>
                <br />
                <div style={{fontSize:"0.7rem", display: "inline-block", width: "11rem " }}>
                  15-30 Days <span className="amt"> {thirty? thirty:0}</span>
                </div>
                <br />

                <div style={{ fontSize:"0.7rem",display: "inline-block", width: "11rem " }}>
                 More than  60 days<span className="amt">{month?month:0}</span>
                </div>
              </div>
            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
};
export default Maindash;
