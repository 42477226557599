import React from 'react'
import './mycss.css'

const Mysmallbox = (props) => {
  return (
    <>
    
    <div className="thesmall">
        <h1>{props.names}</h1>
    
        <div className='thisisimg'><img src={props.pic} alt="thisisIMG" /></div>
        <ul>
        {props.data.map((item, index) => (
          <li className='mydofl' key={index}>{item}</li>
        ))}
      </ul>
   
    </div>
    </>
  )
}

export default Mysmallbox