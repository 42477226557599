import React, { useEffect, useRef, useState, useContext } from "react";
import IconPage from "./IconPage";
import Aform from "./Aform";
import axios from "axios";

import DataContext from "../../../Context/dataContext";

// import the library
import { library } from "@fortawesome/fontawesome-svg-core";

// import your icons
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";

function ProfilePage(props) {
  console.log(props);
  const employeeData = useContext(DataContext);

  const [data, setData] = useState(null);
 
  console.log(employeeData);

  useEffect(() => {
    axios
      .get(employeeData.URL + "/api/v1/profile/get-profile-data", {
        withCredentials: true,
      })
      .then((response) => {
        console.log(response.data.loads.rows);

        setData(response.data.loads.rows);

        // setData(response)
      })
      .catch((Error) => {
        console.log(Error);
      });
  }, []);

  return (
    <>
      {/* <br></br> */}
      <div className=" d-flex align-items-start"></div>

      <IconPage />
      {data ? (
        <Aform data={data} employeeData={employeeData} />
      ) : (
        <p>Loading...</p>
      )}
    </>
  );
}

export default ProfilePage;
