import React, { useState } from "react";
import axios from "axios";
import "./dashboard.css";
import { useEffect, useContext } from "react";
import DataContext from "../../../Context/dataContext";
import FreightTable from "./FRTable";

const Dashboard = (props) => {
  console.log(props);
  console.log(props.data);
  // useEffect(() => {
  //   console.log("manoj");
  //   axios
  //     .get("http://yzulu.com" + "/api/v1/dashboard/loads", {
  //       withCredentials: true,
  //     })
  //     .then((response) => {
  //       console.log(response);

  //       setColumns((columns) => {
  //         let newCols = response.data.data.loads.fields.map((col) => {
  //           return {
  //             field: col.name,
  //             headerName: col.name
  //               .split("_")
  //               .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
  //               .join(" "),
  //             width: 150,
  //           };
  //         });

  //         const newNewCols = [...newCols];
  //         return newNewCols;
  //       });
  //       setRows((rows) => {
  //         let newRows = response.data.data.loads.rows.map((row, index) => {
  //           const { status } = row; // Assuming 'status' is the name of the column

  //           // Determine the arrow symbol based on the 'status' value
  //           let arrow;
  //           let textColor;
  //           if (status === "winning") {
  //             arrow = "↑";
  //             textColor = "green";
  //           } else if (status === "losing") {
  //             arrow = "↓";
  //             textColor = "red";
  //           } else {
  //             // Handle other cases if needed
  //             arrow = ""; // You can set a default value or leave it empty
  //             textColor = "";
  //           }

  //           // Update the 'status' property with the combined value
  //           row.status = (
  //             <span style={{ color: textColor }}>
  //               {status}
  //               {arrow}
  //             </span>
  //           );

  //           return {
  //             id: index + 1,
  //             ...row,
  //           };
  //         });
  //         return newRows;
  //       });
  //     })

  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }, []);
  // const rows = props.data;
  // for (let i = 0; i < rows.length; i++) {
  //   const row = rows[i];
  //   const { status } = row; // Assuming 'status' is the name of the column

  //   // Determine the arrow symbol based on the 'status' value
  //   let arrow;
  //   let textColor;
  //   if (status === "winning") {
  //     arrow = "↑";
  //     textColor = "green";
  //   } else if (status === "losing") {
  //     arrow = "↓";
  //     textColor = "red";
  //   } else {
  //     // Handle other cases if needed
  //     arrow = ""; // You can set a default value or leave it empty
  //     textColor = "";
  //   }

  //   // Update the 'status' property with the combined value
  //   row.status = (
  //     <span style={{ color: textColor }}>
  //       {status}
  //       {arrow}
  //     </span>
  //   );
  // }
  // console.log(rows);
  // const [columns, setColumns] = useState([]);
  // //   const [rows, setRows] = useState(null);
  // const FreightData = useContext(DataContext);
  // console.log(FreightData);
  // const rows = FreightData.analysis.loads.rows;
  // useEffect(() => {
  //   setColumns((columns) => {
  //     const newCols = FreightData.analysis.loads.fields.map((col) => ({
  //       field: col.name,
  //       headerName: col.name
  //         .split("_")
  //         .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
  //         .join(" "),
  //       width: 150,
  //     }));

  //     return [...newCols];
  //   });

  //   // setRows((rows) => {
  //   //   let newRows = FreightData.analysis.loads.rows;
  //   //   return newRows;
  //   // });
  // }, []);
  return (
    <>
      <div className="big">
 
   
  

        <FreightTable data={props.data} columns={props.columns} val={true} />
      </div>
    </>
  );
};

export default Dashboard;
