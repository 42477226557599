

import React, { useEffect, useRef, useState, useContext } from "react";
import IconPage from "./IconPage";
import Aform from "./Aform";
import axios from "axios";

// import {Login} from '../../../Pages/LoginPage/LoginPage'

import DataContext from "../../../Context/dataContext";

// import the library
import { library } from "@fortawesome/fontawesome-svg-core";

// import your icons
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";

// import { response } from '../../../../../app';

function ProfilePage(props) {
  // console.log(dataProfile);
  console.log("my name is aakash 0");

  const employeeData = useContext(DataContext);
  const [data, setData] = useState(null);

  console.log("my name is aakash 1");
  console.log(employeeData);

  let thisData;
  useEffect(() => {
    axios
      .get(employeeData.URL + "/api/v1/profile/get-preference", {
        withCredentials: true,
      })
      .then((response) => {
        console.log(response.data.loads.rows);
        thisData = response.data.loads.rows;
        setData(response.data.loads.rows);

        // setData(response)
      })
      .catch((Error) => {
        console.log(Error);
      });
  }, []);

  console.log(thisData);
  console.log(data);

  // console.log(props);

  return (
    <>
      {/* <br></br> */}
      <div className=" d-flex align-items-start"></div>

      <IconPage />
      {data ? (
        <Aform data={data} employeeData={employeeData} />
      ) : (
        <p>Loading...</p>
      )}
    </>
  );
}

export default ProfilePage;
