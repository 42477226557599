import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import "./valid.css";
import { useLocation, useNavigate } from "react-router-dom";
import { message } from "antd";
import DataContext from "../../../Context/dataContext";

const Validation = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const email = location.pathname.split("/").pop(); // Extracting email from the URL
  console.log(email);
  // let planNumber;
  const [planNumber, setPlanNumber] = useState(1);

  const [planSelected, setPlanSelected] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(0);
  const employeeData = useContext(DataContext);

  const handlePlanSelect = (number) => {
    setPlanNumber(number);
    setPlanSelected(true);

    setSelectedPlan(number);
  };

  const handleClick = () => {
    if (planSelected == false) {
      // Display alert if no plan is selected
      alert("Please select a plan first");
      return;
    }
    // Replace 'your-api-endpoint' with the actual API endpoint
    // const apiUrl = `http://localhost:8000/api/v1/type/email-validate/${email}/${planNumber}`;
    const apiUrl = employeeData.URL + `/api/v1/type/email-validate/${email}/${planNumber}`;

    // Replace 'your-data' with the data you want to send in the POST request
    const data = { email: email };

    // Send POST request using Axios
    axios
      .post(apiUrl, data, { withCredentials: true })
      .then((response) => {
        // Handle success
        console.log("POST request successful:", response);
        message.success({
          content: "Email Verified! Unlock Your Account Now.",
          className: "custom-class",
          style: {
            marginTop: "20vh",
          },
          duration: 4,
        });

        // Navigate to another page (replace '/new-page' with your desired route)
        navigate("/trucker");
      })
      .catch((error) => {
        // Handle error
        console.error("Error in POST request:", error);
      });
  };

  return (
    <>
      <div class="validation-page-container">
        <div className="validation-page">
          <div className="header">
            <h1>Validation Gateway</h1>
          </div>
          <div className="content">
            <p>
              Thank you for choosing our platform. Please click the button below
              to initiate the validation process.
            </p>

            <div className="subscription-table-container">
              <table
                className="subscription-table"
                style={{ overflow: "auto", height: "20rem" }}
              >
                <thead>
                  <tr>
                    <th></th>
                    <th>BASIC</th>
                    <th>ADVANCED</th>
                    <th>PRO</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Price</td>
                    <td>$40/MONTH</td>
                    <td>$80/MONTH</td>
                    <td>$120/MONTH</td>
                  </tr>
                  <tr>
                    <td>Mobile App</td>
                    <td>1</td>
                    <td>1</td>
                    <td>1</td>
                  </tr>
                  <tr>
                    <td>Web Access</td>
                    <td>No</td>
                    <td>Yes</td>
                    <td>Yes</td>
                  </tr>
                  <tr>
                    <td>FZ Planner</td>
                    <td>Yes</td>
                    <td>Yes</td>
                    <td>Yes</td>
                  </tr>
                  <tr>
                    <td>Discounts</td>
                    <td>Standard</td>
                    <td>Better</td>
                    <td>Best</td>
                  </tr>
                  <tr>
                    <td>Reward Points</td>
                    <td>1x</td>
                    <td>1.5x</td>
                    <td>2x</td>
                  </tr>
                  <tr>
                    <td>Free Loads</td>
                    <td>1</td>
                    <td>2</td>
                    <td>5</td>
                  </tr>
                  <tr>
                    <td>Support</td>
                    <td>24/7</td>
                    <td>24/7</td>
                    <td>24/7</td>
                  </tr>
                  <tr>
                    <td>Real-time Updates</td>
                    <td>Yes</td>
                    <td>Yes</td>
                    <td>Yes</td>
                  </tr>
                  <tr>
                    <td>Trips</td>
                    <td>Up to 20</td>
                    <td>Up to 30</td>
                    <td>Unlimited</td>
                  </tr>
                  <tr>
                    <td>Load Trip</td>
                    <td>Any</td>
                    <td>Preferred</td>
                    <td>Preferred</td>
                  </tr>
                  <tr>
                    <td>Bid</td>
                    <td>Yes</td>
                    <td>Yes</td>
                    <td>Yes</td>
                  </tr>
                  <tr>
                    <td>Insurance</td>
                    <td>No</td>
                    <td>Yes</td>
                    <td>Yes + Service Stations</td>
                  </tr>
                  {/* 
                  <tr>
                    <td>Select</td>
                    <td>
                      <button onClick={() => handlePlanSelect(1)}>Select</button>
                    </td>
                    <td>
                      <button onClick={() => handlePlanSelect(2)}>Select</button>
                    </td>
                    <td>
                      <button onClick={() => handlePlanSelect(3)}>Select</button>
                     
                    </td>
                  </tr> */}

                  <tr>
                    <td>Select</td>
                    <td>
                      <button
                        onClick={() => handlePlanSelect(1)}
                        className={selectedPlan === 1 ? "selected" : ""}
                      >
                        {selectedPlan === 1 ? "Selected" : "Select"}
                      </button>
                    </td>
                    <td>
                      <button
                        onClick={() => handlePlanSelect(2)}
                        className={selectedPlan === 2 ? "selected" : ""}
                      >
                        {selectedPlan === 2 ? "Selected" : "Select"}
                      </button>
                    </td>
                    <td>
                      <button
                        onClick={() => handlePlanSelect(3)}
                        className={selectedPlan === 3 ? "selected" : ""}
                      >
                        {selectedPlan === 3 ? "Selected" : "Select"}
                      </button>
                    </td>
                  </tr>

                  {/* <tr>
  <td>Select</td>
  <td>
    <button
      onClick={() => handlePlanSelect(1)}
      style={{
        backgroundColor: selectedPlan === 1 ? "black" : "inherit",
        color: selectedPlan === 1 ? "white" : "inherit"
      }}
    >
      {selectedPlan === 1 ? "Selected" : "Select"}
    </button>
  </td>
  <td>
    <button
      onClick={() => handlePlanSelect(2)}
      style={{
        backgroundColor: selectedPlan === 2 ? "black" : "inherit",
        color: selectedPlan === 2 ? "white" : "inherit"
      }}
    >
      {selectedPlan === 2 ? "Selected" : "Select"}
    </button>
  </td>
  <td>
    <button
      onClick={() => handlePlanSelect(3)}
      style={{
        backgroundColor: selectedPlan === 3 ? "black" : "inherit",
        color: selectedPlan === 3 ? "white" : "inherit"
      }}
    >
      {selectedPlan === 3 ? "Selected" : "Select"}
    </button>
  </td>
</tr> */}
                </tbody>
              </table>
            </div>

            {/* You may not need the button if you want to trigger the request immediately */}
            <button onClick={handleClick}>Login</button>
          </div>
          <div className="footer-note">
            <p>
              We prioritize your privacy. Rest assured, we do not share your
              personal data; it is safe with us.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Validation;
