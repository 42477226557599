// import React from "react";
// import axios from "axios";
// import BiddingForm from "./BiddingForm";

// import DataContext from "../../../Context/dataContext";

// import { useState, useEffect, useContext } from "react";

// import { useLocation } from "react-router-dom";

// const Bidding = () => {
//   const [allData, setAllData] = useState(null);
//   const employeeData = useContext(DataContext);

//   const location = useLocation();

//   useEffect(() => {
//     setAllData(null);
//     axios
//       .get(
//         employeeData.URL +
//           "/api/v1/loadt/" +
//           location.pathname.split("/").pop(),
//         {
//           withCredentials: true,
//         }
//       )
//       .then((response) => {
//         console.log(response);

//         setAllData((allData) => {
//           let newDetails = response.data.data;
//           return {
//             ...newDetails,
//           };
//         });
//       })
//       .catch((error) => {
//         console.log(error);
//       });
//   }, []);

//   return (
//     <>
//       <div className=" d-flex align-items-start"></div>

//       {allData ? (
//         <BiddingForm setData={setAllData} data={allData.getOneLoad.rows[0]} />
//       ) : (
//         <>
//           <h1>loading...</h1>
//         </>
//       )}
//     </>
//   );
// };

// export default Bidding;

import React from "react";
import axios from "axios";
import BiddingForm from "./BiddingForm";

import DataContext from "../../../Context/dataContext";

import { useState, useEffect, useContext } from "react";

import { useLocation } from "react-router-dom";

const Bidding = () => {
  const [allData, setAllData] = useState(null);
  const employeeData = useContext(DataContext);

  const location = useLocation();

  useEffect(() => {
    setAllData(null);
    axios
      .get(
        employeeData.URL +
          "/api/v1/loadt/" +
          location.pathname.split("/").pop(),
        {
          withCredentials: true,
        }
      )
      .then((response) => {
        console.log(response);

        setAllData((allData) => {
          let newDetails = response.data.data;
          return {
            ...newDetails,
          };
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <>
      <div className=" d-flex align-items-start"></div>

      {allData ? (
        <BiddingForm
          setData={setAllData}
          data={allData.getOneLoad[0]}
          extraData={allData.getBids}
        />
      ) : (
        <>
          <h1>loading...</h1>
        </>
      )}
    </>
  );
};

export default Bidding;
