import React, { useEffect, useState } from "react";
import axios from "axios";
import FreightTable from "../../FreightTable/FreightTable";
import DataContext from "../../../Context/dataContext";

const ActiveLanes = () => {
  const [data, setData] = useState([]);
  const [rows, setRow] = useState();
  const [columns, setColumns] = useState();
  const employeeData = useContext(DataContext);

  useEffect(() => {
    // pending-bids
    console.log("admin testing is here");
    axios
      .get(employeeData.URL + "/api/v1/admin/lane", {
        withCredentials: true,
      })
      .then((response) => {
        console.log(response);
  
        setRow((rows) => {
          let newRows = response.data.data.Activelane.rows.map(
            (rows, index) => {
              return {
                id: index + 1,
                ...rows,
              };
            }
          );
          return newRows;
        });
  
        setColumns((columns) => {
          let newCols = response.data.data.Activelane.fields.map((col) => {
            let headerName =
              col.name === "origin_city_cd"
                ? "Origin City"
                : col.name === "jhg"
                  ? "mnb"
                  : col.name === "des_city_cd"
                    ? "Des City"
                  : col.name
                      .split("_")
                      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                      .join(" ");
  
            return {
              field: col.name,
              headerName: headerName,
              width: 150,
            };
          });
  
          return newCols;
        });
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);
  

  return (
    <>
      <h1>
        <div>Active Lanes</div>
      </h1>

      {rows && columns.length > 0 ? (
        <div style={{ display: "inline-block" }}>
          <FreightTable data={rows} columns={columns} />
        </div>
      ) : (
        <div>No matching results found.</div>
      )}
    </>
  );
};

export default ActiveLanes;
