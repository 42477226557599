import React from "react";
import { Row, Col, Skeleton, Tooltip } from "antd";
import { RiUserSearchFill } from "react-icons/ri";
import axios from "axios";
import classes from "../Pages.module.css";
import { useState, useEffect, useContext } from "react";
import DataContext from "../../../Context/dataContext";
import FreightTable from "../../FreightTable/FreightTable";
import { AiFillDelete } from "react-icons/ai";
import "./LT.css";
import { UilSearch } from "@iconscout/react-unicons";
import { Link } from "react-router-dom";

export const LoadTemplate = (props) => {
  const employeeData = useContext(DataContext);
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState(null);
  const [drows, setDRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [nrow, setNrow] = useState([]);

  const searchHere = (e) => {
    console.log(nrow);
    const searchTerm = e.target.value.toLowerCase();
    const filteredRows = nrow.filter((row) => {
      return Object.values(row).some((value) => {
        if (value === null || typeof value === "undefined") {
          return false;
        }
        return value.toString().toLowerCase().indexOf(searchTerm) !== -1;
      });
    });
    setRows(filteredRows);
  };

  useEffect(() => {
    setDRows(null);
    setRows(null);
    setColumns([]);

    axios
      .get(employeeData.URL + `/api/v1/load/${props.pendingapi}`, {
        withCredentials: true,
      })
      .then((response) => {
        console.log(response);

        setColumns((columns) => {
          let newCols = response.data.data.loads.fields.map((col) => {
            return {
              field: col.name,
              headerName: col.name.split("_").join(" ").toLowerCase(),
              width: 150,
            };
          });

          const newNewCols = [...newCols];
          return newNewCols;
        });

        setNrow((rows) => {
          let newRows = response.data.data.loads.rows.map((row, index) => {
            if (props.name === "PENDING LOADS" && row.load_status === null) {
              row.load_status = "Pending";
            }

            if (props.name === "DELIVERED LOADS" && row.load_status === null) {
              row.load_status = "Delivered";
            }

            return {
              id: index + 1,
              ...row,
              load_status:
                row.load_status === "Pending" ? (
                  <span style={{ color: "red", fontWeight: "bold" }}>
                    Tracking
                  </span>
                ) : row.load_status === "Delivered" ? (
                  <span style={{ color: "green", fontWeight: "bold" }}>
                    Delivered
                  </span>
                ) : (
                  row.load_status
                ),
            };
          });
          return newRows;
        });

        setRows((rows) => {
          let newRows = response.data.data.loads.rows.map((row, index) => {
            if (props.name === "PENDING LOADS" && row.load_status === null) {
              row.load_status = "Pending";
            }

            if (props.name === "DELIVERED LOADS" && row.load_status === null) {
              row.load_status = "Delivered";
            }
            return {
              id: index + 1,
              ...row,
              load_status:
                row.load_status === "Pending" ? (
                  <span style={{ color: "red", fontWeight: "bold" }}>
                    Tracking
                  </span>
                ) : row.load_status === "Delivered" ? (
                  <span style={{ color: "green", fontWeight: "bold" }}>
                    Delivered
                  </span>
                ) : (
                  row.load_status
                ),
            };
          });
          return newRows;
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const deleteHandler = (event, index, newrows, code) => {
    console.log(index);
    console.log(code);

    setRows(null);
    axios
      .delete(employeeData.URL + "/api/v1/items/" + code.toString(), {
        withCredentials: true,
      })
      .then((response) => {
        console.log(response);
        const rowvals = newrows.filter((row) => row["item_code"] !== code);
        setRows((rows) => {
          let newDRows = rowvals.map((row, index) => {
            return {
              D: (
                <Tooltip placement="bottom" title="Delete" color="red">
                  <Link to="#" style={{ color: "red", fontWeight: "bolder" }}>
                    <AiFillDelete
                      onClick={(event) =>
                        deleteHandler(event, index, rowvals, row["item_code"])
                      }
                      style={{
                        color: "red",
                        fontWeight: "bolder",
                        fontSize: "0.8rem",
                      }}
                    />
                  </Link>
                </Tooltip>
              ),
              V: (
                <Tooltip placement="bottom" title="View" color="#1777C4">
                  <Link
                    to={
                      "/sales/itemMaster/item-details-view/" + row["item_code"]
                    }
                    style={{ color: "#1777C4", fontWeight: "bolder" }}
                  >
                    <RiUserSearchFill
                      style={{
                        color: "#1777C4",
                        fontWeight: "bolder",
                        fontSize: "0.9rem",
                      }}
                    />
                  </Link>
                </Tooltip>
              ),

              SNO: index + 1,
              ...row,
            };
          });
          return newDRows;
        });
      })
      .catch((error) => {
        setRows((rows) => {
          let newDRows = newrows.map((row, index) => {
            return {
              D: (
                <Tooltip placement="bottom" title="Delete" color="red">
                  <Link to="#" style={{ color: "red", fontWeight: "bolder" }}>
                    <AiFillDelete
                      onClick={(event) =>
                        deleteHandler(event, index, newrows, row["item_code"])
                      }
                      style={{
                        color: "red",
                        fontWeight: "bolder",
                        fontSize: "0.8rem",
                      }}
                    />
                  </Link>
                </Tooltip>
              ),
              V: (
                <Tooltip placement="bottom" title="View" color="#1777C4">
                  <Link
                    to={
                      "/sales/itemMaster/item-details-view/" + row["item_code"]
                    }
                    style={{ color: "#1777C4", fontWeight: "bolder" }}
                  >
                    <RiUserSearchFill
                      style={{
                        color: "#1777C4",
                        fontWeight: "bolder",
                        fontSize: "0.9rem",
                      }}
                    />
                  </Link>
                </Tooltip>
              ),

              SNO: index + 1,
              ...row,
            };
          });
          return newDRows;
        });
        console.log(error);
      });
  };

  return (
    <>
     <h1 style={{ color: "#A769A7"}}>
            {props.name}
          </h1>
      <form>
        <div
          // id="section"
          className="d-flex flex-row justify-content-between"
          style={{width:"90%", margin:"auto",  marginBottom:"1rem"}}
        >
          <div
            id="search"
            className="d-flex flex-row justify-content-between"
            style={{ width: "15rem", height: "3rem"}}
          >
            <input
              id="searchInput"
              type="search"
              placeholder="Search anything..."
              style={{
                border: "1px solid white",
                width: "13rem",
                height: "1.8rem",
                marginLeft: "2px",
                marginTop: "8px",
              }}
              onChange={searchHere}
            />
            <UilSearch size="20" color="black" style={{ margin: "auto" }} />
          </div>

         

        
        </div>

        <div
          className="table"
          style={{ display: "inline-block", width: "100%" }}
        >
          {rows && columns.length > 0 ? (
            <div style={{ display: "inline-block" }}>
              <FreightTable
                data={rows}
                columns={columns}
                handelChange={searchHere}
                val={true}
              />
            </div>
          ) : (
            <div>No matching results found.</div>
          )}
        </div>
      </form>
    </>
  );
};
