import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import FreightTable from "../../FreightTable/FreightTable";
import DataContext from "../../../Context/dataContext";
const NewTrucker = () => {
  const [row, setRow] = useState();
  const [columns, setColumns] = useState();
  const employeeData = useContext(DataContext);

  useEffect(() => {
    // pending-bids
    console.log("admin testing is here");
    axios
      .get(employeeData.URL + "/api/v1/admin/detail-of-trucker", {
        withCredentials: true,
      })
      .then((response) => {
        setRow((rows) => {
          let newRows = response.data.data.Detailtrucker.rows.map(
            (row, index) => {
              return {
                id: index + 1,
                ...row,
              };
            }
          );
          return newRows;
        });

        setColumns((columns) => {
          let newCols = response.data.data.Detailtrucker.fields.map((col) => {
            let headerName;
            if (col.name === "get_loadtype") {
              headerName = "Load type";
            } else if (col.name === "get_rating") {
              headerName = "Rating";
            } else {
              headerName = col.name
                .split("_")
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(" ");
            }
        
            return {
              field: col.name,
              headerName: headerName,
              width: 150,
              // Other styling or configurations here
            };
          });
        
          return newCols;
        });
        
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  // useEffect(() => {
  //     // pending-bids
  //     console.log("admin testing is here");
  //     axios
  //         .get("http://localhost:8000/api/v1/admin/detail-of-trucker", {
  //             withCredentials: true,
  //         })
  //         .then((response) => {
  //             setRow((rows) => {
  //                 let newRows = response.data.data.Detailtrucker.rows.map((row, index) => {
  //                     return {
  //                         id: index + 1,
  //                         ...row,
  //                     };
  //                 });
  //                 return newRows;
  //             });

  //             setColumns((columns) => {
  //                 let newCols = response.data.data.Detailtrucker.fields.map((col) => {
  //                     // Limit the width of the acompany_add column to accommodate 18 characters
  //                     const columnWidth = col.name === 'acompany_add' ? 18 * 10 : 150; // Adjust the width as needed
  //                     return {
  //                         field: col.name,
  //                         headerName: col.name
  //                             .split("_")
  //                             .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
  //                             .join(" "),
  //                         width: columnWidth,
  //                     };
  //                 });
  //                 return newCols;
  //             });
  //         })
  //         .catch((error) => {
  //             console.error("Error fetching data:", error);
  //         });
  // }, []);

  // useEffect(() => {
  //     // pending-bids
  //     console.log("admin testing is here");
  //     axios
  //         .get("http://localhost:8000/api/v1/admin/detail-of-trucker", {
  //             withCredentials: true,
  //         })
  //         .then((response) => {
  //             setRow((rows) => {
  //                 let newRows = response.data.data.Detailtrucker.rows.map((row, index) => {
  //                     // Limit the length of the acompany_add value to 17 characters
  //                     const limitedAcompanyAdd = row.acompany_add.length > 17
  //                         ? row.acompany_add.substring(0, 17) + "..."
  //                         : row.acompany_add;

  //                     return {
  //                         id: index + 1,
  //                         ...row,
  //                         acompany_add: limitedAcompanyAdd, // Update the acompany_add property
  //                     };
  //                 });
  //                 return newRows;
  //             });

  //             setColumns((columns) => {
  //                 let newCols = response.data.data.Detailtrucker.fields.map((col) => {
  //                     return {
  //                         field: col.name,
  //                         headerName: col.name
  //                             .split("_")
  //                             .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
  //                             .join(" "),
  //                         width: 150,
  //                     };
  //                 });
  //                 return newCols;
  //             });
  //         })
  //         .catch((error) => {
  //             console.error("Error fetching data:", error);
  //         });
  // }, []);

  // useEffect(() => {
  //     // pending-bids
  //     console.log("admin testing is here");
  //     axios
  //         .get("http://localhost:8000/api/v1/admin/detail-of-trucker", {
  //             withCredentials: true,
  //         })
  //         .then((response) => {
  //             // setData(response.data.data.detailofmovingloads.rows);
  //             console.log(response);

  //             setRow((rows) => {
  //                 let newRows = response.data.data.Detailtrucker
  //                 .rows.map((row, index) => {
  //                   return {
  //                     id: index + 1,
  //                     ...row,
  //                 }

  //             })
  //             return newRows

  //             })

  //                   setColumns((columns) => {
  //                     let newCols = response.data.data.Detailtrucker
  //                     .fields.map((col) => {
  //                       return {
  //                         field: col.name,
  //                         headerName: col.name
  //                           .split("_")
  //                           .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
  //                           .join(" "),

  //                         width: 150,
  //                       };
  //                     })
  //                     return newCols;
  //                 });

  //         })
  //         .catch((error) => {
  //             console.error("Error fetching data:", error);
  //         });

  // }, []);

  return (
    <>
      <div>
        <h1> New Truckers</h1>
      </div>
      {row && columns.length > 0 ? (
        <div style={{ display: "inline-block" }}>
          <FreightTable data={row} columns={columns} />
        </div>
      ) : (
        <div>No matching results found.</div>
      )}
    </>
  );
};

export default NewTrucker;
