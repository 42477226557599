import { Row } from "antd";
import classes from "../Pages.module.css";
import DataField from "./DataField";
//import AccountDetailsEdit from './AccountDetailsEdit';

const AccountDetails = (props) => {
  const handleChange = (e, param) => {
    props.setData((data) => {
      const newdata = [...data["postingheader"]];
      newdata[0][param] = e.target.value;
      return {
        ...data,
        postingheader: newdata,
      };
    });
  };

  const handleCheckboxChange = (value, param) => {
    let checkboxValue = "";

    if (value.includes("Yes")) {
      checkboxValue = "Y";
    } else if (value.includes("No")) {
      checkboxValue = "N";
    }

    console.log("Checkbox clicked:", checkboxValue);

    props.setData((data) => {
      const newdata = [...data["postingheader"]];
      newdata[0][param] = value[0];
      return {
        ...data,
        postingheader: newdata,
      };
    });
  };

  return (
    <div>
      <p></p>
      <Row className={props.editMode ? classes["RowDEX"] : classes["RowD"]}>
        <DataField
          editMode={props.editMode}
          lg={24}
          md={24}
          name="Hazmat Shipment"
          param="hazmat"
          type="Checkbox"
          handleChange={handleCheckboxChange}
          value={props.data.hazmat}
        />
        <DataField
          editMode={props.editMode}
          lg={24}
          md={24}
          name="Loading Tail Gate Required"
          param="ltgr__code"
          type="Checkbox"
          handleChange={handleCheckboxChange}
          value={props.data.ltgr__code}
        />
        <DataField
          editMode={props.editMode}
          lg={24}
          md={24}
          name="Unloading Tail Gate Required"
          param="un_tgr_code"
          type="Checkbox"
          handleChange={handleCheckboxChange}
          value={props.data.un_tgr_code}
        />
        <DataField
          editMode={props.editMode}
          lg={24}
          md={24}
          name="Loading Assistance Required"
          param="lar_code"
          type="Checkbox"
          handleChange={handleCheckboxChange}
          value={props.data.lar_code}
        />
        <DataField
          editMode={props.editMode}
          lg={24}
          md={24}
          name="Unloading Assistance Required"
          param="un_ar_code"
          type="Checkbox"
          handleChange={handleCheckboxChange}
          value={props.data.un_ar_code}
        />
        <DataField
          editMode={props.editMode}
          lg={24}
          md={24}
          name="Load Insured"
          param="insurance"
          type="Checkbox"
          handleChange={handleCheckboxChange}
          value={props.data.insurance}
        />
    
        {/* <DataField
          editMode={props.editMode}
          lg={12}
          md={24}
          name="Prefered Route"
          param="preferred_route"
          handleChange={handleChange}
          value={props.data.preferred_route}
        /> */}
      </Row>
      <p></p>
    </div>
  );
};

export default AccountDetails;
