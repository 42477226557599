import React, { useEffect, useRef, useState, useContext } from "react";
import IconPage from "./IconPage";
import Aform from "./Aform";
import axios from "axios";

// import DataContext from "../../../Context/dataContext";

// import the library
import { library } from "@fortawesome/fontawesome-svg-core";

// import your icons
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";

function ProfilePage(props) {
  console.log(props);

  



  return (
    <>
      {/* <br></br> */}
      <div className=" d-flex align-items-start"></div>

      <IconPage />
     
        <Aform  />
  
      
    </>
  );
}

export default ProfilePage;
