import axios from "axios";
import React, { useState, useEffect } from "react";
// import classes from "./SignUppage.module.css";
import { Link, useNavigate } from "react-router-dom";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";
import Validation from "../ValidationShipper/validation";
import classes from "./SignUppage.module.css";
import logoImage from "../../../assets/LOGO.png";
import { Content } from "antd/es/layout/layout";

import { InfoCircleOutlined, UserOutlined, EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { EyeOutlined } from '@ant-design/icons';
import { message, Input, Tooltip, Row, Col, Button } from "antd";

// import { useHistory } from "react-router-dom";
// import { useNavigate } from "react-router-dom";

function SignUpPage(props) {
  // const history = useHistory();

  const [successMessage, setSuccessMessage] = useState("");
  const [duplicateEmailError, setDuplicateEmailError] = useState(false);
  const [type, setType] = useState("shipper");
  const [emailValidate, setEmailValidate] = useState("false");
  const [email, setUserCode] = useState("");
  const [pwd, setPassword] = useState("");
  const [conPwd, setConPwd] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const [values, setValues] = useState({
    email: "",
    pwd: "",
    account_type: type,
    validation: emailValidate,
  });

  // const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const [backendError, setBackendError] = useState([]);

  const handleInput = (event) => {
    setValues((prev) => ({ ...prev, [event.target.name]: event.target.value }));
  };

  const handleConfirmPasswordInput = (event) => {
    setConPwd(event.target.value);
  };

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(values);
    console.log(conPwd);
    console.log(props.url);
    let err = Validation(values, values.pwd, conPwd);
    setErrors(err);
    console.log(err);

    if (err.email == "" && err.pwd == "" && err.password == "") {
      console.log("workling");
    } else {
      console.log("please Enter the fields properly");
      console.log(err.email && err.pwd);
      console.log(err);
      return;
    }

    const postdata = {
      signUp: [
        {
          ...values,
        },
      ],
    };
    console.log(postdata);
    axios
      .post(props.url + "/api/v1/signup/create-account", postdata, {
        withCredentials: true,
      })
      .then((res) => {
        console.log(res.data); // Log the response data specifically
        // setSuccessMessage("Registration successful! Please log in.");

        axios
          .patch(props.url + "/api/v1/type/validate", postdata, {
            withCredentials: true,
          })
          .then((response) => {
            // Handle the response of the second GET request here
            console.log(response.data);
          })
          .catch((error) => {
            // Handle errors of the second GET request here
            console.error(error);
          });
        message.success({
          content: "Complete email verification to log in securely.",
          className: "custom-class",
          style: {
            marginTop: "20vh",
          },
        });

        // alert("PLEASE VALIDATE YOUR EMAIL , TO SIGN UP NOW");
        navigate("/shipper"); // Navigate to the login page

        // You can also set a state for displaying a successful message
        // For example:
      })
      .catch((error) => {
        console.error("Error:", error);
        if (error.response.status === 410) {
          // alert("eamil id used")
          setDuplicateEmailError(true);
        }
      });
  };

  const imageStyle = {
    // position:'absolute',

    // marginTop: "20px",
    marginLeft: "20px",
    backgroundImage: `url(${logoImage})`,
    backgroundPosition: "top left",
    backgroundRepeat: "no-repeat",
    backgroundSize: "200px",
  };

  const navigate = useNavigate();

  useEffect(() => {
    // Load Google Sign-In API
    window.gapi.load("auth2", () => {
      window.gapi.auth2
        .init({
          client_id:
            "66230657890-idgmm57a1a6jrbb82j7o7voefajcp9ea.apps.googleusercontent.com",
        })
        .then((auth2) => {
          // You can use auth2 instance here if needed
        })
        .catch((error) => {
          console.error("Error initializing Google Sign-In:", error);
        });
    });
  }, []);

  return (
    <>
      <div className={classes["container"]}>
        <div
          className={classes["form-container"]}
          style={{
            // border: "1px solid gray",
            boxShadow: " 5px 2px 4px 1px gray",
            height: "37rem",
          }}
        >
          <div className={classes["logo"]} style={imageStyle}></div>

          <h2
            style={{
              color: "#8A31A8",
              textShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)",
              transform: "rotateX(20deg) translateZ(20px)",
              // fontSize: "30px",
              marginTop: "2rem",
            }}
          >
            Welcome
          </h2>

          {backendError ? (
            backendError.map((e) => <p className="text-danger">{e.msg}</p>)
          ) : (
            <span></span>
          )}
          <form action="" onSubmit={handleSubmit}>
            <Row gutter={[16, 0]} align="middle" style={{ display: 'flex', alignItems: 'center' }}>
            <Col flex="auto">
              <Input
                placeholder="Enter Email"
                onChange={handleInput}
                name="email"
                // className="form-control rounded-0"
                // prefix={<UserOutlined className="site-form-item-icon" />}
                style={{
                  width: "20rem", // Adjust the width as needed
                  height: "2.5rem", // Adjust the height as needed
                  border: "1px solid black",
                  boxShadow: '10 2px 17px  rgba(0, 0, 0, 0.2)', // Box shadow style
                  // border:"2px solid red",
                  margin: "auto"
                }}
              />
            </Col>

          </Row>
             
              
              {duplicateEmailError && (
                <span className="text-danger">Email already exists.</span>
              )}
              {errors.email && (
                <span className="text-danger"> {errors.email}</span>
              )}
            

            <div
              className="mb-3 d-flex flex-column"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
               <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  marginTop:"1rem"
                }}
              >
                 <Input.Password
                placeholder="Enter Password"
                onChange={handleInput}
                name="pwd"
                // className="form-control rounded-0 custom-input"
                style={{
                  width: "20rem", // Adjust the width as needed
                  height: "2.5rem", // Adjust the height as needed
                  border: "1px solid black",
                  boxShadow: '10 2px 17px 20px rgba(0, 0, 0, 0.2)', // Box shadow style
                  // border:"2px solid red",
                  margin: "auto"
                }}
                iconRender={visible => (visible ? <EyeOutlined /> : <EyeInvisibleOutlined />)}
              />
                
              </div>
              {errors.password && (
                <span className="text-danger">{errors.password}</span>
              )}
            </div>

            <div
              className="mb-3 d-flex flex-column"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                    marginTop:"1rem"
              }}
            >
              
<Input.Password
                  placeholder="Confirm Password"
                  name="pwd"
                  onChange={handleConfirmPasswordInput}
                // className="form-control rounded-0 custom-input"
                style={{
                  width: "20rem", // Adjust the width as needed
                  height: "2.5rem", // Adjust the height as needed
                  border: "1px solid black",
                  boxShadow: '10 2px 17px 20px rgba(0, 0, 0, 0.2)', // Box shadow style
                  // border:"2px solid red",
                  margin: "auto"
                }}
                iconRender={visible => (visible ? <EyeOutlined /> : <EyeInvisibleOutlined />)}
              />
               
              {errors.pwd && <span className="text-danger">{errors.pwd}</span>}
            </div>

            <button
              type="submit"
              className={classes["custom-btn"]}
              style={{ marginTop: "30px" }}
            >
              Register
            </button>

            <div
              style={{
                // border: "solid black",
                display: "flex",
                justifyContent: "center",
                marginTop: "1rem",
              }}
            >
              <GoogleOAuthProvider clientId="66230657890-idgmm57a1a6jrbb82j7o7voefajcp9ea.apps.googleusercontent.com">
                <GoogleLogin
                  onSuccess={(credentialResponse) => {
                    const token = "eyJ0eXAiO.../// jwt token";
                    const decoded = jwtDecode(credentialResponse.credential);

                    // Log the decoded object
                    console.log(decoded);

                    // Make a request with email and "googleAuth" as password and confirm password
                    const postdata = {
                      signUp: [
                        {
                          email: decoded.email,
                          pwd: "googleAuth",
                          account_type: "shipper", // You can set a specific account_type for Google authentication if needed
                        },
                      ],
                    };

                    axios
                      .post(
                        props.url + "/api/v1/signup/create-account",
                        postdata,
                        {
                          withCredentials: true,
                        }
                      )
                      .then((res) => {
                        console.log(res.data);
                        setSuccessMessage(
                          "Registration successful! Please log in."
                        );
                        message.success({
                          content:
                            "Thank you for signing up! Your account has been successfully created.",
                          className: "custom-class",
                          style: {
                            marginTop: "20vh",
                          },
                        });

                        navigate("/shipper");
                      })
                      .catch((error) => {
                        console.error("Error:", error);
                        if (
                          error.response.data.message.code === "23505" &&
                          error.response.data.message.constraint ===
                            "mst_fzulu_login_pkey"
                        ) {
                          setDuplicateEmailError(true);
                        }
                      });
                  }}
                  onError={() => {
                    console.log("Login Failed");
                  }}
                />
              </GoogleOAuthProvider>
            </div>

            {/* <div className = { classes["remember-font"] }><p >Already have an account? <Link  to='/Login' style={{ textDecoration: 'none' }}> <span style={{color: 'F0E5F3'}}> Login </span> </Link></p></div> */}
            <div
              className={classes["remember-font"]}
              style={{ marginTop: "9px" }}
            >
              <p>
                <span style={{ fontSize: "15px", fontWeight: "100" }}>
                  {" "}
                  Already have an account?{" "}
                </span>
                <Link
                  to="/shipper"
                  style={{ textDecoration: "none", fontSize: "15px" }}
                >
                  <span style={{ color: "#A951C7" }}> Login </span>
                </Link>
              </p>
            </div>
          </form>
        </div>
        {successMessage && <p className="text-success">{successMessage}</p>}
      </div>
    </>
  );
}

export default SignUpPage;
