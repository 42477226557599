// import React from "react";
// import axios from "axios";
// import { useEffect, useState } from "react";
// import {
//   LaptopOutlined,
//   NotificationOutlined,
//   LogoutOutlined,
//   SettingOutlined,
//   SearchOutlined,
//   UploadOutlined,
//   UserOutlined,
//   VideoCameraOutlined,
// } from "@ant-design/icons";

// import "./PayrollPage.css";
// import { Breadcrumb, Input, Layout, Menu, theme } from "antd";
// import logoImage from "../../../assets/LOGO.png";
// import SiderContentF from "../../../components/FreightModuleShipper/SiderContentShipper/SiderContentF";
// import FeildDataF from "../../../components/FreightModuleShipper/FeildDataShipper/FeildDataF";
// //import LoginPage from '../LoginPage/LoginPage';
// import { DataProvider } from "../../../Context/dataContext";
// import { Link, Route, Routes } from "react-router-dom";
// import ItemNew from "../../../components/FreightModuleShipper/ItemMaster/ItemNew";

// const { Header, Content, Sider } = Layout;
// const { Item: MenuItem } = Menu;
// const { Search } = Input;

// <Routes>
//   <Route path="/home/itemnew" element={<ItemNew />} end={true} />
// </Routes>;

// const items1 = [
//   {
//     key: "notification",
//     label: (
//       <MenuItem key="notification">
//         <Link to="/notification">
//           <NotificationOutlined style={{ fontSize: 20, color: "#000" }} />
//         </Link>
//       </MenuItem>
//     ),
//     onClick: () => {
//       // Handle click for Notification
//       console.log("Notification clicked");
//     },
//   },
//   {
//     key: "setting",
//     label: (
//       <MenuItem key="setting">
//         <Link to="/setting">
//           <SettingOutlined style={{ fontSize: 20, color: "#000" }} />
//         </Link>
//       </MenuItem>
//     ),
//     onClick: () => {
//       // Handle click for Setting
//       console.log("Setting clicked");
//     },
//   },
//   {
//     key: "profile",
//     label: (
//       <MenuItem key="profile">
//         <Link to="/home/profile">
//           <UserOutlined style={{ fontSize: 20, color: "#000" }} />
//         </Link>
//       </MenuItem>
//     ),
//     onClick: () => {
//       // Handle click for Profile
//       console.log("Profile clicked");
//     },
//   },
// ];

// const FreightModule = (props) => {
//   const [profiledata, setProfiledata] = useState(null);
//   console.log(props);
//   const FreightData = {
//     URL: props.url,
//     profiledata: props.profiledata,
//   };

//   useEffect(() => {
//     axios
//       .get(props.url + "/api/v1/load/pdata", { withCredentials: true })
//       .then((response) => {
//         console.log(response);
//         setProfiledata(response.data.data);
//       })

//       .catch((err) => {
//         console.log(err.response);
//       });
//   }, []);

//   const {
//     token: { colorBgContainer },
//   } = theme.useToken();

//   return (
//     <Layout>
//       <Header
//         style={{
//           background: "linear-gradient(to right, #F0E5F3, #FFFFFF)",
//           padding: "0rem",
//           height: "100%",
//         }}
//       >
//         <div
//           style={{
//             display: "flex",
//             alignItems: "center",
//             justifyContent: "space-between",
//           }}
//         >
//           {/* Insert your logo here */}
//           <img src={logoImage} style={{ height: "24px", width: "192px" }} />
//           {/* <div style={{ flex: '1', textAlign: 'center', display: 'flex', justifyContent: 'center' }}>
//             <Search
//               placeholder="Search"
//               onSearch={(value) => {
//                 console.log(value);
//               }}
//               style={{ maxWidth: '400px', backgroundColor: '#F0E5F3' }}
//             />
//           </div> */}
//           <div style={{ marginLeft: "auto", marginRight: "20px" }}>
//             <Menu theme="" mode="horizontal">
//               {items1.map((item) => (
//                 <MenuItem key={item.key} onClick={item.onClick}>
//                   {item.label}
//                 </MenuItem>
//               ))}
//             </Menu>
//           </div>
//         </div>
//       </Header>
//       <Layout>
//         <Sider
//           className="sidebar"
//           style={{ background: colorBgContainer, width: "10rem" }}
//         >
//           <SiderContentF url={props.url} profiledata={profiledata} />
//         </Sider>
//         <Layout
//           style={{
//             padding: "3.5rem 3rem 1rem 3rem",
//             background: "#F0E5F3",
//             width: "100%",
//           }}
//         >
//           <DataProvider value={FreightData}>
//             <Content
//               style={{
//                 padding: "0rem 1rem 1rem 1rem",
//                 minHeight: "45rem",
//                 background: colorBgContainer,
//                 overflow: "auto",
//               }}
//             >
//               <FeildDataF />
//             </Content>
//           </DataProvider>
//         </Layout>
//       </Layout>
//     </Layout>
//   );
// };

// export default FreightModule;

import React from "react";
import axios from "axios";
import { useEffect, useState } from "react";
import {
  LaptopOutlined,
  NotificationOutlined,
  LogoutOutlined,
  SettingOutlined,
  SearchOutlined,
  UploadOutlined,
  UserOutlined,
  VideoCameraOutlined,
} from "@ant-design/icons";

import "./PayrollPage.css";
import { Breadcrumb, Input, Layout, Menu, theme } from "antd";
import logoImage from "../../../assets/LOGO.png";
import SiderContentF from "../../../components/FreightModuleShipper/SiderContentShipper/SiderContentF";
import FeildDataF from "../../../components/FreightModuleShipper/FeildDataShipper/FeildDataF";
//import LoginPage from '../LoginPage/LoginPage';
import { DataProvider } from "../../../Context/dataContext";
import { Link, Route, Routes } from "react-router-dom";
import ItemNew from "../../../components/FreightModuleShipper/ItemMaster/ItemNew";

const { Header, Content, Sider } = Layout;
const { Item: MenuItem } = Menu;
const { Search } = Input;

<Routes>
  <Route path="/home/itemnew" element={<ItemNew />} end={true} />
</Routes>;

const items1 = [
  // {
  //   key: "notification",
  //   label: (
  //     <MenuItem key="notification">
  //       <Link to="/notification">
  //         <NotificationOutlined style={{ fontSize: 20, color: "#000" }} />
  //       </Link>
  //     </MenuItem>
  //   ),
  //   onClick: () => {
  //     // Handle click for Notification
  //     console.log("Notification clicked");
  //   },
  // },
  // {
  //   key: "setting",
  //   label: (
  //     <MenuItem key="setting">
  //       <Link to="/setting">
  //         <SettingOutlined style={{ fontSize: 20, color: "#000" }} />
  //       </Link>
  //     </MenuItem>
  //   ),
  //   onClick: () => {
  //     // Handle click for Setting
  //     console.log("Setting clicked");
  //   },
  // },
  {
    key: "profile",
    label: (
      <MenuItem key="profile">
        <Link to="/home/profile">
          <UserOutlined style={{ fontSize: 20, color: "#000" }} />
        </Link>
      </MenuItem>
    ),
    onClick: () => {
      // Handle click for Profile
      console.log("Profile clicked");
    },
  },
];

const FreightModule = (props) => {
  const [profiledata, setProfiledata] = useState(null);
  const [nameC, setNameC] = useState(null);

  console.log(props);

  const FreightData = {
    URL: props.url,
    profiledata: props.profiledata,
    check: props.check,
    setCheck: props.setCheck,
    mail:props.mail
  };
  let n;
  console.log(FreightData);
  console.log(FreightData.mail);
  if (FreightData.mail == null) {
    console.log("NO");
  } else {
    n = FreightData.mail;
  }
    console.log(n);
  console.log(FreightData.mail);

  useEffect(() => {
    axios
      .get(props.url + "/api/v1/load/pdata", { withCredentials: true })
      .then((response) => {
        console.log(response);
        setProfiledata(response.data.data);
      })

      .catch((err) => {
        console.log(err.response);
      });




      axios
      .get(props.url + "/api/v1/commonThings/getCompanyName", { withCredentials: true })
      .then((response) => {
        console.log(response);
      // setNameC(response.data.data.getName.rows[0].account_name);
      let fetchedName = response.data.data.getName.rows[0].account_name;
        
      // Split the fetchedName string by spaces
      let nameParts = fetchedName.split(" ");

      // Take the first two words (or less if there are fewer than two words)
      let truncatedName = nameParts.slice(0, 2).join(" ");
      console.log(truncatedName," there is the name");
      
      setNameC(truncatedName);
  })

      .catch((err) => {
        console.log(err.response);
      });
  }, []);

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  return (
    <Layout>
      <Header
        style={{
          background: "linear-gradient(to right, #F0E5F3, #FFFFFF)",
          padding: "0rem",
          height: "100%",
          // width:"100vw",

        }}
      >
        <div
          style={{
            // border:"2px solid red",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {/* Insert your logo here */}
          <img src={logoImage} style={{ height: "24px", width: "192px" }} />
          {/* <div style={{ flex: '1', textAlign: 'center', display: 'flex', justifyContent: 'center' }}>
            <Search
              placeholder="Search"
              onSearch={(value) => {
                console.log(value);
              }}
              style={{ maxWidth: '400px', backgroundColor: '#F0E5F3' }}
            />
          </div> */}
          <div style={{ marginLeft: "auto", marginRight: "20px" }}>
            <Menu theme="" mode="horizontal">
              {nameC!=null? <div>
                Hello, {nameC}
              </div>:"Please Fill Your Profile" }
              {items1.map((item) => (
                <MenuItem style={{}} key={item.key} onClick={item.onClick}>
                  {item.label}
                </MenuItem>
              ))}
            </Menu>
          </div>
        </div>
      </Header>



      <Layout
      style={{  }}
      >
        <Sider
        width={150}
          className="sidebar"
          style={{ background: colorBgContainer }}
        >
          <SiderContentF
            url={props.url}
            profiledata={profiledata}
            check={props.check}
       
          />
        </Sider>
        <Layout
          style={{
            padding: "1rem 1rem 1rem 1rem",
            background: "#F0E5F3",
            width: "100%",
            // border:"2px solid green"
          }}
        >
          <DataProvider value={FreightData}>
            <Content
              style={{
                padding: "0rem 1rem 1rem 1rem",
                minHeight: "45rem",
                background: colorBgContainer,
                overflow: "auto",
              }}
            >
              <FeildDataF />
            </Content>
          </DataProvider>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default FreightModule;
