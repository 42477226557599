import { Layout } from "antd";
import { Link, Route, Routes } from "react-router-dom";
//import React from 'react';
import LoginPage from "../../../Pages/FzuluPageTrucker/LoginPageTrucker/LoginPageTrucker";

import SiderContentF from "../SiderContentTrucker/SiderContentF";
import LayoutWrapper from "../../LayoutWrapper/LayoutWrapper";
import { useLocation } from "react-router-dom";

import ProfilePage from "../Profile/ProfilePage";
import ViewBids from "../ViewBids/ViewBids";
import LoadView from "../LoadView/LoadView";
import Bidding from "../ViewBids/Bidding";
import Payment from "../Payment/payment";
import Pendingload from "../PendingLoad/pendingload";
import Deliveredloads from "../Deliverdoad/delivered";
import Preference from "../../../Pages/FzuluPageTrucker/PreferenceTrucker/preference";
import Mytruck from "../MY-Truck/mytruck";
import TruckDetails from "../TruckDetails/TruckDetails";
import Dashboard from "../Dashbord/Maindash";
import Ledger from "../MyLedger/Ledger";
import Checking from "../../../Pages/FzuluPageTrucker/Check/Check";
const FeildDataF = (fieldProps) => {
  const location = useLocation();

  console.log("Current path:", location.pathname);

  return (
    <>
      <Routes>
        {/* <Route path="/" element={<BasicDetails />}/> */}
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/load-view" element={<LoadView />} />
        <Route path="/profilet" element={<ProfilePage />} />
        <Route path="/view-bids" element={<ViewBids />} />
        <Route path="/load-data/load-view/:id" element={<Bidding />} />
        <Route path="/payment" element={<Payment />} />
        <Route path="/pending-load-view" element={<Pendingload />} />
        <Route path="/delivered-loads" element={<Deliveredloads />} />
        <Route path="/preference" element={<Preference />} />
        <Route path="/truck" element={<Mytruck />} />
        <Route path="/truckDetails" element={<TruckDetails />} />
        <Route path="/Checking" element={<Checking />} />

        {/* <Route path="/trucker" element={<Dashboard />} /> */}
        <Route path="/ledger" element={<Ledger />} />
      </Routes>
    </>
  );
};

export default FeildDataF;
