import { Row, Col } from "antd";
import classes from "../Pages.module.css";
import DataField from "./DataField";
import { Button } from "antd";
import moment from "moment";
import dayjs from "dayjs";
import React, { useEffect, useRef, useState } from "react";
const BasicDetails = (props) => {
  let AdressComponents;

  const autocompleteInputRef = useRef(null);
  const aautocompleteInputRef = useRef(null);
  const aaautocompleteInputRef = useRef(null);
  const timePickerRef = useRef(null);

  const [fromDate,setFromDate] = useState("")
  const [toDate,setToDate] = useState("")
  const [check,setCheck] = useState()

  const [current,setCurrent] = useState("hello")
  const [anotherCheck,setAnotherCheck] = useState("")



  
  
  const googleChange = (e, param, inputRef, id) => {
    console.log(e.target.value);
    console.log(param);
    console.log(inputRef);
    console.log(id);
    if (param === "origin_address") {
      console.log(aaautocompleteInputRef.current);
     

      const initializeAutocomplete = () => {
        // const input = originAutocompleteRef.current;
        const autocomplete = new window.google.maps.places.Autocomplete( document.getElementById("goo"), {
          fields: ["formatted_address", "address_components", "geometry", "name"],
          strictBounds: false,
        });
        console.log(autocomplete);
      // const autocomplete = new window.google.maps.places.Autocomplete(aaautocompleteInputRef.current, {
      //   fields: ["formatted_address", "address_components", "geometry", "name"],
      //   strictBounds: false,
      // });
      // console.log(autocomplete," this sis the autocomplete");
      
  
      function setupClickListener(id, types) {
        const radioButton = document.getElementById(id);
        if (radioButton) {
          radioButton.addEventListener("click", () => {
            autocomplete.setTypes(types);
            input.value = "";
          });
        }
      }
  console.log("123");
  setupClickListener("changetype-all", []);
  setupClickListener("changetype-address", ["address"]);
  setupClickListener("changetype-establishment", ["establishment"]);
  setupClickListener("changetype-geocode", ["geocode"]);
  setupClickListener("changetype-cities", ["(cities)"]);
  setupClickListener("changetype-regions", ["(regions)"]);
  
  console.log("456");
  const biasInputElement = document.getElementById("use-location-bias");
      const strictBoundsInputElement = document.getElementById("use-strict-bounds");
  
      if (biasInputElement) {
        biasInputElement.addEventListener("change", () => {
          if (biasInputElement.checked) {
            autocomplete.bindTo("bounds", map);
          } else {
            autocomplete.unbind("bounds");
            autocomplete.setBounds({ east: 180, west: -180, north: 90, south: -90 });
            strictBoundsInputElement.checked = biasInputElement.checked;
          }
  
          input.value = "";
        });
      }
  
      if (strictBoundsInputElement) {
        strictBoundsInputElement.addEventListener("change", () => {
          autocomplete.setOptions({
            strictBounds: strictBoundsInputElement.checked,
          });
          if (strictBoundsInputElement.checked) {
            biasInputElement.checked = strictBoundsInputElement.checked;
            autocomplete.bindTo("bounds", map);
          }
  
          input.value = "";
        });
      }
  
      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        if (!place.geometry) {
          console.log("Place details not found for input: ", input.value);
          return;
        }
  
        const address = place.formatted_address;
        const addressComponents = place.address_components;
        // console.log();
        // console.log(addressComponents, " thisis the adress component");
  
        let city = "";
        let state = "";
        let zip = "";
  
        // Loop through address components to find city, state, and zip
        addressComponents.forEach(component => {
          const types = component.types;
          if (types.includes("locality")) {
            city = component.long_name;
          } else if (types.includes("administrative_area_level_1")) {
            // console.log(addressComponents, " thisis the adress component");
            state = component.long_name;
          } else if (types.includes("postal_code")) {
            zip = component.long_name;
          }
        });
  
             
        const selectedAddress = document.getElementById('goo').value;
        console.log("this is the thing", selectedAddress);
  
        // Update the fields with the extracted values
        document.getElementById("state").value = state;
        console.log(state);
        document.getElementById("city").value = city;
        document.getElementById("zipcode").value = zip;
        props.setData((data) => {
          const newdata = [...data["postingheader"]];
          newdata[0]["origin_city_cd"] = city;
          newdata[0]["origin_address"] = selectedAddress;
          newdata[0]["origin_state_cd"] = state;
          newdata[0]["origin_zip_cd"] = zip;
  
          return {
            ...data,
            postingheader: newdata,
          };
        });
      });
    }


    initializeAutocomplete();
      // const input =aaautocompleteInputRef.current
     
    //     const autocomplete = new window.google.maps.places.Autocomplete(aaautocompleteInputRef.current, {
    //       fields: ["formatted_address", "address_components", "geometry", "name"],
    //       strictBounds: false,
    //     });
    //     console.log(autocomplete," this sis the autocomplete");
        

    //     function setupClickListener(id, types) {
    //       const radioButton = document.getElementById(id);
    //       if (radioButton) {
    //         radioButton.addEventListener("click", () => {
    //           autocomplete.setTypes(types);
    //           input.value = "";
    //         });
    //       }
    //     }
    // console.log("123");
    // setupClickListener("changetype-all", []);
    // setupClickListener("changetype-address", ["address"]);
    // setupClickListener("changetype-establishment", ["establishment"]);
    // setupClickListener("changetype-geocode", ["geocode"]);
    // setupClickListener("changetype-cities", ["(cities)"]);
    // setupClickListener("changetype-regions", ["(regions)"]);
    
    // console.log("456");
    // const biasInputElement = document.getElementById("use-location-bias");
    //     const strictBoundsInputElement = document.getElementById("use-strict-bounds");
    
    //     if (biasInputElement) {
    //       biasInputElement.addEventListener("change", () => {
    //         if (biasInputElement.checked) {
    //           autocomplete.bindTo("bounds", map);
    //         } else {
    //           autocomplete.unbind("bounds");
    //           autocomplete.setBounds({ east: 180, west: -180, north: 90, south: -90 });
    //           strictBoundsInputElement.checked = biasInputElement.checked;
    //         }
    
    //         input.value = "";
    //       });
    //     }
    
    //     if (strictBoundsInputElement) {
    //       strictBoundsInputElement.addEventListener("change", () => {
    //         autocomplete.setOptions({
    //           strictBounds: strictBoundsInputElement.checked,
    //         });
    //         if (strictBoundsInputElement.checked) {
    //           biasInputElement.checked = strictBoundsInputElement.checked;
    //           autocomplete.bindTo("bounds", map);
    //         }
    
    //         input.value = "";
    //       });
    //     }
    
    //     autocomplete.addListener("place_changed", () => {
    //       const place = autocomplete.getPlace();
    //       if (!place.geometry) {
    //         console.log("Place details not found for input: ", input.value);
    //         return;
    //       }
    
    //       const address = place.formatted_address;
    //       const addressComponents = place.address_components;
    //       // console.log();
    //       // console.log(addressComponents, " thisis the adress component");
  
    //       let city = "";
    //       let state = "";
    //       let zip = "";
    
    //       // Loop through address components to find city, state, and zip
    //       addressComponents.forEach(component => {
    //         const types = component.types;
    //         if (types.includes("locality")) {
    //           city = component.long_name;
    //         } else if (types.includes("administrative_area_level_1")) {
    //           // console.log(addressComponents, " thisis the adress component");
    //           state = component.long_name;
    //         } else if (types.includes("postal_code")) {
    //           zip = component.long_name;
    //         }
    //       });
  
               
    //       const selectedAddress = document.getElementById('goo').value;
    //       console.log("this is the thing", selectedAddress);

    //       // Update the fields with the extracted values
    //       document.getElementById("state").value = state;
    //       console.log(state);
    //       document.getElementById("city").value = city;
    //       document.getElementById("zipcode").value = zip;
    //       props.setData((data) => {
    //         const newdata = [...data["postingheader"]];
    //         newdata[0]["origin_city_cd"] = city;
    //         newdata[0]["origin_address"] = selectedAddress;
    //         newdata[0]["origin_state_cd"] = state;
    //         newdata[0]["origin_zip_cd"] = zip;

    //         return {
    //           ...data,
    //           postingheader: newdata,
    //         };
    //       });
    //     });
      
    } else if (param === "des_address") {

      console.log(aaautocompleteInputRef.current);
     

      const initializeAutocomplete = () => {
        // const input = originAutocompleteRef.current;
        const autocomplete = new window.google.maps.places.Autocomplete( document.getElementById("doo"), {
          fields: ["formatted_address", "address_components", "geometry", "name"],
          strictBounds: false,
        });
        console.log(autocomplete);
      // const autocomplete = new window.google.maps.places.Autocomplete(aaautocompleteInputRef.current, {
      //   fields: ["formatted_address", "address_components", "geometry", "name"],
      //   strictBounds: false,
      // });
      // console.log(autocomplete," this sis the autocomplete");
      
  
      function setupClickListener(id, types) {
        const radioButton = document.getElementById(id);
        if (radioButton) {
          radioButton.addEventListener("click", () => {
            autocomplete.setTypes(types);
            input.value = "";
          });
        }
      }
  console.log("123");
  setupClickListener("changetype-all", []);
  setupClickListener("changetype-address", ["address"]);
  setupClickListener("changetype-establishment", ["establishment"]);
  setupClickListener("changetype-geocode", ["geocode"]);
  setupClickListener("changetype-cities", ["(cities)"]);
  setupClickListener("changetype-regions", ["(regions)"]);
  
  console.log("456");
  const biasInputElement = document.getElementById("use-location-bias");
      const strictBoundsInputElement = document.getElementById("use-strict-bounds");
  
      if (biasInputElement) {
        biasInputElement.addEventListener("change", () => {
          if (biasInputElement.checked) {
            autocomplete.bindTo("bounds", map);
          } else {
            autocomplete.unbind("bounds");
            autocomplete.setBounds({ east: 180, west: -180, north: 90, south: -90 });
            strictBoundsInputElement.checked = biasInputElement.checked;
          }
  
          input.value = "";
        });
      }
  
      if (strictBoundsInputElement) {
        strictBoundsInputElement.addEventListener("change", () => {
          autocomplete.setOptions({
            strictBounds: strictBoundsInputElement.checked,
          });
          if (strictBoundsInputElement.checked) {
            biasInputElement.checked = strictBoundsInputElement.checked;
            autocomplete.bindTo("bounds", map);
          }
  
          input.value = "";
        });
      }
  
      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        if (!place.geometry) {
          console.log("Place details not found for input: ", input.value);
          return;
        }
  
        const address = place.formatted_address;
        const addressComponents = place.address_components;
        // console.log();
        // console.log(addressComponents, " thisis the adress component");
  
        let city = "";
        let state = "";
        let zip = "";
  
        // Loop through address components to find city, state, and zip
        addressComponents.forEach(component => {
          const types = component.types;
          if (types.includes("locality")) {
            city = component.long_name;
          } else if (types.includes("administrative_area_level_1")) {
            // console.log(addressComponents, " thisis the adress component");
            state = component.long_name;
          } else if (types.includes("postal_code")) {
            zip = component.long_name;
          }
        });
  
             
        const selectedAddress = document.getElementById('doo').value;
        console.log("this is the thing", selectedAddress);
  
        // Update the fields with the extracted values
        document.getElementById("state").value = state;
        console.log(state);
        document.getElementById("city").value = city;
        document.getElementById("zipcode").value = zip;
        props.setData((data) => {
          const newdata = [...data["postingheader"]];
          newdata[0]["des_city_cd"] = city;
          newdata[0]["des_address"] = selectedAddress;
          newdata[0]["des_state_cd"] = state;
          newdata[0]["des_zip_cd"] = zip;
  
          return {
            ...data,
            postingheader: newdata,
          };
        });
      });
    }


    initializeAutocomplete();
      
      // if (id === "doo" && aautocompleteInputRef.current) {
      //   console.log(inputRef);
      //   const autocomplete = new window.google.maps.places.Autocomplete(
      //     document.getElementById("doo")
      //   );
      //   console.log(autocomplete,"destination auto");

      //   autocomplete.addListener("place_changed", () => {
      //     const place = autocomplete.getPlace();
      //     const addressComponents = place.address_components || [];

      //     let state = "";
      //     let city = "";
      //     let zip = "";

      //     const fullAddressd = addressComponents
      //       .map((component) => component.long_name)
      //       .join(", ");

      //     console.log(fullAddressd);

      //     addressComponents.forEach((component) => {
      //       if (component.types.includes("administrative_area_level_1")) {
      //         state = component.long_name;
      //       } else if (component.types.includes("locality")) {
      //         city = component.long_name;
      //       } else if (component.types.includes("postal_code")) {
      //         zip = component.long_name;
      //       }
      //     });

      //     // Update the fields with the extracted values
      //     document.getElementById("dstate").value = state;
      //     document.getElementById("dcity").value = city;
      //     document.getElementById("dzipcode").value = zip;

      //     props.setData((data) => {
      //       const newdata = [...data["postingheader"]];
      //       newdata[0]["des_city_cd"] = city;
      //       newdata[0]["des_address"] = fullAddressd;
      //       newdata[0]["des_state_cd"] = state;
      //       newdata[0]["des_zip_cd"] = zip;

      //       return {
      //         ...data,
      //         postingheader: newdata,
      //       };
      //     });
      //   });
      // }
    }
  };

  const handleChange = (e, param) => {
    props.setData((data) => {
      const newdata = [...data["postingheader"]];
      newdata[0][param] = e.target.value;
      return {
        ...data,
        postingheader: newdata,
      };
    });
  };

  const handleSChange = (val, param) => {
    props.setData((data) => {
      const newdata = [...data["postingheader"]];
      newdata[0][param] = val;
      return {
        ...data,
        postingheader: newdata,
      };
    });
 
  };

  const handleDChange = (date, param) => {
    console.log(param);
    console.log(date);
  
    if (param === "loading_date") {
      if (date && dayjs(date, "DD-MM-YYYY").isValid()) {
        const formattedDate = dayjs(date, "DD-MM-YYYY").format("DD-MM-YYYY");
        console.log(formattedDate);
        setFromDate(date);
        setToDate(date);
        setCurrent(undefined)
       
        
        setCheck(true)

  
        // Check if toDate is before the selected fromDate, then update toDate
      
  
        props.setData((data) => {
          const newdata = [...data["postingheader"]];
          newdata[0][param] = formattedDate;
          return {
            ...data,
            postingheader: newdata,
          };
        });
      } else {
        console.log("Invalid date format or null date");
      }
    }
  
    if (param === "unloading_date") {
      if (date && dayjs(date, "DD-MM-YYYY").isValid()) {
        const formattedDate = dayjs(date, "DD-MM-YYYY").format("DD-MM-YYYY");
        console.log(formattedDate);
        // setToDate(date);
        setCheck(false)
        // setCurrent(undefined)
        setAnotherCheck(date)
  
        // Check if fromDate is after the selected toDate, then update fromDate
       
  
        props.setData((data) => {
          const newdata = [...data["postingheader"]];
          newdata[0][param] = formattedDate;
          return {
            ...data,
            postingheader: newdata,
          };
        });
      } else {
        console.log("Invalid date format or null date");
      }
    }
  };
  
  const setMaxFromDate = () => {
    return {
      maxDate: toDate,
    };
  };
  
  const setMinToDate = () => {
    return {
      minDate: fromDate,
    };
  };
  



  // const handleDChange = (date, param) => {
  //   console.log(param);
  //   console.log(date);
  
  //   if (param === "loading_date") {
  //     if (date && dayjs(date, "DD-MM-YYYY").isValid()) {
  //       const formattedDate = dayjs(date, "DD-MM-YYYY").format("DD-MM-YYYY");
  //       console.log(formattedDate);
  //       setFromDate(formattedDate);
  
  //       // Check if toDate is before the selected fromDate, then update toDate
  //       if (toDate && dayjs(toDate, "DD-MM-YYYY").isBefore(formattedDate, "day")) {
  //         setToDate(formattedDate);
  //       }
  
  //       props.setData((data) => {
  //         const newdata = [...data["postingheader"]];
  //         newdata[0][param] = formattedDate;
  //         return {
  //           ...data,
  //           postingheader: newdata,
  //         };
  //       });
  //     } else {
  //       console.log("Invalid date format or null date");
  //     }
  //   }
  
  //   if (param === "unloading_date") {
  //     if (date && dayjs(date, "DD-MM-YYYY").isValid()) {
  //       const formattedDate = dayjs(date, "DD-MM-YYYY").format("DD-MM-YYYY");
  //       console.log(formattedDate);
  //       setToDate(formattedDate);
  
  //       // Check if fromDate is after the selected toDate, then update fromDate
  //       if (fromDate && dayjs(fromDate, "DD-MM-YYYY").isAfter(formattedDate, "day")) {
  //         setFromDate(formattedDate);
  //       }
  
  //       props.setData((data) => {
  //         const newdata = [...data["postingheader"]];
  //         newdata[0][param] = formattedDate;
  //         return {
  //           ...data,
  //           postingheader: newdata,
  //         };
  //       });
  //     } else {
  //       console.log("Invalid date format or null date");
  //     }
  //   }
  // };
  



  // const handleDChange = (date, param) => {
  //   console.log(param);
  //   console.log(date);
  //   if (date && dayjs(date, "DD-MM-YYYY").isValid()) {
  //     // Convert the date to the format expected by the state (e.g., "YYYY-MM-DD")
  //     const formattedDate = dayjs(date, "DD-MM-YYYY").format("DD-MM-YYYY");
  //     console.log(formattedDate);

  //     props.setData((data) => {
  //       const newdata = [...data["postingheader"]];
  //       newdata[0][param] = formattedDate; // Use the formatted date
  //       return {
  //         ...data,
  //         postingheader: newdata,
  //       };
  //     });
  //   } else {
  //     // Handle invalid date or null date (optional, based on your needs)
  //     console.log("Invalid date format or null date");
  //   }
  // };

  const handleTChange = (time, param) => {
    if (time && dayjs(time).isValid()) {
      // Convert the time to the format expected by the state (e.g., "HH:mm:ss")
      const formattedTime = dayjs(time).format("HH:mm:ss");
      console.log(formattedTime);

      props.setData((data) => {
        const newdata = [...data["postingheader"]];
        newdata[0][param] = formattedTime; // Use the formatted time
        return {
          ...data,
          postingheader: newdata,
        };
      });
    } else {
      // Handle invalid time or null time (optional, based on your needs)
      console.log("Invalid time format or null time");
    }
  };

  
 
  const handleDTChange = (time, param) => {
    if (time && dayjs(time).isValid()) {
      // Convert the time to the format expected by the state (e.g., "YYYY-MM-DD HH:mm:ss")
      const formattedDateTime = dayjs(time).format("DD-MM-YYYY HH:mm:ss");
      console.log(formattedDateTime);

      props.setData((data) => {
        const newdata = [...data["postingheader"]];
        newdata[0][param] = formattedDateTime; // Use the formatted date and time
        return {
          ...data,
          postingheader: newdata,
        };
      });
    } else {
      // Handle invalid date and time or null date and time (optional, based on your needs)
      console.log("Invalid date and time format or null date and time");
    }
  };

  

  return (
    <div>
      {/* <div style={{ display: 'flex', border:"2px solid black", justifyContent:"space-evenly"}}>
      <div style={{display:"flex", flexDirection:"column"}}>
        <div>
        <label htmlFor="origin">ORIGIN</label>
        </div>
        <input type="text" name="origin" placeholder="origin" ref={autocompleteInputRef}  />
        <br />
        <div style={{diplay:"flex", flexDirection:"row"}}>

        <input type="text" name="state" placeholder="state" />
        <input type="text" name="city" placeholder="city" />
        <input type="text" name="zip" placeholder="zip" />
        </div>
      </div>

      <br />

      <div style={{display:"flex", flexDirection:"column"}}>
        <div>
        <label htmlFor="destination">Destination</label>
        </div>
        <input type="text" name="destination" placeholder="destination" ref={autocompleteInputRef} />
        <br />
        <div style={{diplay:"flex", flexDirection:"row"}}>

        <input type="text" name="dstate" placeholder="state" />
        <input type="text" name="dcity" placeholder="city" />
        <input type="text" name="dzip" placeholder="zip" />
        </div>
      </div>
      </div> */}
 
      <p></p>

      <Row className={props.editMode ? classes["RowDEX"] : classes["RowD"]}>
        <DataField
          editMode={props.editMode}
          lg={11}
          md={24}
          id="goo"
          handleGChange={googleChange}
          handleChange={handleChange}
          name="Origin Address"
          param="origin_address"
          inputRef={aaautocompleteInputRef}
          value={props.data.origin_address}
          required="True"
        />
        <Col lg={1} md={2} />
        <DataField
          editMode={props.editMode}
          lg={11}
          md={24}
          id="doo"
          handleGChange={googleChange}
          handleChange={handleChange}
          name="Destination Address"
          param="des_address"
          inputRef={aautocompleteInputRef}
          value={props.data.des_address}
          required="True"
        />
        <DataField
          editMode={props.editMode}
          lg={4}
          md={8}
          handleChange={handleChange}
          name="State"
          id="state"
          param="origin_state_cd"
          value={props.data.origin_state_cd}
          required="True"
        />
        <DataField
          editMode={props.editMode}
          lg={4}
          md={8}
          handleChange={handleChange}
          name="City"
          id="city"
          param="origin_city_cd"
          value={props.data.origin_city_cd}
          required="True"
        />
        <DataField
          editMode={props.editMode}
          lg={3}
          md={8}
          handleChange={handleChange}
          name="Zip code"
          id="zipcode"
          param="origin_zip_cd"
          value={props.data.origin_zip_cd}
          required="True"
        />

        <Col lg={1} md={2} />

        {/* This Col creates a space of 4 columns in large screens (lg) and is hidden in medium screens (md) */}

        <DataField
          editMode={props.editMode}
          lg={4}
          md={8}
          handleChange={handleChange}
          id="dstate"
          name="State"
          param="des_state_cd"
          value={props.data.des_state_cd}
          required="True"
        />

        <DataField
          editMode={props.editMode}
          lg={4}
          md={8}
          handleChange={handleChange}
          id="dcity"
          name="City"
          param="des_city_cd"
          value={props.data.des_city_cd}
          required="True"
        />
        <DataField
          editMode={props.editMode}
          lg={3}
          md={8}
          handleChange={handleChange}
          id="dzipcode"
          name="Zip code"
          param="des_zip_cd"
          value={props.data.des_zip_cd}
          required="True"
        />

        <DataField
          editMode={props.editMode}
          lg={5}
          md={12}
          handleChange={handleDChange}
          type="Date"
          name="Pick Up Date"
          param="loading_date"
          id="from_date"
          value={props.data.loading_date}
          required="True"
          mydatee={fromDate}
          check={check}
          current={current}
          anotherCheckr={anotherCheck}
         
        />

        <DataField
          editMode={props.editMode}
          lg={5}
          md={12}
          handleChange={handleTChange}
          type="Time"
          name="Pick Up Time"
          param="loading_time"
          value={props.data.loading_time}
          required="True"
          id="loadintime"
          ref={timePickerRef}
        />
        <Col lg={2} md={2} />
        <DataField
          editMode={props.editMode}
          lg={5}
          md={12}
          handleChange={handleDChange}
          type="Date"
          name="Drop Off Date"
          param="unloading_date"
          id="unloaddate"
          value={props.data.unloading_date}
          required="True"
          // onChange={setMaxFromDate} onFocus={setMinToDate}
          mydate={toDate}
          check={check}
          current={current}
          anotherCheckrr={anotherCheck}
        />
        <DataField
          editMode={props.editMode}
          lg={5}
          md={12}
          handleChange={handleTChange}
          type="Time"
          name="Drop Off Time"
          param="unloading_time"
          value={props.data.unloading_time}
          required="True"
        />
        <DataField
          editMode={props.editMode}
          lg={6}
          md={12}
          handleChange={handleDTChange}
          // onOk={onOk}
          type="TimewithDate"
          name="Bidding end Time"
          param="bidding_end_time"
          value={props.data.bidding_end_time}
          required="True"
          id="bidding_end_time"
        />

        <Col lg={6} md={12} />
        <DataField
          editMode={props.editMode}
          lg={6}
          md={12}
          handleChange={handleSChange}
          id="vehicletype"
          name="Vehicle Type"
          param="type_code"
          options={props.ad.TYPE_CODE}
          required="True"
          type="Select"
          sc={props.sc}
        />

        <DataField
          editMode={props.editMode}
          lg={5}
          md={24}
          name="Take This Load Now At $"
          param="load_amount"
          handleChange={handleChange}
          value={props.data.load_amount}
          id="amount_up"
          numericInput
        />
      </Row>
    </div>
  );
};

export default BasicDetails;
