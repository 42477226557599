import React, { useState, useRef, useEffect, useContext } from "react";
import { useJsApiLoader, Autocomplete } from "@react-google-maps/api";
import { message } from "antd";
import { useLocation } from "react-router-dom";

import "./Aform.css";
import axios from "axios";

import { useNavigate } from "react-router-dom";

const filledInputs = new Set();

const Aform = (props) => {
  const [formData, setFormData] = useState({});

  const location = useLocation();
  const id = location.pathname ? location.pathname.split("/").pop() : null;

  // const [formData, setFormData] = useState({
  //   account_name: props.data[0].account_name || "",
  //   contact_name: props.data[0].contact_name || "",
  //   company_add: props.data[0].company_add || "",
  //   state_code: props.data[0].state_code || "",
  //   city_code: props.data[0].city_code || "",
  //   pin_code: props.data[0].pin_code || "",
  //   company_registration_no: props.data[0].company_registration_no || "",
  //   company_tax_no: props.data[0].company_tax_no || "",
  //   company_size: props.data[0].company_size || "",
  //   industry_type_cd: props.data[0].industry_type_cd || "",
  //   annual_revenue: props.data[0].annual_revenue || "",
  //   vichel_type_cd: props.data[0].vichel_type_cd || "",
  //   age_of_mc: props.data[0].age_of_mc || "",
  //   start_location: props.data[0].start_location || "",
  //   load_type_cd: props.data[0].load_type_cd || "",
  //   carrier_rating_cd: props.data[0].carrier_rating_cd || "",
  //   end_location: props.data[0].end_location || "",
  // });

  const history = useNavigate();

  useEffect(() => {
    console.log("hey there thissi stesting is going on");

    axios
      .get(`http://yzulu.com/api/v1/admin/shipper-profile-data/${id}`, {
        withCredentials: true,
      })
      .then((response) => {
        const data = response.data.loads.rows[0];
        setFormData(data);
        console.log(response);
        console.log("just checking");
        console.log(response.data.loads.rows[0]);
      })
      .catch((errors) => {
        console.log(errors);
      });
  }, []);

  return (
    <>
      <form className="hereForm" name="myForm">
        <div className="mb-3">
          <div>
            <label
              htmlFor="companyName"
              className="d-flex justify-content-start"
            >
              Company Name <span style={{ color: "red" }}>*</span>
            </label>
          </div>
          <input
            name="account_name"
            type="text"
            id="companyName"
            className={`form-control `}
            placeholder="Company Name:"
            value={formData.account_name}
            style={{ backgroundColor: "#f0e5f3" }}
          />
        </div>

        <div className="mb-3">
          <div>
            <label htmlFor="contact" className="d-flex justify-content-start">
              Contact <span style={{ color: "red" }}>*</span>
            </label>
          </div>
          <div className="d-flex">
            <input
              name="contact_name"
              type="text"
              id="contact"
              className={`form-control `}
              placeholder="Contact:"
              value={formData.contact_name}
              style={{ backgroundColor: "#f0e5f3", width: "20%" }}
            />
          </div>
        </div>

        <div class="d-flex justify-content-start">
          <h5>Company Address</h5>
        </div>

        <div className="Company-Address">
          <div className="mb-3">
            <div>
              <label htmlFor="Address" class="d-flex justify-content-start">
                {" "}
                Address <span style={{ color: "red" }}>*</span>
              </label>
            </div>

            <input
              name="company_add"
              type="text"
              id="Address"
              className={`form-control `}
              placeholder="Address:"
              value={formData.company_add}
              style={{ backgroundColor: "#f0e5f3" }}
            />
          </div>

          <div className=" d-flex justify-content-between mb-3 " id="first3">
            <div style={{ width: "13rem" }}>
              <div className="d-flex">
                <label htmlFor="state">
                  State <span style={{ color: "red" }}>*</span>
                </label>
              </div>
              <input
                name="state_code"
                type="text"
                id="state"
                className={`form-control `}
                placeholder="State:"
                value={formData.state_code}
                //
                style={{ backgroundColor: "#f0e5f3", width: "13em" }}
              />
            </div>

            <div style={{ width: "13rem" }}>
              <div className="d-flex">
                <label htmlFor="city">
                  City <span style={{ color: "red" }}>*</span>
                </label>
              </div>
              <input
                name="city_code"
                type="text"
                id="city"
                className={`form-control `}
                placeholder="City:"
                value={formData.city_code}
                style={{ backgroundColor: "#f0e5f3", width: "13rem" }}
              />
            </div>

            <div style={{ width: "13rem" }}>
              <div className="d-flex ">
                <label htmlFor="zipCode">
                  Zip code <span style={{ color: "red" }}>*</span>
                </label>
              </div>
              <input
                name="pin_code"
                type="text"
                id="zip"
                className={`form-control `}
                placeholder="Zip code:"
                value={formData.pin_code}
                style={{ backgroundColor: "#f0e5f3", width: "13rem" }}
              />
            </div>
          </div>

          <div className="d-flex justify-content-between mb-3 " id="second2">
            <div style={{ width: "15rem" }}>
              <div className="d-flex">
                <label htmlFor="Registration">
                  Company Registration Number{" "}
                  <span style={{ color: "red" }}>*</span>
                </label>
              </div>

              <input
                name="company_registration_no"
                type="text"
                id="Registration"
                style={{ width: "20rem", backgroundColor: "#f0e5f3" }}
                className={`form-control `}
                placeholder="Company Registration Number:"
                value={formData.company_registration_no}
              />
            </div>

            <div style={{ width: "30rem" }}>
              <div className="d-flex">
                <label htmlFor="Tax">
                  Company Tax Number <span style={{ color: "red" }}>*</span>
                </label>
              </div>
              <input
                name="company_tax_no"
                type="text"
                id="Tax"
                className={`form-control `}
                style={{ width: "30rem", backgroundColor: "#f0e5f3" }}
                placeholder="Company Tax Number:"
                value={formData.company_tax_no}
              />
            </div>
          </div>

          <div className="d-flex justify-content-between mb-3" id="third3">
            <div style={{ width: "18rem" }}>
              <div className="d-flex">
                <label htmlFor="Size">Company Size</label>
              </div>
              <input
                name="company_size"
                type="text"
                id="Size"
                className="form-control"
                style={{ width: "17rem", backgroundColor: "#f0e5f3" }}
                placeholder="Company Size:"
                value={formData.company_size}
              />
            </div>

            <div style={{ width: "18rem" }}>
              <div className="d-flex">
                <label htmlFor="Industry">Industry Type</label>
              </div>

              <select
                name="industry_type_cd"
                id="Industry"
                style={{ width: "17rem", backgroundColor: "#f0e5f3" }}
                className={`form-control`}
                value={formData.industry_type_cd}
              ></select>
            </div>

            <div style={{ width: "17rem" }}>
              <div className="d-flex">
                <label htmlFor="Revenue">Annual Revenue</label>
              </div>
              <input
                name="annual_revenue"
                type="text"
                id="Revenue"
                className="form-control"
                style={{ width: "17rem", backgroundColor: "#f0e5f3" }}
                placeholder="Annual Revenue:"
                value={formData.annual_revenue}
              />
            </div>
          </div>

          <div class="d-flex justify-content-start">
            <h4>My Preferences</h4>
          </div>

          <div className="d-flex justify-content-between mb-3" id="fourth3">
            <div style={{ width: "16rem" }}>
              <div className="d-flex">
                <label htmlFor="vehicle-type">Vehicle Type</label>
              </div>

              <select
                name="vichel_type_cd"
                id="vehicle-type"
                style={{ width: "17rem", backgroundColor: "#f0e5f3" }}
                className={`form-control`}
                value={formData.vichel_type_cd}
              ></select>
            </div>

            <div style={{ width: "16rem" }}>
              <div className="d-flex">
                <label htmlFor="HCage">Age of HC</label>
              </div>
              <input
                name="age_of_mc"
                type="text"
                id="HCage"
                className="form-control"
                style={{ width: "17rem", backgroundColor: "#f0e5f3" }}
                placeholder="Age of HC:"
                value={formData.age_of_mc}
              />
            </div>

            <div style={{ width: "17rem" }}>
              <div className="d-flex">
                <label htmlFor="Slocation">Start Location</label>
              </div>
              <input
                name="start_location"
                type="text"
                id="Slocation"
                className="form-control"
                style={{ width: "17rem", backgroundColor: "#f0e5f3" }}
                placeholder="Start Location:"
                value={formData.start_location}
              />
            </div>
          </div>

          <div className="d-flex justify-content-between mb-3" id="fifth3">
            <div style={{ width: "17rem" }}>
              <div className="d-flex">
                <label htmlFor="load">Load Type</label>
              </div>

              <select
                name="load_type_cd"
                id="load"
                style={{ width: "17rem", backgroundColor: "#f0e5f3" }}
                className={`form-control`}
                value={formData.load_type_cd}
              ></select>
            </div>

            <div style={{ width: "17rem" }}>
              <div className="d-flex">
                <label htmlFor="Rating">Carrier Rating</label>
              </div>

              <select
                name="carrier_rating_cd"
                id="Rating"
                style={{ width: "17rem", backgroundColor: "#f0e5f3" }}
                className={`form-control`}
                value={formData.carrier_rating_cd}
              ></select>
            </div>

            <div style={{ width: "17rem" }}>
              <div className="d-flex">
                <label htmlFor="Elocation">End Location</label>
              </div>
              <input
                name="end_location"
                type="text"
                id="Elocation"
                className="form-control"
                style={{ width: "17rem", backgroundColor: "#f0e5f3" }}
                placeholder="End Location:"
                value={formData.end_location}
              />
            </div>
          </div>
        </div>

        <br></br>
      </form>
    </>
  );
};

export default Aform;
