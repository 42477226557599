
import React from "react";
import { UilCheckCircle } from "@iconscout/react-unicons";
import { UilEdit } from "@iconscout/react-unicons";
import { useState } from "react";
import "./payment.css";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import PersonIcon from "@mui/icons-material/Person";
import AddIcon from "@mui/icons-material/Add";
import Typography from "@mui/material/Typography";
import { blue } from "@mui/material/colors";
import TextField from "@mui/material/TextField";
import axios from "axios";
import DataContext from "../../../Context/dataContext";
import { useEffect, useContext } from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
// import { PayPalButtons } from '@paypal/react-paypal-js'
import { PaypalPayment } from "../PaypalPayment/PaypalPayment";
// import { Button, Flex } from 'antd'
export default function Payment(props) {
  console.log(props);

  const employeeData = useContext(DataContext);
  const [loadsWithAmount, setLoadsWithAmount] = useState([]);
  const [selectedLoadId, setSelectedLoadId] = useState("");
  const [editedAmount, setEditedAmount] = useState("");
  const [yele, setYele] = useState(false);

  const handleAmountEdit = (e) => {
    // Remove non-numeric characters using a regular expression
    const sanitizedValue = e.target.value.replace(/[^0-9]/g, "");
  
    // Ensure the entered amount doesn't exceed the selectedLoad.load_amount
    const maxAmount = selectedLoad ? selectedLoad.load_amount : 0;
    const enteredAmount = sanitizedValue !== "" ? parseInt(sanitizedValue) : 0;
    const validAmount = Math.min(enteredAmount, maxAmount);
  
    if (enteredAmount > maxAmount) {
      alert(`Sorry, you can enter a maximum of $${maxAmount}`);
    }
  
    setEditedAmount(validAmount.toString());
  };
  

  const paythis = () => {
    console.log("hey ther e it is working");
    // setEditedAmount()
    setYele(true);
  };

  useEffect(() => {
    axios
      .get(employeeData.URL + "/api/v1/payment/getPaypal", {
        withCredentials: true,
      })
      .then((response) => {
        console.log(response);
        // Assuming response.data.data.loadswithamount.rows is an array
        const rows = response.data.data.loadswithamount.rows;
        setLoadsWithAmount(rows);
  
        if (rows.length > 0) {
          // Select the first load_id in the dropdown
          setSelectedLoadId(rows[0].load_id);
          // Set the initial editedAmount to the maximum amount of the first selected load
          setEditedAmount(rows[0].load_amount.toString());
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);



  useEffect(() => {
    if (selectedLoadId) {
      const selectedLoad = loadsWithAmount.find((item) => item.load_id === selectedLoadId);
      if (selectedLoad) {
        setEditedAmount(selectedLoad.load_amount.toString());
      }
    }
  }, [selectedLoadId, loadsWithAmount]);
  





  

  const handleSelectChange = (e) => {
    setSelectedLoadId(e.target.value);
  };
  const selectedLoad = loadsWithAmount.find(
    (item) => item.load_id === selectedLoadId
  );
  console.log(selectedLoad);
  return (
    <>
      <h1 style={{ color: "#A769A7" }}>Payment</h1>
      <div style={{ textAlign: "end" }}>
        <select
          style={{
            marginTop: "2rem",
            width: "20rem",
            height: "3.5rem",
            textAlign: "center",
            border: "1px solid #ddd",
            backgroundColor: "#F2E0F8",
            fontFamily: "Arial",
            fontSize: "1.5rem",
            boxShadow: "-2px 4px 6px rgba(0, 0, 0, 0.3)",
          }}
          value={selectedLoadId} // Set the selected value based on state
          onChange={handleSelectChange}
        >
          <option value="">Select Load ID</option>
          {loadsWithAmount.map((item) => (
            <option key={item.load_id} value={item.load_id}>
              {`Load ID: ${item.load_id}`}
            </option>
          ))}
        </select>
      </div>

      <div style={{ textAlign: "center" }}>
        {selectedLoad ? (
          <>
            {/* Show the button with the initial value of selectedLoad.load_amount */}
            <button
              onClick={() => {
                // Handle the button click event here
              }}
              style={{
                width: "15rem",
                borderRadius: "1rem",
                height: "3rem",
                border: "1px solid #ddd",
                fontFamily: "Arial",
                backgroundColor: "#F2E0F8",
                color: "black",
                marginRight: "1rem",
                boxShadow: "-2px 4px 6px rgba(0, 0, 0, 0.3)",
                transition: "background-color 0.3s ease", // Add transition for smooth effect
                ":hover": {
                  backgroundColor: "#531967", // Change background color on hover
                  color: "white", // Change text color on hover
                  boxShadow: "-2px 4px 10px rgba(0, 0, 0, 0.5)", // Adjust box shadow on hover
                },
              }}
              
            >
              Amount to Pay ${selectedLoad.load_amount}
            </button>
            <input
              placeholder={`Enter Amount to Pay Now`}
              value={editedAmount}
              onChange={handleAmountEdit}
              style={{
                width: "15rem",
                height: "3rem",
                border: "1px solid #ddd",
                borderRadius:"1rem",
                backgroundColor: "#F2E0F8",
                fontFamily: "Arial",
                textAlign:"center",
                fontSize: "1rem",
                color: "black",
                marginRight: "1rem",
                // marginBottom: ".5rem",
                boxShadow: "-2px 4px 6px rgba(0, 0, 0, 0.3)",
              }}
            />
          </>
        ) : null}
      </div>
      <br />
      <div>
      <Button onClick={paythis} style={{color:"white", width: '12rem', transition: 'width 0.5s', backgroundColor: '#8A31A8',height:"2rem",  textTransform: 'none' }}>
             Pay
            </Button>
        
      </div>

      <div style={{ textAlign: "end" }}>
        {selectedLoad && editedAmount > 0 && yele == true ? (
          <PayPalScriptProvider
            options={{
              clientId:
                "ATl2tzBs7wABbhgKAMKXLwhsGNdjm_6hoofpt62YDbIWn5WMLuKcx7WtfLBHKHyBLdRdcn7734ACwvOC",
            }}
          >
            <PaypalPayment amount={selectedLoad} newamount={editedAmount} />
          </PayPalScriptProvider>
        ) : null}
      </div>
    </>
  );
}
