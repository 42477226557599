function Validation(values) {
  let error = {};
  const email_pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const password_pattern = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9]{8,}$/;

  if (values.email === "") {
    error.email = "Email should not be empty";
  } else if (!email_pattern.test(values.email)) {
    error.email = "Email format is invalid";
  } else {
    error.email = "";
  }

  if (values.password === "") {
    error.password = "Password should not be empty";
  }
  //else if (!password_pattern.test(values.password)) {
  //   error.password =
  //     "Password must contain at least 8 characters with at least one uppercase letter, one lowercase letter, and one digit.";
  // }
  else {
    error.password = "";
  }

  return error;
}

export default Validation;
