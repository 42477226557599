import React, { useState, useEffect } from "react";
import "./FTable.css";
import Modal from "react-modal";
import Modalmy from "./modal";
import { useNavigate } from "react-router-dom";

const FRTable = (props) => {
  console.log(props, "fr table")
  let status_model;
  const { data, columns } = props;
  const navigate = useNavigate()

  const [sortInfo, setSortInfo] = useState({
    field: null,
    order: "asc",
  });

  const [dataforId, setDataforId] = useState();
  const [dataforstatus, setDataforstatus] = useState();
  const closeModalFromParent = () => {
    setModalIsOpen(false);
  };

  const [modalIsOpen, setModalIsOpen] = useState(false); // State for modal

  const [selectedRow, setSelectedRow] = useState(null); // State to store the selected row data

  const yourFunction = (row) => {
    setSelectedRow(row);
    setModalIsOpen(true); // Open the modal
    console.log("-hey there this is the sselected ROW");
    console.log(selectedRow);
  };

  const closeModal = () => {
    setSelectedRow(null);
    setModalIsOpen(false); // Close the modal
  };

  const handleSort = (field) => {
    setSortInfo((prevSortInfo) => {
      if (prevSortInfo.field === field) {
        return {
          ...prevSortInfo,
          order: prevSortInfo.order === "asc" ? "desc" : "asc",
        };
      }
      return {
        field,
        order: "asc",
      };
    });
  };

  
    const sortedData = [...data].sort((a, b) => {
        const aValue = a[sortInfo.field];
        const bValue = b[sortInfo.field];
      
        // Custom comparison function for strings, numeric strings, and dates
        const compareValues = (a, b) => {
          // Check if the values are dates in the format "DD-MM-YYYY"
          const isDate = (value) => {
            const datePattern = /^\d{2}-\d{2}-\d{4}$/;
            return datePattern.test(value);
          };
      
          if (!isNaN(a) && !isNaN(b)) {
            return Number(a) - Number(b);
          } else if (isDate(a) && isDate(b)) {
            const dateA = new Date(a.split("-").reverse().join("-"));
            const dateB = new Date(b.split("-").reverse().join("-"));
            return dateA - dateB;
          } else {
            return String(a).localeCompare(String(b), undefined, {
              numeric: true,
              sensitivity: "base",
            });
          }
        };
      
        if (sortInfo.order === "asc") {
          return compareValues(aValue, bValue);
        } else {
          return compareValues(bValue, aValue);
        }
      });

  // const sortedData = [...data].sort((a, b) => {
  //   const aValue = a[sortInfo.field];
  //   const bValue = b[sortInfo.field];

  //   if (aValue < bValue) {
  //     return sortInfo.order === "asc" ? -1 : 1;
  //   }
  //   if (aValue > bValue) {
  //     return sortInfo.order === "asc" ? 1 : -1;
  //   }
  //   return 0;
  // });



  const handleWordClick = (word) => {
    console.log("Clicked on word:", word);
    // Call your desired function here with the clicked word
  };


  const myRebuiding = (row) => {
    // setSelectedRow(row, " HERE IRT IS THE SETsELECT");
    console.log(row, " hey there this is the row");
    const id = row.load_id;
    navigate(`/trucker/load-data/load-view/${id}`)



  };

  // useEffect(() => {
  //   const timeout = setTimeout(() => {

  //     var messageContainer = document.getElementById("messageContainer");
  //     if (!messageContainer) {
  //       console.error("Message container not found.");
  //       return;
  //     }

  //     // Set the message text
  //     messageContainer.innerText = "Loosing bid? Re-bid now by clicking on Losing Bid!";

  //     // Show the message
  //     messageContainer.style.display = "block";

  //     // Hide the message after the specified duration
  //     setTimeout(function () {
  //       messageContainer.style.display = "none";
  //     }, 4000);


  //   }, 3000);

  //   // Clean up the timeout to avoid memory leaks
  //   return () => clearTimeout(timeout);
  // }, []);








  return (
    <>
      <div id="messageContainer" class="message-container"></div>

      <table>
        <div id="head">
          <thead>
            <tr>
              {columns.map((col, index) => (
                <th
                  key={index}
                  style={{}}
                  onClick={() => handleSort(col.field)}
                >
                  {col.headerName}

                  {sortInfo.field === col.field && (
                    <span>{sortInfo.order === "asc" ? " ▲" : " ▼"}</span>
                  )}
                </th>
              ))}
            </tr>
          </thead>
        </div>

        <tbody style={{}}>
          {sortedData.map((row, rowIndex) => (
            <tr key={rowIndex} style={{}}>
              <div id="row">
                {columns.map((col, colIndex) => (
                  console.log(row[col.field]),
                  <td
                    id="thisiscstmbtn"
                    key={colIndex}
                    // style={{
                    //   textAlign: "center",
                    //   width: "11rem",
                    //   cursor: typeof row[col.field] === 'object' &&
                    //     row[col.field].props.children[0].props.children[0] === 'losing'
                    //     ? 'pointer'
                    //     : 'default',

                    //   border:
                    //     row[col.field] === "Take Action" ? "none" : "none",
                    //   boxShadow:
                    //     row[col.field] === "Take Action"
                    //       ? "0px 2px 10px black"
                    //       : "none",
                    //   backgroundColor:
                    //     row[col.field] === "Take Action"
                    //       ? "rgb(63, 131, 227)"
                    //       : "none",

                    // }}
                    onClick={() => {
                      if (row[col.field] === "Take Action") {
                        console.log(row);
                        setDataforId(row.load_id);
                        setDataforstatus(row.actual_status);
                        console.log("blah blah b;ahg");
                        yourFunction(row);
                      }
                      // if (typeof row[col.field] === 'object' &&
                      //   row[col.field].props.children[0].props.children[0] === 'losing') {
                      //   console.log(" hey there this isthe ");
                        // myRebuiding(row)
                      // }
                    }}
                  >
                    {row[col.field]}
                  </td>
                ))}
              </div>
            </tr>
          ))}
        </tbody>
      </table>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
        style={{
          overlay: {
            backgroundColor: "rgba(0.4, 1, 1, 0.3bv)", // Background overlay color
          },
          content: {
            width: "50rem", // Set the desired width of the modal
            height: "25rem", // Set the desired height of the modal
            margin: "auto", // Center the modal horizontally
            marginTop: "100px", // Adjust the top margin to center vertically

            borderRadius: "10px", // Border radius
          },
        }}
      >
        <Modalmy
          iid={dataforId}
          status={dataforstatus}
          closeModal={closeModalFromParent}
        />
      </Modal>
    </>
  );
};

export default FRTable;
