import React from 'react'
import LoadView from '../LoadView/LoadView'
import { LoadTemplate } from '../ComponentTemplate/LoadTemplate'

 const Loading = () => {
  return (
    <>
   
 
    <LoadTemplate name={"Loading"} pendingapi={"loading-view"} status={"Completed"}  />
   
    </>
  )
}



export default Loading;


