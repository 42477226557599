import axios from "axios";
import React, { useEffect, useState, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { Card } from "antd";
import DataContext from "../../../Context/dataContext";

import "./load.css";

const LoadDetails = () => {
  const location = useLocation();

  const employeeData = useContext(DataContext);
  //   const id = location.pathname.split("/").pop();
  const id = location.pathname ? location.pathname.split("/").pop() : null;
  const [loadDetails, setLoadDetails] = useState(null);
  useEffect(() => {
    axios
      .get(employeeData.URL + `/api/v1/load/load-details/${id}`, {
        withCredentials: true,
      })
      .then((response) => {
        console.log(response);
        setLoadDetails(response.data.data.getLoadDetails.rows);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [id]); // Make sure to include dependencies if necessary, like 'id'

  // Function to format the date and time
  const formatDateTime = (dateTimeString) => {
    const options = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    const formattedDate = new Date(dateTimeString).toLocaleString(
      "en-US",
      options
    );
    return formattedDate;
  };

  // Function to format the date
  const formatDate = (dateString) => {
    const options = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    };
    const formattedDate = new Date(dateString).toLocaleString("en-US", options);
    return formattedDate;
  };

  console.log(location.pathname);

  //   Function to format the time
  const formatTime = (timeString) => {
    const options = {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };

    const [hours, minutes, seconds] = timeString.split(":");
    const formattedTime = new Date();
    formattedTime.setHours(parseInt(hours, 10));
    formattedTime.setMinutes(parseInt(minutes, 10));
    formattedTime.setSeconds(parseInt(seconds, 10));

    return formattedTime.toLocaleString("en-US", options);
  };

  return (
    <>
      {loadDetails && loadDetails.length > 0 ? (
        <div className="thisisthedetaisv" style={{ width: "90%"}}>
          <div
            className="thisisthedetaisv"
            style={{
              width: "45%",
              position: "fixed",
              top: 180,
              left: -100,
              zIndex: 1000,
              // border:"2px solid blue"
            }}
          >
            <Link
              to={`/home/load-view`}
              style={{ color: "#1777C4", fontWeight: "bolder" }}
            >
              <button
                className="cool-button"
                style={{
                  color: "white",
                  fontSize: "0.7rem",
                  height:"1.8rem",
                  paddingBottom:"1.2rem",
                  paddingTop:"0.3rem",
                  backgroundColor:"#355576"

                }}
              >
                Back
              </button>
            </Link>
          </div>
          <div style={{ marginTop: "",  color:"#A769A7" }}>
            <h1>Load Details</h1>
          </div>

          {loadDetails.map((detail, index) => (
            <Card
              style={{
                borderRadius: 20,
                height: "4.9rem",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                transition: "transform 0.3s ease-in-out",
                // border:"2px solid red",
                paddingTop:"0rem",
                
              }}
              >
              <div
                key={index}
                style={{
                  // border:"2px solid red",
                  display: "flex",
                  justifyContent: "space-around",
                  paddingTop:"0rem",
                  marginTop:"-2.7rem"

                  //   flexDirection: "column",
                }}
                className="firstDivLD"
              >
                {index === 0 && ( // Render label only if index is 0
                  <div style={{ textAlign:"left"}}>
                    <label
                      style={{
                        width: "8rem",
                        padding: "1.2rem 0rem",
                      }}
                      htmlFor=""
                    >
                      Item Name
                    </label>

                    <Card
                      bodyStyle={{ padding: "0.1rem 0.5rem" }}
                      style={{
                        height: "2rem",
                        backgroundColor: "rgb(240, 229, 243)",
                      }}
                    >
                      <span
                        style={{
                          // fontSize:"0.8rem",
                          display: "inline-block",
                          // border: "2px solid red",
                          width: "8rem",

                          color: "#355576",
                          fontSize: "1rem",
                          fontWeight: "bolder",
                          // backgroundColor: "rgb(240, 229, 243)",
                        }}
                        name="item_name"
                      >
                        {loadDetails[index].item_name}
                      </span>
                    </Card>
                  </div>
                )}
                {index > 0 && ( // Render other details without the label when index > 0
                  <div style={{textAlign:"left",  paddingTop:"1.9rem"}}>
                    <div style={{    width:"100%" }}>
                      <span
                        style={{
                          fontSize: "0.8rem",
                          display: "inline-block",
                          width: "9rem",
                          color: "#355576",
                          fontSize: "1rem",
                          fontWeight: "bolder",
                          height: "2rem",
                          backgroundColor: "rgb(240, 229, 243)",
                          // border:"2px solid black",
                          paddingLeft:"8px"

                        }}
                        name="item_name"
                      >
                        {loadDetails[index].item_name}
                      </span>
                    </div>
                  </div>
                )}

                {index === 0 && (
                  <div style={{textAlign:"right"}}>
                    <label style={{ padding: "1.2rem 0rem" }} htmlFor="">
                      Length
                    </label>
                    <div style={{}}>
                      <span
                        style={{
                          fontSize: "0.8rem",
                          display: "inline-block",
                          color: "#355576",
                          fontSize: "1rem",
                          fontWeight: "bolder",
                          width: "8rem",
                          height: "2rem",
                          backgroundColor: "rgb(240, 229, 243)",
                        }}
                        name="length"
                      >
                        {loadDetails[index].length}
                      </span>
                    </div>
                  </div>
                )}
                {index > 0 && (
                  <div style={{textAlign:"right", paddingTop:"1.9rem"}}>
                    <div style={{width:"100%"  }}>
                      <span
                        style={{
                          fontSize: "0.8rem",
                          display: "inline-block",
                          width: "8rem",
                          color: "#355576",
                          fontSize: "1rem",
                          fontWeight: "bolder",
                          height: "2rem",
                          backgroundColor: "rgb(240, 229, 243)",
                          paddingLeft:"8px"
                        }}
                        name="item_name"
                      >
                        {loadDetails[index].length}
                      </span>
                    </div>
                  </div>
                )}

                {index === 0 && (
                  <div style={{textAlign:"right"}}>
                    <label style={{ padding: "1.2rem 0rem" }} htmlFor="">
                      Width
                    </label>
                    <div style={{textAlign:"right"}}>
                      <span
                        style={{
                          fontSize: "0.8rem",
                          display: "inline-block",
                          color: "#355576",
                          fontSize: "1rem",
                          fontWeight: "bolder",
                          width: "8rem",
                          height: "2rem",
                          backgroundColor: "rgb(240, 229, 243)",
                        }}
                        name="width"
                      >
                        {loadDetails[index].width}
                      </span>
                    </div>
                  </div>
                )}

                {index > 0 && (
                  <div style={{textAlign:"right", paddingTop:"1.9rem"}}>
                    <div style={{ width:"100%" }}>
                      <span
                        style={{
                          fontSize: "0.8rem",
                          display: "inline-block",
                          width: "8rem",
                          color: "#355576",
                          fontSize: "1rem",
                          fontWeight: "bolder",
                          height: "2rem",
                          backgroundColor: "rgb(240, 229, 243)",
                        }}
                        name="item_name"
                      >
                        {loadDetails[index].width}
                      </span>
                    </div>
                  </div>
                )}

                {index === 0 && (
                  <div style={{textAlign:"right"}}>
                    <label style={{ padding: "1.2rem 0rem" }} htmlFor="">
                      Height
                    </label>
                    <div style={{}}>
                      <span
                        style={{
                          fontSize: "0.8rem",
                          display: "inline-block",
                          color: "#355576",
                          fontSize: "1rem",
                          fontWeight: "bolder",
                          width: "8rem",
                          height: "2rem",
                          backgroundColor: "rgb(240, 229, 243)",
                        }}
                        name="height"
                      >
                        {loadDetails[index].height}
                      </span>
                    </div>
                  </div>
                )}

                {index > 0 && (
                  <div style={{textAlign:"right", paddingTop:"1.9rem"}}>
                  <div style={{ width:"100%" }}>
                      <span
                        style={{
                          fontSize: "0.8rem",
                          display: "inline-block",
                          color: "#355576",
                          fontSize: "1rem",
                          fontWeight: "bolder",
                          width: "8rem",
                          height: "2rem",
                          backgroundColor: "rgb(240, 229, 243)",
                        }}
                        name="item_name"
                      >
                        {loadDetails[index].height}
                      </span>
                    </div>
                  </div>
                )}

                {index === 0 && (
                  <div style={{textAlign:"right"}}>
                    <label style={{ padding: "1.2rem 0rem" }} htmlFor="">
                      Weight
                    </label>
                    <div style={{}}>
                      <span
                        style={{
                          fontSize: "0.8rem",
                          display: "inline-block",
                          color: "#355576",
                          fontSize: "1rem",
                          fontWeight: "bolder",
                          width: "8rem",
                          height: "2rem",
                          backgroundColor: "rgb(240, 229, 243)",
                        }}
                        name="load_wt"
                      >
                        {loadDetails[index].load_wt}
                      </span>
                    </div>
                  </div>
                )}

                {index > 0 && (
                  <div style={{textAlign:"right", paddingTop:"1.9rem"}}>
                  <div style={{ width:"100%" }}>
                      <span
                        style={{
                          fontSize: "0.8rem",
                          display: "inline-block",
                          width: "8rem",
                          color: "#355576",
                          fontSize: "1rem",
                          fontWeight: "bolder",
                          height: "2rem",
                          backgroundColor: "rgb(240, 229, 243)",
                        }}
                        name="item_name"
                      >
                        {loadDetails[index].load_wt}
                      </span>
                    </div>
                  </div>
                )}

                {index === 0 && (
                  <div style={{textAlign:"left"}}>
                    <label style={{ padding: "1.2rem 0rem" }} htmlFor="">
                      Uom
                    </label>
                    <div style={{}}>
                      <span
                        style={{
                          fontSize: "0.8rem",
                          display: "inline-block",
                          color: "#355576",
                          fontSize: "1rem",
                          fontWeight: "bolder",
                          width: "8rem",
                          height: "2rem",
                          backgroundColor: "rgb(240, 229, 243)",
                        }}
                        name="uom_name"
                      >
                        {loadDetails[index].uom_name}
                      </span>
                    </div>
                  </div>
                )}
                {index > 0 && (
                  <div style={{textAlign:"left", paddingTop:"1.9rem"}}>
                  <div style={{ width:"100%" }}>
                      <span
                        style={{
                          fontSize: "0.8rem",
                          display: "inline-block",
                          width: "8rem",
                          color: "#355576",
                          fontSize: "1rem",
                          fontWeight: "bolder",
                          height: "2rem",
                          backgroundColor: "rgb(240, 229, 243)",
                        }}
                        name="item_name"
                      >
                        {loadDetails[index].uom_name}
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </Card>
          ))}

          <div
            style={{
              //   border: "2px solid black",
              marginTop: "1.3rem",
              display: "flex",
              justifyContent: "space-between",
              //   flexDirection: "row",
              padding: "",
              //   width: "100%",
            }}
            className="secondLD"
          >
            <Card
              style={{
                borderRadius: 20,
                // height: '150px',
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                transition: "transform 0.3s ease-in-out",
                // border:"2px solid red",
                height:"27 %"
                
                // paddingBottom:"1rem"
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  // width: "45%",
                  marginTop: "-1rem",
                  // border:"2px solid red"
                }}
              >
                <label
                  style={{ width: "90%", padding: " 0rem" }}
                  htmlFor=""
                >
                  Origin Address
                </label>
                <span
                  style={{
                    //   height: "2.5rem",
                    textAlign: "start",
                    width: "100%",
                    color: "#355576",
                    fontSize: "1rem",
                    fontWeight: "bolder",
                    fontSize: "1rem",
                    fontWeight: "bolder",
                    //   border: "2px solid black",
                  }}
                  className="originLD"
                  name="origin_address"
                >
                  {loadDetails[0].origin_address}
                </span>
                <div
                  style={{
                    //   border: "2px solid black",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-around",
                  }}
                >
                  <div
                    style={{
                      // border: "4px solid red",
                      display: "flex",
                      justifyContent: "right",
                      flexDirection: "column",
                    }}
                  >
                    <label style={{ padding: "1.2rem 0rem" }} htmlFor="">
                      State
                    </label>
                    <span
                      style={{
                        width: "5rem",
                        height: "2rem",
                        color: "#355576",
                        fontSize: "1rem",
                        fontWeight: "bolder",
                      }}
                      name="origin_state_cd"
                    >
                      {loadDetails[0].origin_state_cd}
                    </span>
                  </div>
                  <div
                    style={{
                      // border: "4px solid red",
                      display: "flex",
                      justifyContent: "right",
                      flexDirection: "column",
                    }}
                  >
                    <label style={{ padding: "1.2rem 0rem" }} htmlFor="">
                      City
                    </label>
                    <span
                      style={{
                        width: "5rem",
                        height: "2rem",
                        color: "#355576",
                        fontSize: "1rem",
                        fontWeight: "bolder",
                      }}
                      name="origin_city_cd"
                    >
                      {" "}
                      {loadDetails[0].origin_city_cd}
                    </span>
                  </div>
                  <div
                    style={{
                      // border: "4px solid red",
                      display: "flex",
                      justifyContent: "right",
                      flexDirection: "column",
                    }}
                  >
                    <label style={{ padding: "1.2rem 0rem" }} htmlFor="">
                      Zip
                    </label>
                    <span
                      style={{
                        width: "5rem",
                        height: "2rem",
                        color: "#355576",
                        fontSize: "1rem",
                        fontWeight: "bolder",
                      }}
                      name="origin_zip_cd"
                    >
                      {loadDetails[0].origin_zip_cd}
                    </span>
                  </div>
                </div>
              </div>
            </Card>

            {/* Destination Address */}
            <Card
              style={{
                borderRadius: 20,
                // height: '150px',
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                transition: "transform 0.3s ease-in-out",
                height:"27 %"
              }}
            >
              <div
                style={{
                  // border: "2px solid red",
                  // overflow: "hidden",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  marginTop: "-1rem",
                  // width: "45%",
                  // margin: "0rem 2rem 0rem 2rem",
                }}
              >
                <label
                  style={{ width: "90%", padding: " 0rem" }}
                  htmlFor=""
                >
                  Destination Address
                </label>
                <span
                  style={{
                    // fontSize: "small",
                    //   height: "2.5rem",
                    textAlign: "start",
                    width: "100%",
                    color: "#355576",
                    fontSize: "1rem",
                    fontWeight: "bolder",

                    //   border: "2px solid black",
                  }}
                  className="DestLD"
                  name="des_address"
                >
                  {loadDetails[0].des_address}
                </span>
                <div
                  style={{
                    //   border: "2px solid black",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-around",
                  }}
                >
                  <div
                    style={{
                      // border: "4px solid red",
                      display: "flex",
                      justifyContent: "right",
                      flexDirection: "column",
                    }}
                  >
                    <label style={{ padding: "1.2rem 0rem" }} htmlFor="">
                      State
                    </label>
                    <span
                      style={{
                        width: "5rem",
                        height: "2rem",
                        color: "#355576",
                        fontSize: "1rem",
                        fontWeight: "bolder",
                      }}
                      name="des_state_cd"
                    >
                      {" "}
                      {loadDetails[0].des_state_cd}
                    </span>
                  </div>
                  <div
                    style={{
                      // border: "4px solid red",
                      display: "flex",
                      justifyContent: "right",
                      flexDirection: "column",
                    }}
                  >
                    <label style={{ padding: "1.2rem 0rem" }} htmlFor="">
                      City
                    </label>
                    <span
                      style={{
                        width: "5rem",
                        height: "2rem",
                        color: "#355576",
                        fontSize: "1rem",
                        fontWeight: "bolder",
                      }}
                      name="des_city_cd"
                    >
                      {loadDetails[0].des_city_cd}
                    </span>
                  </div>
                  <div
                    style={{
                      // border: "4px solid red",
                      display: "flex",
                      justifyContent: "right",
                      flexDirection: "column",
                    }}
                  >
                    <label style={{ padding: "1.2rem 0rem" }} htmlFor="">
                      Zip
                    </label>
                    <span
                      style={{
                        width: "5rem",
                        height: "2rem",
                        color: "#355576",
                        fontSize: "1rem",
                        fontWeight: "bolder",
                      }}
                      name="des_zip_cd"
                    >
                      {loadDetails[0].des_zip_cd}
                    </span>
                  </div>
                </div>
              </div>
            </Card>
          </div>

          <Card
            style={{
              // border:"2px solid green",
              marginTop: "1rem",
              
              borderRadius: 20,
              // height: '150px',
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              transition: "transform 0.3s ease-in-out",
              height:"4.9rem"
            }}
            >
            <div
              style={{
                // border:"2px solid red",
                //   border: "2px solid black",
                marginTop: "-2rem",
                display: "flex",

                justifyContent: "space-around",
              }}
              className="thirdLD"
            >
              <div
                style={{
                  // border: "2px solid black",
                  width: "45%",
                  display: "flex",
                  justifyContent: "space-evenly",
                  flexDirection: "row",
                }}
              >
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <label
                    style={{ width: "7rem", padding: "0.9rem 0" }}
                    htmlFor=""
                  >
                    Pick Up Date
                  </label>
                  <span
                    style={{
                      width: "7rem",
                      height: "2rem",
                      color: "#355576",
                      fontSize: "1rem",
                      fontWeight: "bolder",
                    }}
                    name="loading_date"
                  >
                    {formatDate(loadDetails[0].loading_date)}
                  </span>
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <label
                    style={{ width: "7rem", padding: "0.9rem 0" }}
                    htmlFor=""
                  >
                    Pick Up Time
                  </label>
                  <span
                    style={{
                      width: "7rem",
                      height: "2rem",
                      color: "#355576",
                      fontSize: "1rem",
                      fontWeight: "bolder",
                    }}
                    name="loading_time"
                  >
                    {formatTime(loadDetails[0].loading_time)}
                  </span>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "start",
                }}
              >
                <label style={{ width: "9rem", padding: "1rem 0" }} htmlFor="">
                  Bidding End Time
                </label>
                <span
                  style={{
                    width: "12rem",
                    height: "3rem",
                    color: "#355576",
                    fontSize: "1rem",
                    fontWeight: "bolder",
                  }}
                  name="bidding_end_time"
                >
                  {formatDateTime(loadDetails[0].bidding_end_time)}
                </span>
              </div>

              <div
                style={{
                  // border: "2px solid black",
                  width: "45%",
                  display: "flex",
                  justifyContent: "space-evenly",
                  flexDirection: "row",
                }}
              >
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <label
                    style={{ width: "7rem", padding: "0.9rem 0" }}
                    htmlFor=""
                  >
                    Drop Up Date
                  </label>
                  <span
                    style={{
                      width: "7rem",
                      height: "2rem",
                      color: "#355576",
                      fontSize: "1rem",
                      fontWeight: "bolder",
                    }}
                    name="unloading_date"
                  >
                    {" "}
                    {formatDate(loadDetails[0].unloading_date)}
                  </span>
                </div>

                <div style={{ display: "flex", flexDirection: "column" }}>
                  <label
                    style={{ width: "7rem", padding: "0.9rem 0" }}
                    htmlFor=""
                  >
                    Drop Up Time
                  </label>
                  <span
                    style={{
                      width: "7rem",
                      height: "2rem",
                      color: "#355576",
                      fontSize: "1rem",
                      fontWeight: "bolder",
                    }}
                    name="unloading_time"
                  >
                    {formatTime(loadDetails[0].unloading_time)}
                  </span>
                </div>
              </div>
            </div>
          </Card>

         
        </div>
      ) : (
        <div>
          <p>Sorry, no data found.</p>
        </div>
      )}
    </>
  );
};

export default LoadDetails;
