import React, { useEffect, useState, useContext } from "react";

import DataContext from "../../../Context/dataContext";
import axios from "axios";
import FreightTable from "../../FreightTable/FreightTable";
import { Skeleton, Spin, Alert } from "antd";
const Mytruck = () => {
  const [boxes, setBoxes] = useState([]);
  const [boxs, setBoxs] = useState([]);
  const employeeData = useContext(DataContext);

  useEffect(() => {
    axios
      .get(employeeData.URL + "/api/v1/truckD/mytruck", {
        withCredentials: true,
      })
      .then((response) => {
        console.log(response);

        setBoxes(response.data.data.loads.fields);
        setBoxs(response.data.data.loads.rows);

        setBoxes((columns) => {
          let newCols = response.data.data.loads.fields.map((col) => {
            return {
              field: col.name,
              headerName: col.name
                .split("_")
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(" "),

              width: 150,
            };
          });

          const newNewCols = [...newCols];
          return newNewCols;
        });
      })
      .catch((error) => {
        console.log("this is error");
      });
  }, []);

  // console.log("-_______________________________________________________");

  // console.log(boxes[0].name);

  return (
    <>
      <h1  style={{  color:"#A769A7"}}>Trucks Registered</h1>

      <form>
        <br />

        <div
          className="table"
          style={{ display: "inline-block", width: "100%" }}
        >
          <div style={{ display: "inline-block" }}>
          {boxes.length > 0 ? (
              <FreightTable
                data={boxs}
                columns={boxes}
                // val={true}
              />
            ) : (
              <>
                <Skeleton active />
                <Skeleton active />
                <Skeleton active />
              </>
            )}
            {/* <FreightTable data={rows} columns={columns} handelChange={searchHere} val={true} /> */}
          </div>
        </div>
      </form>
    </>
    // <div>hey there this is MY TRUCK</div>
  );
};

export default Mytruck;
