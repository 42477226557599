import React, { useState } from 'react';
import { FileUpload } from 'primereact/fileupload';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';

const ImageUpload = () => {
  const [uploadedFile, setUploadedFile] = useState(null);

  const onFileUpload = (event) => {
    const uploadedFile = event.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      setUploadedFile(e.target.result);
    };

    reader.readAsDataURL(uploadedFile);
  };

  return (
    <div style={{border:"2px solid black", marginTop:"1.4rem", display:"inline-block", width:"10rem", backgroundColor:"#F0E5F3"}}>
    
      <FileUpload
        mode="basic"
        chooseLabel="Photos of Vehicle"
        uploadLabel="Upload"
        onUpload={onFileUpload}
        accept="image/*"
        maxFileSize={1000000}
      />
      {uploadedFile && (
        <div>
          <h3>Uploaded Image:</h3>
          <img src={uploadedFile} alt="Uploaded" />
        </div>
      )}
    </div>
  );
};

export default ImageUpload;
