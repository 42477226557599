

import React, { useEffect, useRef, useState, useContext } from "react";
import "./Aform.css";
import axios from "axios";
import { UilArrowUpRight } from "@iconscout/react-unicons";
import { Routes, Route } from "react-router-dom";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import DataContext from "../../../Context/dataContext";

const filledInputs = new Set();
function highlightBorder(inputId) {
  const inputElement = document.getElementById(inputId);
  if (inputElement.value.trim() === "") {
    inputElement.classList.remove("highlight-border");
    filledInputs.delete(inputId);
  } else {
    document.getElementById(inputId).style.border = "2px solid #f0e5f3";
  }
}

const Aform = (props) => {
  const { data } = useContext(DataContext);
  const employeeData = useContext(DataContext);

  const navigate = useNavigate();

  const [integerValidationErrors, setIntegerValidationErrors] = useState({
    company_size: "",
    annual_revenue: "",
  });

  console.log(data);
  // hey the r

  console.log(props.data[0].company_add);
  console.log(props.data);

  const originAutocompleteRef = useRef(null);

  const [formData, setFormData] = useState({
    account_name: props.data[0].account_name || "", // Use default value if data is undefined
    contact_name: props.data[0].contact_name || "",
    company_add: props.data[0].company_add || "",
    state_code: props.data[0].state_code || "",
    city_code: props.data[0].city_code || "",
    pin_code: props.data[0].pin_code || "",
    company_registration_no: props.data[0].company_registration_no || "",
    company_tax_no: props.data[0].company_tax_no || "",
    company_size: props.data[0].company_size || "",
    industry_type_cd: props.data[0].industry_type_cd || "",
    annual_revenue: props.data[0].annual_revenue || "",
    currency_id: props.data[0].currency_id || "",
    size_id: props.data[0].size_id || "",

  });



  const [errors, setErrors] = useState({});

  const [industryOptions, setIndustryOptions] = useState([]);
  const [rating, setRating] = useState([]);
  const [Currency, setCurrency] = useState([]);
  const [Unit, setUnit] = useState([])

  const [countryCode, setCountryCode] = useState("+1"); // Default country code

  // List of country codes with their respective names
  const countryCodes = [
    { code: "+1", name: "USA" },
    { code: "+91", name: "India" },
    { code: "+44", name: "United Kingdom" },
    { code: "+86", name: "China" },
    { code: "+81", name: "Japan" },
    { code: "+33", name: "France" },
    { code: "+49", name: "Germany" },
    { code: "+7", name: "Russia" },
    { code: "+55", name: "Brazil" },
    { code: "+61", name: "Australia" },
    { code: "+52", name: "Mexico" },
    { code: "+82", name: "South Korea" },
    { code: "+34", name: "Spain" },
    { code: "+39", name: "Italy" },
    { code: "+1", name: "Canada" },
    { code: "+31", name: "Netherlands" },
    { code: "+46", name: "Sweden" },
    { code: "+91", name: "Pakistan" },
    { code: "+20", name: "Egypt" },
    { code: "+64", name: "New Zealand" },
    // You can continue to add more country codes as needed
  ];

  // Handle change in the country code dropdown
  const handleCountryCodeChange = (event) => {
    setCountryCode(event.target.value);
  };

  function isInteger(value) {
    return /^-?\d+$/.test(value);
  }

  const handleNumericKeyPress = (event) => {
    const charCode = event.which || event.keyCode;
    if (charCode < 48 || charCode > 57) {
      event.preventDefault(); // Prevent input of non-numeric characters
      const inputName = event.target.name;
      setIntegerValidationErrors((prevErrors) => ({
        ...prevErrors,
        [inputName]: "Please enter numbers only.",
      }));
    } else {
      const inputName = event.target.name;
      setIntegerValidationErrors((prevErrors) => ({
        ...prevErrors,
        [inputName]: "", // Clear the error message if a valid character is entered
      }));
    }
  };

  // Handle change in the contact field
  const handleContactChange = (event) => {
    const { name, value } = event.target;
    // Remove any non-numeric characters from the input
    const numericValue = value.replace(/\D/g, "");
    // Limit the input to 10 digits
    const limitedValue = numericValue.slice(0, 10);
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: limitedValue,
    }));

    // Validate the contact number length
    if (limitedValue.length !== 10) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "Please enter a valid 10-digit contact number.",
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "", // Clear the error message if the contact number is valid
      }));
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));

    // Validate "Company Size" and "Annual Revenue"
    if (name === "company_size" || name === "annual_revenue") {
      if (!isInteger(value)) {
        setIntegerValidationErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "Please enter an integer value.",
        }));
      } else {
        setIntegerValidationErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "",
        }));
      }
    }
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const handleCompanyRegistrationChange = (event) => {
    const { name, value } = event.target;
    // Remove any non-numeric characters from the input
    const numericValue = value;
    // Limit the input to 20 to 24 digits
    const limitedValue = numericValue.slice(0, 24);
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: limitedValue,
    }));

    // Validate the input length for Company Registration Number
    if (limitedValue.length < 20 || limitedValue.length > 24) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]:
          "Please enter a valid Company Registration Number with 20 to 24 digits.",
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "", // Clear the error message if the input is valid
      }));
    }
  };

  const handleCompanyTaxChange = (event) => {
    const { name, value } = event.target;
    // Remove any non-numeric characters from the input
    const numericValue = value;
    // Limit the input to 10 to 14 digits
    const limitedValue = numericValue.slice(0, 14);
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: limitedValue,
    }));

    // Validate the input length for Company Tax Number
    if (limitedValue.length < 10 || limitedValue.length > 14) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "Please enter a valid Company Tax Number with 10 to 14 digits.",
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "", // Clear the error message if the input is valid
      }));
    }
  };

  useEffect(() => {
    let a;
    if (originAutocompleteRef.current) {
      const originAutocomplete = new window.google.maps.places.Autocomplete(
        originAutocompleteRef.current
      );

      originAutocomplete.addListener("place_changed", () => {
        const place = originAutocomplete.getPlace();
        const addressComponents = place.address_components || [];
        console.log(addressComponents);

        a = addressComponents[0].long_name;
        console.log(a);

        let state = "";
        let city = "";
        let zip = "";
        let streetAddress = "";

        addressComponents.forEach((component) => {
          if (component.types.includes("administrative_area_level_1")) {
            state = component.long_name;
          } else if (component.types.includes("locality")) {
            city = component.long_name;
          } else if (component.types.includes("postal_code")) {
            zip = component.long_name;
          } else if (component.types.includes("street_number")) {
            streetAddress += component.long_name + " ";
          } else if (component.types.includes("route")) {
            streetAddress += component.long_name;
          }
        });

        const completeAddress = `${a},${streetAddress}, ${city}, ${state}, ${zip}`;

        // Update the fields with the extracted values for the destination
        setFormData((prevFormData) => ({
          ...prevFormData,
          state_code: state,
          city_code: city,
          pin_code: zip,
          company_add: completeAddress,
        }));

        // You can also set these values using querySelector if needed
        document.querySelector('[name="state_code"]').value = state;
        document.querySelector('[name="city_code"]').value = city;
        document.querySelector('[name="pin_code"]').value = zip;
      });
    }

    axios
      .get(employeeData.URL + "/api/v1/profile/get-Data-lov", {
        withCredentials: true,
      })
      .then((response) => {
        console.log(response);
        setIndustryOptions(response.data.IndustryType);
        // setLoadTypeOptions(response.data.LoadType);
        // setVehicleTypeOptions(response.data.VecheilType);
        setRating(response.data.Rating);
        setCurrency(response.data.Currency)
        setUnit(response.data.Unit)
        //
      })
      .catch((errors) => {
        console.log(errors);
      });
  }, []);

  const handleSubmit = () => {
    const mandatoryFields = [
      "account_name",
      "contact_name",
      "company_registration_no",
      "company_tax_no",
    ];
    const theID = [
      "companyName",
      "contact",
      "Address",
      "Registration",
      "Registration",
      "Tax",
      "state",
      "city",
      "zip",
      "Unit",
      "Currency",


    ];
    const errors = {};

    // Check for empty mandatory fields
    mandatoryFields.forEach((field) => {
      if (!formData[field]) {
        errors[field] = "Please Enter the data in mendetory Fields";
      }
    });

    theID.forEach((fields) => {
      const inputElement = document.getElementById(fields);
      if (inputElement.value.trim() === "") {
        document.getElementById(fields).style.border = "2px solid red";
      }
    });

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }

    console.log(formData);
    const postdata = {
      profile: [formData],
    };


    axios
      .post(employeeData.URL + "/api/v1/profile/update-account", postdata, {
        withCredentials: true,
        credentials: "include",
      })
      .then((response) => {
        console.log("Data successfully sent to the server:", response.data);
        alert("Thanks for the input");
        // navigate("/trucker/dashboard");
        window.location.reload();

        // Perform any further actions after successful submission
      })
      .catch((error) => {
        console.error("Error sending data to the server:", error);
      });
  };

  return (
    <>
      <form className="hereForma" name="myForm">
        <div className="mb-3" style={{  display: "flex", justifyContent: "space-between" }} >
          <div style={{ width:"40%"}}>
            <div >
              <label
                htmlFor="companyName"
                className="d-flex justify-content-start"
              >
                Company Name <span style={{ color: "red" }}>*</span>
              </label>
            </div>
            <input
              name="account_name"
              type="text"
              id="companyName"
              className={`form-control ${errors["account_name"] ? "highlight-red" : ""
                }`}
              placeholder="Company Name:"
              value={formData.account_name}
              onChange={handleChange}
              style={{ backgroundColor: "#f0e5f3" }}
              onInput={() => highlightBorder("companyName")}
            />
            {errors["account_name"] && (
              <div style={{ color: "red" }} className="error-message">
                {errors["account_name"]}
              </div>
            )}

          </div>







          <div  style={{width:"30%"}}>
            <div>
              <label htmlFor="contact" className="d-flex justify-content-start">
                Contact <span style={{ color: "red" }}>*</span>
              </label>
            </div>
            <div className="d-flex">
              <select
                name="countryCode"
                id="countryCode"
                className="form-control"
                // style={{ width: "8rem", backgroundColor: "#f0e5f3" }}
                style={{
                  height: "1.5rem",
                  width: "8rem",
                  padding: "0.1rem 0",
                  backgroundColor: "#f0e5f3",
                
                  // other styles...
                }}
                value={countryCode}
                onChange={handleCountryCodeChange}
              >
                {countryCodes.map((country) => (
                  <option key={country.code} value={country.code}>
                    {`${country.name} (${country.code})`}
                  </option>
                ))}
              </select>
              <input
                name="contact_name"
                type="text"
                id="contact"
                className={`form-control ${errors["contact_name"]}`}
                placeholder="Contact:"
                value={formData.contact_name}
                onChange={handleContactChange}
                style={{ backgroundColor: "#f0e5f3", 
                // border:"2px solid pink",
                marginLeft: "0.6rem",
                width:"70%"
               }}
                onInput={() => highlightBorder("contact")}
              />
              {errors["contact_name"] && (
                <div style={{ color: "red" }} className="error-message">
                  {errors["contact_name"]}
                </div>
              )}
            </div>
          </div>


        </div>



        <div class="d-flex justify-content-start">
          <h5>Company Address</h5>
        </div>

        <div className="Company-Address">
          <div >
            <div>
              <label htmlFor="Address" class="d-flex justify-content-start">
                {" "}
                Address <span style={{ color: "red" }}>*</span>
              </label>
            </div>
            <input
              name="company_add"
              type="text"
              id="Address"
              className={`form-control ${errors["company_add"]}`}
              placeholder="Address:"
              ref={originAutocompleteRef}
              value={formData.company_add}
              onChange={handleChange}
              style={{ backgroundColor: "#f0e5f3" }}
              onInput={() => highlightBorder("Address")}
            />
            {errors["company_add"] && (
              <div style={{ color: "red" }} className="error-message">
                {errors["company_add"]}
              </div>
            )}
          </div>

          <div className=" d-flex justify-content-between mb-3 " id="first3">
            <div style={{ width: "17rem" }}>
              <div className="d-flex">
                <label htmlFor="state">
                  State <span style={{ color: "red" }}>*</span>
                </label>
              </div>
              <input
                name="state_code"
                type="text"
                id="state"
                className={`form-control ${errors["state_code"]}`}
                placeholder="State:"
                value={formData.state_code}
                onChange={handleChange}
                style={{ backgroundColor: "#f0e5f3" }}
                onInput={() => highlightBorder("state")}
              />
              {errors["state_code"] && (
                <div style={{ color: "red" }} className="error-message">
                  {errors["state_code"]}
                </div>
              )}
            </div>

            <div style={{ width: "17rem" }}>
              <div className="d-flex">
                <label htmlFor="city">
                  City <span style={{ color: "red" }}>*</span>
                </label>
              </div>
              <input
                name="city_code"
                type="text"
                id="city"
                className={`form-control ${errors["city_code"]}`}
                placeholder="City:"
                value={formData.city_code}
                onChange={handleChange}
                style={{ backgroundColor: "#f0e5f3" }}
                onInput={() => highlightBorder("city")}
              />
              {errors["city_code"] && (
                <div style={{ color: "red" }} className="error-message">
                  {errors["city_code"]}
                </div>
              )}
            </div>

            <div style={{ width: "17rem" }}>
              <div className="d-flex ">
                <label htmlFor="zipCode">
                  Zip code <span style={{ color: "red" }}>*</span>
                </label>
              </div>
              <input
                name="pin_code"
                type="text"
                id="zip"
                className={`form-control ${errors["pin_code"]}`}
                placeholder="Zip code:"
                value={formData.pin_code}
                onChange={handleChange}
                style={{ backgroundColor: "#f0e5f3" }}
                onInput={() => highlightBorder("zip")}
              />
              {errors["pin_code"] && (
                <div style={{ color: "red" }} className="error-message">
                  {errors["pin_code"]}
                </div>
              )}
            </div>
          </div>

          <div className="d-flex justify-content-between mb-3 " id="second2">
            <div style={{ width: "17rem"}}>
              <div className="d-flex">
                <label htmlFor="Registration">
                  Company Registration Number{" "}
                  <span style={{ color: "red" }}>*</span>
                </label>
              </div>
              <input
                name="company_registration_no"
                type="text"
                id="Registration"
                style={{  backgroundColor: "#f0e5f3" }}
                className={`form-control ${errors["company_registration_no"]}`}
                placeholder="Company Registration Number:"
                value={formData.company_registration_no}
                onChange={handleCompanyRegistrationChange}
                onInput={() => highlightBorder("Registration")}
              />
              {errors["company_registration_no"] && (
                <div style={{ color: "red" }} className="error-message">
                  {errors["company_registration_no"]}
                </div>
              )}
            </div>

            <div style={{ width: "17rem" }}>
              <div className="d-flex">
                <label htmlFor="Tax">
                  Company Tax Number <span style={{ color: "red" }}>*</span>
                </label>
              </div>
              <input
                name="company_tax_no"
                type="text"
                id="Tax"
                style={{  backgroundColor: "#f0e5f3" }}
                className={`form-control ${errors["company_tax_no"]}`}
                placeholder="Company Tax Number:"
                value={formData.company_tax_no}
                onChange={handleCompanyTaxChange}
                onInput={() => highlightBorder("Tax")}
              />
              {errors["company_tax_no"] && (
                <div style={{ color: "red" }} className="error-message">
                  {errors["company_tax_no"]}
                </div>
              )}
            </div>
          </div>

          <div className="d-flex justify-content-between mb-3" id="third3">
            <div style={{ width: "17rem" }}>
              <div className="d-flex">
                <label htmlFor="Size">Company Size</label>
              </div>
              <input
                name="company_size"
                type="text"
                id="Size"
                style={{ backgroundColor: "#f0e5f3" }}
                className={`form-control ${errors["company_size"] ||
                    integerValidationErrors["company_size"]
                    ? "highlight-red"
                    : ""
                  }`}
                placeholder="Company Size:"
                value={formData.company_size}
                onKeyPress={handleNumericKeyPress}
                onChange={handleChange}
                onInput={() => highlightBorder("Size")}
              />
              {errors["company_size"] && (
                <div style={{ color: "red" }} className="error-message">
                  {errors["company_size"]}
                </div>
              )}
              {integerValidationErrors["company_size"] && (
                <div style={{ color: "red" }} className="error-message">
                  {integerValidationErrors["company_size"]}
                </div>
              )}
            </div>

            <div style={{ width: "17rem" }}>
              <div className="d-flex">
                <label htmlFor="Industry">Industry Type</label>
              </div>

              <select
                name="industry_type_cd"
                id="Industry"
                // style={{ width: "17rem", backgroundColor: "#f0e5f3" }}
                style={{
                  // height: "1.78rem",
                  // height: "1.5rem",
                  width: "17rem",
                  padding: "0.1rem 0 0rem 0.8rem",
                  height: "1.5rem",
                  backgroundColor: "#f0e5f3",
                  
                  fontSize: "0.9rem",
                  // other styles...
                }}
                className={`form-control`}
                onChange={handleChange}
                value={formData.industry_type_cd}
                onInput={() => highlightBorder("Industry")}
              >
                {industryOptions.map((commodityOption, optionIndex) => (
                  <option style={{ fontSize: "1rem" }} key={optionIndex} value={optionIndex + 1}>
                    {commodityOption}
                  </option>
                ))}
              </select>
            </div>

            <div style={{ width: "17rem" }}>
              <div className="d-flex">
                <label htmlFor="Revenue">Annual Revenue</label>
              </div>
              <input
                name="annual_revenue"
                type="text"
                id="Revenue"
                className={`form-control ${errors["annual_revenue"] ||
                    integerValidationErrors["annual_revenue"]
                    ? "highlight-red"
                    : ""
                  }`}
                placeholder="Annual Revenue:"
                value={formData.annual_revenue}
                style={{ backgroundColor: "#f0e5f3" }}
                onKeyPress={handleNumericKeyPress}
                onChange={handleChange}
                onInput={() => highlightBorder("Revenue")}
              />
              {errors["annual_revenue"] && (
                <div style={{ color: "red" }} className="error-message">
                  {errors["annual_revenue"]}
                </div>
              )}
              {integerValidationErrors["annual_revenue"] && (
                <div style={{ color: "red" }} className="error-message">
                  {integerValidationErrors["annual_revenue"]}
                </div>
              )}
            </div>
          </div>

          <div style={{ width: "100%", height: "6rem", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>


            <div style={{ width: "18rem" }}>
              <div className="d-flex">
                <label htmlFor="Industry">Currency</label>
              </div>

              <select
                name="currency_id"
                id="Currency"
                // style={{ width: "17rem", backgroundColor: "#f0e5f3" }}
                style={{
                  // height: "1.78rem",
                  width: "17rem",
                  padding: "0.1rem 0 0rem 0.8rem",
                  backgroundColor: "#f0e5f3",
                  height: "1.5rem",
                  // padding: "0rem 0 0rem 0rem",
                  fontSize: "0.9rem",
                  // other styles...
                }}
                className={`form-control`}
                onChange={handleChange}
                value={formData.currency_id}
                onInput={() => highlightBorder("Currency")}
              >
                {Currency.map((commodityOption, optionIndex) => (
                  <option key={optionIndex} value={optionIndex + 1}>
                    {commodityOption}
                  </option>
                ))}
              </select>
            </div>



            <div style={{ width: "17rem" }}>
              <div className="d-flex">
                <label htmlFor="Industry">Unit</label>
              </div>

              <select
                name="size_id"
                id="Unit"
                // style={{ width: "17rem", backgroundColor: "#f0e5f3" }}
                style={{
                  // height: "1.78rem",
                  
                  // padding: "0.1rem 0",
                  backgroundColor: "#f0e5f3",
                  height: "1.5rem",
                  padding: "0.1rem 0 0rem 0.8rem",
                  fontSize: "0.9rem",




                  // other styles...
                }}
                className={`form-control`}
                onChange={handleChange}
                value={formData.size_id}
                onInput={() => highlightBorder("Unit")}
              >
                {Unit.map((commodityOption, optionIndex) => (
                  <option key={optionIndex} value={optionIndex + 1}>
                    {commodityOption}
                  </option>
                ))}
              </select>
            </div>

          </div>

          <div style={{ width: "18rem" }}>
            <Link
              to="/trucker/truckDetails"
              style={{ width: "18rem", textDecoration: "none" }}
            >
              <button
                name="TruckButton"
                type="button"
                id="Truck-Details"
                className="form-control"
                style={{
                  // border:"solid blue",
                  width: "18rem",
                  backgroundColor: "#f0e5f3",
                  height: "2rem",
                }}
                onClick={handleChange}
              >
                Truck Details <UilArrowUpRight color="#FC6D02" />
              </button>
            </Link>
          </div>
        </div>

    <br />

        <div style={{ display: "flex", justifyContent: "space-evenly" }}>
          <Link to="/trucker/preference" style={{ textDecoration: "none" }}>
            <button
              name="addPre"
              type="button"
              id="addPRE"
              className="form-control"
              style={{
                width: "12rem",
                height: "2rem",
                margin: "auto",
                color: "white",
                backgroundColor: "#8A31A8",
                paddingTop:"0.2rem"
              }}
            >
              + Add a Preference
            </button>
          </Link>

          <input
            type="button"
            value="Update"
            style={{
              width: "12rem",
              height: "2rem",
              color: "white",
              backgroundColor: "#8A31A8",
              border: "none",
              borderRadius: "5px",
            }}
            onClick={handleSubmit}
          />
        </div>
      </form>
    </>
  );
};

export default Aform;
