import React from "react";
import { PayPalButtons } from "@paypal/react-paypal-js";
import axios from "axios"; // Import Axios
import { useNavigate } from "react-router-dom";
import { Row, Col, Tabs, Skeleton, message } from "antd";
export const PaypalPayment = (props) => {
  console.log(props);
  const history = useNavigate();
  console.log(props.newamount);

  const load_id = props.amount.load_id;
  const id = props.id;
  const account_code = props.amount.account_code;
  const serverurl = "http://yzulu.com";

  const createOrder = (data, amount, load_id, account_code, id) => {
    console.log(amount);
    return fetch(`${serverurl}/api/v1/paypal/my-server/create-paypal-order`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        cart: [
          {
            quantity: "9",
            amount: amount,
            load: load_id,
            account: account_code,
            id: id,
          },
        ],
      }),
    }).then((response) => {
      if (response.ok) {
        return response.json().then((order) => {
          console.log("Order ID:", order.orderID); // Log the order ID
          console.log("Amount:", order.amount);
          console.log("Amount:", order.load);
          console.log("Amount:", order.account);
          return order.orderID;
        });
      } else {
        // Handle errors if the response status is not 201
        throw new Error("Failed to create the order");
      }
    });
  };

  const onApprove = (data, amount, load_id, account_code, id) => {
    console.log("onApprove function called", data);
    console.log("onApprove function called", amount);
    // Assuming your server endpoint returns JSON data
    fetch(`${serverurl}/api/v1/paypal/my-server/capture-paypal-order`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        orderID: data.orderID,
        amount: amount,
        load_id: load_id,
        account_code: account_code,
        id: id,
      }),
    })
      .then((response) => {
        return response.json(); // Parse the response as JSON
      })
      .then((details) => {
        console.log("Payment done!", details);

        // Assuming that the response is structured as you provided earlier
        console.log("Capture Response:", details);
        message.success({
          content: "Payment success.!!!",
          className: "custom-class",
          style: {
            marginTop: "2vh",
          },
        });
        // Show an alert with the payer's name if available

        history("/home");
      })
      .catch((error) => {
        console.error("Error capturing order:", error);
      });
  };

  return (
    <>
      <PayPalButtons
        createOrder={(data) =>
          createOrder(data, props.newamount, load_id, account_code, id)
        }
        onApprove={(data) =>
          onApprove(data, props.newamount, load_id, account_code, id)
        }
      />
    </>
  );
};
