import { Row, Col, Skeleton, Tooltip, Button } from "antd";

import { RiUserSearchFill } from "react-icons/ri";
import axios from "axios";
import classes from "../Pages.module.css";
import { useState, useEffect, useContext } from "react";
import DataContext from "../../../Context/dataContext";
import FreightTable from "./Fr";
import { AiFillDelete } from "react-icons/ai";
import "./ModelView.css";
import { UilSearch } from "@iconscout/react-unicons";
import { Link } from "react-router-dom";
import { Modal } from "antd";

const ModelView = (props) => {
  const employeeData = useContext(DataContext);
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState(null);
  const [drows, setDRows] = useState([]);
  console.log(props);

  const handleAccept = (truckerId, loadid) => {
    console.log(truckerId);
    console.log(loadid);
    const trucker = truckerId;
    const loadId = loadid;
    axios
      .post(
        employeeData.URL +
          `/api/v1/load/winning/${parseInt(trucker)}/${loadId}`,
        null, // Use null as the request body if no data needs to be sent
        {
          withCredentials: true,
        }
      )
      .then((response) => {
        console.log("Update successful", response);

        // Close the modal after successful update
        props.onCloseModal();
      })
      .catch((error) => {
        console.log("Update failed", error);
      });
  };

  useEffect(() => {
    setDRows(null);
    setRows(null);
    setColumns([]);

    axios
      .get(employeeData.URL + "/api/v1/load/model-data/" + parseInt(props.id), {
        withCredentials: true,
      })
      .then((response) => {
        console.log(response);
        setColumns((columns) => {
          let newCols = response.data.data.totalloads.fields.map((col) => {
            return {
              field: col.name,
              headerName: col.name
                .split("_")
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(" "),

              width: 150,
            };
          });

          const newNewCols = [
            ...newCols,
            { field: "Bid Posted", headerName: "Bid Posted" },
          ];
          return newNewCols;
        });

        setRows((rows) => {
          let newRows = response.data.data.totalloads.rows.map((row, index) => {
            return {
              id: index + 1,
              ...row,
              "Bid Posted": (
                <Tooltip title="Bid" color="#1777C4">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {/* <Link
                            to={"load-view/" + row["load_id"]}
                            style={{ color: "#1777C4", fontWeight: "bolder" }}
                          > */}
                    <Button
                      onClick={() => handleAccept(row.trucker_id, row.load_id)}
                      style={{
                        width: "80px",
                        backgroundColor: "#8A31A8",
                        color: "#FFF",
                        fontWeight: "bolder",
                        borderRadius: "4px",
                        marginTop: "10px",
                      }}
                      disabled={row.status === "winning"}
                    >
                      Accept
                    </Button>
                    {/* </Link> */}
                  </div>
                </Tooltip>
              ),
            };
          });
          return newRows;
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }, [props.id]);

  return (
    <>
      <form>
       

        <div
          className="table"
          style={{
            display: "inline-block",
            width: "100%",
            // border: "10px solid black",
          }}
        >
          {rows && columns.length > 0 ? (
            <div>
              <FreightTable data={rows} columns={columns} val={true}  />
            </div>
          ) : (
            <div>No matching results found.</div>
          )}
        </div>
      </form>
    </>
  );
};

export default ModelView;
