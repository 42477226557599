
import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import "./Headbox";
import Headbox from "./Headbox";
import "./dash.css";
import { Link } from "react-router-dom";
import Amap from "../MyMap/amap";
import DataContext from "../../../Context/dataContext";
import { Button, Flex } from 'antd';

export default function Dashboard(props) {
  const employeeData = useContext(DataContext);
  console.log(props);
  console.log(employeeData);
  const [check, setCheck] = useState(true);
  const [ndata, setNdata] = useState();
  let P = 1,
    O,
    D,
    PB;

  const [data, setData] = useState([]);
  const [mybill, setmybill] = useState();
  const [fifteen, setfifteen] = useState();
  const [thirty, setthirty] = useState();
  const [sixtey, setsixtey] = useState();
  const [month, setmonth] = useState();


  useEffect(() => {
    axios
      .get(employeeData.URL + "/api/v1/load/dashamount", {
        withCredentials: true,
      })
      .then((response) => {
        // const toCheck = response.data.profile.rows[0];
        console.log(response.data);
        setmybill(response.data.mybill.final_result);
        if(response.data.zerotofifteen.final_result==null){
          setfifteen(response.data.zerotofifteen.total_shipper_dr);
        }else{
          setfifteen(response.data.zerotofifteen.final_result);
        }
        setthirty(response.data.fifteentothirty.final_result);
        setsixtey(response.data.thirtytosixteen.final_result);
        setmonth(response.data.morethantwomonth.final_result);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });

    // pending-bids
    axios
      .get(employeeData.URL + "/api/v1/load/getPrefilledData", {
        withCredentials: true,
      })
      .then((response) => {
        setData(response.data);
        console.log(response);
        D = response.data.Delivered;
        P = response.data.Pending;
        O = response.data.Ongoing;
        PB = response.data.BidsPending;
        console.log("ths is PD " + PB);
        // console.log(data.BidsPending == 0);
        console.log("this is Pending: " + P);
        console.log("this is Delivered: " + D);
        console.log("this is Ongoing: " + O);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });

    axios
      .get(employeeData.URL + "/api/v1/profile/check-profile", {
        withCredentials: true,
      })
      .then((response) => {
        const toCheck = response.data.profile.rows[0];
        console.log(response.data.profile.rows[0]);

        // Define a function to check the object properties asynchronously
        const checkProperties = async (obj) => {
          let midCheck = true;
          for (const key in obj) {
            if (obj.hasOwnProperty(key) && !obj[key]) {
              midCheck = false;
              return false; // If any key is empty, return false
            }
          }
          return midCheck;
        };

        // Use the function and set the result asynchronously
        checkProperties(toCheck)
          .then((result) => {
            if (result === true) {
              console.log(result);
              setCheck(result);
            }
            if (result === false) {
              setCheck(result);
              console.log(result);
              console.log("setCheckValue:" + check);
            }

            console.log("waah bete moj kardi");
          })
          .catch((error) => {
            console.error("abe yarr ab ye kya ho gya");
          });
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  console.log(P, O, D);

  return (
    <>
      {/* style={{border:"solid black 2px"}} */}
      <div id="topbox">
        {data.BidsPending == 0 ? (
          <div className="disabled-link">
            <Headbox data={data.BidsPending} abc="Open Bids" />
          </div>
        ) : (
          <Link className="myLinks" to="/home/pending-bids">
            <Headbox data={data.BidsPending} abc="Open Bids" />
          </Link>
        )}

        {/* <Link className="myLinks" to="/home/pending-bids">
          <Headbox data={data.BidsPending} abc="PENDING BIDS" />
        </Link> */}
        <Link className="myLinks" to="pending-load-view">
          <Headbox data={data.Pending} abc="Scheduled Loads" />{" "}
        </Link>
        <Link className="myLinks" to="Loading">
          <Headbox data={data.Loading} abc="Loading" />{" "}
        </Link>
        <Link className="myLinks" to="tracking">
          <Headbox data={data.Ongoing} abc="Active Shipments" />{" "}
        </Link>
        <Link className="myLinks" to="unloading">
          <Headbox data={data.OnGoingLoads} abc="Unloading" />{" "}
        </Link>
        <Link className="myLinks" to="delivered-loads">
          <Headbox data={data.Delivered} abc="Delivered" />{" "}
        </Link>

      </div>

      <div style={{height:"15rem"}} className="sectionB">
        <div id="firstD" style={{ flexGrow: "10" }}>
          <h3 style={{ flexGrow: "5", marginTop: "2rem" }}>
            You got nothing moving yet!
          </h3>
          <div
            style={{
              borderRadius: "5px",
              flexGrow: "10",
              overflow: "hidden",
              marginRight: "0.3rem",
            }}
          >
            <Amap />
          </div>
        </div>

        <div
          id="secondD"
          style={{
            flexGrow: "2",
            marginBottom: "2rem",
            color: "rgb(182, 126, 225",
            borderRadius:"1rem",
            // border:"4px solid red",
            height:"96%",
            background: "linear-gradient(to Bottom,rgb(153, 79, 179),white)"
          }}
        >
          <h5 style={{ color: "#531967" }}>My Bills</h5>
          <h3 style={{ fontSize:"1.4rem",color: "#8A31A8", marginTop: "0.3rem" }}> Total Amount Payable: {mybill}</h3>
         

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              marginTop: "2rem",
              justifyContent: "space-between",
              height: "1rem",
            }}
          > <Link to="/home/zero-fifteen" style={{ textDecoration: "none",color: "#333",
          cursor: "pointer", }}>
          <h5 style={{ display: "inline-block", fontSize:"small" }}>
                0-15 Days <span className="amt"> {fifteen?fifteen :0}</span>
              </h5>
        </Link>
           
            <Link to="/home/fifteen-thirty"
              style={{
                textDecoration: "none",
                color: "#333",
                cursor: "pointer",
              }}
            >
              <h5 style={{ display: "inline-block", fontSize:"small" }}>
                15-30 Days <span className="amt"> {thirty ? thirty : 0}</span>
              </h5>
            </Link>
            <Link to="/home/thirty-sixty"
              style={{
                textDecoration: "none",
                color: "#333",
                cursor: "pointer",
              }}
            >
              <h5 style={{ display: "inline-block", fontSize:"small" }}>
                30-60 Days <span className="amt"> {sixtey? sixtey:0}</span>
              </h5>
            </Link>
            <Link to="/home/more-months"
              style={{
                textDecoration: "none",
                color: "#333",
                cursor: "pointer",
              }}
            >
              <h5 style={{ display: "inline-block", fontSize:"small" }}>
                More than 60 days <span className="amt">{month?month:0}</span>
              </h5>
            </Link>
          </div>
        </div>
      </div>

      <div style={{marginTop:"1rem"}} className="sectionC">
        {employeeData.check ? (
          <Link className="" to="post-load">
            <Button style={{color:"white", width: '12rem', transition: 'width 0.5s', backgroundColor: '#8A31A8',height: "2rem", }}>
              Post Load
            </Button>

          </Link>
        ) : (
          <Link to="/home/Checking">
            <Button style={{color:"white", width: '12rem', transition: 'width 0.5s', backgroundColor: '#8A31A8' ,height: "2rem",}}>
              Post Load
            </Button>

          </Link>
          // <CheckProfile />
        )}
      </div>
    </>
  );
}
